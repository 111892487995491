'use strict';

var SingleSelectMatch = React.createClass({

    propTypes: {
        values                  :   React.PropTypes.object,
        filterKey               :   React.PropTypes.string,
        filterLabel             :   React.PropTypes.string,
        updateFilters           :   React.PropTypes.func,
        setAnalyticSingleFilter :   React.PropTypes.func,
        selectedEvent           :   React.PropTypes.array,
        setDisplayedFilter      :   React.PropTypes.func,
        clearFilter             :   React.PropTypes.func,
    },

    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
            displayedDropdown: false,
            data: this.props.values,
            selectedFilter : {},
            searchValue:'',
            searchQueryId: this.props.searchQueryId,
            searchQueryName: this.props.searchQueryName,
        }
    },

    componentWillMount() {
        this.searchDebounced = _.debounce(query => {
            this.setState({searchValue:query});
        }, 500);

        this.matchNameSearchDebounced = _.debounce(query => {
            this.props.updateLocationFilter('analyticSearch', query)
            this.props.updateFilters();
        }, 500);

        var data = this.props.values;

        if(this.props.selectedEvent){
            data.map(function(option){
                if(option.id==this.props.selectedEvent){
                    var selectedOption = {
                        matchId:option.id,
                        matchName:option.name,
                        matchDisplayId:option.displayId,
                    };
                    this.setState({
                        selectedFilter:selectedOption,
                        searchQueryId: selectedOption.matchDisplayId,
                        searchQueryName: selectedOption.matchName,
                        data: data
                    });
                }
            }.bind(this));
        }
    },
    componentWillReceiveProps(nextProps){
        if(!nextProps.selectedEvent){
            this.setState({
                selectedFilter: {},
                searchValue: '',
                searchQueryId: nextProps.searchQueryId,
                searchQueryName: nextProps.searchQueryName
            });
        }
        if(nextProps.displayedFilter !== this.props.filterKey){
            this.setState({
                displayedDropdown: false
            });
        }
        if (nextProps.searchQueryName !== this.props.searchQueryName) {
            this.setState({
                searchQueryName: nextProps.searchQueryName
            });
        }
        if (nextProps.searchQueryId !== this.props.searchQueryId) {
            this.setState({
                searchQueryId: nextProps.searchQueryId
            });
        }
        this.setState({
            data: nextProps.values
        });
    },
    handleClick: function(event){
        if(event && (event.target.name === 'matchId' || event.target.name === 'matchName')){
            if(this.state.dropdownOpener === event.target.name){
                this.setState({
                    dropdownOpener: event.target.name,
                    displayedDropdown: this.state.displayedDropdown ? false : true,
                }, () => {
                        this.props.setDisplayedFilter(this.props.filterKey);
                });
            }else {
                this.setState({
                    dropdownOpener: event.target.name,
                    displayedDropdown: true,
                    }, () => {
                        this.props.setDisplayedFilter(this.props.filterKey);
                });
            }
        }else{
            this.setState({
                dropdownOpener: '',
                displayedDropdown: false,
                }, () => {
                    this.props.setDisplayedFilter(this.props.filterKey);
            });
        }
    },

    handleChange:  function(selected){
        event.preventDefault();
        var selectedOption = {
            matchId:selected.id,
            matchName:selected.name,
            matchDisplayId:selected.displayId
        };
        this.setState({
            selectedFilter:selectedOption,
            searchValue: '',
            }, () => {
                this.props.handleInputSearch(selected.name, 'name');
                this.props.handleInputSearch(selected.displayId, 'id');

                this.props.clearFilter('analyticPendingTicketBets');
                this.props.clearFilter('analyticMarketOutcome');
                this.props.clearFilter('analyticMarket');
                this.props.setAnalyticSingleFilter(this.props.filterKey, this.state.selectedFilter.matchId);
                this.handleClick();
        });

    },

    clearAction:function(){
        this.setState({
            selectedFilter: {},
            searchQueryId: '',
            searchQueryName: '',
            searchValue: '',
            }, () => {
                this.props.clearFilter(this.props.filterKey);
                this.props.setAnalyticSingleFilter(this.props.filterKey, '');
                this.handleClick();
        });
        this.props.handleInputSearch('', 'id');
        this.props.handleInputSearch('', 'name');
    },

    setSearchValId: function(event){
        this.props.handleInputSearch(event.target.value, 'id');
        this.matchNameSearchDebounced(event.target.value);
    },

    setSearchValName: function(event){
        console.log('event.target.value', event.target.value);
        this.props.handleInputSearch(event.target.value, 'name');
        this.matchNameSearchDebounced(event.target.value);
    },

    render: function() {
        var data = this.state.data;

        return (
            <div className="sm-filter-row">
                <div className="sm-filters-name-label" >
                    {this.props.filterLabel}
                </div>
                <div className="sm-filters-select" >
                    <div className="sm-filter-match-id"
                        onClick={this.handleClick.bind(this)}>
                        <input
                            name={'matchId'}
                            type={'text'}
                            autoComplete="off"
                            onChange={this.setSearchValId}
                            value={this.state.searchQueryId}
                            className={'react-filter-search'}/>
                    </div>
                    <div className="sm-filter-match-name"
                        onClick={this.handleClick.bind(this)}>
                        <input
                            name={'matchName'}
                            type={'text'}
                            autoComplete="off"
                            onChange={this.setSearchValName}
                            value={this.state.searchQueryName}
                            className={'react-filter-search'} />
                        <span className="arrow-filter"><i className="n-i n-i-arrow-down-b sm-filters-arrow"></i></span>
                    </div>
                </div>
                {
                    this.state.displayedDropdown && (
                        <div className="sm-filters-content sm-filters-content-wrap">
                            {
                                data.length > 0 && (
                                    <div className="filter-options">
                                        {
                                            !_.isEmpty(this.state.selectedFilter) &&
                                            <span onClick={this.clearAction}
                                                className="clear-filter-option">
                                                {this.translate('general.clear')}
                                            </span>
                                        }
                                    </div>
                                )
                            }
                            <div className="sm-filters-content-body">
                                {
                                    data.length > 0 && data.map(function (option) {
                                        return (
                                            <div>
                                                <label className="single-select-radio" htmlFor={"filter-" + option.displayId}>
                                                    <input type="radio"
                                                        id={"filter-" + option.displayId}
                                                        className="normal-radio-btn"
                                                        onChange={this.handleChange.bind(this, option)}
                                                        checked={this.state.selectedFilter.matchDisplayId === option.displayId}
                                                    /><i></i>
                                                </label>
                                                <label className="sm-filter-value-label" htmlFor={"filter-" + option.displayId}>{option.name}</label>
                                            </div>
                                        )
                                    }.bind(this))
                                }
                                {
                                    data.length === 0 &&
                                    <div>
                                        <label className="sm-filter-no-data">{this.translate('general.no_data_for_filters')}</label>
                                    </div>
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
});

var SingleSelectMarket = React.createClass({

    propTypes: {
        values                  :   React.PropTypes.object,
        filterKey               :   React.PropTypes.string,
        displayedFilter         :   React.PropTypes.string,
        filterLabel             :   React.PropTypes.string,
        updateFilters           :   React.PropTypes.func,
        setAnalyticSingleFilter :   React.PropTypes.func,
        selectedMarket          :   React.PropTypes.array,
        setDisplayedFilter      :   React.PropTypes.func,
        clearFilter             :   React.PropTypes.func,
    },

    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
            displayedDropdown: false,
            data: this.props.values,
            selectedFilter : {},
            searchValue:'',
            searchQuery:'',
        }
    },

    componentWillMount() {
        this.searchDebounced = _.debounce(query => {
            this.setState({searchValue:query});
        }, 500);

        var data = this.props.values;

        if(this.props.selectedMarket){
            data.map(function(option){
                if(option.id==this.props.selectedMarket[0]){
                    var selectedOption = {
                        marketId:option.id,
                        marketName:option.name,
                    };
                    this.setState({
                        selectedFilter:selectedOption,
                        searchQuery: selectedOption.marketName,
                    });
                }
            }.bind(this));
        }
    },
    componentWillReceiveProps(nextProps){
        if(!nextProps.selectedMarket){
            this.setState({
                selectedFilter: {},
                searchValue: '',
                searchQuery:'',
            });
        }
        if(nextProps.displayedFilter !== this.props.filterKey){
            this.setState({
                displayedDropdown: false,
            });
        }
        if(!_.isEqual(this.state.data, nextProps.values)){
            this.setState({
                data:nextProps.values,
            });
        }
    },
    handleClick: function(){
        this.setState({
            displayedDropdown: !this.state.displayedDropdown,
            }, () => {
                this.props.setDisplayedFilter(this.props.filterKey);
        });
    },

    handleChange:  function(selected){
        var selectedOption = {
            marketId:selected.id,
            marketName:selected.name,
        };
        this.setState({
            selectedFilter:selectedOption,
            searchQuery: selectedOption.marketName,
            searchValue: '',
            }, () => {
                this.props.clearFilter('analyticMarketOutcome');
                this.props.clearFilter('analyticPendingTicketBets');
                this.props.setAnalyticSingleFilter(this.props.filterKey, this.state.selectedFilter.marketId);
                this.handleClick();
        });
    },

    clearAction:function(){
        this.setState({
            selectedFilter: {},
            searchQuery: '',
            searchValue: '',
            }, () => {
                this.props.clearFilter(this.props.filterKey);
                this.props.clearFilter('analyticMarketOutcome');
                this.props.clearFilter('analyticPendingTicketBets');
                this.props.setAnalyticSingleFilter(this.props.filterKey, '');
                this.handleClick();
        });
    },

    setSearchVal: function(event){
        this.setState({
            searchQuery:event.target.value,
            },
            this.searchDebounced(event.target.value)
        );
    },

    render: function () {
        var data = this.state.data;

        return (
            <div className="sm-filter-row">
                <div className="sm-filters-name-label" >
                    {this.props.filterLabel}
                </div>
                <div className="sm-filters-select" >
                    <div className="sm-filter-market-name" onClick={this.handleClick.bind(this)}>
                        <input
                            name={'market-search'}
                            type={'text'}
                            onChange={this.setSearchVal}
                            className={'react-filter-search'}
                            value={this.state.searchQuery}
                        />
                        {this.state.selectedFilter.matchName}
                        <span className="arrow-filter"><i className="n-i n-i-arrow-down-b sm-filters-arrow"></i></span>
                    </div>
                </div>
                {
                    this.state.displayedDropdown && (
                        <div className="sm-filters-content sm-filters-content-wrap">
                            {
                                data.length > 0 && (
                                    <div className="filter-options">
                                        {
                                            !_.isEmpty(this.state.selectedFilter) &&
                                            <span className="clear-filter-option" onClick={this.clearAction}>
                                                {this.translate('general.clear')}
                                            </span>
                                        }
                                    </div>
                                )
                            }
                            <div className="sm-filters-content-body">
                                {
                                    data.length > 0 && (data.map(function (option) {
                                        if (option.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1) {
                                            return (
                                                <div>
                                                    <label className="single-select-radio" htmlFor={"filter-" + option.id}>
                                                        <input type="radio"
                                                            id={"filter-" + option.id}
                                                            className="normal-radio-btn"
                                                            onChange={this.handleChange.bind(this, option)}
                                                            checked={this.state.selectedFilter.marketId === option.id}
                                                        /><i></i>
                                                    </label>
                                                    <label className="sm-filter-value-label"
                                                        htmlFor={"filter-" + option.id}>{option.name}</label>
                                                </div>
                                            )
                                        }
                                    }.bind(this)))
                                }
                                {
                                    data.length === 0 &&
                                    <div>
                                        <label className="sm-filter-no-data">{this.translate('general.no_data_for_filters')}</label>
                                    </div>
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
});

var MarketOutcomesToggle = React.createClass({

    propTypes: {
        values                  :   React.PropTypes.object,
        filterKey               :   React.PropTypes.string,
        filterLabel             :   React.PropTypes.string,
        selectedMarketOutcomes  :   React.PropTypes.array,
        setAnalyticMultiFilter  :   React.PropTypes.func,
    },

    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
            data: this.props.values || [],
            selectedValues : this.props.selectedMarketOutcomes || [],
        }
    },

    componentWillReceiveProps(nextProps){
        if(!_.isEqual(this.state.data, nextProps.values)){
            this.setState({data:nextProps.values});
        }
        this.setState({selectedValues: nextProps.selectedMarketOutcomes});
    },

    handleToggleChange: function(id){
        var selectedValues = this.state.selectedValues;
        if(selectedValues.indexOf(id)>-1){
            selectedValues=_.without(selectedValues,id);
        }else{
            selectedValues.push(id);
        }

        this.setState({
            selectedValues:selectedValues
            },  ()  =>  {
                this.props.setAnalyticMultiFilter(this.props.filterKey, this.state.selectedValues );
        });
    },

    render: function() {

        return(
            <div className="sm-filter-row">
                <div className="sm-filters-name-label" >
                    {this.props.filterLabel}
                </div>
                {
                    this.state.data.length>0 &&
                    <div className="sm-filters-toggles" >
                        {
                            this.state.data.map(function(outcome){
                                return (
                                    <div className="sm-filter-toggle-outcome">
                                        <label for={"check-active-"+outcome.id} className="checkbox-toggle-btn">
                                            <label className="outcome-name-label">{outcome.name}</label>
                                            <input  type="checkbox"
                                                    id={"check-active-"+outcome.id}
                                                    checked={this.state.selectedValues.indexOf(outcome.id)>-1}
                                                    onClick={this.handleToggleChange.bind(null,outcome.id)}/><i></i>
                                        </label>
                                    </div>)
                            }.bind(this))
                        }
                    </div>
                }
            </div>
        )
    }
});
var PendingTicketsInput = React.createClass({

    propTypes: {
        filterKey               :   React.PropTypes.string,
        filterLabel             :   React.PropTypes.string,
        selectedPendingBets     :   React.PropTypes.string,
        updateLocationFilter    :   React.PropTypes.func,
        toggleFindButton        :   React.PropTypes.func,
    },

    mixins: [TranslateMixin],

    getInitialState: function () {
        return({
            value: typeof this.props.selectedPendingBets[0] !== 'undefined' ? this.props.selectedPendingBets[0] : '',
            valid: null,
        })
    },

    componentWillReceiveProps(nextProps) {
        this.setState({
            value : typeof nextProps.selectedPendingBets[0] !== 'undefined' ? nextProps.selectedPendingBets[0] : '',
            valid: null,
        });
    },

    componentWillMount() {
        this.setValueDebounced = _.debounce(query => {
            this.props.updateLocationFilter(this.props.filterKey,query);
        }, 500);
    },

    handleChange: function(event){
        if(event.target.value>=1 || event.target.value===''){
            this.setState({
                value:event.target.value,
                valid:true,
                },
                this.setValueDebounced(event.target.value)
            );
        }else{
            this.setState({
                value:event.target.value,
                valid:false,
                }
            );
        }
    },

    render: function() {

        return(
            <div className="sm-filter-row">
                <div className="sm-filters-name-label" >
                    {this.props.filterLabel}
                </div>
                <div className="sm-filters-select" >
                    <input type={'number'}
                        className={['react-filter-search pending-ticket-bets', this.state.valid===false ? 'invalid' : 'valid'].join(' ')}
                        onChange={this.handleChange}
                        value={this.state.value}
                        min="1"
                    />
                    { (!this.state.valid && this.state.valid!=null) &&
                        <span >{ this.props.filterLabel+ ' ' + this.translate('general.must_be_greater_than') + ' 0'}</span>
                    }
                </div>
            </div>
        )}
});

var AuthorizedToggle = React.createClass({

    //Implementation waiting for backend API to be fully supported

    render: function() {

        return(
            <div className="sm-filter-row">
                <div className="sm-filters-name-label" >
                    {this.props.filterLabel}
                </div>
                <div className="sm-filters-select" >
                    <label for={"check-active-authorized"} className="checkbox-toggle-btn">
                        <input  type="checkbox"
                                id={"check-active-authorized"}
                                /><i></i>
                    </label>
                </div>
            </div>
        )}

});

var AnalyticsFilter = React.createClass({

    propTypes: {
        values                    :   React.PropTypes.object,
        updateFilters             :   React.PropTypes.func,
        closeFilters              :   React.PropTypes.func,
        setAnalyticSingleFilter   :   React.PropTypes.func,
        selectedAnalytics         :   React.PropTypes.array,
        setMultiFilter            :   React.PropTypes.func,
        updateLocationFilter      :   React.PropTypes.func
    },

    mixins: [TranslateMixin],


    getInitialState: function () {
        var selectedEvent = this.props.selectedAnalytics && this.props.selectedAnalytics.analyticEvent;
        var searchQueryId = '';
        var searchQueryName = '';

        if (selectedEvent && selectedEvent[0]) {
            var foundEvent = this.props.values.eventFilter.find(function(event) {
                return event.id === selectedEvent[0];
            });

            if (foundEvent) {
                searchQueryId = foundEvent.displayId;
                searchQueryName = foundEvent.name;
            }
        }
        return({
            selectedFilters: this.props.selectedAnalytics,
            values: this.props.values,
            searchQueryId: searchQueryId,
            searchQueryName: searchQueryName
        })
    },

    componentWillMount: function() {
        this.searchDebounced = _.debounce(query => {
            this.setState({ searchValue:query} );
        }, 500);
    },

    componentWillReceiveProps: function(nextProps){
        this.setState({
            selectedFilters: nextProps.selectedAnalytics,
            values: nextProps.values
        });
    },

    setDisplayedFilter: function(filter){
        this.setState({
            displayedFilter:filter
        });
    },

    handleInputSearch(value, type) {
        if (type === 'id') {
            this.setState({
                searchQueryId: value,
                searchQuery: value
                },
                this.searchDebounced(value)
            );
            return;
        }

        this.setState({
            searchQueryName: value,
            searchQuery: value
            },
            this.searchDebounced(value)
        );
    },

    handleClearAllButtonClick: function () {
        this.props.updateLocationFilter('analyticEvent', [], true);
        this.props.updateLocationFilter('analyticMarket', [], true);
        this.props.updateLocationFilter('analyticMarketOutcome', [], true);
        this.props.updateLocationFilter('analyticPendingTicketBets', [], true);
        this.props.updateLocationFilter('analyticSearch', [], true);

        var selectedFilters=this.state.selectedFilters;
        selectedFilters.analyticEvent=null;
        selectedFilters.analyticMarket=null;
        selectedFilters.analyticMarketOutcome=[];
        selectedFilters.analyticPendingTicketBets=[];
        this.setState({
            selectedFilters:selectedFilters,
            searchQueryId: '',
            searchQueryName: '',
            }, () => {
                this.props.updateFilters();
        });
    },

    handleClearFilter: function (filterKey) {
        this.props.updateLocationFilter(filterKey, [], true);
        var selectedFilters=this.state.selectedFilters;
        selectedFilters[filterKey]=null;
        this.setState({
            selectedFilters:selectedFilters,
        });
    },

    render: function render() {
        return (
            <div>
                <div className='clearfix'>
                    <SingleSelectMatch values={this.state.values.eventFilter}
                        selectedEvent={this.state.selectedFilters.analyticEvent}
                        searchQueryId={this.state.searchQueryId}
                        searchQueryName={this.state.searchQueryName}
                        setAnalyticSingleFilter={this.props.setAnalyticSingleFilter}
                        updateLocationFilter={this.props.updateLocationFilter}
                        handleInputSearch={this.handleInputSearch}
                        filterKey="analyticEvent"
                        displayedFilter={this.state.displayedFilter}
                        setDisplayedFilter={this.setDisplayedFilter}
                        updateFilters={this.props.updateFilters}
                        filterLabel={this.translate('general.match_id') + '/' + this.translate('general.match_name')}
                        clearFilter={this.handleClearFilter}>
                    </SingleSelectMatch>

                    <SingleSelectMarket values={this.props.values.marketFilter}
                        selectedMarket={this.state.selectedFilters.analyticMarket}
                        setAnalyticSingleFilter={this.props.setAnalyticSingleFilter}
                        filterKey="analyticMarket"
                        displayedFilter={this.state.displayedFilter}
                        setDisplayedFilter={this.setDisplayedFilter}
                        updateFilters={this.props.updateFilters}
                        filterLabel={this.translate('general.market')}
                        clearFilter={this.handleClearFilter}>
                    </SingleSelectMarket>

                    <MarketOutcomesToggle values={this.props.values.marketOutcomeFilter}
                        selectedMarketOutcomes={this.state.selectedFilters.analyticMarketOutcome || []}
                        filterKey="analyticMarketOutcome"
                        filterLabel={this.translate('general.outcomes')}
                        setAnalyticMultiFilter={this.props.setAnalyticMultiFilter}>
                    </MarketOutcomesToggle>

                    <PendingTicketsInput filterKey="analyticPendingTicketBets"
                        filterLabel={this.translate('general.pending_bets')}
                        updateLocationFilter={this.props.updateLocationFilter}
                        selectedPendingBets={this.state.selectedFilters.analyticPendingTicketBets || ''}
                        toggleFindButton={this.toggleFindButton}>
                    </PendingTicketsInput>

                </div>
                <div className='analytic-filter-actionbar'>
                    <button className='btn btn-default'
                        onClick={this.handleClearAllButtonClick}>
                        {this.translate('general.filter_reset')}</button>
                    <button className='btn btn-primary'
                        onClick={this.props.updateFilters}>
                        {this.translate('general.find')}</button>
                </div>
            </div>
        )
    }
});
;
'use strict';

/**
 * Date picker Time inputs
 * Handle change in inputs for hours and minutes value
 */
var DateFilterTimeContent = React.createClass({

    propTypes: {
        setTimeValues: React.PropTypes.func,
        hoursValue: React.PropTypes.string,
        minutesValue: React.PropTypes.string
    },
    // RegExp to match 0-2 numbers
    validateNumbersInput: new RegExp('^[0-9]{0,2}$'),

   getInitialState: function getInitialState () {
       return ({
           hoursValue: this.props.hoursValue,
           minutesValue: this.props.minutesValue
       })
   },

    componentWillReceiveProps: function (newProps) {
       this.setState({
           hoursValue: newProps.hoursValue,
           minutesValue: newProps.minutesValue
       })
    },

   // Set focus to input on click
   focusInput: function focusInput (e) {
        e.stopPropagation();
        e.target.select();
   },

   //update the input field (state) with missing numbers on blur event (e.g. if input is empty set it to '00',if input is 1-digit add '0' before)
   blurInput: function blurInput(evt, handler) {

        var inputValue = evt.target.value,
        stateValues;

        if (inputValue.length === 0) {
            inputValue = '00';
        } else if (inputValue.length === 1) {
            inputValue = '0' + inputValue;
        }
        stateValues = {
            hoursValue: handler === 'handleHours' ? inputValue : this.state.hoursValue,
            minutesValue: handler === 'handleMinutes' ? inputValue : this.state.minutesValue
        };

        handler === 'handleHours' ? this.props.setTimeValues(inputValue, this.state.minutesValue) : this.props.setTimeValues(this.state.hoursValue, inputValue);

        this.setState(stateValues);

   },


   // Hours time input handler
   handleHours: function handleHours (evt) {
       var inputValue = evt.target.value;

        // First do a RegExp check to validate the input value (matches numbers [0-2 chars])
        if (this.validateNumbersInput.exec(inputValue) === null) return;
        // Hours range restriction
        if (parseInt(inputValue) < 0 || parseInt(inputValue) > 23) return;

        this.setState({
            hoursValue: inputValue,
            minutesValue: this.state.minutesValue
        })

   },

   // Minutes time input handler
   handleMinutes: function handleMinutes (evt) {
       var inputValue = evt.target.value;

       // First do a RegExp check to validate the input value (matches numbers [0-2 chars])
       if (this.validateNumbersInput.exec(inputValue) === null) return;
       // Hours range restriction
       if ((parseInt(inputValue) < 0) || (parseInt(inputValue) > 59)) return;

       this.setState({
           hoursValue: this.state.hoursValue,
           minutesValue: inputValue
       })
   },

    handleKeyDown: function (e) {
        e.stopPropagation();
    },


   render: function render () {
       return (
            <div className='rc-date-filter-time-wrapper'>
                <i className="n-i n-i-clock"></i>
                <input type="text" className="rc-date-filter-time-value" value={this.state.hoursValue} onChange={this.handleHours} onClick={this.focusInput} onBlur={(evt) => {this.blurInput(evt, 'handleHours')}} onKeyDown={this.handleKeyDown}/>
                <input type="text" className="rc-date-filter-time-value" value={this.state.minutesValue} onChange={this.handleMinutes} onClick={this.focusInput} onBlur={(evt) => {this.blurInput(evt, 'handleMinutes')}} onKeyDown={this.handleKeyDown}/>
            </div>
       )
   }
});


/**
 * Date filter content
 * Filter input with external @DayPicker component
 * Handle changes in date picker and trigger main component @DateFilter
 */
var DateFilterContent = React.createClass({

    propTypes: {
        updateFilters: React.PropTypes.func,
        serverTime: React.PropTypes.object,
        filterKey: React.PropTypes.string,
        hoursValue: React.PropTypes.string,
        minutesValue: React.PropTypes.string,
        type: React.PropTypes.string,
        locale: React.PropTypes.string
    },
    // Input value for hours from @DateFilterTimeContent
    hoursValue: null,
    // Input value for minutes from @DateFilterTimeContent
    minutesValue: null,

    getInitialState: function getInitialState () {
        // Set local time values to provided one on first load
        this.hoursValue = this.props.hoursValue;
        this.minutesValue = this.props.minutesValue;

        return ({
            showDatePicker: false,
            dateValue: this.props.dateValue,
            hoursValue: this.props.hoursValue,
            minutesValue: this.props.minutesValue
        })
    },


    componentWillReceiveProps: function (newProps) {
        this.setState({
            dateValue: newProps.dateValue,
            hoursValue: newProps.hoursValue,
            minutesValue: newProps.minutesValue
        })
    },

    handleGlobalClick: function handleGlobalClick (e) {
        var domNode = ReactDOM.findDOMNode(this);

        if (this.state.showDatePicker && !domNode.contains(e.target)){
            this.setState({showDatePicker: false});
        }
    },

    componentWillMount: function () {
        document.addEventListener('click', this.handleGlobalClick, false);
    },

    componentWillUnmount: function componentWillUnmount () {
        document.removeEventListener('click', this.handleGlobalClick, false);
    },

    // Selected value is ISO Date object. Format it to string to display it
    formatDateToString: function formatDateToString (date) {
        return date ? $.format.date(date, this.props.dateFormat) : '';
    },

    // Set new date value if date is changed in date picker calendar
    handleDatePickerChange: function handleDatePickerChange (evt, date) {

        var seconds = this.props.type === 'start' ? '00' : '59';
        var minutes = this.props.type === 'start' ? '00' : '59';
        var hours   = this.props.type === 'start' ? '00' : '23';
        this.props.setDates(this.props.filterKey, date, this.hoursValue, this.minutesValue, seconds);

        this.setState({
            showDatePicker: false,
            dateValue: date,
            hoursValue: this.state.hoursValue !== null ? this.state.hoursValue : hours,
            minutesValue: this.state.minutesValue !== null ? this.state.minutesValue : minutes
        });

    },

    // Display date picker wrapper
    showDatePicker: function showDatePicker () {
        if(!this.state.dateValue) {
            this.hoursValue = this.props.type === 'start'? '00': '23';
            this.minutesValue = this.props.type === 'start'? '00': '59';
        }

        this.setState({
            showDatePicker: !this.state.showDatePicker,
            dateValue: this.state.dateValue || new Date(this.props.serverTime.currentServerDate)
        })
    },

    // Set hours and minutes values to local properties
    setTimeValues: function setTimeValue (hoursValue, minutesValue) {
        this.hoursValue = hoursValue;
        this.minutesValue = minutesValue;
        var seconds = this.props.type === 'start' ? '00' : '59';
        // Set date value to main component
        this.props.setDates(this.props.filterKey, this.state.dateValue, hoursValue, minutesValue, seconds);
    },

    stopClick: function stopClick (e) {
        e.stopPropagation();
    },

    render: function render() {
        var dateValue = this.state.dateValue,
            props = this.props,
            self = this;

        return (
                <div className="rc-date-filter-wrapper">
                    <div className={props.disableTime === 'yes' ? 'rc-filter-date-value no-time' : 'rc-filter-date-value'} onClick={this.showDatePicker}>
                        <input type="text" className="rc-date-filter-time-value" value={this.formatDateToString(dateValue)} onChange={this.handleDateInputChange} />
                        <i className="n-i n-i-calendar"></i>
                        {function() {
                            if (!this.state.showDatePicker) return null;

                        return <div className="rc-date-picker-wrapper" onClick={this.stopClick}>
                            {function () {
                                return React.createElement(DayPicker, {
                                    onDayClick: this.handleDatePickerChange,
                                    initialMonth: dateValue,
                                    enableOutsideDays: true,
                                    modifiers: {
                                        selected: function selected(day) {
                                            return DayPicker.DateUtils.isSameDay(dateValue, day);
                                        }
                                    },
                                    localeUtils: MomentLocaleUtils,
                                    locale: this.props.locale
                                })
                            }.bind(this)()}
                        </div>
                    }.bind(this)()}
                </div>

                {function() {
                    if (props.disableTime !== 'yes') {
                        return <DateFilterTimeContent setTimeValues={this.setTimeValues} hoursValue={this.state.hoursValue} minutesValue={this.state.minutesValue} />
                    }
                }.bind(this)()}
            </div>
        )
    }
});

// Included since MomentLocaleUtils is not included in react-day-picker npm package (needs 2.17.0)
var MomentLocaleUtils = {
    formatDay: function formatDay(day, locale) {
        return moment(day).locale(locale || 'en').format('ddd ll');
    },

    formatMonthTitle: function formatMonthTitle(date, locale) {
        return moment(date).locale(locale || 'en').format('MMMM YYYY');
    },
    formatWeekdayShort: function formatWeekdayShort(day, locale) {
        return moment().locale(locale || 'en')._locale.weekdaysMin()[day];
    },
    formatWeekdayLong: function formatWeekdayLong(day, locale) {
        return moment().locale(locale || 'en')._locale.weekdays()[day];
    },
    getFirstDayOfWeek: function getFirstDayOfWeek(locale) {
        var localeData = moment.localeData(locale || 'en');
        return localeData.firstDayOfWeek();
    },
    getMonths: function getMonths(locale) {
        var months = [];
        var i = 0;
        while (i < 12) {
            months.push(moment().locale(locale).month(i).format('MMMM'));
            i += 1;
        }
        return months;
    }
};

/**
 * Header section in date picker
 * Displays current time
 * Handles action for setting current date
 */
var DateFilterHeaderContent = React.createClass({
    mixins: [TranslateMixin],

    handleClick: function handleTodayLableClick() {
        var today = new Date(this.props.serverTime.currentServerDate);
        this.props.setPredefinedDate(today, today);
    },

    render: function render() {
        var today = $.format.date(this.props.serverTime.currentServerDate, this.props.dateFormat);

        return (
            <div>
                <div className='rc-date-filter-icon'>
                    <i className='n-i n-i-arrow-right-a'></i>
                </div>
                <div className='rc-date-filter-time'>
                    <p> {today} </p>
                    <p role="button" onClick={this.handleClick}> {this.translate('general.today')} </p>
                </div>
            </div>
        )
    }
});


/**
 * Footer section in date picker
 * Sets one of predefined date ranges
 */
var DateFilterFooterContent = React.createClass({

    propTypes: {
        serverTime: React.PropTypes.object,
        filterData: React.PropTypes.object
    },

    mixins: [TranslateMixin],

    getInitialState: function getInitialState () {
        var selectedValue = this.getPredefinedValueFromRange(this.props.filterData);

        return ({selectValue: selectedValue})
    },

    componentWillReceiveProps: function (nextProps) {
        this.getPredefinedValueFromRange(nextProps.filterData);
    },

    getPredefinedValueFromRange: function (dateFilterState) {
        if (!(dateFilterState.startDate && dateFilterState.endDate)) return null;

        var startDate = moment(dateFilterState.startDate);
        var endDate = moment(dateFilterState.endDate);
        var selected = null;

        var dates = {
            today: {
                start: moment(this.props.serverTime.currentServerDate).hour(0).minutes(0).seconds(0),
                end: moment(this.props.serverTime.currentServerDate).hour(23).minutes(59).seconds(59),
                key: 'currDay'
            },
            yesterday: {
                start: moment(this.props.serverTime.currentServerDate).subtract(1, 'day').hour(0).minutes(0).seconds(0),
                end: moment(this.props.serverTime.currentServerDate).subtract(1, 'day').hour(23).minutes(59).seconds(59),
                key: 'prevDay'
            },
            currentWeek: {
                start: moment(this.props.serverTime.currentWeekStart).hour(0).minutes(0).seconds(0),
                end: moment(this.props.serverTime.currentServerDate).hour(23).minutes(59).seconds(59),
                key: 'currWeek'
            },
            previousWeek: {
                start: moment(this.props.serverTime.previousWeekStart).hour(0).minutes(0).seconds(0),
                end: moment(this.props.serverTime.previousWeekStart).add(6, 'day').hour(23).minutes(59).seconds(59),
                key: 'prevWeek'
            },
            currentMonth: {
                start: moment(this.props.serverTime.currentServerDate).date(1).hour(0).minutes(0).seconds(0),
                end: moment(this.props.serverTime.currentServerDate).hour(23).minutes(59).seconds(59),
                key: 'currMonth'
            },
            previousMonth: {
                start: moment(this.props.serverTime.currentServerDate).subtract(1, 'month').date(1).hour(0).minutes(0).seconds(0),
                end: moment(this.props.serverTime.currentServerDate).date(0).hour(23).minutes(59).seconds(59),
                key: 'prevMonth'

            }
        };

        _.each(dates, function (date) {
            if (startDate.isSame(date.start) && endDate.isSame(date.end))
                selected = date.key;
        });

        return selected;
    },

    setCurrentDay: function setCurrentDate () {
        var today = new Date(this.props.serverTime.currentServerDate);
        this.props.setPredefinedDate(today, today);
    },

    setPreviousDay: function setPreviousDay () {
        var d = new Date(this.props.serverTime.currentServerDate),
            startDate = new Date(d.setDate(d.getDate() - 1));

        this.props.setPredefinedDate(startDate, startDate);
    },

    setCurrentWeek: function setCurrentWeek () {
        var startDate = new Date(this.props.serverTime.currentWeekStart),
            endDate = new Date(this.props.serverTime.currentServerDate);

        this.props.setPredefinedDate(startDate, endDate);
    },

    setPreviousWeek: function setPreviousWeek () {
        var d = new Date(this.props.serverTime.previousWeekStart),
            startDate = new Date(this.props.serverTime.previousWeekStart),
            endDate = new Date(d.setDate(d.getDate() + 6));

        this.props.setPredefinedDate(startDate, endDate);
    },

    setCurrentMonth: function setCurrentMonth () {
        var startDate = new Date(new Date(this.props.serverTime.currentServerDate).setDate(1)),
            endDate = new Date(this.props.serverTime.currentServerDate);

        this.props.setPredefinedDate(startDate, endDate);
    },

    setPreviousMonth: function setPreviousMonth () {
        var d = new Date(new Date(this.props.serverTime.currentServerDate).setDate(0)),
            startDate = new Date(d.setDate(1)),
            endDate = new Date(new Date(this.props.serverTime.currentServerDate).setDate(0));

        this.props.setPredefinedDate(startDate, endDate);
    },

    handleSelectChange: function handleSelectChange (evt) {
        switch (evt.target.value) {
            case 'currDay':     this.setCurrentDay();break;
            case 'prevDay':     this.setPreviousDay();break;
            case 'currWeek':    this.setCurrentWeek();break;
            case 'prevWeek':    this.setPreviousWeek();break;
            case 'currMonth':   this.setCurrentMonth();break;
            case 'prevMonth':   this.setPreviousMonth();break;
            default: console.log('Exit'); break;
        }
        this.setState({selectValue: evt.target.value})
    },

    render: function render () {

        return (
            <div className='rc-date-filter-select'>
                <div className='rc-date-filter-separator'> {this.translate('general.or_choose')} </div>
                <label className='n-i custom-select'>
                    <select className='form-control' value={this.state.selectValue} onChange={this.handleSelectChange} >
                        <option value={null}> {this.translate('general.predefined_ranges')} </option>
                        <option value={'currDay'}> {this.translate('general.current_day')} </option>
                        <option value={'prevDay'}> {this.translate('general.previous_day')} </option>
                        <option value={'currWeek'}> {this.translate('general.current_week')} </option>
                        <option value={'prevWeek'}> {this.translate('general.previous_week')} </option>
                        <option value={'currMonth'}> {this.translate('general.current_month')} </option>
                        <option value={'prevMonth'}> {this.translate('general.previous_month')} </option>
                    </select>
                </label>
            </div>
        )
    }
});


var DateFilter = React.createClass({

    propTypes: {
        dateFilterData:     React.PropTypes.object,
        filterKey:          React.PropTypes.string,
        serverTime:         React.PropTypes.object,
        dateFormat:         React.PropTypes.string,
        updateLocationFilter: React.PropTypes.func,
        updateFilters:      React.PropTypes.func,
        locale:             React.PropTypes.string
    },

    mixins: [TranslateMixin],


    getInitialState: function () {
        var selected = this.props.dateFilterData.selected;

        return({
            startDate:      selected.startDate,
            endDate:        selected.endDate,
            startHours:     selected.startHours,
            startMinutes:   selected.startMinutes,
            endHours:       selected.endHours,
            endMinutes:     selected.endMinutes
        })
    },

    componentWillUnmount: function () {
        for (var dateKey in this.dates) {
            delete this.dates[dateKey];
        }
    },

    // All date filters
    dates: {},

    // Set changes on every date change (date and time)
    setDates: function setDates (key, date, hours, minutes, seconds) {
        hours = hours || '00';
        minutes = minutes || '00';
        seconds = seconds || '00';
        date.setHours(parseInt(hours));
        date.setMinutes(parseInt(minutes));
        date.setSeconds(parseInt(seconds));
        this.dates[key] = $.format.date(date, 'yyyy-MM-dd*HH:mm:ss');
    },


    setPredefinedDate: function setPredefinedDate (startDate, endDate) {
        var startKey = this.props.dateFilterData.startKey,
            endKey = this.props.dateFilterData.endKey;
        this.setDates(startKey, startDate, '00', '00', '00');
        this.setDates(endKey, endDate, '23', '59', '59');

        this.setState({
            startDate:      startDate,
            endDate:        endDate,
            startHours:     '00',
            startMinutes:   '00',
            endHours:       '23',
            endMinutes:     '59'
        });

    },

    // Save currently selection in date picker
    handleSaveButtonClick: function handleSaveButtonClick (evt) {
        evt.stopPropagation();
        // Add date values to url
        for (var i in this.dates) {
            this.props.updateLocationFilter(i, this.dates[i]);
        }

        this.props.updateLocationFilter('page', '1');

        // Use url filters and reload
        this.props.updateFilters();
        this.props.closeFilter();
    },

    // Close date picker
    handleCancelButtonClick: function (evt) {
        evt.stopPropagation();
        this.props.closeFilter();
    },

    handleClearButtonClick: function () {
        var data = this.props.dateFilterData,
            startKey = data.startKey,
            endKey = data.endKey;

        for (var dateKey in this.dates) {
            delete this.dates[dateKey];
        }

        this.props.updateLocationFilter(startKey, [], false);
        this.props.updateLocationFilter(endKey, [], false);

        this.setState({
            startDate:      '',
            endDate:        '',
            startHours:     '',
            startMinutes:   '',
            endHours:       '',
            endMinutes:     ''
        });
    },

    render: function render() {
        var data = this.props.dateFilterData,
            startKey = data.startKey,
            endKey = data.endKey,
            clearButton = this.props.dateFilterData.removeClear ? '' : <button className='btn btn-default'
                                                                               onClick={this.handleClearButtonClick}> {this.translate('general.clear')} </button>;


        return (
            <div>
                <DateFilterHeaderContent serverTime={this.props.serverTime} dateFormat={this.props.dateFormat}
                                         setPredefinedDate={this.setPredefinedDate} locale={this.props.locale}/>
                <div className='clearfix'>
                    <div className='rc-date-filter-separator'> {this.translate('general.date_start')} </div>
                    <DateFilterContent
                        setDates={this.setDates}
                        filterKey={startKey}
                        disableTime={data.disableTime}
                        dateValue={this.state.startDate}
                        hoursValue={this.state.startHours}
                        minutesValue={this.state.startMinutes}
                        dateFormat={this.props.dateFormat}
                        locale={this.props.locale}
                        serverTime={this.props.serverTime}
                        type="start"
                    />

                    <div className='rc-date-filter-separator'> {this.translate('general.date_end')} </div>
                    <DateFilterContent
                        setDates={this.setDates}
                        filterKey={endKey}
                        disableTime={data.disableTime}
                        dateValue={this.state.endDate}
                        hoursValue={this.state.endHours}
                        minutesValue={this.state.endMinutes}
                        dateFormat={this.props.dateFormat}
                        locale={this.props.locale}
                        serverTime={this.props.serverTime}
                        type="end"
                    />
                </div>
                <DateFilterFooterContent serverTime={this.props.serverTime} filterData={this.state} setPredefinedDate={this.setPredefinedDate}/>
                <div className='rc-date-filter-actionbar'>
                    <button className='btn btn-primary'
                            onClick={this.handleSaveButtonClick}> {this.translate('general.save')} </button>
                    {clearButton}
                </div>
            </div>
        )
    }
});
;
'use strict';


/**
 *  Empty react element without any content
 * */
var EmptyReactElement = React.createElement(
    'div',
    null,
    ''
);


/**
 * Filter content with message
 * Will be displayed if some filter doesn't have values to select
 */
var EmptyFilter = React.createClass({
    displayName: 'EmptyFilterContent',
    mixins: [TranslateMixin],

    render: function render() {

        return <div> {this.translate('general.no_data_for_filters')} </div>
    }
});

var TableWidthToggler = React.createClass({
    getInitialState: function () {
        return ({selected: this.props.config.selectedToggle || 'left'});
    },

    selectToggle: function (value) {
        this.setState({selected: value});
        this.props.changeTableWidth(value);
    },

    render: function () {
        return (<div className="table-width-toggler">
            <div className="toggler-values">
                <span onClick={this.selectToggle.bind(this, 'center')} className={this.state.selected === 'center' ? 'active' : ''}>50:50</span>
                <span onClick={this.selectToggle.bind(this, 'right')} className={this.state.selected === 'right' ? 'active' : ''}>30:70</span>
            </div>
            <span className="toggle-circle">{}</span>
        </div>);
    }
});


/**
 * Filter content component
 * Check for filter type and init specific component for each of them ( @MultiFilter, @SingleFilter )
 * @type {*|Function}
 */
var FiltersContent = React.createClass({

    propTypes: {
        setFilter:  React.PropTypes.func,
        selected:   React.PropTypes.array,
        values:     React.PropTypes.array,
        type:       React.PropTypes.string,
        filterKey:  React.PropTypes.string,
        dateFormat: React.PropTypes.string,
        locale:     React.PropTypes.string,
        config:     React.PropTypes.object
    },


    handleClick: function handleClick (evt) {
        evt.stopPropagation();
    },

    render: function render() {

        var component = null;

        switch (this.props.type) {
            case 'multi': component = React.createElement(MultiFilter, {
                setMultiFilter: this.props.setMultiFilter,
                selectAllFilters: this.props.selectAllFilters,
                clearAllFilters: this.props.clearAllFilters,
                clearFilteredItems : this.props.clearFilteredItems,
                selected:       this.props.selected,
                values:         this.props.values,
                filterKey:      this.props.filterKey
            }, '');break;
            case 'multi-dynamic-search': component = React.createElement(MultiFilterDynamicSearch, {
                setMultiFilter: this.props.setMultiFilter,
                selectAllFilters: this.props.selectAllFilters,
                clearAllFilters: this.props.clearAllFilters,
                clearFilteredItems : this.props.clearFilteredItems,
                selected:       this.props.selected,
                values:         this.props.values,
                filterKey:      this.props.filterKey,
                searchCallback: this.props.config.filterSearchCallback,
                searchValueMapper: this.props.config.searchValueMapper,
                urlFilters: this.props.urlFilters
            }, '');break;
            case 'multi-dynamic-load-search': component = React.createElement(MultiFilterDynamicLoadSearch, {
                setMultiFilter: this.props.setMultiFilter,
                clearAllFilters: this.props.clearAllFilters,
                loadAllFilters: this.props.config.loadAllFilters,
                clearFilteredItems : this.props.clearFilteredItems,
                selected:       this.props.selected,
                values:         this.props.values,
                filterKey:      this.props.filterKey,
                scrollCallback: this.props.config.filterScrollCallback,
                searchCallback: this.props.config.filterSearchCallback,
                searchValueMapper: this.props.config.searchValueMapper,
                urlFilters: this.props.urlFilters
            }, '');break;
            case 'single': component = React.createElement(SingleFilter, {
                setSingleFilter: this.props.setSingleFilter,
                selected:       this.props.selected,
                values:         this.props.values,
                filterKey:      this.props.filterKey
            }, '');break;
            case 'range': component = React.createElement(RangeFilter, {
                setRangeFilter: this.props.setRangeFilter,
                selected:       this.props.selected,
                filterKey:      this.props.filterKey
            }, '');break;
            case 'date': component = React.createElement(DateFilter, {
                updateFilters:        this.props.updateFilters,
                updateLocationFilter: this.props.updateLocationFilter,
                closeFilter:          this.props.closeFilter,

                dateFilterData: this.props.dateFilterData,
                serverTime:     this.props.serverTime,
                dateFormat:     this.props.dateFormat,
                filterKey:      this.props.filterKey,
                locale:         this.props.locale
            }, '');break;
            case 'analytics': component = React.createElement(AnalyticsFilter, {
                updateFilters:        this.props.updateFilters,
                closeFilter:          this.props.closeFilter,
                values:               this.props.values,
                setAnalyticMultiFilter:  this.props.setAnalyticMultiFilter,
                selectedAnalytics:       this.props.selectedAnalytics,
                setAnalyticSingleFilter: this.props.setAnalyticSingleFilter,
                updateLocationFilter:    this.props.updateLocationFilter,
            }, '');break;

            default: component = React.createElement(EmptyFilter);break;
        }

        var contentClasses = 'filter-content';
        if (this.props.type === 'date') {
            contentClasses = 'filter-content rc-date-filter';
        }
        if (this.props.filterKey === 'id_tournament' || this.props.filterKey === 'id_bet') {
            contentClasses = 'filter-content wide-filter-content';
        }
        if (this.props.type === 'analytics') {
            contentClasses = 'filter-content sm-analytics-filter';
        }

        return (<div className={contentClasses} onClick={this.handleClick}> {component} </div>)
    }
});

var filterTimeout = null;


// Filter wrapper
var Filters = React.createClass({

    propTypes: {
        data:           React.PropTypes.object,
        config:         React.PropTypes.object,
        columns:        React.PropTypes.object,
        serverTime:     React.PropTypes.object,
        urlFilters:     React.PropTypes.object,
        dateFormat:     React.PropTypes.string,
        locale:         React.PropTypes.string,
        supportedExportFormat: React.PropTypes.arrayOf(React.PropTypes.string),
        changeAutoRefresh: React.PropTypes.func,
        updateFilters:  React.PropTypes.func,
        updateLocationFilters: React.PropTypes.func,
        updateLocationWithMultipleParams: React.PropTypes.func
    },

    mixins: [TranslateMixin],

    filters: {},


    getInitialState: function getInitialState() {
        this.initSelectedFilters();
        return { openedFilter: '', renderFilters: false };
    },

    initSelectedFilters: function initSelectedFilters () {
        var config = this.props.config;
        // If state.openedFilter is set to filter's key, it's content will be displayed
        this.filters = {};

        // Set values to filter object from provided url filters
        for (var key in this.props.urlFilters) {
            var delimiter = (config.filterDelimiters && config.filterDelimiters[key]) || config.filterDelimiter || ',';
            if(this.props.data[key] && this.props.data[key].overridenParams) {
                let overridenParams = this.props.data[key].overridenParams;
                if(this.props.urlFilters[overridenParams.from] || this.props.urlFilters[overridenParams.to]) {
                    let array = [];
                    array.push(this.props.urlFilters[overridenParams.from] + '~' + this.props.urlFilters[overridenParams.to]);
                    this.filters[key] = array;
                }
            } else {
                this.filters[key] = String(this.props.urlFilters[key]).split(delimiter);
            }
        }
    },

    componentWillReceiveProps: function componentWillReceiveProps () {
        this.initSelectedFilters();
    },


    // Hide all filters
    handleGlobalClick: function handleGlobalClick (e) {
        if (this.state.openedFilter.length) {
            this.setState({openedFilter: ''});
        }
    },

    componentWillMount: function () {
        window.addEventListener('click', this.handleGlobalClick);
    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('click', this.handleGlobalClick);
    },

    // Display clicked filter
    handleClick: function handleClick (filterKey, evt) {
        evt.stopPropagation();

        this.setState({openedFilter: filterKey !== this.state.openedFilter ? filterKey : ''});
    },

    closeFilter: function () {
        this.setState({openedFilter: ''});
    },


    //Set multi and single filter values to filter object
    setMultiFilter: function setMultiFilter (key, value, state) {
        var config = this.props.config || {};
        var delimiter = (config.filterDelimiters && config.filterDelimiters[key]) || config.filterDelimiter || ',';
        var filtersTimeout = config.filtersTimeoutRequest || 1500;
        // If currently opened filter is empty, create new array for it
        if (typeof this.filters[key] === 'undefined') {
            this.filters[key] = [];
        }

        var index = this.filters[key].indexOf(value);

        // State represents checkbox clicked value
        if (index === -1) {
            this.filters[key].push(value);
        } else {
            this.filters[key].splice(index, 1);
        }

        // Set currently selected filter to url
        this.props.updateLocationFilter(key, this.filters[key].join(delimiter));
        this.props.updateLocationFilter('page', '1');


        if (filterTimeout !== null) {
            clearInterval(filterTimeout);
        }
        filterTimeout = setTimeout(this.props.updateFilters, filtersTimeout);
    },

    setSingleFilter: function setSingleFilter (key, value) {
        var config = this.props.config || {};
        var filtersTimeout = config.filtersTimeoutRequest || 1500;

        this.filters[key] = [value];
        this.props.updateLocationFilter(key, this.filters[key][0]);
        this.props.updateLocationFilter('page', '1');

        if (filterTimeout !== null) {
            clearInterval(filterTimeout);
        }
        filterTimeout = setTimeout(this.props.updateFilters, filtersTimeout);
    },

    setAnalyticSingleFilter: function setAnalyticSingleFilter (key, value) {
        this.filters[key] = [value];
        this.props.updateLocationFilter(key, value);
        this.props.updateLocationFilter('page', '1');

        if (filterTimeout !== null) {
            clearInterval(filterTimeout);
        }
        filterTimeout = setTimeout(this.props.updateFilters, 0);
    },

    setAnalyticMultiFilter: function setAnalyticMultiFilter (key, values, state) {
        var currentFilters = _.cloneDeep(this.filters);
        var config = this.props.config;
        var delimiter = (config.filterDelimiters && config.filterDelimiters[key]) || config.filterDelimiter || ',';

        // If currently opened filter is empty, create new array for it
        if (typeof currentFilters[key] === 'undefined') {
            currentFilters[key] = [];
        }

        currentFilters[key]=values;

        this.props.updateLocationFilter(key, currentFilters[key].join(delimiter));
        this.props.updateLocationFilter('page', '1');

        if (filterTimeout !== null) {
            clearInterval(filterTimeout);
        }
        filterTimeout = setTimeout(
            this.props.updateFilters
        , 1500);
    },

    setRangeFilter: function setRangeFilter (key, value) {
        this.filters[key] = [value];

        if(this.props.data[key].overridenParams) {
            let overridenParams = this.props.data[key].overridenParams;
            let generatedParams = {
                [overridenParams.from] : '',
                [overridenParams.to] : '',
            };
            if(value && value.length) {
                let arr = value.split('~');
                generatedParams = {
                    [overridenParams.from] : arr[0],
                    [overridenParams.to] : arr[1],
                };
            }
            this.props.updateLocationWithMultipleParams(generatedParams);
        } else {
            this.props.updateLocationFilter(key, this.filters[key][0]);
        }

        this.props.updateLocationFilter('page', '1');
        this.closeFilter();

        this.props.updateFilters();
    },

    selectAllFilters: function (filterKey, filterVals) {
        var config = this.props.config;
        var delimiter = (config.filterDelimiters && config.filterDelimiters[filterKey]) || config.filterDelimiter || ',';

        for (var valIndex in filterVals) {
            if (!this.filters[filterKey] || this.filters[filterKey].length == 0) {
                this.filters[filterKey] = filterVals;
            } else if (this.filters[filterKey].indexOf(filterVals[valIndex]) == -1) {
                this.filters[filterKey].push(filterVals[valIndex]);
            }
        }

        // Set currently selected filter to url
        this.props.updateLocationFilter(filterKey, this.filters[filterKey].join(delimiter));
        this.props.updateLocationFilter('page', '1');

        this.props.updateFilters();
        this.setState({renderFilters: !this.state.renderFilters});
    },

    resetPage: function () {
        this.props.updateLocationFilter('page', '1');
    },


    clearFilteredItems: function(filterKey, filterVals) {
        if (this.filters[filterKey] && this.filters[filterKey].length > 0) {
            var config = this.props.config;
            var delimiter = (config.filterDelimiters && config.filterDelimiters[filterKey]) || config.filterDelimiter || ',';

            for (var valIndex in filterVals) {
                var thisIndex = this.filters[filterKey].indexOf(filterVals[valIndex]);
                if ( thisIndex !== -1 ) {
                    this.filters[filterKey].splice( thisIndex, 1)
                }
            }
            // Set currently selected filter to url
            this.props.updateLocationFilter(filterKey, this.filters[filterKey].join(delimiter));
            this.props.updateLocationFilter('page', '1');

            this.props.updateFilters();
            this.setState({renderFilters: !this.state.renderFilters});
        }
    },

    /** Returns selected template name
     * @return {String}
     */
    getSelectedTemplate: function() {
        var matchSelectionData = this.props.config.matchSelectionData;
        return matchSelectionData.selectedTemplate || '';
    },

    render: function render() {
        // Show message if there is no data in filter


        var data                = this.props.data,
            filter              = null,
            filters             = [],
            filterActive        = '',
            toggler             = null,
            dateFilterActive    = '',
            resetFilters = null,
            search = null,
            filtersExist = false;
        if (!data) return EmptyReactElement;



        // Create list of filter blocks
        for (var i in data) {
            filter = data[i];

            if (filter.type === 'search') {

                search = (function () {
                    return <SearchFilter
                        updateFilters       = {this.props.updateFilters}
                        updateLocationFilter= {this.props.updateLocationFilter}
                        resetPage           = {this.resetPage}
                        selected            = {this.filters[filter.name]}
                        filterKey           = {filter.name}
                        key                 = {filter.name} //required
                     />
                }.bind(this)());
            }

            else {
                // Multi, Single, Range, Date filters
                filterActive = (this.filters[filter.name] && this.filters[filter.name].length > 0) || (filter.type === 'date' && (filter.selected.startDate || filter.selected.endDate))
                || (filter.type === 'analytics' && (this.filters.analyticEvent || this.filters.analyticMarket || this.filters.analyticPendingTicketBets));
                filters.push(function () {
                    var filterName = filter.type === 'date' ? 'date_' + i : filter.name;

                    return <div className='filter pull-left' onClick={this.handleClick.bind(this, filterName)} key={'key-filter' + filterName}>
                        <div className = {filterActive ? 'filter-name active-filter' : 'filter-name'}>
                            <div className = 'filter-title'> {filter.displayName} </div>
                            <i className = 'n-i n-i-arrow-down-b filter-arrow'></i>
                        </div>

                        {function (){
                            if (this.state.openedFilter !== filterName) return null;

                            return <FiltersContent
                                setMultiFilter      = {this.setMultiFilter}
                                setSingleFilter     = {this.setSingleFilter}
                                setRangeFilter      = {this.setRangeFilter}
                                selectAllFilters    = {this.selectAllFilters}
                                clearFilteredItems  = {this.clearFilteredItems}
                                clearAllFilters     = {this.clearAllFilters}

                                updateFilters       = {this.props.updateFilters}
                                updateLocationFilter= {this.props.updateLocationFilter}
                                closeFilter         = {this.closeFilter}

                                dateFilterData      = {filter.type === 'date' ? filter : []}
                                serverTime          = {this.props.serverTime}
                                dateFormat          = {this.props.dateFormat}
                                locale              = {this.props.locale}


                                selected            = {this.filters[filter.name]}
                                values              = {filter.data}
                                type                = {filter.type}
                                filterKey           = {filter.name}
                                key                 = {filter.name}
                                overridenParams     = {filter.overridenParams}

                                config              = {this.props.config}
                                urlFilters          = {this.props.urlFilters}

                                //Analytics filter props
                                selectedAnalytics       = {filterName==='analyticsFilter' ? this.filters : {} }
                                setAnalyticSingleFilter = {this.setAnalyticSingleFilter}
                                setAnalyticMultiFilter  = {this.setAnalyticMultiFilter}
                             />

                        }.bind(this)()}

                    </div>

                }.bind(this)());
            }
        }

        // Match selection filters
        if (this.props.config.matchSelection === 'yes') {
            filters.push(<SelectionFilters selectedTemplate={this.getSelectedTemplate()} config={this.props.config} matchSelectionAction={this.props.matchSelectionAction} />);
        }

        if (this.props.config.limitsList) {
            filters.push(<LimitsFilter config={this.props.config} matchSelectionAction={this.props.matchSelectionAction} />);
        }

        if ( filters.length > 0 ) {
            filtersExist = true;
        }

        if (this.props.config.tableWidthToggler === 'yes') {
            toggler = <TableWidthToggler changeTableWidth={this.props.changeTableWidth} config={this.props.config}/>
        }

        return (
                <div className='filters-container clearfix'>
                    <div>
                        <GridActions
                            config              = {this.props.config}
                            urlFilters          = {this.props.urlFilters}

                            sevenLocalInfo      = {this.props.sevenLocalInfo}
                            columns             = {this.props.columns}
                            changeAutoRefresh   = {this.props.changeAutoRefresh}
                            updateFilters       = {this.props.updateFilters}
                            chartButtonClick    = {this.props.chartButtonClick}
                            supportedExportFormats = {this.props.supportedExportFormats}
                            rememberFilters = {this.props.rememberFilters}
                            removeRememberedFilters = {this.props.removeRememberedFilters}
                            resetFilters = {this.props.resetFilters}
                            filtersExist = {filtersExist}
                            dateFakingEnabledForView = {this.props.dateFakingEnabledForView}
                            getFakedExportDateParams = {this.props.getFakedExportDateParams}
                            onExport                 = {this.props.onExport}
                        />
                        {search}
                        {toggler}
                    </div>
                    {filters}

                </div>
            )
    }

});
;

var LimitsFilterContent = React.createClass({
    mixins: [TranslateMixin],

    getInitialState: function () {
        return ({limits: this.props.config.limitsList});
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({limits: newProps.config.limitsList});
    },

    handleWrapClick: function (evt) {
        evt.stopPropagation();
    },

    handleChange: function (evt) {
        var value        = evt.target.value,
            searchValues = [],
            limits       = this.props.config.limitsList,
            limit        = null;

        for (var i = 0; i < limits.length; i++) {
            limit = limits[i].limit_name.toLowerCase();
            if (limit.indexOf(value.toLowerCase()) !== -1) {
                searchValues.push(limits[i]);
            }
        }

        this.setState({limits: searchValues});
    },

    handleClick: function (id, name, evt) {
        evt.stopPropagation();
        this.props.changeFilterName(name);
        this.props.matchSelectionAction('limitId', id);
    },

    render: function () {

        console.log("Config: ", this.props.config);

        return (
            <div className='filter-content filter-content--limits' onClick={this.handleWrapClick}>
                <div className='filter-options'>

                    <div className='filter-option'>
                        <input type='text' placeholder={this.translate('general.search')} onChange={this.handleChange}></input>
                    </div>

                    <div className='filter-option'>
                        {
                            this.state.limits.map(function (limit) {
                                return <div onClick={this.handleClick.bind(this, limit.id, limit.limit_name)}>{limit.limit_name}</div>
                        }.bind(this))}
                    </div>
                </div>
            </div>
        );

    }
});


var LimitsFilter = React.createClass({

    getInitialState: function () {
        return ({displayFilter: false, displayName: 'Select limit'});
    },

    componentWillReceiveProps: function () {
        this.setState({displayName: 'Select limit'});
    },

    // Hide all filters
    handleGlobalClick: function handleGlobalClick (e) {
        this.setState({displayFilter: false});
    },

    componentWillMount: function () {
        window.addEventListener('click', this.handleGlobalClick);
    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('click', this.handleGlobalClick);
    },

    changeFilterName: function (name) {
        this.setState({displayName: name});
    },

    handleClick: function (evt) {
        evt.stopPropagation();
        this.setState({displayFilter: true});
    },

    render: function () {
        return(
            <div className='selection-filter filter-selection-name filter pull-right'>
                <div className='filter-name' onClick={this.handleClick}>
                    <i className='n-i n-i-tv'></i>
                    <div className='filter-title'>{this.state.displayName}</div>
                    <i className='n-i n-i-arrow-down-b filter-arrow'></i>
                </div>
                {function () {
                    if (!this.state.displayFilter) return;

                    return <LimitsFilterContent config={this.props.config} matchSelectionAction={this.props.matchSelectionAction} changeFilterName={this.changeFilterName} />
                }.bind(this)()}
            </div>
        );
    }
});;


/**
 * Checkbox value in filters content
 * Will be populated by selected filters provided from @Filters component (if any is selected)
 * Will set property filters in @Filters component at every value change
 */
var MultiFilterCheckbox = React.createClass({

    getInitialState: function getInitialState () {
        var selected = this.props.selected || [];
        return { inputValue: selected.indexOf(this.props.filterVal) !== -1 };
    },

    componentWillReceiveProps: function (newProps) {
        var selected = newProps.selected || [];
        this.setState({inputValue: selected.indexOf(newProps.filterVal) !== -1});
    },

    handleChange: function handleClick (evt) {
        evt.stopPropagation();

        // Provided method from parent component @Filters. Set every change to parent component property.
        this.props.setMultiFilter(this.props.filterKey, this.props.filterVal, !this.state.inputValue);
        // Change checkbox input value
        this.setState({inputValue: !this.state.inputValue});
    },


    render: function render () {
        var checkboxId = 'filter-content-value' + this.props.filterKey + '-' + this.props.filterVal;

        return (
                <label className='checkbox' htmlFor={checkboxId}>
                    <input
                        type        = 'checkbox'
                        className   = 'normal-checkbox'
                        onChange    = {this.handleChange}
                        value       = {this.state.inputValue}
                        checked     = {this.state.inputValue}
                        id          = {checkboxId}
                     />
                     <i></i>
                     <span>{this.props.values[this.props.filterVal]}</span>
                </label>
            )
    }

});


/**
 * Component that contains list of checkbox components @MultiFilterCheckbox
 * @type {*|Function}
 */
var MultiFilterWrap = React.createClass({

    propTypes: {
        setMultiFilter: React.PropTypes.func,
        selected: React.PropTypes.array,
        values: React.PropTypes.object,
        filterKey: React.PropTypes.string,
        key: React.PropTypes.string
    },

    render: function render() {

        var multiFilterWrapsValues = [];
        for (var filterVal in this.props.values) {

            multiFilterWrapsValues.push(function() {
                return <span key={this.props.filterKey + '-' + filterVal}>
                    <MultiFilterCheckbox
                        setMultiFilter      = {this.props.setMultiFilter}
                        selected            = {this.props.selected}
                        filterKey           = {this.props.filterKey}
                        filterVal           = {filterVal}
                        values              = {this.props.values}
                        key                 = {this.props.filterKey + '-' + filterVal}
                    />
                </span>

            }.bind(this)());
        }

        return (<div className='filter-multi-value'> {multiFilterWrapsValues} </div>)
    }
});


/**
 * Create wrapper for multi filter values content
 * @type {*|Function}
 */
var MultiFilter = React.createClass({

    propTypes: {
        setMultiFilter: React.PropTypes.func,
        selected: React.PropTypes.array,
        values: React.PropTypes.array,
        filterKey: React.PropTypes.string,
        key: React.PropTypes.string
    },

    mixins: [TranslateMixin],

    timeout: null,

    searchFilters: [],

    searchText: '',

    getInitialState: function getInitialState () {

        this.searchFilters = [];
        return({inputValue: ''});
    },


    filterContent: function filterContent() {

        var value = null,
            searchFilters = [];

        for (var i = 0; i < this.props.values.length; i++) {
            value = this.props.values[i];

            if (typeof value[Object.keys(value)[0]] === 'number') {
                // Convert to string if value is number
                value[Object.keys(value)[0]] += '';
            }

            if (value[Object.keys(value)[0]] && value[Object.keys(value)[0]].toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1) {
                searchFilters.push(value);
            }
        }
        return searchFilters;
    },


    handleInputChange: function handleChange (e) {
        this.searchText = '';
        if (e) { this.searchText = e.target.value; }

        this.searchFilters = this.filterContent();
        this.setState({inputValue: this.searchText});

    },

    selectAllAction: function (evt) {

        var values = this.filterContent(),
            filterVal = null,
            filterKey = this.props.filterKey,
            tmpList = [];
        for (var i in values) {
            filterVal = Object.keys(values[i])[0];
            tmpList.push (filterVal);
        }
        this.props.selectAllFilters(filterKey, tmpList);
    },

    clearAllAction: function (evt) {
        var selectedValues = this.filterContent(),
            tmpList = [],
            thisVal;
        for (var i in selectedValues) {
            thisVal = Object.keys(selectedValues[i])[0];
            tmpList.push(thisVal);
        }
        if (this.searchText !== '') {
            //this.handleInputChange(false); // clear filter search input
            this.props.clearFilteredItems(this.props.filterKey, tmpList);
        } else {
            var allList = _.uniq(_.concat(tmpList, this.props.selected));
            this.props.clearFilteredItems(this.props.filterKey, allList);
        }
    },


    render: function render() {

        var multiFilterWraps = [],
            values = this.searchFilters.length || this.searchText.length ? this.searchFilters : this.props.values;

            multiFilterWraps = values.map(function (value, i) {
                return <MultiFilterWrap
                    setMultiFilter      = {this.props.setMultiFilter}
                    selected            = {this.props.selected}
                    values              = {value}
                    filterKey           = {this.props.filterKey}
                    key                 = {i + '-' + this.props.filterKey}
                />
            }.bind(this));


        return (
            <div>
                {function() {
                    if (this.props.values.length <= 10) return '';

                    return <input
                        name            = {'filter-search'}
                        type            = {'text'}
                        placeholder     = {this.translate('general.search_filters')}
                        value           = {this.state.inputValue}
                        onChange        = {this.handleInputChange}
                        className       = {'react-filter-search'}
                        autoFocus       = "autoFocus"
                    />
                }.bind(this)()}

                <div className="filter-option">
                    <span onClick={this.selectAllAction}>{this.translate('general.select_all')}</span>
                    <span onClick={this.clearAllAction}>{this.translate('general.clear')}</span>
                </div>

                <div className={values.length > 10 ? 'filter-multi-wrap add-scroll' : 'filter-multi-wrap'}>
                    {function(){
                        return this.props.values.length > 0 ? multiFilterWraps : this.translate('general.no_data_for_filters');
                    }.bind(this)()}
                </div>
            </div>
            )
    }
});

var MultiFilterDynamicSearch = React.createClass({

    propTypes: {
        setMultiFilter: React.PropTypes.func,
        selected: React.PropTypes.array,
        values: React.PropTypes.array,
        filterKey: React.PropTypes.string,
        key: React.PropTypes.string,
        searchCallback: React.PropTypes.func,
    },

    mixins: [TranslateMixin],

    timeout: null,

    searchText: '',

    getInitialState: function getInitialState () {
        var inputValue = '';
        var searchValueMap = this.props.searchValueMapper || {};
        var searchKey = searchValueMap[this.props.filterKey];

        if (searchKey && this.props.urlFilters[searchKey]) {
            inputValue = this.props.urlFilters[searchKey];
        }

        return({inputValue: inputValue});
    },

    handleInputChange: function handleChange (e) {
        this.searchText = '';
        if (e) { this.searchText = e.target.value; }

        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
            this.props.searchCallback(this.searchText, this.props.filterKey);
            clearTimeout(this.timeout);
        }.bind(this), 300);

        this.setState({inputValue: this.searchText});
    },

    componentDidMount: function () {
        var searchInputRef = this.refs['filter-search-input-' + this.props.filterKey];

        if (searchInputRef) {
            searchInputRef.focus();
            searchInputRef.select();
        }
    },

    filterContent: function filterContent() {

        var value = null,
            searchFilters = [];

        for (var i = 0; i < this.props.values.length; i++) {
            value = this.props.values[i];

            if (typeof value[Object.keys(value)[0]] === 'number') {
                // Convert to string if value is number
                value[Object.keys(value)[0]] += '';
            }

            if (value[Object.keys(value)[0]] && value[Object.keys(value)[0]].toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1) {
                searchFilters.push(value);
            }
        }
        return searchFilters;
    },

    clearAllAction: function (evt) {
        var selectedValues = this.filterContent(),
            tmpList = [],
            thisVal;
        for (var i in selectedValues) {
            thisVal = Object.keys(selectedValues[i])[0];
            tmpList.push(thisVal);
        }
        if (this.searchText !== '') {
            this.props.clearFilteredItems(this.props.filterKey, tmpList);
        } else {
            var allList = _.uniq(_.concat(tmpList, this.props.selected));
            this.props.clearFilteredItems(this.props.filterKey, allList);
        }
    },


    render: function render() {

        var multiFilterWraps = [],
            values = this.props.values;

            multiFilterWraps = values.map(function (value, i) {
                return <MultiFilterWrap
                    setMultiFilter      = {this.props.setMultiFilter}
                    selected            = {this.props.selected}
                    values              = {value}
                    filterKey           = {this.props.filterKey}
                    key                 = {i + '-' + this.props.filterKey}
                />
            }.bind(this));


        return (
            <div>
                {function() {
                    return <input
                        name            = {'filter-search'}
                        type            = {'text'}
                        ref             = { 'filter-search-input-' + this.props.filterKey }
                        placeholder     = {this.translate('general.search_filters')}
                        value           = {this.state.inputValue}
                        onChange        = {this.handleInputChange}
                        className       = {'react-filter-search'}
                        autoFocus       = "autoFocus"
                    />
                }.bind(this)()}

                <div className="filter-option">
                    <span onClick={this.clearAllAction}>{this.translate('general.clear')}</span>
                </div>

                <div className={values.length > 10 ? 'filter-multi-wrap add-scroll' : 'filter-multi-wrap'}>
                    {function(){
                        return this.props.values.length > 0 ? multiFilterWraps : this.translate('general.no_data_for_filters');
                    }.bind(this)()}
                </div>
            </div>
            )
    }
});

var MultiFilterDynamicLoadSearch = React.createClass({

    propTypes: {
        setMultiFilter: React.PropTypes.func,
        selected: React.PropTypes.array,
        values: React.PropTypes.array,
        filterKey: React.PropTypes.string,
        key: React.PropTypes.string,
        scrollCallback: React.PropTypes.func,
        searchCallback: React.PropTypes.func,
    },

    mixins: [TranslateMixin],

    timeout: null,

    searchText: '',

    getInitialState: function getInitialState () {
        var inputValue = '';
        var searchValueMap = this.props.searchValueMapper || {};
        var searchKey = searchValueMap[this.props.filterKey];

        if (searchKey && this.props.urlFilters[searchKey]) {
            inputValue = this.props.urlFilters[searchKey];
        }

        return({inputValue: inputValue});
    },

    handleInputChange: function handleChange (e) {
        this.searchText = '';
        if (e) { this.searchText = e.target.value; }

        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
            this.props.searchCallback(this.searchText, this.props.filterKey);
            clearTimeout(this.timeout);
        }.bind(this), 300);

        this.setState({inputValue: this.searchText});
    },

    componentDidMount: function () {
        var searchInputRef = this.refs['filter-search-input-' + this.props.filterKey];

        if (searchInputRef) {
            searchInputRef.focus();
            searchInputRef.select();
        }
    },

    filterContent: function filterContent() {

        var value = null,
            searchFilters = [];

        for (var i = 0; i < this.props.values.length; i++) {
            value = this.props.values[i];

            if (typeof value[Object.keys(value)[0]] === 'number') {
                // Convert to string if value is number
                value[Object.keys(value)[0]] += '';
            }

            if (value[Object.keys(value)[0]] && value[Object.keys(value)[0]].toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1) {
                searchFilters.push(value);
            }
        }
        return searchFilters;
    },

    clearAllAction: function (evt) {
        var selectedValues = this.filterContent(),
            tmpList = [],
            thisVal,
            allList;

        for (var i in selectedValues) {
            thisVal = Object.keys(selectedValues[i])[0];
            tmpList.push(thisVal);
        }

        allList = _.uniq(_.concat(tmpList, this.props.selected));
        this.props.clearFilteredItems(this.props.filterKey, allList);
    },


    render: function render() {

        var multiFilterWraps = [],
            values = this.props.values;

            multiFilterWraps = values.map(function (value, i) {
                return <MultiFilterWrap
                    setMultiFilter      = {this.props.setMultiFilter}
                    selected            = {this.props.selected}
                    values              = {value}
                    filterKey           = {this.props.filterKey}
                    key                 = {i + '-' + this.props.filterKey}
                />
            }.bind(this));


        return (
            <div>
                {function() {
                    return <input
                        name            = {'filter-search'}
                        type            = {'text'}
                        ref             = { 'filter-search-input-' + this.props.filterKey }
                        placeholder     = {this.translate('general.search_filters')}
                        value           = {this.state.inputValue}
                        onChange        = {this.handleInputChange}
                        className       = {'react-filter-search'}
                        autoFocus       = "autoFocus"
                    />
                }.bind(this)()}

                <div className="filter-option">
                    <span onClick={this.clearAllAction}>{this.translate('general.clear')}</span>
                </div>

                <div className={values.length > 10 ? 'filter-multi-wrap add-scroll' : 'filter-multi-wrap'}
                    onScroll={event => this.props.scrollCallback(event)}>
                    {function(){
                        return this.props.values.length > 0 ? multiFilterWraps : this.translate('general.no_data_for_filters');
                    }.bind(this)()}
                </div>
            </div>
            )
    }
});
;


var RangeFilter = React.createClass({

    propTypes: {
        setSingleFilter: React.PropTypes.func,
        selected: React.PropTypes.array,
        filterKey: React.PropTypes.string
    },

    mixins: [TranslateMixin],


    getInitialState: function getInitialState () {
        var fromInput = '',
            toInput = '';

        if (typeof this.props.selected !== 'undefined') {
            fromInput = this.props.selected[0].split('~')[0];
            toInput = this.props.selected[0].split('~')[1];
        }

        return ({
            fromInput: fromInput,
            toInput: toInput
        })
    },

    disableButton: function () {
        document.getElementById('range-action-button').setAttribute('disabled', 'disabled');
    },

    enableButton: function () {
        document.getElementById('range-action-button').removeAttribute('disabled');
    },


    handleFromChange: function handleFromChange (e) {
        var value = parseInt(e.target.value),
            toInput = this.state.toInput,
            elem = document.getElementById('from-range-input');

        this.enableButton();
        elem.classList.remove('invalid-range-input');

        if (toInput !== '' && value !== '' && value > parseInt(toInput)) {
            elem.classList.add('invalid-range-input');
            this.disableButton();
        }

        this.setState({fromInput: e.target.value});
    },


    handleToChange: function handleToChange (e) {
        var value = e.target.value,
            fromInput = this.state.fromInput,
            elem = document.getElementById('to-range-input');

        this.enableButton();
        elem.classList.remove('invalid-range-input');

        if (fromInput !== '' && value !== '' && value < parseInt(fromInput)) {
            elem.classList.add('invalid-range-input');
            this.disableButton();
        }

        this.setState({toInput: e.target.value});
    },


    applyRange: function applyRange () {
        var range = this.state.fromInput === '' && this.state.toInput === '' ?  [] : this.state.fromInput + '~' + this.state.toInput;
        this.props.setRangeFilter(this.props.filterKey, range);
    },


    render: function render () {
        return (
                <div className='filter-options filter-range'>

                    <div className='filter-option range-input'>
                        <label> {this.translate('general.from')} </label>
                        <input type='text' id='from-range-input' value={this.state.fromInput} onChange={this.handleFromChange} autoFocus="autoFocus" />
                    </div>

                    <div className='filter-option range-input'>
                        <label> {this.translate('general.to')} </label>
                        <input type='text' id='to-range-input' value={this.state.toInput} onChange={this.handleToChange} />
                    </div>

                    <button id='range-action-button' className='btn btn-default filter-apply' onClick={this.applyRange}> {this.translate('general.apply')} </button>

                </div>
            )
    }

});;
var timeout = null;


// Main filter (type search)
var SearchFilter = React.createClass({

    timeout: null,

    propTypes: {
        updateFilters: React.PropTypes.func,
        updateLocationFilter: React.PropTypes.func,
        selected: React.PropTypes.array,
        filterKey: React.PropTypes.string
    },
    
    mixins: [TranslateMixin],

    getInitialState: function getInitialState () {
        return ({inputValue: this.props.selected && this.props.selected.length ? this.props.selected : ''});
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({inputValue: newProps.selected && newProps.selected.length ? newProps.selected : ''});
    },

    /**
     * Check for changes in input
     * Set value to url and trigger update filters function in 1s
     * @param e
     */
    handleChange: function handleChange (e) {
        var inputValue = e.target.value;

        if (timeout !== null) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(function () {
            if (this.props.resetPage) {
                this.props.resetPage();
            }
            this.props.updateLocationFilter('search', inputValue.trim());
            this.props.updateFilters({});
        }.bind(this), 1000);

        this.setState({inputValue: inputValue});
    },

    render: function render () {

        return (
                <div>
                    <input 
                        className       = {'react-search-input'}
                        placeholder     = {this.translate('general.search')}
                        type            = {'text'}
                        value           = {this.state.inputValue}
                        onChange        = {this.handleChange}
                     />
                </div>
            )
    }
});;

// List of selections layouts
var SelectionLayouts = React.createClass({
    mixins: [TranslateMixin],

    handleClick: function (evt) {
        evt.stopPropagation();
        this.props.displayFilter('layouts');
    },

    handleLayoutClick: function (key, value, name, evt) {
        evt.stopPropagation();
        this.props.updateSelection(key, value, 'layouts');
        this.props.displayFilter('');

        this.props.selectLayout(name);
    },

    render: function () {
        var layouts = this.props.config.matchSelectionData.layouts;

        return (
            <div>
                <div className='filter selection-filter' onClick={this.handleClick}>
                    <div className='filter-selection-name filter-name custom-filter-content'>
                        <i className='n-i n-i-tv'></i>
                        <div className='filter-title'>{this.props.selected}</div>
                        <i className='n-i n-i-arrow-down-b filter-arrow'></i>
                    </div>

                    <div className='tv-select-wrapper layout-filter-content filter-content ng-scope'>
                         {
                            function () {
                                if (this.props.filterOpened !== 'layouts') return '';

                                return layouts.map(function(layout, layoutIndex){
                                    return (<div className='selection-layouts'>
                                        {function(){
                                            return layout.map(function(val, key){
                                                return (
                                                    <div
                                                        className={'tv-select-content menu-template-' + val.id_tv_layout_template}
                                                        onClick={this.handleLayoutClick.bind(this, 'id_tv_layout', val.id_tv_layout, val.name)}>
                                                        <i className="n-i n-i-desktop"></i>
                                                        {val.name}
                                                    </div>
                                                );
                                            }.bind(this));
                                        }.bind(this)()}
                                    </div>);
                                }.bind(this));

                            }.bind(this)()
                         }
                    </div>
                </div>
            </div>
        );
    }
});


// List of selection templates
var SelectionTemplates = React.createClass({
    mixins: [TranslateMixin],

    handleClick: function (evt) {
        evt.stopPropagation();
        this.props.displayFilter('templates');
    },

    handleTemplateClick: function (key, value, name, evt) {
        evt.stopPropagation();
        this.props.updateSelection(key, value, 'templates');
        this.props.displayFilter('');
        this.props.selectTemplate(name);
    },

    render: function () {
        var templates           = this.props.config.matchSelectionData.templates,
            bookmakerTemplate   = this.props.config.matchSelectionData.bookmakerTemplate;

        return (
            <div>
                <div className='filter selection-filter' onClick={this.handleClick}>
                    <div className='filter-selection-name filter-name custom-filter-content'>
                        <i className='n-i n-i-desktop'></i>
                        <div className='filter-title'>{this.props.selected}</div>
                        <i className='n-i n-i-arrow-down-b filter-arrow'></i>
                    </div>

                    <div className='tv-select-wrapper template-filter-content filter-content'>
                        {function (){
                            if (this.props.filterOpened !== 'templates') return '';

                            return templates.map(function(value) {
                                return (
                                <div
                                    className={'tv-select-content template-content template-' + value.id_tv_layout_template}
                                    onClick={this.handleTemplateClick.bind(this, 'id_tv_layout_template', value.id_tv_layout_template, value.name)} >
                                    <i className={bookmakerTemplate === value.id_tv_layout_template ? 'n-i n-i-bankers bookmakerFlag' : ''}></i>
                                    {value.name}
                                </div>);
                            }.bind(this));
                        }.bind(this)()}
                    </div>
                </div>
            </div>
        );
    }
});

var SelectionOrigin = React.createClass({
    mixins: [TranslateMixin],

    handleClick: function (evt) {
        evt.stopPropagation();
        this.props.displayFilter('origin');
    },

    handleOriginClick: function (value, name, evt) {
        evt.stopPropagation();
        this.props.updateSelection('id_tv_layout_active_origin', value, 'origin');
        this.props.displayFilter('');
        this.props.selectOrigin(name);
    },

    render: function () {
        var selectedOrigin = this.props.selected;
        // origin selection should not be displayed on SA(Super Admin)
        if (!selectedOrigin)
            return null;

        return (
            <div>
                <div className='filter selection-filter' onClick={this.handleClick}>
                    <div className='filter-selection-name filter-name custom-filter-content'>
                        <i className='n-i n-i-menu-toggle'></i>
                        <div className='filter-title'>{this.props.selected}</div>
                        <i className='n-i n-i-arrow-down-b filter-arrow'></i>
                    </div>

                    <div className='tv-select-wrapper template-filter-content filter-content'>
                        {function (){
                            if (this.props.filterOpened !== 'origin') return '';

                            return (<div>
                                    <div onClick={this.handleOriginClick.bind(this, 'bookmaker', 'Provider')} className={'tv-select-content template-content menu-active-user-bookmaker' + (selectedOrigin === 'Provider' ? ' selected-tvsel-filter' : '')}>Provider</div>
                                    <div onClick={this.handleOriginClick.bind(this, 'company', 'Custom')} className={'tv-select-content template-content menu-active-user-company' + (selectedOrigin === 'Custom' ? ' selected-tvsel-filter' : '')}>Custom</div>
                                </div>);
                        }.bind(this)()}
                    </div>
                </div>
            </div>
        );
    }
});


// Copy, Paste, Select all buttons
var SelectionButtons = React.createClass({
    mixins: [TranslateMixin],

    handleClick: function (action) {
        var value = null;
        if (this.actions[action])
            value = this.actions[action](this);

        this.props.updateSelection(null, value, action);
    },

    actions: {
        selectAll: function(self) {
            var selected = !self.state.selectAll.value;
            var trans = selected ? 'unselect_all' : 'select_all';

            self.setState(function(prevState) {
                return {
                    selectAll: {
                        text: self.translate('general.' + trans),
                        value: !prevState.selectAll.value
                    }
                };
            });

            return selected;
        }
    },

    getInitialState: function() {
        return {
            selectAll: {
                value: 0,
                text: this.translate('general.select_all')
            }
        };
    },

    render: function () {
        return (
            <div>
                <div className='filter selection-filter' onClick={this.handleClick.bind(this, 'selectAll')}>
                    <div className='selection-btn'>
                        {this.state.selectAll.text}
                    </div>
                </div>
                <div className='filter selection-filter' onClick={this.handleClick.bind(this, 'paste')}>
                    <div className='selection-btn'>
                        {this.translate('general.paste')}
                    </div>
                </div>
                <div className='filter selection-filter' onClick={this.handleClick.bind(this, 'copy')}>
                    <div className='selection-btn'>
                        {this.translate('general.copy')}
                    </div>
                </div>
            </div>
        );
    }
});


// Selection filters wrapper
var SelectionFilters = React.createClass({
    mixins: [TranslateMixin],

    propTypes: {
        matchSelectionData: React.PropTypes.object
    },

    checkOriginName: function () {
        var selectedOrigin = this.props.config.matchSelectionData.activeOrigin;
        if (selectedOrigin === 'company')
            return 'Custom';

        if (selectedOrigin === 'bookmaker')
            return 'Provider';
    },

    checkLayoutName: function () {
        var layouts         = this.props.config.matchSelectionData.layouts,
            selectedLayout  = this.props.config.matchSelectionData.selectedLayout,
            layout          = null;

            for (var i = 0; i < layouts.length; i++) {
                layout = layouts[i];
                for (var j = 0; j < layout.length; j++) {
                    if (layout[j].id_tv_layout == selectedLayout) {
                        return layout[j].name;
                    }
                }
            }
    },

    getInitialState: function getInitialState () {
        var _selectedOrigin = this.checkOriginName();
        var _selectedLayout = this.checkLayoutName() || this.translate('general.layouts');

        return {
            filterOpened: '',
            selectedOrigin: _selectedOrigin,
            selectedLayout: _selectedLayout
        };
    },

    handleGlobalClick: function () {
        if (this.state.filterOpened === '')
            return;

        this.setState({filterOpened: ''});
    },

    componentWillMount: function () {
        window.addEventListener('click', this.handleGlobalClick);
    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('click', this.handleGlobalClick);
    },

    displayFilter: function displayFilter (filterName) {
        this.setState({filterOpened: filterName});
    },

    updateSelection: function updateSelection (key, value, action) {
        this.props.matchSelectionAction(key, value, action);
    },

    selectTemplate: function selectTemplate(name) {
        this.setState({
            selectedTemplate: name
        });
    },

    selectOrigin: function selectOrigin(name) {
        this.setState({
            selectedOrigin: name
        });
    },

    selectLayout: function selectLayout(name) {
        this.setState({
            selectedLayout: name
        });
    },

    render: function render () {
        return (
            <div>
                <SelectionButtons updateSelection={this.updateSelection} />

                <SelectionOrigin
                    config          = {this.props.config}
                    filterOpened    = {this.state.filterOpened}
                    displayFilter   = {this.displayFilter}
                    selectOrigin    = {this.selectOrigin}
                    selected        = {this.checkOriginName()}
                    updateSelection = {this.updateSelection} />

                <SelectionLayouts
                    config          = {this.props.config}
                    filterOpened    = {this.state.filterOpened}
                    displayFilter   = {this.displayFilter}
                    selected        = {this.state.selectedLayout}
                    selectLayout    = {this.selectLayout}
                    updateSelection = {this.updateSelection} />

                <SelectionTemplates
                    config          = {this.props.config}
                    filterOpened    = {this.state.filterOpened}
                    displayFilter   = {this.displayFilter}
                    selectTemplate  = {this.selectTemplate}
                    selected        = {this.props.selectedTemplate}
                    updateSelection = {this.updateSelection} />
            </div>
        );
    }


});
;

var SingleFilterRadio = React.createClass({

     handleChange: function handleClick (evt) {
         evt.stopPropagation();
         this.props.handleRadioChange(this.props.radioKey);
     },


    render: function render () {

        return (
                <div>
                    <label className='single-select-radio'>
                        <input  
                            type        = 'radio'
                            className   = 'normal-radio-btn'
                            name        = {'single-radio-' + this.props.filterName}
                            checked     = {this.props.selected === this.props.radioKey}
                            onChange    = {this.handleChange}
                            />
                            <i></i>
                            { this.props.radioName }
                    </label>
                </div>
           )
    }
});

var SingleFilter = React.createClass({

    propTypes: {
        setSingleFilter: React.PropTypes.func,
        selected: React.PropTypes.array,
        values: React.PropTypes.array,
        filterKey: React.PropTypes.string,
        key: React.PropTypes.string
    },

    mixins: [TranslateMixin],

    getInitialState: function getInitialState () {
        // if no selected values are provided, checkbox will not be selected
        if (typeof this.props.selected === 'undefined') {
            return { inputValue: '' }
        }

        // Check checkbox if value is listed in selected values
        return { inputValue: this.props.selected[0] };
    },


    handleRadioChange: function handleFilterChange (value) {

        this.props.setSingleFilter(this.props.filterKey, value, true);
        this.setState({inputValue: value})
    },


    render: function render() {

        var filterKey = '';

        return (
                <div>
                    {function() {
                        var values = this.props.values;

                        return values.map(function (value, index) {
                            filterKey = Object.keys(value)[0];

                            return <SingleFilterRadio 
                                radioName           = {value[filterKey]}
                                radioKey            = {filterKey}
                                filterName          = {this.props.filterKey}
                                selected            = {this.state.inputValue}
                                handleRadioChange   = {this.handleRadioChange}
                            />

                        }.bind(this));
                    }.bind(this)()}
                </div>
            )
    }
});
;

// If there is no supported type, Empty cell component should be loaded
var EmptyCell = React.createClass({
    displayName: 'EmptyCell',

    render: function render() {
        return (<div></div>)
    }
});


var StringCell = React.createClass({
    displayName: 'StringCell',

    render: function render() {
        return (
            <div title={this.props.hoverValue} className={this.props.config.sensitiveColumns && this.props.config.sensitiveColumns.indexOf(this.props.cellKey) !== -1 ? 'sensitive-data' : ''}> {this.props.value} </div>
            )
    }
});


var IntegerCell = React.createClass({
    displayName: 'IntegerCell',

    render: function render() {
        return (
            <div className='integer-cell sensitive-data'> {this.props.value} </div>
            )
    }
});


var DateTimeCell = React.createClass({
    displayName: 'DateTimeCell',

    render: function render() {
        var hideTime = (this.props.showTime && this.props.showTime[this.props.cellKey] === false) || this.props.showTime === 'no',
            disableUtcConversion = this.props.config.disableUtcConversion;

        var d1 = new Date(this.props.value * 1000);
        // TODO: rewrite this ternary mess
        var date = new Date(d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), d1.getUTCHours(), d1.getUTCMinutes(), d1.getUTCSeconds()),
            value = this.props.value !== null && this.props.value !== undefined && this.props.value !== '' ?
                $.format.date(
                    !disableUtcConversion ? date : d1,
                    (this.props.dateFormat + (hideTime ?
                        '' :
                        this.props.showSeconds === 'no' ?
                            '\xa0\xa0\xa0' + ' HH:mm' :
                            ' HH:mm:ss')
                    )
                ) :
                '-';

        return (<div className='date-time-cell'> {value} </div>)
    }
});

var StandardizedDateTimeCell = React.createClass({
    displayName: 'StandardizedDateTimeCell',
    propTypes: {
        value: React.PropTypes.string,
        dateFormat: React.PropTypes.string,
        sevenTimezoneMinuteOffset: React.PropTypes.number,
        showSeconds: React.PropTypes.string
    },
    render: function render() {
        var hideTime = (this.props.showTime && this.props.showTime[this.props.cellKey] === false) || this.props.showTime === 'no';
        var momentDateFormat = this.props.dateFormat.toUpperCase() + (hideTime ? '' : this.props.showSeconds === 'no' ? ' HH:mm' : ' HH:mm:ss');
        var value = this.props.value && moment.utc(this.props.value).add(this.props.sevenTimezoneMinuteOffset, 'm').format(momentDateFormat)|| '-';

        return (<div className='date-time-cell'>{value}</div>)
    }
});


var IconCell = React.createClass({
    displayName: 'IconCell',

    render: function render() {

        var className = 'n-i n-i-' + (this.props.value ? this.props.value.toLowerCase() : '');

        return (
            <div className="cell-icon">
                <i className={className}></i>
            </div>
         )
    }
});

var OnlineStatusCell = React.createClass({
    mixins: [TranslateMixin],

    render: function render() {
        var className = 'n-i n-i-' + (this.props.status ? this.props.status.toLowerCase() : '');
        var details = this.props.row.details;
        var tooltip;

        if (details) {
            tooltip = this.translate('betshop.num_i_devices_online', details.onlineDevices + '/' + details.totalDevices);
        }

        return (
            <div className="cell-icon" data-tip={tooltip}>
                <i className={className}></i>
                <ReactTooltip multiline={true} className="custom-react-tooltip"/>
            </div>
        )
    }
});

var DistributionStatusCell = React.createClass({
    displayName: 'IconCell',

    render: function render() {

        var className = 'distribution-status distribution-status-' + this.props.value;

        return (
            <div className='cell-icon' >
                <i className={className}></i>
            </div>
        )
    }
});

var RiskSelectCell = React.createClass({
    getInitialState: function () {
        return {
            value: String(this.props.value)
        }
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({value: String(newProps.value)})
    },

    handleChange: function (e) {
        this.setState({value: e.target.value});
        this.props.inputAction(e.target.value, this.props.cellKey, true);
    },

    render: function () {
        var data = this.props.config.riskFactors;

        var options = data.map(function (value) {
            return (<option value={String(value.id)}>{value.name + ' : ' + value.value}</option>)
        });

        return <div>
            <select value={this.state.value} onChange={this.handleChange} className="grid-select">
                <option value="null">None</option>
                {options}
            </select>
        </div>
    }
});

var GlobalSelectCell = React.createClass({
    getInitialState: function () {
        return {
            value: String(this.props.value)
        }
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({value: String(newProps.value)})
    },

    handleChange: function (e) {
        this.setState({value: e.target.value});
        this.props.inputAction(e.target.value, this.props.cellKey, true);
    },

    render: function () {
        var data = this.props.config[this.props.config.selectCellProp];

        var options = data.map(function (value) {
            return (<option value={String(value.id)}>{value.name}</option>)
        });

        return <div>
            <select value={this.state.value} onChange={this.handleChange} className="grid-select">
                <option value="null">None</option>
                {options}
            </select>
        </div>
    }
});

var LiabilitySelectCell = React.createClass({
    getInitialState: function () {
        return {
            value: String(this.props.value)
        }
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({value: String(newProps.value)})
    },

    handleChange: function (e) {
        this.setState({value: e.target.value});
        this.props.inputAction(e.target.value, this.props.cellKey, true);
    },

    render: function () {
        var data = this.props.config.limits.data,
            options = data.filter(function (value) {
                return value.active;
            }).map(function (value) {
                return (<option value={String(value.id)}>{value.name + ' : ' + value.value}</option>)
            });

        return <div>
            <select value={this.state.value} onChange={this.handleChange} className="grid-select">
                <option value="null">None</option>
                {options}
            </select>
        </div>
    }
});


var CategoryFlagCell = React.createClass({
    displayName: 'CategoryFlagCell',

    render: function render () {
        var value = this.props.value || '';
        return (
            <div className="flag-wrap">
                <i className={'flag flag-' + this.props.value}></i>
            </div>
         )
    }

});

var PreviewCell = React.createClass({
    displayName: 'PreviewCell',

    previewOffer: function () {
          this.props.previewOffer();
    },

    render: function render () {
        return (
            <div className="preview-icon" onClick={this.previewOffer}>
                <i className='n-i n-i-eye-b'></i>
            </div>
        )
    }

});

var ExcelExportCell = React.createClass({
    displayName: 'ExcelExportCell',

    exportToExcel: function () {
        this.props.exportToExcel();
    },

    render: function render () {
        return (
            <div className="preview-icon" onClick={this.exportToExcel}>
                <i className='n-i n-i-download'></i>
            </div>
        )
    }

});


var StatusCell = React.createClass({
    displayName: 'StatusCell',

    render: function render() {
        var val     = this.props.value,
            status  = val && val.value ? val.value.replace(/ /ig, '-').toLowerCase() : '',
            name    = val ? val.name : '';

        return (
                <div className={'grid-status-cell-' + status}> {name} </div>
            )
    }
});


var EditableBoolCell = React.createClass({
    displayName: 'EditableBoolCell',

    // State value must be initialized on load in order to change checkbox value
    getInitialState: function () {
        return {
            inputValue: this.props.value
        }
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({inputValue: newProps.value});
    },

    handleClick: function (e) {
        e.stopPropagation();
    },

    handleChange: function handleChange(e) {
        if (!this.props.checkboxToggleAction)
            return;

        this.props.checkboxToggleAction(!this.state.inputValue, this.props.cellKey, true);

        if (this.props.selectMatchAction)
            this.props.selectMatchAction(!this.state.inputValue, this.props.rowData);

        this.setState({
            inputValue: !this.state.inputValue
        });
    },


    render: function render() {
        var checkboxId = 'check-' + this.props.cellKey + '-' + this.props.rowKey,
            type = this.props.config.checkboxType || 'toggle',
            toggleDisabled = typeof this.props.checkboxToggleDisabled === 'function' ?
                this.props.checkboxToggleDisabled(this.props.cellKey) :
                false;

        if (type === 'toggle') {
            return(<div className={this.props.class || ''}>
                <label disabled={toggleDisabled} htmlFor={checkboxId} className={'checkbox-toggle-btn'} onClick = {this.handleClick}>
                    <input
                        id          = {checkboxId}
                        onChange    = {this.handleChange}
                        onClick    = {this.handleClick}
                        type        = 'checkbox'
                        disabled    ={toggleDisabled}
                        value       = {this.state.inputValue}
                        checked     = {this.state.inputValue}
                    />
                    <i>{}</i>
                </label>
            </div>);
        } else if (type === 'check') {
            return(<div className={this.props.class || ''}>
                <input
                    id={checkboxId}
                    onChange={this.handleChange}
                    checked={this.state.inputValue}
                    value= {this.state.inputValue}
                    type="checkbox"/>
                {/*<label htmlFor={checkboxId} className="checkbox-check-btn">
                    <input
                        id={checkboxId}
                        onChange={this.handleChange}
                        onClick = {this.handleClick}
                        checked={this.state.inputValue}
                        value= {this.state.inputValue}
                        type="checkbox"
                        className="normal-checkbox"/>
                    <i onClick = {this.handleClick}>{}</i>
                </label>*/}
            </div>);
        }
    }
});


var NotEditableBoolCell = React.createClass({
    displayName: 'NotEditableBoolCell',

    render: function render() {
        var value = this.props.value;

        return (
                <div>
                    <i className={value ? 'n-i n-i-check-a table-icon-true' : 'n-i n-i-close table-icon-false'}></i>
                </div>
            )
    }
});


var BoolCell = React.createClass({
    displayName: 'BoolCell',

    render: function render() {
        return (
                <div>
                    {function() {
                        if (this.props.editable || (
                                this.props.config.isBoolCellEditable &&
                                typeof this.props.config.isBoolCellEditable === 'function' &&
                                this.props.config.isBoolCellEditable(this.props.cellKey, this.props.rowData)
                            )
                        ) {
                            return (<EditableBoolCell
                                value={this.props.value}
                                rowData={this.props.rowData}
                                config={this.props.config}
                                rowKey={this.props.rowKey}
                                cellKey={this.props.cellKey}
                                selectMatchAction={this.props.selectMatchAction}
                                checkboxToggleDisabled={this.props.checkboxToggleDisabled}
                                checkboxToggleAction={this.props.checkboxToggleAction} />)
                        } else {
                            return <NotEditableBoolCell value={this.props.value} />
                        }
                    }.bind(this)()}
                </div>
            )
    }
});


var InputNumberCell = React.createClass({
    displayName: 'InputNumberCell',
    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
            inputValue: (this.props.value || this.props.value === 0) ? this.props.value : ''
        }
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({inputValue: (newProps.value || newProps.value === 0) ? newProps.value : ''});
    },

    handleClick: function (e) {
        e.stopPropagation();
    },

    handleChange: function (e) {
        if (this.props.inputAction) {
            this.props.inputAction(e.target.value, this.props.cellKey, false);
        }
        this.setState({inputValue: e.target.value});
        if (this.props.inputChangeAction) {
            this.props.inputChangeAction(e.target.value, this.props.cellKey);
        }
    },

    handleKeyDown: function (e) {
        if (!this.props.inputAction) return;

        // If enter is pressed, trigger controller function
        var submit = e.keyCode === 13;
        this.props.inputAction(this.state.inputValue, this.props.cellKey, submit);
    },

    render: function render() {

        return (
                <div className='grid-input grid-field-num'>
                    <input
                        type        = 'number'
                        className   = 'form-control input-sm grid-input'
                        placeholder = {this.translate('general.enter_value')}
                        onChange    = {this.handleChange}
                        onKeyDown   = {this.handleKeyDown}
                        onClick     = {this.handleClick}
                        value       = {this.state.inputValue}
                    />
                </div>
            )
    }
});


var InputTextCell = React.createClass({
    displayName: 'InputTextCell',
    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
            inputValue: this.props.value || ''
        }
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({inputValue: newProps.value || ''});
    },

    handleClick: function (e) {
        e.stopPropagation();
    },

    handleChange: function (e) {
        if (this.props.inputAction) {
            this.props.inputAction(e.target.value, this.props.cellKey, false);
        }
        this.setState({inputValue: e.target.value});
        if (this.props.inputChangeAction) {
            this.props.inputChangeAction(e.target.value, this.props.cellKey);
        }
    },

    handleKeyDown: function (e) {
        if (!this.props.inputAction) return;
        // If enter is pressed, trigger controller function
        var submit = e.keyCode === 13;
        if (submit && this.props.handleSubmit) {
            this.props.handleSubmit(this.state.inputValue, this.props.cellKey);
        }
        this.props.inputAction(this.state.inputValue, this.props.cellKey, submit);

    },

    render: function render() {

        return (
                <div className='grid-input'>
                    <input
                        type        = 'text'
                        title       = {this.props.hoverValue}
                        className   = 'form-control input-sm grid-input'
                        placeholder = {this.translate('general.enter_value')}
                        onChange    = {this.handleChange}
                        onKeyDown   = {this.handleKeyDown}
                        onClick     = {this.handleClick}
                        value       = {this.state.inputValue}
                    />
                </div>
            )
    }
});

var DeleteButtonCell = React.createClass({
    displayName: 'DeleteButtonCell',

    handleClick: function (e) {
        e.stopPropagation();
        this.props.handleDeleteAction();
    },

    render: function () {
        return (
            <div>
                <a className='edit-link'>
                    <i className="n-i n-i-close clickable" onClick={this.handleClick}>{}</i>
                </a>
            </div>);
    }
});


var EditButtonCell = React.createClass({
    displayName: 'EditButtonCell',


    handleEditClick: function handleClick (e) {
        e.stopPropagation();
        this.props.handleEditBtnClick();
    },

    handleDeleteClick: function handleClick () {
        this.props.handleDeleteBtnClick();
    },

    render: function render () {
        var deleteAction = null,
            editIcon = this.props.config.editActionClass
                ? 'n-i clickable ' + this.props.config.editActionClass
                : 'n-i n-i-edit clickable',
            editUrlAdditionalParams = this.props.config.editUrlAdditionalParams || '',
            iconCenterAlign = !!this.props.config.editCenterAligned;

        var ordinaryEdit =  (<a className='edit-link' href={this.props.config.toUrl + this.props.urlParam + editUrlAdditionalParams}>
            <i className={editIcon}>{}</i>
        </a>);

        var specialEdit = (<a className='edit-link'>
            <i className={editIcon} onClick={this.handleEditClick}>{}</i>
        </a>);

        var edit = this.props.config.specialEdit ? specialEdit : ordinaryEdit;

        if (this.props.config.deleteAction === 'yes') {
            deleteAction = (<a className='edit-link'> <i className="n-i n-i-close clickable" onClick={this.handleDeleteClick}>{}</i> </a>);
        }


        return (
            <div className={iconCenterAlign ? 'center-align' : ''}>
                {edit}
                {deleteAction}
            </div>

        );
    }
});

var InfoCell = React.createClass({

    render: function () {
        if (!this.props.value) return null;
        return (<div data-tip={this.props.value} className="cell-icon">
            <i className="n-i n-i-info"></i>
            <ReactTooltip multiline={true} className="custom-react-tooltip" />
        </div>);
    }

});


var TvPreviewCell = React.createClass({

    render: function render () {
        var value = this.props.value;
        var items = [];

        for (var i in value) {
            items.push(function () {
                return (<div
                    className       = {'inner-tv-wrap inner-tv-position-' + value[i].name.split('/')[0] + ' inner-tv-color-' + value[i].id_tv_layout_template.toLowerCase()}
                    title           = {value[i].name.split('/')[0] + ' - ' + value[i].id_tv_layout_template} >
                    { value[i].name.split('/')[0] }
                </div>);
            }.bind(this)())
        }

        return (
            <div className={'main-tv-wrap main-tv-wrap-' + this.props.tvKey}> {items} </div>
            )
    }

});


var ArrayCell = React.createClass({

    items: [],

    getInitialState: function() {
        var id = '';
        if (this.props.cellKey === 'id_tv_layout_match_display') {
            id = this.findSelectedId(this.props.value);
        }

        return {
            selected: id
        }
    },

    componentWillReceiveProps: function (newProps) {
        if (newProps.cellKey === 'id_tv_layout_match_display') {
            this.setState({selected: this.findSelectedId(newProps.value)});
        }
    },

    findSelectedId: function (data) {
        var id = '';
        data.forEach(function (value) {
            if (value.selected) {
                id = value.id;
            }
        });

        return id;
    },

    isLayoutSelected: function(id) {
        return id === this.state.selected ? 'selection-true' : '';
    },

    handleLayoutClick: function(value) {
        var id = (this.state.selected === value.id) ? '' : value.id;
        this.setState({
            selected: id
        });

        this.props.selectDisplayChange(value, this.props.rowData);
    },

    generateElements: function generateElements() {

        if (this.props.cellKey === 'tv_previews') {
            this.items = this.props.value.map(function (item, index) {
                return React.createElement(TvPreviewCell, {
                    key: 'tv-wrap-' + index,
                    tvKey: index,
                    value: item
                })
            });
        }

        // Display cells for Special+ matches
        if (this.props.cellKey === 'id_tv_layout_match_display') {
            this.items = this.props.value.map(function (item, index) {
               return React.createElement(
                   'i',
                   {
                       key: 'display-cell-' + index,
                       className: 'n-i n-i-' + item.id + ' ' + (item.id === this.state.selected && 'selection-true'),
                       onClick: this.handleLayoutClick.bind(this, item)
                   }
               )
            }.bind(this));
        }

        if (this.props.cellKey === 'selections') {
            this.items = React.createElement(SelectionComponent, {
                handleSelectionChange: this.props.handleSelectionChange,
                value: this.props.value,
                rowData: this.props.rowData
            })
        }
    },

    render: function render () {
        this.generateElements();

        var className = '';
        switch (this.props.cellKey) {
            case 'tv_previews':
                className = 'tv-previews-content';
                break;
            case 'id_tv_layout_match_display':
                className = 'selections-s-wrap';
                break;
        }

        return (
            <div className={className}>{this.items}</div>
        )

    }
});

var SelectionComponent = React.createClass({
    propTypes: {
        value: React.PropTypes.object,
        meta: React.PropTypes.object,
        handleSelectionChange: React.PropTypes.func,
        betBlocked: React.PropTypes.boolean
    },
    getInitialState: function (){
        var value = this.props.value;

        return {
            selection_0: value.selection_1 && value.selection_2 && value.selection_3 && value.selection_4,
            selection_1: value.selection_1,
            selection_2: value.selection_2,
            selection_3: value.selection_3,
            selection_4: value.selection_4,
            betBlocked: this.props.betBlocked
        }
    },

    componentWillReceiveProps: function (newProps) {
        var value = newProps.value;

        this.setState({
            selection_0: value.selection_1 && value.selection_2 && value.selection_3 && value.selection_4,
            selection_1: value.selection_1,
            selection_2: value.selection_2,
            selection_3: value.selection_3,
            selection_4: value.selection_4,
            betBlocked: newProps.betBlocked
        });
    },

    handleClick: function (index, event) {
        var nextState = _.clone(this.state),
            rowData = this.props.rowData,
            meta = this.props.meta,
            i;

        delete nextState.betBlocked;

        event.stopPropagation();

        if (index === 0) {
            nextState['selection_' + index] = !nextState['selection_' + index];

            for (i = 1; i < 5; i++) {
                nextState['selection_' + i] = nextState['selection_' + index];
            }
        } else {
            var allSelected = true;
            nextState['selection_' + index] = !nextState['selection_' + index];

            for (i = 1; i < 5; i++) {
                if (!nextState['selection_' + i]) {
                    allSelected = false;
                }
            }

            nextState.selection_0 = allSelected;
        }

        this.setState(nextState);
        this.props.handleSelectionChange(nextState, index, meta, rowData);
    },


    render: function () {
        var selectionClasses = ['star', 'retail', 'terminal', 'web', 'mobile'];

        return (<div className="selections-wrap">{
            selectionClasses.map(function (iconClass, index) {
                iconClass = 'n-i n-i-' + iconClass + ' selection-' + this.state['selection_' + index] + ' blocked-' + this.state.betBlocked;

                return (<i className={iconClass} role='checkbox' onClick={this.handleClick.bind(this, index)} key={index}>{}</i>);
            }, this)
        }</div>)
    }
});

var InputAutoComplete = React.createClass({
    mixins: [TranslateMixin],

    getInitialState: function () {
        return ({
            inputValue: this.props.value,
            displayList: false,
            listItems: this.props.config.listItems || []
        });
    },

    // Update state values
    componentWillReceiveProps: function (newProps) {
        this.setState({
            inputValue: newProps.value,
            displayList: false,
            listItems: newProps.config.listItems || []
        });
    },

    // Filter list items using input
    handleInputChange: function (evt) {
        var searchValues    = [],
            value           = evt.target.value,
            name            = null,
            listItems       = this.props.config.listItems;

        for(var i = 0; i < listItems.length; i++) {
            name = listItems[i].name.toLowerCase();
            if (name.indexOf(value.toLowerCase()) !== -1) {
                searchValues.push(listItems[i]);
            }
        }

        this.setState({listItems: searchValues, inputValue: value});
    },

    // Display list
    handleInputFocus: function () {
        this.setState({displayList: true, listItems: this.props.config.listItems || []})
    },

    // Hide list
    handleInputBlur: function () {
        setTimeout(function () {
            this.setState({displayList: false});
        }.bind(this), 100);
    },

    handleListClick: function (value) {
        this.props.inputAction(value, this.props.cellKey, true);
        this.setState({inputValue: value});
    },

    clearInput: function () {
        this.props.inputAction('', this.props.cellKey, true);
        this.setState({inputValue: ''});
    },

    render: function () {
        var listItems = this.state.listItems;
        return (
           <div className="grid-input autocomplete-row">
               <input
                   type        = 'text'
                   className   = 'form-control input-sm grid-input'
                   placeholder = {this.translate('product.find_match')}
                   value       = {this.state.inputValue}
                   onChange    = {this.handleInputChange}
                   onFocus     = {this.handleInputFocus}
                   onBlur      = {this.handleInputBlur}
               />
               <i className={this.state.inputValue ? "n-i n-i-close" : ''} onClick={this.clearInput} ></i>
               {function (){
                   if (!this.state.displayList) return;

                   return <ul className='autocomplete-options'>
                       {listItems.map(function (val) {
                           return <li onClick={this.handleListClick.bind(this, val.value)}>{val.name}</li>
                       }.bind(this))}

                   </ul>

               }.bind(this)()}

           </div>
        )
    }
});

var BinaryActionCell = React.createClass({
    propTypes: {
        cellBinaryAction: React.PropTypes.func,
        row: React.PropTypes.object,
        config: React.PropTypes.object
    },

    mixins: [TranslateMixin],

    handleApproveClick: function () {
        this.props.cellBinaryAction(this.props.row, 'approve');
    },

    handleDeclineClick: function () {
        this.props.cellBinaryAction(this.props.row, 'decline');
    },

    render: function renderBinaryActionCell(){
        const disableBinaryAction = this.props.config.disableBinaryAction;
        const approveButtonElement = (
            <button className="btn binary-action-pos" onClick={this.handleApproveClick}>{this.translate('general.approve')}</button>
        );
        const declineButtonElement = (
            <button className="btn binary-action-neg" onClick={this.handleDeclineClick}>{this.translate('general.decline')}</button>
        );
        let hasApprove = true;
        let hasDecline = true;

        if (_.isObject(disableBinaryAction)) {
            const column = this.props.row[disableBinaryAction.column];
            const columnValue = (column && column.value) || column;

            if (_.isArray(disableBinaryAction.values.approve)) {
                hasApprove = !disableBinaryAction.values.approve.includes(columnValue);
            }

            if (_.isArray(disableBinaryAction.values.decline)) {
                hasDecline = !disableBinaryAction.values.decline.includes(columnValue);
            }
        }

        const approveElement = (hasApprove && approveButtonElement) || '';
        const declineElement = (hasDecline && declineButtonElement) || '';

        return (
            <div>
                {approveElement}
                {declineElement}
            </div>
        );
    }
});

var ResultInputCell = React.createClass({
    displayName: 'ResultInputCell',
    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
            results: this.props.row.results || [],
            inputValue: this.props.value || ''
        }
    },

    handleChange: function (result,position,e) {
        var results = this.state.results;
        results.forEach(function (res) {
            if(res.id === result.id){
                if(position === 'first'){
                    res.first_value = e.target.value;
                    res.second_value = res.second_value || '';
                }
                else {
                    res.first_value = res.first_value || '';
                    res.second_value = e.target.value;
                }
                res.value = res.first_value + ':' + res.second_value;
            }
        });
        this.setState({results: results});
    },

    componentWillReceiveProps: function (nextProps) {
        if(nextProps.row.results !== this.props.row.results) {
            this.setState({results: nextProps.row.results});
        }
    },

    handleClick: function (e) {
        e.stopPropagation();
    },

    handleKeyDown: function (e) {
        if (!this.props.config.updateRowResults) return;

        // If enter is pressed, trigger controller function
        if(e.keyCode === 13){
            var results = _.clone(this.state.results);
            results.forEach(function (result,index) {
                if(!result.value) results.splice(index,1);
            });
            this.props.config.updateRowResults(this.props.row.eventId, results);
        }
    },

    handleInputFocus: function () {
        if(this.props.config.selectedRowIndex !== this.props.rowKey){
            this.props.rowSelectAction();
        }
    },

    render: function render() {
        var score = '',
            resultsCount = 0;
        if(this.state.results.length){
            score = this.state.results[1] ? '(' + this.state.results[0].value + ') ' +  this.state.results[1].value  : this.state.results[0].short_name === 'FT' ? this.state.results[0].value : '(' + this.state.results[0].value + ') ';
        }
        if(score === ':') score = '';
        var resultSummary = this.state.results.length && this.state.results[0].value ?
            <div className="grid-result-score-width">
                <span className="grid-results-summary">{score}</span>
            </div> : '';


        return (
            <div className='grid-result-wrapper grid-result-input' onClick={this.handleClick}>
                {this.state.results.map(function (result) {
                    if(result.value_type === 'SCORE') {
                        if(resultsCount < 2){
                            if (result.value) {
                                result.first_value = result.value.split(':')[0];
                                result.second_value = result.value.split(':')[1];
                            }
                            else {
                                result.first_value = '';
                                result.second_value = '';
                            }
                            resultsCount++;
                            return <div className="grid-result-input-width">
                                <span className="grid-result-name">{result.short_name}</span>
                                <span>
                            <input
                                type='text'
                                className='form-control input-sm grid-result-input grid-result-input-sm'
                                value={result.first_value}
                                onKeyDown={this.handleKeyDown}
                                onChange={this.handleChange.bind(null, result, 'first')}
                                onFocus={this.handleInputFocus}
                            />
                        </span>
                                <span>
                            <input
                                type='text'
                                className='form-control input-sm grid-result-input grid-result-input-sm'
                                value={result.second_value}
                                onKeyDown={this.handleKeyDown}
                                onChange={this.handleChange.bind(null, result, 'second')}
                                onFocus={this.handleInputFocus}
                            />
                        </span>
                            </div>
                        }
                    }
                }.bind(this))}
                {resultSummary}
            </div>
        )
    }
});

var ExpandArrowCell = React.createClass({
    displayName: 'ExpandArrowCell',
    mixins: [TranslateMixin],

    handleClick: function (e) {
        e.stopPropagation();
        this.props.expandArrowClick(this.props.row, this.props.rowKey);
    },

    render: function (){
        return (
            <div>
                <i className='n-i n-i-arrow-down-a result-grid-icon clickable' onClick={this.handleClick}>{}</i>
            </div>
        )
    }
});

function TicketAuthCheckbox (props) {

    var handleChange = function (e) {
        props.checkboxToggleAction(e.target.checked, props.cellKey);
    };

    var checkId = 'ticket-auth-check-' + props.cellKey;

    return <div>
        <label htmlFor={checkId} className={'checkbox-toggle-btn'}>
            {props.text}
            <input
                id          = {checkId}
                onChange    = {handleChange}
                type        = 'checkbox'
                checked     = {props.value}
            />
            <i>{}</i>
        </label>
    </div>
}

var SelectDropdown = React.createClass({
    getInitialState: function () {
        return {
            value: this.props.value,
            opened: false,
            searchVal: ''
        }
    },

    collapseDropdown: function() {
        this.setState({
            opened: false,
        });
    },

    expand: function() {
        this.setState({
            opened: true,
        });
    },

    handleOutsideClick: function (e) {
        if (!this.state.opened) return;
        if (this.componentRef && this.componentRef.contains(e.target)) {
            return;
        }
        this.collapseDropdown();
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({value: newProps.value})
    },

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick, { passive: true });
    },

    handleChange: function (value, e) {
        this.setState({value: value});
        this.props.inputAction(value, this.props.cellKey, true);
        this.collapseDropdown(e);
    },

    setRef: function(ref) {
        this.componentRef = ref;
    },

    toggleDropdown: function(e) {
        if(!this.state.opened) {
            document.addEventListener('click', this.handleOutsideClick, { passive: true });
        } else {
            document.removeEventListener('click', this.handleOutsideClick, { passive: true });
        }
        this.setState({
            opened: !this.state.opened
        });
    },

    searchValues: function(e) {
        this.setState({
            searchVal: e.target.value
        });
    },

    render: function () {
        var data = this.props.config[this.props.config.selectCellProp];
        var selected = _.find(data, { id : this.state.value });

        var options = this.state.opened && data.map(function (value) {
            return (
                (this.state.searchVal === '' || value.name.toLowerCase().indexOf(this.state.searchVal.toLowerCase()) > -1)
                && <div
                        onClick={this.handleChange.bind(null, value.id)}
                        className="sm-dropdown-select-option">
                        {value.name}
                    </div>);
        }.bind(this));

        return (
            <div
                ref={this.setRef}
                className="sm-dropdown-select"
                onClick={this.toggleDropdown}>
                <div
                    className={["sm-dropdown-selected-value", this.state.opened ? "focused" : "" ].join(' ')}>
                    {
                        this.state.opened
                            ? <input
                                autoFocus
                                tabIndex="0"
                                className="dropdown-search-input"
                                type="text"
                                onChange={this.searchValues}
                                value={this.state.searchVal}></input>
                            : (selected && selected.name) || ''
                    }
                    <i className="n-i n-i-arrow-down-b"></i>
                </div>
                {
                    this.state.opened && <div className="sm-dropdown-select-list">
                        <div
                            onClick={this.handleChange.bind(null, null)}
                            className="sm-dropdown-select-option">
                            None
                        </div>
                        {options}
                    </div>
                }
            </div>
        );
    }
});
;
var TicketAuthActions = React.createClass({
    mixins: [TranslateMixin],
    interval: null,

    getInitialState: function () {
        return {
            value: 0,
            checkedValues: {
                myTickets: false,
                acceptingTickets: false,
                sendTicketsToLiability: false
            }
        }
    },

    setDelayValue: function (value) {
        value = value >= 0 ? value : 0;
        this.setState({value: value});
    },

    handleFilterChange: function (value, key) {
        var checkedValues = this.state.checkedValues;
        checkedValues[key] = value;

        if (key === 'sendTicketsToLiability' && value) {
            checkedValues.acceptingTickets = true;
            this.props.ticketAuthActionChange('filters', true, 'acceptingTickets');
        }

        if (key === 'acceptingTickets' && !value) {
            checkedValues.sendTicketsToLiability = false;
            this.props.ticketAuthActionChange('filters', false, 'sendTicketsToLiability');
        }

        this.setState({checkValue: checkedValues});
        this.props.ticketAuthActionChange('filters', value, key);
    },

    handleDelayClick: function (e) {
        this.props.ticketAuthActionChange('delay');
    },

    render: function () {
        return(<div className='col-12 ticket-auth-actions'>
            <div className="ticket-auth-item">
                <TicketAuthCheckbox
                    text={this.translate('sportsbook.tauth_my_tickets')}
                    value={this.state.checkedValues.myTickets}
                    cellKey='myTickets'
                    checkboxToggleAction={this.handleFilterChange}
                />
            </div>

            <div className="ticket-auth-item">
                <TicketAuthCheckbox
                    text={this.translate('sportsbook.tauth_accepting_tickets')}
                    value={this.state.checkedValues.acceptingTickets}
                    cellKey='acceptingTickets'
                    checkboxToggleAction={this.handleFilterChange}
                />
            </div>

            <div className="ticket-auth-item">
                <TicketAuthCheckbox
                    text={this.translate('sportsbook.tauth_send_to_liability')}
                    value={this.state.checkedValues.sendTicketsToLiability}
                    cellKey='sendTicketsToLiability'
                    checkboxToggleAction={this.handleFilterChange}
                />
            </div>

            <div className="ticket-auth-delay-item">
                <div className="ticket-auth-count">
                    {this.state.value}
                </div>
                <div className="ticket-auth-interval">
                    <div className="ticket-auth-clock col-6">
                        <i className="n-i n-i-clock"></i>
                    </div>
                    <div className="ticket-auth-add-interval col-6" onClick={this.handleDelayClick}>+60</div>
                </div>
            </div>
        </div>)
    }
});


// Export table data
var ExportTableAction = React.createClass({
    mixins: [TranslateMixin, LocationMixin],

    getInitialState: function getInitialState () {
        var exportDetails = this.props.config.detailsExportSelector;
        // To stay backwards compatible with detailsExportSelector (boolean) that denoted both will details export be
        // displayed and selected check type of this.props.config.detailsExportSelector
        var exportDetailsIsObject = typeof exportDetails === 'object';
        var exportDetailsEnabled = exportDetailsIsObject ? exportDetails.enabled : exportDetails;
        var exportDetailsSelected = exportDetailsIsObject ? exportDetails.selected : exportDetails;
        var exportDetailsLabel = (exportDetails && exportDetails.selectorLabel) || this.translate('general.export_details');

        return ({
            showExportContent: false,
            selected: this.props.config.disableExportAllColumns === 'yes' ? 'selected-columns' : 'all-columns',
            exportDetails: exportDetailsSelected,
            exportDetailsEnabled: exportDetailsEnabled,
            exportDetailsLabel: exportDetailsLabel
        });
    },


    generateExportUrl: function generateExportUrls (format) {
        var config = this.props.config,
            storageColumns = this.getLocalColumns(config.columnSaveKey),
            columns = storageColumns ? storageColumns : this.props.columns,
            searchString = window.location.href.split('?')[1],
            dateFakingEnabled = this.props.dateFakingEnabledForView;

        if (dateFakingEnabled) {
            searchString = this.props.getFakedExportDateParams(searchString);
        }

        var urlFilterParams = '?requestFormat=' + format + '&' + searchString;
        var columnsToExport = 'exportColumns=';

        for (var i in columns) {
            if ((this.state.selected === 'selected-columns' && columns[i].visibility) || this.state.selected === 'all-columns') {
                if (!columns[i].hidden) {
                    columnsToExport += i + ',';
                }
            }
        }

        columnsToExport += '&sevenToken=' + localStorage.getItem('SevenAccessToken');

        columnsToExport += '&sevenLocal=' + this.props.sevenLocalInfo.sevenLocal;
        columnsToExport += '&sevenTimezone=' + this.props.sevenLocalInfo.sevenTimezone;

        if (config.additionalExportQueryParams) {
            for (var key in config.additionalExportQueryParams)
                if (config.additionalExportQueryParams.hasOwnProperty(key) && key !== 'companyUuid')
                    columnsToExport += '&' + key + '=' + config.additionalExportQueryParams[key];
        }

        if (config.companyUuid)
            columnsToExport += '&companyUuid=' + config.companyUuid;


        if (config.detailsExportSelector) {
            columnsToExport += '&exportDetails=' + this.state.exportDetails;
        }

        return this.props.exportRoute + (config.ignoreExportFormat === 'yes' ? '' : ('.' + format)) + urlFilterParams + '&' + columnsToExport;
    },

    getCustomExportParameters: function(format) {
        var searchString = window.location.href.split('?')[1];
        var dateFakingEnabled = this.props.dateFakingEnabledForView;
        var config = this.props.config;
        var storageColumns = this.getLocalColumns(config.columnSaveKey);
        var columns = storageColumns ? storageColumns : this.props.columns;
        var exportParameters;

        if (dateFakingEnabled) {
            searchString = this.props.getFakedExportDateParams(searchString);
        }

        exportParameters = {
            format: format,
            filtersUrlFragment: searchString,
            exportColumns: [],
            token: localStorage.getItem('SevenAccessToken')
        };

        for (var column in columns) {
            if ((this.state.selected === 'selected-columns' && columns[column].visibility && !columns[column].hidden) || this.state.selected === 'all-columns') {
                exportParameters.exportColumns.push(column)
            }
        }

        if (config.additionalExportQueryParams) {
            exportParameters.additionalExportParams = {};
            for (var key in config.additionalExportQueryParams)
                if (config.additionalExportQueryParams.hasOwnProperty(key) && key !== 'companyUuid')
                    exportParameters.additionalExportQueryParams[key] = config.additionalExportQueryParams[key];
        }

        if (config.companyUuid) {
            exportParameters.companyUuid = config.companyUuid;
        }

        if (config.detailsExportSelector) {
            exportParameters.exportDetails = this.state.exportDetails;
        }

        return exportParameters;
    },

    componentWillMount: function () {
        window.addEventListener('click', this.handleGlobalClick);
    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('click', this.handleGlobalClick);
    },

    handleGlobalClick: function () {
        if (this.state.showExportContent)
            this.setState({
                showExportContent: false,
            });
    },

    handleClick: function handleClick (event) {
        event.stopPropagation();
        this.setState({
            showExportContent: true,
        });
    },

    handleExportDetailsCheckboxChange: function (event) {
        this.setState({
            exportDetails: event.target.checked
        });
    },

    handleExportClick: function (event) {
        event.stopPropagation();
        this.setState({
            showExportContent: false,
        });

        if (this.props.onExport && typeof this.props.onExport === 'function') {
            this.props.onExport(this.props);
        }
    },

    handleCustomExportClick: function(format) {
        var self = this;

        return function (event) {
            self.props.config.export.method(self.getCustomExportParameters(format));
            event.stopPropagation();
            self.setState({
                showExportContent: false,
            });

            if (self.props.onExport && typeof self.props.onExport === 'function') {
                self.props.onExport(self.props);
            }
        }
    },

    // TODO: Componetize to prevent binding
    handleChange: function (selected) {
        this.setState({selected: selected})
    },

    render: function render () {
        var exportConfig = this.props.config.export;
        var formats = this.props.formats.length ? this.props.formats : ['xls', 'xlsx'];
        var formatButtons = formats.map(function (value) {
            return <div className='btn export-format-btn'>
                { exportConfig && exportConfig.useCustomMethod && exportConfig.method && typeof exportConfig.method === "function" ?
                    <div className="export-link" onClick={this.handleCustomExportClick(value)}> {value.toUpperCase()} </div>:
                    <a className="export-link" href={this.generateExportUrl(value)} onClick={this.handleExportClick}  target='_blank'> {value.toUpperCase()} </a>
                }
            </div>
        }, this);

        return (
                <div className='table-settings table-header-div' onClick={this.handleClick} title={this.translate('general.export')} >
                    <i className='n-i n-i-download'>
                        {function () {
                            if (!this.state.showExportContent) return '';

                            return <div className='file-formats'>
                                <div className="export-header-title">
                                    Export
                                </div>
                                <div className="export-selection">
                                    {function() {
                                        if (this.props.config.disableExportAllColumns === 'yes') return '';

                                        return (
                                            <label className="export-selection-item">
                                                <input type="radio"
                                                   className="normal-radio-btn"
                                                   name="export-all-columns"
                                                   key="all-columns"
                                                   checked={this.state.selected === 'all-columns'}
                                                   onChange={this.handleChange.bind(this, 'all-columns')}/>
                                                   <i></i>
                                                   <span className="export-selection-item-name">{this.translate('general.all_columns')}</span>
                                            </label>
                                        );
                                    }.bind(this)()}
                                    {function () {
                                        if (this.props.config.disableExportSelectedColumns) return ''

                                        return (
                                            <label className="export-selection-item">
                                                <input type="radio"
                                                       className="normal-radio-btn"
                                                       name="export-selected-columns"
                                                       key="selected-columns"
                                                       checked={this.state.selected === 'selected-columns'}
                                                       onChange={this.handleChange.bind(this, 'selected-columns')}/>
                                                <i></i>
                                                <span className="export-selection-item-name">{this.translate('general.selected_columns')}</span>
                                            </label>
                                        );
                                    }.bind(this)()}
                                </div>
                                <div className="export-formats">{formatButtons}</div>
                                {function () {
                                    // Enables option in export menu for selecting/deselecting export of table details
                                    if (!this.state.exportDetailsEnabled) return '';

                                    return (
                                        <div className="export-details-selection">
                                            <label className="checkbox-toggle-btn checkbox-toggle-right"  htmlFor="export_details">
                                                <input
                                                    type = 'checkbox'
                                                    name="export_details"
                                                    id="export_details"
                                                    onChange={this.handleExportDetailsCheckboxChange}
                                                    value={this.state.exportDetails}
                                                    checked={this.state.exportDetails}
                                                />
                                                <i className="checkbox"></i>
                                                <span className="checkbox-toggle-label">{this.state.exportDetailsLabel}</span>
                                            </label>
                                        </div>
                                    );
                                }.bind(this)()}
                            </div>
                        }.bind(this)()}
                    </i>
                </div>
            )
    }
});

// Grid auto reload

var AutoReloadTableAction = React.createClass({
    mixins: [TranslateMixin],

    getInitialState: function getInitialState () {
        return ({showContent: false, selectedRefresh: 0});
    },

    handleGlobalClick: function handleGlobalClick () {
        this.setState({showContent: false});
    },

    componentWillMount: function componentWillMount () {
        this.refreshRate = [
            {name: this.translate('general.no_refresh'), duration: 0, selected: true},
            {name: this.translate('general.num_pre_seconds', '5'), duration: 5, selected: false},
            {name: this.translate('general.num_pre_seconds', '10'), duration: 10, selected: false},
            {name: this.translate('general.num_pre_seconds', '30'), duration: 30, selected: false},
            {name: this.translate('general.num_pre_seconds', '60'), duration: 60, selected: false},
            {name: this.translate('general.num_pre_minutes', '5'), duration: 300, selected: false}
        ];

        window.addEventListener('click', this.handleGlobalClick);
    },

    componentWillUnmount: function componentWillMount () {
        window.removeEventListener('click', this.handleGlobalClick);
    },

    handleChange: function handleChange (index, duration, e) {
        var self = this;
        this.props.changeAutoRefresh(index, duration);
        this.setState({selectedRefresh: index});
        setTimeout(function () {
            self.setState({showContent: false});
        }, 300);

    },

    handleClick: function handleClick (e) {
        this.setState({showContent: !this.state.showContent})
    },

    handleWrapClick: function handleClick (e) {
        e.stopPropagation();
    },

    render: function render () {


        return (
                <div onClick={this.handleWrapClick}>
                    <div className='table-settings table-header-div' title='Set table auto reload' onClick={this.handleClick}>
                        <i className='n-i n-i-refresh-a'></i>
                    </div>
                    {function(){
                        if (!this.state.showContent) return '';

                        return <div className='refresh-select'>
                            <p> {this.translate('general.auto_reload')} </p>

                            {function (){
                                return this.refreshRate.map(function (val, i) {
                                    return <div className='single-select-radio' key={'refresh-rate' + i}>
                                        <label>
                                            <input
                                                type        = 'radio'
                                                className   = 'normal-radio-btn'
                                                name        = 'auto-refresh-btns'
                                                checked     = {this.state.selectedRefresh === i}
                                                onChange    = {this.handleChange.bind(this, i, val.duration)}
                                            />
                                            <i></i>
                                            {val.name}
                                        </label>
                                    </div>
                                }.bind(this))
                            }.bind(this)()}

                        </div>
                    }.bind(this)()}
                </div>
            )
    }
});



var OpenChartAction = React.createClass({
    mixins: [TranslateMixin],

    handleClick: function (evt) {
        var chart = document.getElementById('column-chart-wrapper');

        if (chart.classList.contains('chart-wrapper-hide')) {
            chart.classList.remove('chart-wrapper-hide');
            chart.classList.add('column-chart-wrapper-show');
            setTimeout(function () {
                chart.classList.add('chart-wrapper-show-overflow');
            }, 1000);
            this.props.chartButtonClick(true);
        } else {
            this.props.chartButtonClick(false);
            chart.classList.remove('column-chart-wrapper-show', 'chart-wrapper-show-overflow');
            chart.classList.add('chart-wrapper-hide');
        }
    },

    render: function render () {
        return (
                <div className='table-settings table-header-div' title={this.translate('general.show_graph')} onClick={this.handleClick} >
                    <i className='n-i n-i-reporting'></i>
                </div>
            )
    }
});

var FilterActions = React.createClass({
    mixins: [TranslateMixin],
    getInitialState: function () {
        return ({show: false});
    },
    handleOutsideClick: function () {
        this.close();
    },
    componentWillMount: function () {
        window.addEventListener('click', this.handleOutsideClick);
    },
    componentWillUnmount: function () {
        window.removeEventListener('click', this.handleOutsideClick);
    },
    close: function () {
        this.setState({show: false})
    },
    toggle: function (e) {
        this.stopClick(e);
        this.setState({show: !this.state.show})
    },
    stopClick: function (e) {
        e.stopPropagation();
    },
    render: function render() {
        return (
            <div>
                <div className='table-settings table-header-div' title={this.translate('general.filters')} onClick={this.toggle}>
                    <i className='n-i n-i-filter-c'></i>
                </div>
                {this.state.show &&
                <div className='table-header-callout'>
                    <div className='callout-header'>{this.translate('general.filters')}</div>
                    <div className='callout-body'>
                        {!this.props.config.disableRememberFilters && <div className="btn export-format-btn" onClick={this.props.rememberFilters}>{this.translate('general.remember_filters')}</div>}
                        {!this.props.config.disableRememberFilters && <div className="btn export-format-btn" onClick={this.props.removeRememberedFilters}>{this.translate('general.remove_remembered_filters')}</div>}
                        { this.props.config.disableReset !== 'yes' && <div className="btn export-format-btn" onClick={this.props.resetFilters}>{this.translate('general.reset_filters')}</div>}
                    </div>
                </div>}
            </div>
        )
    }
});


var GridActions = React.createClass({

    propTypes: {
        config: React.PropTypes.object
    },

    render: function render () {
        var config = this.props.config;

        return (
                <div className='table-header-options'>
                    {this.props.filtersExist &&
                    <FilterActions rememberFilters={this.props.rememberFilters}
                                   removeRememberedFilters={this.props.removeRememberedFilters}
                                   resetFilters={this.props.resetFilters}
                                   config={this.props.config}></FilterActions>}
                    {function(){
                        if (config.autoReload === 'yes')
                            return <AutoReloadTableAction changeAutoRefresh={this.props.changeAutoRefresh} />
                    }.bind(this)()}

                    {function () {
                        if (config.disableExport === 'yes') return null;
                        return (<ExportTableAction urlFilters={this.props.urlFilters}
                                                  exportRoute={config.exportRoute}
                                                  config={config}
                                                  columns={this.props.columns}
                                                  formats={this.props.supportedExportFormats}
                                                  sevenLocalInfo = {this.props.sevenLocalInfo}
                                                  dateFakingEnabledForView={this.props.dateFakingEnabledForView}
                                                  getFakedExportDateParams={this.props.getFakedExportDateParams}
                                                  onExport={this.props.onExport}/>)
                    }.bind(this)()}

                    {function(){
                        if (config.chart === 'yes')
                            return <OpenChartAction chartButtonClick={this.props.chartButtonClick} />
                    }.bind(this)()}

                </div>
            )
    }

});
;

var ContextMenu = React.createClass({

    handleClick: function (id, evt) {
        this.props.resolveContextMenuAction(id);
    },


    render: function () {
        if (!this.props.rcActions) return null;

        var className = 'react-context-menu context-menu dropdown-menu';
        className += this.props.fixedPosition ? ' fixed' : '';

        return (
                <ul className={className} id={this.props.idValue ? this.props.idValue : 'react-context-menu'}>
                    {function (){
                        return this.props.rcActions.map(function(action) {
                            if(action.id === 'divider'){
                                return <li className='divider-li'><div className={action.class}></div></li>
                            }
                            return <li onClick={this.handleClick.bind(this, action.id)}>
                                <i className={action.class}>{}</i>
                                {action.displayName}
                            </li>
                        }.bind(this));
                    }.bind(this)()}
                </ul>
            )
    }
});

var TableSums = React.createClass({

    propTypes: {
        sums: React.PropTypes.object,
        columns: React.PropTypes.object,
        config: React.PropTypes.object
    },

    render: function render () {

        var cells = [],
            sum = '',
            val = '';

        for (var i in this.props.columns) {
            if (!this.props.columns[i].hidden && this.props.columns[i].visibility) {
                sum = this.props.sums[i];
                val = sum ? (sum.totalSum ? sum.totalSum : sum.pageSum) : ' ';

                sum = this.props.sums[i];
                cells.push(React.createElement('td', {className: 'sensitive-data ' + (this.props.columns[i].type === 'number' ? 'integer-cell' : '') }, val));
                /*cells.push(function(){
                    return <td className='integer-cell' > {val} </td>
                });*/
            }
        }

        return (
                <tr className='table-sum-row sensitive-data'>
                    {cells}

                    {function(){
                        var sum = this.props.sums.edit,
                            val = sum ? (sum.totalSum ? sum.totalSum : sum.pageSum) : ' ';
                        if (this.props.config.editAction === 'yes')
                            return <td>{val}</td>
                    }.bind(this)()}
                    <td></td>
                </tr>
            )
    }
});


/**
 * Table body cell component
 * Create list of components depending on it's types
 */
var TableBodyCell = React.createClass({
    displayName: 'TableCell',

    render: function render() {
        return (
            <td>
                {function(){
                    var value = this.props.value;

                    switch (this.props.type) {

                        case 'string'            :return <StringCell hoverValue={this.props.hoverValue} value={value} config={this.props.config} cellKey={this.props.cellKey}/>; break;
                        case 'number'            :return <IntegerCell value={value} />; break;
                        case 'datetime'          :return <DateTimeCell value={value} dateFormat={this.props.dateFormat} config={this.props.config} showTime={this.props.config.showTimeInGrid} showSeconds={this.props.config.showSecondsInGrid} cellKey={this.props.cellKey}/>; break;
                        case 'standardized-datetime': return (<StandardizedDateTimeCell
                            value={value}
                            dateFormat={this.props.dateFormat}
                            config={this.props.config}
                            showTime={this.props.config.showTimeInGrid}
                            showSeconds={this.props.config.showSecondsInGrid}
                            sevenTimezoneMinuteOffset={this.props.sevenLocalInfo.sevenTimezoneMinuteOffset}
                            cellKey={this.props.cellKey}/>);
                        case 'icon'              :return <IconCell value={value} />; break;
                        case 'distribution-status':return <DistributionStatusCell value={value} />; break;
                        case 'online-status'     : return <OnlineStatusCell status={value} row={this.props.rowData} />; break;
                        case 'category-flag'     :return <CategoryFlagCell value={value} />; break;
                        case 'preview'           :return this.props.rowData.hidePreview !== 'yes' && <PreviewCell previewOffer={this.props.previewOffer}/>; break;
                        case 'excelExport'       :return this.props.config.excelDownloadDisabledTypes.indexOf(this.props.rowData.configType) === -1 && <ExcelExportCell exportToExcel={this.props.exportToExcel}/>; break;
                        case 'risk-select'       :return <RiskSelectCell config={this.props.config} inputAction={this.props.inputAction} cellKey={this.props.cellKey} value={this.props.value}/>; break;
                        case 'liability-select'  :return <LiabilitySelectCell config={this.props.config} inputAction={this.props.inputAction} cellKey={this.props.cellKey} value={this.props.value}/>; break;
                        case 'global-select'     :return <GlobalSelectCell config={this.props.config} inputAction={this.props.inputAction} cellKey={this.props.cellKey} value={this.props.value}/>; break;
                        case 'delete'            :return <DeleteButtonCell handleDeleteAction={this.props.handleDeleteAction}/>; break;
                        case 'status'            :return <StatusCell value={value} />; break;
                        case 'info'              :return <InfoCell value={value} config={this.props.config} />; break;
                        case 'bool'              :return <BoolCell value={value} config={this.props.config} rowKey={this.props.rowKey} editable={this.props.column.editable} cellKey={this.props.cellKey} rowData={this.props.rowData} checkboxToggleAction={this.props.checkboxToggleAction} checkboxToggleDisabled={this.props.config.checkboxToggleDisabled} selectMatchAction={this.props.selectMatchAction} />; break;
                        case 'input-number'      :return <InputNumberCell value={value} config={this.props.config} inputAction={this.props.inputAction} inputChangeAction={this.props.inputChangeAction} cellKey={this.props.cellKey} />; break;
                        case 'input-text'        :return <InputTextCell hoverValue={this.props.hoverValue} value={value} config={this.props.config} handleSubmit={this.props.handleSubmit} inputAction={this.props.inputAction} inputChangeAction={this.props.inputChangeAction} cellKey={this.props.cellKey} />; break;
                        case 'input-autocomplete':return <InputAutoComplete value={value} inputAction={this.props.inputAction} inputChangeAction={this.props.inputChangeAction} cellKey={this.props.cellKey} config={this.props.config} />; break;
                        case 'array'             :return <ArrayCell value={value} rowData={this.props.rowData} selectDisplayChange={this.props.selectDisplay} handleSelectionChange={this.props.handleSelectionChange} cellKey={this.props.cellKey} />; break;
                        case 'binary-action'     :return <BinaryActionCell row={this.props.rowData} cellBinaryAction={this.props.cellBinaryAction} config={this.props.config}/>; break;
                        case 'result-input'      :return <ResultInputCell row={this.props.rowData} config={this.props.config} rowKey={this.props.rowKey} rowSelectAction={this.props.clickRow} highlightRow={this.props.highlightRow}/>; break;
                        case 'expand-arrow'      :return <ExpandArrowCell row={this.props.rowData} config={this.props.config} rowKey={this.props.rowKey} expandArrowClick={this.props.expandArrowClick}/>; break;
                        case 'custom-select-dropdown':return <SelectDropdown config={this.props.config} inputAction={this.props.inputAction} cellKey={this.props.cellKey} value={this.props.value}/>; break;
                        default                  :return <EmptyCell />; break;
                    }

                }.bind(this)()}
            </td>
        );
    }
});


/**
 * Table body row component
 * Will create list of @TableBodyCell components
 * Will handle row click if 'rowSelectable' property is provided in config
 */
var TableBodyRow = React.createClass({
    displayName: 'TableRow',

    handleClick: function handleClick (evt) {

        if(this.props.config.multiSelect === 'yes'){
            this.props.multiSelectClick(this.props.data, this.props.rowKey);
        }

        if (this.props.config.rowSelectable !== 'yes') return;

        if(this.props.config.multiSelect !== 'yes'){
            this.props.highlightRow(this.props.rowKey);
            this.props.rowSelectAction(this.props.data, this.props.rowKey, 0);
        }
    },

    // Hide all filters
    handleGlobalClick: function handleGlobalClick (e) {
        var elemId = this.props.config.contextMenuId || 'react-context-menu';
        $('#' + elemId).css({display: 'none'});
    },

    // Add event for global click when component is loaded
    componentWillMount: function () {
        window.addEventListener('click', this.handleGlobalClick);
    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('click', this.handleGlobalClick);
    },

    handleContextMenu: function (evt) {
        var data = this.props.data;
        $('#react-context-menu').css({display: 'none'});

        if(this.props.config.contextMenu !== 'yes') return;
        evt.preventDefault();
        if (this.props.config.contextMenuCondition && !this.props.config.contextMenuCondition(data)) return;

        var x = evt.pageX - $('#react-table').offset().left;
        var y = evt.pageY - $('#react-table').offset().top;

        this.props.setContextMenuData(this.props.data);
        this.props.setContextMenuPosition(x, y);
    },


    handleEditAction: function () {
        this.props.tableEditAction(this.props.data);
    },

    handleDeleteAction: function () {
        this.props.tableDeleteAction(this.props.data);
    },

    handleInputChange: function (value, cellKey, submit) {
        if (!this.props.inputAction) return;

        this.props.data[cellKey] = value;

        if (submit) {
            this.props.inputAction(this.props.data, this.props.rowKey, cellKey);
        }
    },

    handlePreviewClick: function () {
        this.props.previewOffer(this.props.data);
    },

    handleExportToExcelClick: function () {
        this.props.exportToExcel(this.props.data);
    },

    render: function render() {
        var self = this,
            data = this.props.data,
            columns = this.props.columns,
            config = this.props.config,
            cells = [],
            disabledEdit = this.props.config.disabledEdit,
            multipleEditActions = _.get(config, 'editAction.multiple'),
            rowClass = 'table-content-row',
            hoverValue;

        if (this.props.config.rowSelectable === 'yes') {
            rowClass += ' clickable';
        }

        if (this.props.first) {
            rowClass += ' first';
        }
        if (this.props.last) {
            rowClass += ' last';
        }

        if(this.props.selectedResults && this.props.selectedResults.indexOf(this.props.rowKey) !== -1){
            rowClass += ' selected';
        }

        for (var i in columns) {

                // This is used to display international translations on mouse hover, if they're available ofc
                hoverValue = config.hasOwnProperty('translateColumn') && config.translateColumn.hasOwnProperty(i) && data[ config.translateColumn[i] ] ? data[ config.translateColumn[i] ] : '';

                if (columns[i].visibility && !columns[i].hidden) {
                    cells.push(
                        <TableBodyCell
                            column                  = {columns[i]}
                            value                   = {data[i]}
                            hoverValue              = {hoverValue}
                            config                  = {this.props.config}
                            type                    = {columns[i].type}
                            key                     = {this.props.rowKey + '-cell-' + i}
                            rowKey                  = {this.props.rowKey}
                            rowData                 = {this.props.data}
                            dateFormat              = {this.props.dateFormat}
                            cellKey                 = {i}

                            sevenLocalInfo          = {this.props.sevenLocalInfo}

                            handleDeleteAction      = {this.handleDeleteAction}
                            previewOffer            = {this.handlePreviewClick}
                            exportToExcel           = {this.handleExportToExcelClick}
                            cellBinaryAction        = {this.props.cellBinaryAction}
                            checkboxToggleAction    = {this.handleInputChange}
                            inputAction             = {this.handleInputChange}
                            selectMatchAction       = {this.props.checkboxToggleAction}
                            selectDisplay           = {this.props.selectDisplay}
                            handleSelectionChange   = {this.props.handleSelectionChange}
                            highlightRow            = {this.props.highlightRow}
                            expandArrowClick        = {this.props.expandArrowClick}
                            clickRow                = {this.handleClick}
                        />);
                }
            }

        return (
            <tr onClick={this.handleClick} id={"main-table-row-" + this.props.rowKey} onContextMenu={this.handleContextMenu} className={rowClass} >
                {cells}

                {function(){
                    if (this.props.config.editAction === 'yes') {
                        // If content of row cell whose key is disabledEdit.column matches any of values in disabledEdit.values don't show edit icon
                        if (
                            disabledEdit &&
                            disabledEdit.values.indexOf(
                                data[disabledEdit.column].value ||
                                data[disabledEdit.column])
                            !== -1
                        ) return <td></td>;

                        return (
                            <td>
                                <EditButtonCell
                                    urlParam={this.props.data[this.props.config.urlKey]}
                                    handleDeleteBtnClick={this.handleDeleteAction}
                                    handleEditBtnClick={this.handleEditAction} config={this.props.config}/>
                            </td>
                        )
                    } else if (multipleEditActions) {
                        return _.range(multipleEditActions).map(function(number) {
                            if (
                                disabledEdit &&
                                disabledEdit[number] &&
                                disabledEdit[number].values.indexOf(
                                    data[disabledEdit[number].column].value ||
                                    data[disabledEdit[number].column])
                                !== -1
                            ) return <td></td>;

                            var editConfig = {
                                editActionClass: config.editActionClass[number],
                                editUrlAdditionalParams: config.editUrlAdditionalParams && config.editUrlAdditionalParams[number],
                                toUrl: config.toUrl[number]
                            };

                            return (
                                <td>
                                    <EditButtonCell
                                        urlParam={self.props.data[self.props.config.urlKey[number]]}
                                        config={editConfig}/>
                                </td>
                            );
                        })
                    }
                }.bind(this)()}
                <td></td>
            </tr>
            )
    }
});
;

// Main table wrapper
var TableContent = React.createClass({
    mixins : [TranslateMixin, DistributionSelectionChecker],

    displayName: 'TableContent',

    oldColumns: null,

    previousHighlightedRow: null,

    stickyHeaderScrollStart: null,

    stickyFooterEnabled: false,

    stickyFooterFixedPosition: null,

    called: 0,

    lastSelectedIndex: 0,

    shiftPressed: false,

    ctrlPressed: false,

    widthCheckTimeout: null,

    getInitialState: function getInitialState () {
        return { columns: this.props.columns,
                 selectedRow: null,
                 selectedResults: []
        }
    },

    componentWillMount: function() {
        // Check and set sticky footer settings
        this.stickyFooterEnabled = (this.props.config.disableStickyFooter !== 'yes' && !Array.isArray(this.props.sums) && this.props.data.length);
    },

    componentDidMount: function componentDidMount () {
        // Calculate sticky header nodes
        this.calculateStickyHeader();

        // Calculate sticky footer nodes if sticky footer is enabled
        if(this.stickyFooterEnabled) {
            this.calculateStickyFooter();
        }

        // Add event listener for sticky header
        window.addEventListener('scroll', this.handleScroll);

        // Add event listener for resize to remove columns if needed
        window.addEventListener('resize', this.handleResize);

        // Add event listener for keyboard movement through grid
        if(this.props.config.multiSelect === 'yes' || this.props.config.keyboardMovement === 'yes') {
            window.addEventListener('keydown', this.handleKeyboardInput);
            window.addEventListener('keyup', this.handleKeyUp);
        }

        window.addEventListener('ReactGrid.CheckTableOverflow', this.handleDetailChange);

        // Check if table width overflow, if so, hide some of columns
        if (this.checkTableWidth()) {
            this.removeColumn();
        }

        // Highlight row provided from controller on first component mount
        if (typeof this.props.config.selectedRowIndex === 'undefined') return;
        this.highlightRow(this.props.config.selectedRowIndex);

    },

    handleDetailChange: function handleDetailChange() {
        if (this.checkTableWidth()) {
            this.removeColumn();
        }
    },

    componentWillReceiveProps: function (newProps) {
        if(this.props.config.keyboardMovement === 'yes'){
            // Add event listener for keyboard movement through grid
            window.addEventListener('keydown', this.handleKeyboardInput);
            if(this.props.config.multiSelect === 'yes') window.addEventListener('keyup', this.handleKeyUp);
        }

        if (this.oldPageYOffset !== newProps.config.pageYOffset) {
            this.recalculateStickyPositions();
        }

        this.oldPageYOffset = newProps.config.pageYOffset;
        this.setState({columns: newProps.columns, selectedResults: this.state.selectedResults || []});
    },

    componentDidUpdate: function componentDidUpdate () {
        // Check if table overflows and use last saved columns
        if (this.checkTableWidth() && this.oldColumns !== null ) {
            var a = this.currentLocation();
            localStorage.setItem(a, JSON.stringify(this.oldColumns));
            this.setState({columns: this.oldColumns});
            document.getElementById('columns-overflow-error').classList.remove('hidden');
        } else if (this.checkTableWidth()) { // If there is no old columns, just hide last visible column
            this.removeColumn();
        }

        this.oldColumns = null;

        // Re-check sticky footer settings
        this.stickyFooterEnabled = (this.props.config.disableStickyFooter !== 'yes' && !Array.isArray(this.props.sums) && this.props.data.length);

        // Recalculate sticky header and footer
        this.recalculateStickyPositions();

        // Highlight row provided from controller on first component mount
        if (typeof this.props.config.selectedRowIndex === 'undefined') return;
        this.highlightRow(this.props.config.selectedRowIndex);

    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('keydown', this.handleKeyboardInput);
        window.removeEventListener('keyup', this.handleKeyUp);
        window.removeEventListener('ReactGrid.CheckTableOverflow', this.handleDetailChange);
    },

    widthCheckTimeout: null,

    expandArrowClick: function (data, index) {
        if(!this.props.config.expandRow) return;
        var inputList = document.getElementById('main-table-row-' + index).getElementsByTagName('input'),
            inputListArray = Array.prototype.slice.call(inputList);
        this.blurFocusedInput(inputListArray);
        window.removeEventListener('keydown', this.handleKeyboardInput);
        this.props.config.expandRow(data, index);
    },

    handleKeyboardInput: function (event) {
        if(this.props.config.multiSelect === 'yes') this.handleKeyDown(event);
        if (this.props.config.keyboardMovement !== 'yes') return;
        var inputList = '',
            inputListArray = [],
            focusedInputs = 0,
            data = this.props.config.results,
            index = this.props.config.selectedRowIndex,
            targetElement = document.getElementById('main-table-row-' + index);

        if(!targetElement) return;

        inputList = document.getElementById('main-table-row-' + index).getElementsByTagName('input');
        inputListArray = Array.prototype.slice.call(inputList);

        switch(event.keyCode) {
            case 13:
                var focusedInputs = 0;

                for(var i=0; i<inputListArray.length; i++){
                    if($(inputListArray[i]).is(':focus')) {
                        focusedInputs ++;
                        if(inputListArray[i+1]) {
                            inputList[i+1].focus();
                            inputList[i+1].select();
                            break;
                        }
                        else {
                            inputListArray[i].blur();
                            if(data[index + 1]){
                                this.highlightRow(index + 1);
                                this.props.rowSelectAction(data[index + 1], index + 1, 0);
                            }
                            else {
                                this.highlightRow(index);
                                this.props.rowSelectAction(data[index], index, 0);
                            }
                        }
                    }
                }

                if(focusedInputs === 0 && inputListArray[1]) {
                    inputListArray[0].focus();
                    inputListArray[0].select();
                }
                break;
            case 27:
                if(!this.props.config.expandRow) return;
                this.props.config.expandRow(data[index], index);
                this.blurFocusedInput(inputListArray);
                window.removeEventListener('keydown', this.handleKeyboardInput);
                break;
            case 38:
                if(index !== 0) {
                    this.blurFocusedInput(inputListArray);
                    this.highlightRow(index - 1);
                    this.props.rowSelectAction(data[index - 1], index - 1, 0);
                    if(this.props.config.multiSelect === 'yes') this.handleMultiSelectClick(data[index - 1], index - 1, true);
                    if(window.innerHeight - targetElement.getBoundingClientRect().top < 150){
                        window.scrollBy(0,targetElement.offsetHeight);
                    }
                }
                break;
            case 40:
                if(data[index + 1]) {
                    this.blurFocusedInput(inputListArray);
                    this.highlightRow(index + 1);
                    this.props.rowSelectAction(data[index + 1], index + 1, 0);
                    if(this.props.config.multiSelect === 'yes') this.handleMultiSelectClick(data[index + 1], index + 1, true);
                    if(targetElement.getBoundingClientRect().top < 100){
                        window.scrollTo(0, targetElement.offsetTop + 50);
                    }
                }
                break;
        }
    },

    blurFocusedInput: function (inputListArray) {
        for(var i=0; i<inputListArray.length; i++){
            if($(inputListArray[i]).is(':focus')) {
                inputListArray[i].blur();
            }
        }
    },

    currentLocation: function () {
        var a = window.location.hash,
            subVal = a.indexOf('?') !== -1 ? a.indexOf('?') : a.length;
        a = this.props.config.columnSaveKey || a.substring(1, subVal);
        return a;
    },

    // Get columns saved to local storage by url as object key
    getLocalStorageColumns: function getLocalStorageColumns () {
        var a = this.currentLocation();
        return JSON.parse(localStorage.getItem(a));
    },

    /**
     * Change column visibility and rerender table
     * Will be triggered from child component after it sets new column obect to local storage
     */
    setVisibility: function setVisibility (oldColumns, state) {
        //if (state)
        this.oldColumns = oldColumns;
        var columns = this.getLocalStorageColumns() !== null ? this.getLocalStorageColumns() : this.props.columns;
        this.setState({columns: columns});
    },

    /**
     * Highlight selected row.
     * Will be triggered from child component, only if 'rowSelectable' property is provided in config object
     */
    highlightRow: function highlightRow (index) {
        if (!this.refs[['table-row-' + index]]) return;

        // Unhighlight last selected row
        if (this.refs['table-row-' + this.previousHighlightedRow]) {
            ReactDOM.findDOMNode(this.refs['table-row-' + this.previousHighlightedRow]).className = ReactDOM.findDOMNode(this.refs['table-row-' + this.previousHighlightedRow]).className.replace('selected', 'clickable');
        }

        // Highlight provided row
        ReactDOM.findDOMNode(this.refs['table-row-' + index]).className = ReactDOM.findDOMNode(this.refs['table-row-' + index]).className.replace('clickable', 'selected');

        this.previousHighlightedRow = index;
    },

    removeColumn: function removeColumn () {
        var columns = this.state.columns,
            columnKeys = Object.keys(columns);

        for (var i = columnKeys.length - 1; i >= 0; i--) {

            if (columns[columnKeys[i]].visibility) {
                columns[columnKeys[i]].visibility = false;
                this.setState({columns: columns});
                return;
            }

        }
    },

    checkTableWidth: function checkTableWidth () {
        var details = document.querySelectorAll('.main-content:not(.ng-leave):not(.ng-leave-active) .details-table, .main-content:not(.ng-leave):not(.ng-leave-active) .details-panel, .main-content:not(.ng-leave):not(.ng-leave-active) .stick-panel')[0];

        // get main table element
        var table = ReactDOM.findDOMNode(this.refs['react-table']),
            tableRight = table.getBoundingClientRect().right,
            tablePaddingRight = 10,
            detailsLeft = details ? details.getBoundingClientRect().left : document.getElementsByTagName('body')[0].offsetWidth;

        // For some reason some versions of WebKit are reporting wrong value for table.getBoundingClientRect().right
        // and that can be detected by comparing scrollWidth with offsetWidth. In that case scrollWidth reports correct
        // value, just add left position of table to calculate table rightmost pixel position.
        // Since scrollWidth returns integer and getBoundingClientRect fractional value so in some cases some small
        // overlap may happen
        if (table.scrollWidth !== table.offsetWidth) {
            tableRight = table.scrollWidth + table.getBoundingClientRect().left;
        }

        return (tableRight - tablePaddingRight) >= detailsLeft;
    },

    calculateStickyHeader: function() {
        var extraYPageOffset = 0;

        var tableHeader = ReactDOM.findDOMNode(this.refs['table-header-row']).getElementsByTagName('th'),
            tableHeaderTopOffset = tableHeader[0].getBoundingClientRect().top,
            pageYOffset = window.pageYOffset || document.documentElement.scrollTop,
            scrollHeightStart = pageYOffset + tableHeaderTopOffset - 45 + extraYPageOffset;

        if (!this.stickyHeaderScrollStart) {
            this.stickyHeaderScrollStart = pageYOffset + tableHeaderTopOffset - 45 + extraYPageOffset;
            //this.stickyHeaderScrollStart = scrollHeightStart;
        }

        if (this.stickyHeaderScrollStart) {

            var offsetPosition = pageYOffset > this.stickyHeaderScrollStart ? (pageYOffset - this.stickyHeaderScrollStart) + 'px' : '0px';

            for (var i = 0; i < tableHeader.length; i++) {
                if (tableHeader[i].style.position !== 'relative') {
                    tableHeader[i].style.position = 'relative';
                }
                tableHeader[i].style.top = offsetPosition;
            }
        }
    },

    calculateStickyFooter: function() {
        var mainSums = ReactDOM.findDOMNode(this.refs['table-sums-row']).getElementsByTagName('td');

        if (!this.stickyFooterFixedPosition) {
            this.stickyFooterFixedPosition = mainSums[0].getBoundingClientRect().top + window.pageYOffset;
        }

        var position = (window.pageYOffset + window.innerHeight) - this.stickyFooterFixedPosition - mainSums[0].clientHeight;;

        for (var i = 0; i < mainSums.length; i++) {
            if (mainSums[i].style.position !== 'relative'){
                mainSums[i].style.position = 'relative';
            }
            mainSums[i].style.top = position < 0 ? position + 'px' : '0px';
        }

    },

    recalculateStickyPositions: function() {

        // First we have to reset all positioning so the new calculations dont go wrong
        this.stickyHeaderScrollStart = null;
        this.stickyFooterFixedPosition = null;

        var tableHeader = ReactDOM.findDOMNode(this.refs['table-header-row']).getElementsByTagName('th');

        for (var i = 0; i < tableHeader.length; i++) {
            tableHeader[i].style.position = 'static';
        }


        if (this.stickyFooterEnabled) {

            var mainSums = ReactDOM.findDOMNode(this.refs['table-sums-row']).getElementsByTagName('td');

            for (var i = 0; i < mainSums.length; i++) {
                mainSums[i].style.position = 'static';
            }
        }

        // Recalculate and set header/footer positions
        this.handleScroll();
    },

    handleScroll: function handleScroll () {

        this.calculateStickyHeader();

        if(this.stickyFooterEnabled) {
            this.calculateStickyFooter();
        }

    },

    handleResize: function () {
        if (this.widthCheckTimeout) clearTimeout(this.widthCheckTimeout);

        if (this.checkTableWidth()) {
            this.widthCheckTimeout = setTimeout(function() {
                this.removeColumn();
            }.bind(this), 2000);
        }

        // Recalculate header & footer start positions
        this.recalculateStickyPositions();
    },


    setContextMenuPosition: function (x, y) {
        $('#react-context-menu').css({display: 'block', left: x - 4, top: y - 8});
    },

    contextData: null,

    setContextMenuData: function (data) {
        this.contextData = data;
    },

    handleGlobalContextMenu: function (evt) {
        if (!this.props.rcActions || !this.props.rcActions.length || this.props.config.globalContextMenu !== 'yes') return;
        evt.preventDefault();
        var idValue = this.props.config.contextMenuId || 'react-context-menu',
            table = $('#react-table');
        $('#' + idValue).css({display: 'block', left: evt.pageX - table.offset().left, top: evt.pageY - table.offset().top});
    },

    resolveContextMenuAction: function (id) {
        this.props.contextMenuAction(this.contextData, id);
    },

    handleKeyDown: function (evt) {
        if (evt.keyCode === 16) this.shiftPressed = true;
        if (evt.keyCode === 17) this.ctrlPressed = true;
    },

    handleKeyUp: function (evt) {
        if (evt.keyCode === 16) this.shiftPressed = false;
        if (evt.keyCode === 17) this.ctrlPressed = false;
    },

    handleMultiSelectClick: function (data, index, keyboardMove) {
        if(this.props.config.multiSelect !== 'yes') return;
        if(keyboardMove){
            this.setState({
                selectedResults: [index],
                selectedRow: index
            });
            this.props.config.checkSelected(this.state.selectedResults);
            this.lastSelectedIndex = index;
            return;
        }

        var selected = this.state.selectedResults;

        if (!this.shiftPressed && !this.ctrlPressed) {
            this.props.rowSelectAction(this.props.data[index], index);
        }

        var checkSelected = this.checkSelection(selected, this.shiftPressed, this.ctrlPressed, index, this.lastSelectedIndex);
        this.props.config.checkSelected(checkSelected);

        this.setState({
            selectedResults: checkSelected,
            selectedRow: index
        });

        this.lastSelectedIndex = index;
    },

    render: function render() {

        var data = this.props.data,
            ticketAuthActions = null;

        var bodyRows = data.map(function (val, i) {
            return (<TableBodyRow
                data                    = {data[i]}
                columns                 = {this.state.columns}
                config                  = {this.props.config}
                ref                     = {'table-row-' + i}
                rowKey                  = {i}
                first                   = { 0 === i }
                last                    = { data.length - 1 === i }
                dateFormat              = {this.props.dateFormat}

                sevenLocalInfo          = {this.props.sevenLocalInfo}

                tableEditAction         = {this.props.tableEditAction}
                tableDeleteAction       = {this.props.tableDeleteAction}
                inputAction             = {this.props.inputAction}
                rowSelectAction         = {this.props.rowSelectAction}
                cellBinaryAction        = {this.props.cellBinaryAction}
                checkboxToggleAction    = {this.props.checkboxToggleAction}
                previewOffer            = {this.props.previewOffer}
                selectDisplay           = {this.props.selectDisplay}
                exportToExcel           = {this.props.exportToExcel}
                selectedResults         = {this.state.selectedResults}
                handleSelectionChange   = {this.props.handleSelectionChange}
                highlightRow            = {this.highlightRow}
                setContextMenuPosition  = {this.setContextMenuPosition}
                setContextMenuData      = {this.setContextMenuData}
                key                     = {'row' + i} // Required field
                expandArrowClick        = {this.expandArrowClick}

                multiSelectClick        = {this.handleMultiSelectClick}
            />);

        }.bind(this));


        // Add sums row
        if(!Array.isArray(this.props.sums) && this.props.data.length) {
            bodyRows.push(<TableSums
                sums={this.props.sums}
                columns={this.state.columns}
                config={this.props.config}
                ref='table-sums-row'
            />);
        }

        if (this.props.config.additionalSums === 'playerTransactions') {
            var playerSums = this.props.additionalSums,
                realBalanceClass = 'footer-sum pull-right',
                bonusBalanceClass = realBalanceClass;

            if(playerSums.realBalance > 0) realBalanceClass+= ' positive-sum';
            if(playerSums.realBalance < 0) realBalanceClass+= ' negative-sum';
            if(playerSums.bonusBalance > 0) bonusBalanceClass+= ' positive-sum';
            if(playerSums.bonusBalance < 0) bonusBalanceClass+= ' negative-sum';

            bodyRows.push([
                <tr className="table-sum-row sensitive-data">
                    <td colSpan="40">
                        <span
                            className="footer-sum-title pull-left">{this.translate('general.total_selected_period')}</span>
                    </td>
                </tr>,
                <tr className="table-sum-row sensitive-data">
                    <td colSpan="40">
                        <span className="footer-sum-title pull-left">{this.translate('player.real_balance')}</span>
                        <span className={realBalanceClass}>{playerSums.realBalance}</span></td>
                </tr>,
                <tr className="table-sum-row sensitive-data">
                    <td colSpan="40">
                        <span className="footer-sum-title pull-left">{this.translate('player.bonus_balance')}</span>
                        <span className={bonusBalanceClass}>{playerSums.bonusBalance}</span></td>
                </tr>,
                <tr className="table-sum-row sensitive-data">
                    <td colSpan="40">
                        <span className="footer-sum-title pull-left">
                            {this.translate('player.bonus_balance_status')}
                        </span>
                        <span className="footer-sum-title pull-right">{playerSums.bonusBalanceStatus}</span>
                    </td>
                </tr>
            ]);
        }

        if (this.props.config.additionalSums === 'terminalTransactions') {
            bodyRows.push([
                <tr className="table-sum-row sensitive-data">
                    <td colSpan="40"></td>
                </tr>,
                <tr className="table-sum-row sensitive-data">
                    <td colSpan="40">
                        <span className="footer-sum-title pull-left">{this.translate('general.balance')}</span>
                        <span className="footer-sum pull-right">{this.props.additionalSums.realBalance}</span></td>
                </tr>
            ]);
        }

        var headerRow = (<TableHeaderRow
                updateFilters           = {this.props.updateFilters}
                updateLocationFilter    = {this.props.updateLocationFilter}
                setVisibility           = {this.setVisibility}
                data                    = {this.props.titles}
                config                  = {this.props.config}
                columns                 = {this.state.columns}
                urlFilters              = {this.props.urlFilters}
                ref                     = 'table-header-row'
                key                     = 'table-header'
        />);

        var footerRow = function(){
            return <tr>
                <td colSpan='40'>
                    <Pagination
                        config              = {this.props.config}
                        data                = {this.props.pagination}
                        updateFilters       = {this.props.updateFilters}
                        updateLocationFilter= {this.props.updateLocationFilter}
                    />
                </td>
            </tr>
        }.bind(this)();

        var mainTableClass = this.props.config.multiSelect === 'yes' ? 'table table-condensed main-table table-responsive no-select' : 'table table-condensed main-table table-responsive';

        return(
            <div className={'table-content ' + this.props.config.class} id="react-table-content">
                <div id="ticket-auth-actions"></div>

                <table className={mainTableClass} id='react-table' ref='react-table' onContextMenu={this.handleGlobalContextMenu}>
                    <thead>
                        <tr id='sticky-header-row' className='hide-sticky-header'></tr>
                        {headerRow}
                    </thead>

                    <tbody id='react-table-body' className={ this.props.config.sensitiveData ? 'sensitive-data' : '' }>
                        {bodyRows}

                        {function(){
                            if (this.props.config.disableStickyFooter=== 'yes') return null;

                            return <tr id='sticky-footer-row' className='hide-sticky-footer'></tr>;
                        }.bind(this)()}
                    </tbody>

                    <tfoot className="table-footer">
                        {function () {
                            if (data.length) return null;

                            return (
                            <tr className="tfoot-spacer">
                                <td colSpan='40'>
                                </td>
                            </tr>
                            )
                        }.bind(this)()}
                        {footerRow}
                    </tfoot>
                </table>
                <ContextMenu idValue={this.props.config.contextMenuId} resolveContextMenuAction={this.resolveContextMenuAction} rcActions={this.props.rcActions} />
            </div>
        );
    }
});
;
'use strict';

var DEFAULT_PAGINATION_SELECT_OPTIONS = [5, 10, 25, 50, 100];

var PaginationSelector = React.createClass({

    /**
     * Will generate array of objects containing info about page values.
     * Each object contains type (first, last, prev, next, page, more), active state (if it's clickable) and value (page value to provide at click action).
     * @param {Integer} currentPage / page in response
     * @param {Integer} totalItems / total in response
     * @param {Integer} pageSize / count in response
     */
    generatePagesArray: function generatePagesArray () {
        var currentPage     = parseInt(this.props.pagination.page),
            totalItems      = parseInt(this.props.pagination.total),
            pageSize        = parseInt(this.props.pagination.count);

        var maxBlocks, maxPage, maxPivotPages, minPage, numPages, pages;
        maxBlocks = 11;
        pages = [];
        numPages = Math.ceil(totalItems / pageSize);

        if (numPages > 1) {
            pages.push({
                type: 'prev',
                number: Math.max(1, currentPage - 1),
                active: currentPage > 1
            });

            pages.push({
                type: 'first',
                number: 1,
                active: currentPage > 1
            });

            maxPivotPages = Math.round((maxBlocks - 5) / 2);
            minPage = Math.max(2, currentPage - maxPivotPages);
            maxPage = Math.min(numPages - 1, currentPage + maxPivotPages * 2 - (currentPage - minPage));
            minPage = Math.max(2, minPage - (maxPivotPages * 2 - (maxPage - minPage)));

            var i = minPage;
            while (i <= maxPage) {
                if ((i === minPage && i !== 2) || (i === maxPage && i !== numPages - 1)) {
                    pages.push({
                        type: 'more',
                        active: false
                    });
                } else {
                    pages.push({
                        type: 'page',
                        number: i,
                        active: currentPage !== i
                    });
                }

                i++;
            }

            pages.push({
                type: 'last',
                number: numPages,
                active: currentPage !== numPages
            });

            pages.push({
                type: 'next',
                number: Math.min(numPages, currentPage + 1),
                active: currentPage < numPages
            });
        }

        return pages;
    },


    /**
     * Get react element for First page action
     * @param {Array} pages - Pages generated in @generatePagesArray method
     * @returns {ReactObject}
     */
    getFirstContent: function getFirstContent (pages) {
        var firstArrowContent = null,
            page = null;

        for (var i = 0; i < pages.length; i++) {
            page = pages[i];

            if (page.type === 'first') {
                firstArrowContent = function (){
                    return <li className={page.active ? '' : 'disabled'}>
                        <a className='rc-pagination-arrow-first' onClick={this.handleClick.bind(this, page.number, page.active)}>
                            <i className='n-i n-i-arrow-left-b'></i>
                            <i className='n-i n-i-arrow-left-b'></i>
                        </a>
                    </li>
                }.bind(this)();
            }
        }
        return firstArrowContent;
    },


    /**
     * Get react element for Last page action
     * @param {Array} pages - Pages generated in @generatePagesArray method
     * @returns {ReactObject}
     */
    getLastContent: function getLastContent (pages) {
        var lastArrowContent = null,
            page = null;

        for (var i = 0; i < pages.length; i++) {
            page = pages[i];

            if (page.type === 'last') {
                lastArrowContent = function (){
                    return <li className={page.active ? '' : 'disabled'}>
                        <a className='rc-pagination-arrow-last' onClick={this.handleClick.bind(this, page.number, page.active)}>
                            <i className='n-i n-i-arrow-right-b'></i>
                            <i className='n-i n-i-arrow-right-b'></i>
                        </a>
                    </li>
                }.bind(this)();
            }
        }
        return lastArrowContent;
    },


    /**
     * Generate array of React object with specific content depending on it's type
     * @param {Array} pages - Pages generated in @generatePagesArray method
     * @returns {Array}
     */
    getInnerContent: function getInnerContent (pages) {

        var innerContent = pages.map(function(page, i) {
            switch(page.type) {
                case 'prev': return <li className={page.active ? '' : 'disabled'} key={'inner-' + i}>
                    <a className='rc-pagination-arrow-prev' onClick={this.handleClick.bind(this, page.number, page.active)}>
                        <i className='n-i n-i-arrow-left-b'></i>
                    </a>
                </li>; break;

                case 'first':
                case 'page':
                case 'last': return <li className={page.active ? '' : 'disabled'} key={'inner-' + i}>
                    <a onClick={this.handleClick.bind(this, page.number, page.active)}> {page.number} </a>
                </li>; break;

                case 'more': return <li key={'inner-' + i}>
                    <a className='disabled_dots'>...</a>
                </li>; break;


                case 'next': return <li className={page.active ? '' : 'disabled'} key={'inner-' + i}>
                    <a className='rc-pagination-arrow-next' onClick={this.handleClick.bind(this, page.number, page.active)}>
                        <i className='n-i n-i-arrow-right-b'></i>
                    </a>
                </li>; break;
            }
        }.bind(this));

        return innerContent;
    },


    /**
     * Handle pagination click
     * @Integer value - value to send to request
     * @Boolean active - if it't clickable
     */
    handleClick: function (value, active) {
        if (active) {
            this.props.handlePageChange(String(value));
        }
    },

    render: function render() {
        var pages = this.generatePagesArray(),
            goToPage = _.find(pages, {'type': 'more'}) ? <GoToPage changePage={this.handleClick} lastPage={Math.ceil(this.props.pagination.total / this.props.pagination.count)}></GoToPage> : '';

        return (
            <div className='pull-left'>
                <ul className='rc-pagination'>
                    {this.getFirstContent(pages)}
                    {this.getInnerContent(pages)}
                    {this.getLastContent(pages)}
                    {goToPage}
                </ul>
            </div>
        );
    }
});

var GoToPage = React.createClass({

    mixins: [TranslateMixin],

    toggleGoTo: function () {
        this.setState(function (currentState) {
            return {
                opened: !currentState.opened
            }
        });
    },

    componentDidUpdate: function () {
        if (this.state && this.state.opened)
            this.input.focus();
    },

    setInputValue: function (event) {
        this.setState({
            page: event.target.value
        })
    },

    handleKeyDown: function (event) {
        var pageNum = parseInt(this.state.page);

        if (event.key === 'Enter' && pageNum> 0 && pageNum <= this.props.lastPage) {
            this.props.changePage(this.state.page, true);
            this.setState({opened: false});
        } else if (event.key === 'Escape') {
            this.setState({opened: false})
        }
    },

    setInputReference: function (element) {
        this.input = element;
    },

    render: function () {
        var input = this.state && this.state.opened ? <input type="number" min="1" placeholder={this.translate('general.pagination_page')} max={this.props.lastPage} onChange={this.setInputValue} onKeyDown={this.handleKeyDown} ref={this.setInputReference}/> : '',
            button = input ? '' : <button onClick={this.toggleGoTo}>{this.translate('general.pagination_go_to_page')}</button>;

        return (
            <li className="pagination-go-to">
                {button}
                {input}
            </li>);
    }
});


var Pagination = React.createClass({
    mixins: [TranslateMixin],

    handleSelectChange: function handleSelectChange (event) {
        this.props.updateLocationFilter('page', '1');
        this.props.updateLocationFilter('count', event.target.value);
        this.props.updateFilters(); // If empty object is sent, url filters will be used
        if(this.props.config && this.props.config.scrollTopOnPageChange){
            window.scrollTo(0, 0);
        }
    },

    handlePageChange: function handlePageChange (value) {
        this.props.updateLocationFilter('page', value);
        this.props.updateFilters();

        if(this.props.config && this.props.config.scrollTopOnPageChange){
            window.scrollTo(0, 0);
        }
    },

    render: function render () {
        var count = this.props.data.count || 25;
        var paginationSelectOptions = this.props.config && this.props.config.paginationSelectOptions || DEFAULT_PAGINATION_SELECT_OPTIONS;

        var options = paginationSelectOptions.map(function (item) {
            return <option value={item}>{item}</option>
        });

        var isDropdownDisabled = paginationSelectOptions.length === 1 && !this.props.data.isAllSelectable;

        return (
            <div className='rc-pagination-wrap'>
                <select className='rc-pagination-select' value={count} onChange={this.handleSelectChange} disabled={isDropdownDisabled}>
                    {options}
                        {function(){
                            if (this.props.data.isAllSelectable)
                                return <option value={this.props.config && this.props.config.allParameter ? this.props.config.allParameter : '5000'}>{this.translate('general.all')}</option>
                        }.bind(this)()}
                </select>
                <span>{this.translate('general.rows_per_page')}</span>
                <PaginationSelector pagination={this.props.data} handlePageChange={this.handlePageChange} />
                <div className="rc-pagination-total pull-right">
                    {this.props.config && this.props.config.showCurrencyInFooter && this.props.config.currency &&
                    <span>{this.translate('general.currency') + ': ' + this.props.config.currency}</span>
                    }
                    <span>{this.translate('general.total_results') + ': ' + this.props.data.total}</span>
                </div>
            </div>
        );
    }

});
;
/**
 * Column selection content component
 * Will handle changes in column selection
 */
var ColumnSelectionComponentCheckbox = React.createClass({
    displayName: 'ColumnSelectionComponentCheckbox',

    handleClick: function (e) {
        e.stopPropagation();
    },

    handleChange: function () {
        document.getElementById('columns-overflow-error').classList.add('hidden');
        document.getElementById('columns-off-error').classList.add('hidden');

        var visiblyColumnsCount = 0;

        for (var j in this.props.allColumns) {
            !this.props.allColumns[j].hidden && this.props.allColumns[j].visibility && visiblyColumnsCount++;
        }

        // Prevent disabling all columns
        if (visiblyColumnsCount === 1 && this.props.column.visibility) {
            document.getElementById('columns-off-error').classList.remove('hidden');
            return;
        }

        var a = window.location.hash,
            subVal = a.indexOf('?') !== -1 ? a.indexOf('?') : a.length,
            oldColumns = $.extend(true, {}, this.props.allColumns);

        // If save key is provided use it, else create it by url
        a = this.props.config.columnSaveKey || a.substring(1, subVal);

        for (var i in this.props.allColumns) {
            if (i === this.props.columnKey) {
                this.props.allColumns[i].visibility = !this.props.column.visibility;
            }
        }

        localStorage.setItem(a, JSON.stringify(this.props.allColumns));
        this.props.setVisibility(oldColumns, this.props.column.visibility);

    },

    render: function render() {

        return (
            <div className='filter-multi-value' title={this.props.value}>
                <input
                    type            = 'checkbox'
                    id              = {'column-vis-' + this.props.columnKey}
                    checked         = {this.props.column.visibility}
                    onChange        = {this.handleChange}
                    onClick         = {this.handleClick}
                />
                <label className='checkbox' htmlFor={'column-vis-' + this.props.columnKey} > <span> {this.props.value} </span> </label>
            </div>
        );
    }
});


/**
 * Column selection wrapper
 * Will handle display of column selection content (List of checkboxes-columns)
 */
var ColumnSelectionComponent = React.createClass({
    displayName: 'ColumnSelectionComponent',
    mixins: [TranslateMixin],
    scrollTimeout: null,

    getInitialState: function getInitialState() {
        return {
            columnVisibilityContent: false
        }
    },

    // Hide all filters
    handleGlobalClick: function handleGlobalClick (e) {
        // Close column selection content
        if (this.state.columnVisibilityContent)
            this.setState({columnVisibilityContent: false});
    },

    // Add event for global click when component is loaded
    componentWillMount: function () {
        window.addEventListener('click', this.handleGlobalClick);
        window.addEventListener('resize', this.calculateNanoScroller);
        window.addEventListener('scroll', this.delayedNanoScrollCalculation);
    },

    componentDidUpdate: function() {
        this.calculateNanoScroller();
    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('click', this.handleGlobalClick);
        window.removeEventListener('resize', this.calculateNanoScroller);
        window.removeEventListener('scroll', this.delayedNanoScrollCalculation);
    },

    // Handle checkbox click
    handleClick: function (e) {
        this.setState({columnVisibilityContent: !this.state.columnVisibilityContent});
    },

    delayedNanoScrollCalculation: function() {

        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
        }

        this.scrollTimeout = setTimeout(function() {
            this.calculateNanoScroller();
        }.bind(this), 100)
    },

    calculateNanoScroller: function(e) {

        if (!this.state.columnVisibilityContent) return;

        // Fetch elements
        var columnFilter = this.refs.gridColumnFilter,
            columnFilterContainer = this.refs.gridColumnFilterContainer;

        // Get measurements
        var columnFilterPosition = columnFilter.getBoundingClientRect(),
            windowSize = window.innerHeight;

        // calculate max filter size
        var maxSize = windowSize-(columnFilterPosition.top + 100);

        if (columnFilter.scrollHeight > maxSize) {
            columnFilterContainer.style.height = maxSize + 'px';
            $('.nano-scroll-container').nanoScroller();
        } else {
            columnFilterContainer.style.height = columnFilter.scrollHeight + 'px';
            $('.nano-scroll-container').nanoScroller({ stop: true });
        }
    },

    resetColumns: function () {
        var a = window.location.hash,
            subVal = a.indexOf('?') !== -1 ? a.indexOf('?') : a.length;
        a = this.props.config.columnSaveKey || a.substring(1, subVal);

        delete localStorage[a];

        this.props.updateFilters({});
    },

    handleWrapClick: function (e) {
        e.stopPropagation();
    },

    render: function render() {

        var checkboxValues = [];
        for (var i in this.props.columns) {
            if (!this.props.columns[i].hidden) {
                checkboxValues.push(<ColumnSelectionComponentCheckbox
                    column          = { this.props.columns[i] }
                    config          = {this.props.config}
                    allColumns      = { this.props.columns }
                    value           = { this.props.data[i] }
                    setVisibility   = { this.props.setVisibility }
                    columnKey       = { i }
                    key             = { i }
                />);
            }
        }

        return (
            <div className="relative-pos column-select-header-cell" onClick={this.handleWrapClick}>
                <i className='n-i n-i-columns clickable' onClick={this.handleClick}></i>
                {function () {
                    if (this.state.columnVisibilityContent)
                        return <div className='column-select'>
                            <p>{this.translate('general.column_view')}</p>
                            <div className="column-view-reset column-reset" onClick={this.resetColumns}>
                                <i className="n-i n-i-reset"></i>
                                <span>{this.translate('general.reset')}</span>
                            </div>
                            <div id='columns-overflow-error' className='column-display-err hidden'>{this.translate('general.cant_add_columns')}</div>
                            <div id='columns-off-error' className='column-display-err hidden'>{this.translate('general.cant_turn_off_all_columns')}</div>
                            <div ref='gridColumnFilterContainer' className='nano nano-scroll-container'>
                                <div ref='gridColumnFilter' className='filter-multi-value nano-content'>{checkboxValues}</div>
                            </div>
                        </div>
                }.bind(this)()}
            </div>
        );
    }
});


/**
 * Header cell component
 * Will handle sorting
 */
var TableHeaderCell = React.createClass({
    displayName: 'TableHeaderCell',


    sortHeader: function sortHeader() {
        if (this.props.column.sortDisabled) return;
        if (!this.props.updateLocationFilter) return;

        var sort = this.props.columnKey,
            // Default direction is ascending except for 'number' type columns
            direction = this.props.column.type !== 'number' ? 'asc' : 'desc',
            urlFilters = this.props.urlFilters;

        // If there is already direction set in filters and sort key is same as clicked, change direction.
        if (urlFilters.direction && urlFilters.sort === sort) {
            direction = urlFilters.direction === 'asc' ? 'desc' : 'asc';
        }

        this.props.updateLocationFilter('direction', direction);
        this.props.updateLocationFilter('sort', sort);

        this.props.updateFilters();
    },

    render: function render() {
        var sortClassValue = this.props.columnKey === this.props.urlFilters.sort ?  'n-i react-header-sort-' + this.props.urlFilters.direction : '',
            cursorClass = this.props.column.sortDisabled ? '' : 'clickable',
            value = this.props.column.hiddenInTableHead ? ' ' : this.props.value;
        return (
            <th onClick={this.sortHeader} className={cursorClass}>
                {value}
                <i className={sortClassValue}></i>
            </th>
        );
    }
});

/**
 * Header row component
 * Will create list of @TableHeaderCell components
 */
var TableHeaderRow = React.createClass({
    displayName: 'TableHeaderRow',
    mixins: [TranslateMixin],

    render: function render() {

        var data = this.props.data,
            columns = this.props.columns,
            multipleEditActions = _.get(this.props.config, 'editAction.multiple'),
            editActionText = this.props.config.editActionText || this.translate('general.edit'),
            cells = [];

        for (var i in columns) {

            if (columns[i].visibility && !columns[i].hidden) {
                cells.push(<TableHeaderCell
                    updateFilters           = {this.props.updateFilters}
                    updateLocationFilter    = {this.props.updateLocationFilter}
                    value                   = {data[i]}
                    column                  = {columns[i]}
                    columnKey               = {i}
                    urlFilters              = {this.props.urlFilters}
                    key                     = {'table-header-cell-' + i}
                />);
            }
        }

        return (
            <tr className='table-header'>
                {cells}

                {function () { // Edit button cell
                    if (this.props.config.editAction === 'yes')
                        return <th className='relative-pos edit-header-cell'>{editActionText}</th>;
                    else if (multipleEditActions) {
                        return _.range(multipleEditActions).map(function(number) {
                            return <th className='relative-pos edit-header-cell'>{editActionText[number]}</th>
                        })
                    }
                }.bind(this)()}

                <th className='th-column-select'>
                    <ColumnSelectionComponent
                        updateFilters   = {this.props.updateFilters}
                        data            = {this.props.data}
                        setVisibility   = {this.props.setVisibility}
                        columns         = {this.props.columns}
                        config          = {this.props.config}
                        key             = 'column-select'
                    />
                </th>
            </tr>
        );
    }
});
;
'use strict';

/**
 * Main wrapper
 * Used in react service (./app.js)
 */
var MainWrapper = React.createClass({

    /**
     * Reload main content with new data
     * @param data
     */
    reloadData: function reloadData(data) {
        this.setState({data: data});
    },

    render: function () {
        return (
                <div className="main-wrapper">

                    <Filters
                        data                    = {this.props.data.filters}
                        config                  = {this.props.config}
                        selectedTemplate        = {this.props.selectedTemplate}
                        urlFilters              = {this.props.urlFilters}
                        columns                 = {this.props.data.columns}
                        serverTime              = {this.props.serverTime}
                        dateFormat              = {this.props.dateFormat}
                        locale                  = {this.props.locale}
                        sevenLocalInfo          = {this.props.sevenLocalInfo}
                        supportedExportFormats  = {this.props.data.supportedExportFormats}

                        changeTableWidth        = {this.props.changeTableWidth}
                        changeAutoRefresh       = {this.props.changeAutoRefresh}
                        chartButtonClick        = {this.props.chartButtonClick}
                        resetFilters            = {this.props.resetFilters}
                        updateFilters           = {this.props.updateFilters}
                        updateLocationFilter    = {this.props.updateLocationFilter}
                        updateLocationWithMultipleParams = {this.props.updateLocationWithMultipleParams}
                        matchSelectionAction    = {this.props.matchSelectionAction}
                        rememberFilters         = {this.props.rememberFilters}
                        removeRememberedFilters = {this.props.removeRememberedFilters}
                        dateFakingEnabledForView = {this.props.dateFakingEnabledForView}
                        getFakedExportDateParams = {this.props.getFakedExportDateParams}
                        onExport                = {this.props.onExport}
                     />


                    <TableContent
                        data                = {this.props.data.data}
                        columns             = {this.props.data.columns}
                        titles              = {this.props.data.titles}
                        sums                = {this.props.data.sums}
                        additionalSums      = {this.props.data.additionalSums}
                        pagination          = {this.props.data.pagination}
                        rcActions           = {this.props.data.rcActions}
                        dateFormat          = {this.props.dateFormat}

                        config              = {this.props.config}
                        urlFilters          = {this.props.urlFilters}

                        sevenLocalInfo      = {this.props.sevenLocalInfo}

                        rowSelectAction     = {this.props.rowSelectAction}
                        selectDisplay       = {this.props.selectDisplay}
                        inputAction         = {this.props.inputAction}
                        cellBinaryAction    = {this.props.cellBinaryAction}
                        checkboxToggleAction= {this.props.checkboxToggleAction}
                        previewOffer        = {this.props.previewOffer}
                        exportToExcel       = {this.props.exportToExcel}
                        tableEditAction     = {this.props.tableEditAction}
                        tableDeleteAction   = {this.props.tableDeleteAction}
                        handleSelectionChange={this.props.handleSelectionChange}
                        contextMenuAction   = {this.props.contextMenuAction}
                        updateFilters       = {this.props.updateFilters}
                        updateLocationFilter= {this.props.updateLocationFilter}
                        ticketAuthActionChange= {this.props.ticketAuthActionChange}
                     />
                </div>
            );
    }
});
;
var CategoryFilterContent = React.createClass({

    stopPropagation: function (evt) {
        evt.stopPropagation();
    },

    handleChange: function (category, evt) {
        this.props.handleCategoryChange(category, evt.target.checked);
    },

    render: function () {
        var categories = this.props.categories;

        return <div id='m-t-filter-content' className={"m-t-filter-content" + (this.props.active ? ' active' : '')} onClick={this.stopPropagation}>

            {categories.map(function (category, key) {
                return (
                    <div>
                        <label htmlFor={"category-" + key}>
                            <input
                                id={"category-" + key}
                                onChange={this.handleChange.bind(this, category)}
                                checked={this.props.categoryFilter[category.id_category]}
                                type="checkbox"
                                className="normal-checkbox"/>
                            <i></i>
                            {category.category_name}
                        </label>
                    </div>
                )
            }.bind(this))}
        </div>
    }
});

var CategoryFilter = React.createClass({

    categoryFilter: {},

    getInitialState: function () {
        return ({displayFilter: false});
    },

    handleGlobalClick: function handleGlobalClick (e) {
        if (this.state.displayFilter !== false) {
            this.setState({displayFilter: false});
        }
    },

    componentWillMount: function () {
        window.addEventListener('click', this.handleGlobalClick);
    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('click', this.handleGlobalClick);
    },

    displayFilter: function (evt) {
        evt.stopPropagation();
        this.setState({displayFilter: !this.state.displayFilter});
    },

    render: function () {
        var categories = this.props.categories;
        return (
            <div className="m-t-content-filter">
                <div className="m-t-content-filter-inner">
                    <div className={"m-t-filter-name " + (this.state.displayFilter ? 'active' : '')} onClick={this.displayFilter}><i className="n-i n-i-arrow-down-b">{}</i>Category</div>
                    {function() {
                        if (!this.state.displayFilter) return '';
                        return <CategoryFilterContent active={this.state.displayFilter} categories={categories} categoryFilter={this.props.categoryFilter} handleCategoryChange={this.props.handleCategoryChange} />
                    }.bind(this)()}
                </div>
            </div>
        );
   } 
});



var MarketTemplatesCheckbox = React.createClass({

    /*getInitialState: function () {
        return({inputValue: this.props.checkValue})
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({inputValue: newProps.checkValue});
    },*/

    handleChange: function (evt) {
        this.props.checkboxChangeAction(this.props.idValue, evt.target.checked, this.props.index);
    },

    render: function () {
        var idValue = this.props.idValue,
            displayName = this.props.displayName,
            checkValue = this.props.checkValue;


        return(
            <div className="m-t-content-data-item">
                <label htmlFor={"bet-class-" + idValue}>
                    <input
                        id={"bet-class-" + idValue}
                        onChange={this.handleChange}
                        checked={checkValue}
                        className="market-templates-checkbox"
                        type="checkbox"/>
                    {displayName}
                </label>
            </div>
        );
    }

});


var MarketTemplatesData = React.createClass({
    mixins: [TranslateMixin],

    bets: [],

    tournaments: [],

    tournamentsSearchInput: '',

    categoryFilter: {},

    getInitialState: function () {
        return ({
            bets: [],
            tournaments: [],
            categories: []
        });
    },


    initBets: function (data) {
        if (data === null) return;
        this.bets = data.bets;
        this.setState({bets: data.bets});
    },

    initTournaments: function (data) {
        if (data === null) return;
        this.tournaments = data.tournaments;
        this.setState({tournaments: data.tournaments, categories: data.filters.categories});
        this.handleTournamentsSearch();
    },

    initCategories: function (data) {
        this.setState({categories: data.data});
    },

    clearInputs: function() {
        this.categoryFilter = {};
        this.tournamentsSearchInput = '';

        ReactDOM.findDOMNode(this.refs.tournamentsSearchInput).value = '';
        ReactDOM.findDOMNode(this.refs.betsSearchInput).value = '';

        this.forceUpdate();
    },

    componentDidUpdate: function () {
        $('.nano').nanoScroller();
    },

    handleBetsSearch: function (evt) {
        var value = evt.target.value.toLowerCase(),
            bet = null,
            searchValues = [];

        for (var i = 0; i < this.bets.length; i++) {
            bet = this.bets[i].bet_name.toLowerCase();
            if (bet.indexOf(value) !== -1) {
                searchValues.push(this.bets[i]);
            }
        }

        this.setState({bets: searchValues});
    },

    handleTournamentsSearch: function (evt) {

        this.tournamentsSearchInput = evt ? evt.target.value.toLowerCase() : this.tournamentsSearchInput;

        var tournament = null,
            searchValues = [];

        for (var i = 0; i < this.tournaments.length; i++) {
            tournament = this.tournaments[i].tournament_name.toLowerCase();
            if (tournament.indexOf(this.tournamentsSearchInput) !== -1) {
                searchValues.push(this.tournaments[i]);
            }
        }

        this.setState({tournaments: searchValues});
    },

    handleBetChange: function (idBet, checkValue, index) {
        for (var i = 0; i < this.bets.length; i++) {
            if (this.bets[i].id_bet === idBet) {
                this.bets[i].selected = checkValue;
            }
        }

        this.state.bets[index].selected = checkValue;
        this.props.betCheckboxChange(idBet, checkValue);
        this.setState({bets: this.state.bets});
    },

    handleTournamentChange: function (idTournament, checkValue, index) {
        for (var i = 0; i < this.tournaments.length; i++) {
            if (this.tournaments[i].id_tournament === idTournament) {
                this.tournaments[i].selected = checkValue;
            }
        }

        this.state.tournaments[index].selected = checkValue;
        this.props.tournamentCheckboxChange(idTournament, checkValue);
        this.setState({tournaments: this.state.tournaments});
    },

    handleCategoryChange: function (category, value) {
        var urlString = '';
        this.categoryFilter[category.id_category] = value;

        for (var i in this.categoryFilter) {
            if (this.categoryFilter[i]) {
                urlString += i + ',';
            }
        }
        urlString = urlString.slice(0, -1);
        this.props.categoryFilterChange(urlString);
    },

    render: function () {

        var tournaments = this.state.tournaments || [],
            bets        = this.state.bets || [],
            categories  = this.state.categories || [];

        return (
            <div className="m-t-details-content">

                <div className="m-t-bets nano">
                    <div className="m-t-content-header">{this.translate('product.bets')}</div>
                    <div className="m-t-content-search">
                        <input type="text" placeholder={this.translate('general.search')} ref="betsSearchInput" onChange={this.handleBetsSearch} />
                        <i className="n-i n-i-reset">{}</i>
                    </div>
                    <div className="m-t-content-data nano-content">
                        {bets.map(function (bet, key) {
                            return <MarketTemplatesCheckbox
                                displayName={bet.bet_name}
                                checkValue={bet.selected}
                                idValue={bet.id_bet}
                                index={key}
                                checkboxChangeAction={this.handleBetChange}
                            />;
                        }.bind(this))}
                    </div>
                </div>


                <div className="m-t-tournaments nano">
                    <div className="m-t-content-header">{this.translate('product.tournaments')}</div>
                    <div className="m-t-content-search">
                        <input type="text" placeholder={this.translate('general.search')} ref="tournamentsSearchInput" onChange={this.handleTournamentsSearch} />
                        <i className="n-i n-i-reset">{}</i>
                    </div>
                    <CategoryFilter categories={categories} categoryFilter={this.categoryFilter} handleCategoryChange={this.handleCategoryChange} categoryFilterChange={this.props.categoryFilterChange} />

                    <div className="m-t-content-data nano-content" >
                        {tournaments.map(function (tournament, key) {
                            return <MarketTemplatesCheckbox
                                displayName={tournament.tournament_name}
                                checkValue={tournament.selected}
                                idValue={tournament.id_tournament}
                                index={key}
                                checkboxChangeAction={this.handleTournamentChange}
                            />;
                        }.bind(this))}
                    </div>
                </div>

            </div>
        );
    }
});;
var HighchartsFilters = React.createClass({
    getInitialState: function() {
        return ({
            displayFilter: false,
            selectedSport: '',
            searchValue: ''
        });
    },
    handleSelectChange: function(e) {
        var value = e.target.value;
        this.setState({
            selectedSport: value
        });
        this.props.filterSports(value);
    },
    handleInputChange: function(e) {
        var timeout = null,
            value = e.target.value,
            _this = this;

        clearTimeout(timeout);

        timeout = setTimeout(function() {
            _this.props.filterMatches(value.toLowerCase(), _this.state.selectedSport);
        }, 300);
        this.setState({
            searchValue: value
        });
    },
    render() {
        var data = this.props.data;

        return <div className="highcharts-filter">
            <select onChange={ this.handleSelectChange } value={ this.state.selectedSport }>
                <option value="">Select sport</option>
                {
                    data.map(function(value) {
                        var id = Object.keys(value)[0];
                        var name = value[id];
                        return <option value={ id }>
                            { name }
                        </option>;
                    })
                }
            </select>
            <input
                placeholder="Search match"
                className="input-filter"
                type="text"
                value={ this.state.searchValue }
                onChange={ this.handleInputChange } />
        </div>
    }
});

var HighchartsLegendRow = React.createClass({
    getInitialState: function() {
        return ({
            selected: this.props.value.selected
        });
    },
    componentWillReceiveProps(nextProps){
        if (nextProps.value.selected !== this.state.selected) {
            this.setState({
                selected: nextProps.value.selected
            })
        }
    },
    checkMatchStartTime: function (time) {
        var minutes = new Date(time).getMinutes();
        var roundedMinutes = ((minutes/15 | 0) * 15) % 60;

        return minutes === roundedMinutes;
    },
    roundMatchTime: function (time) {
        var minutes = new Date(time).getMinutes();
        var roundedMinutes = ((minutes/15 | 0) * 15) % 60;

        if (roundedMinutes === minutes) {
            return time;
        }
        return new Date(time).setMinutes(roundedMinutes);
    },
    formatTime: function(value) {
        var time = value * 1000;
        if (!this.checkMatchStartTime(time)) {
            time = this.roundMatchTime(time);
        }

        return moment(time).utc().format('HH:mm');
    },
    handleClick: function() {
        this.props.handleItemClick(this.props.rowIndex, !this.state.selected, this.props.value.id);

        // this.setState({
        //     selected: !this.state.selected
        // });
    },
    render: function() {
        var value = this.props.value;
        var tooltipInfo = value.sport_name + ' - ' + value.category_flag + ' - ' + value.tournament_display_name;
        return <div
            title={tooltipInfo}
            className={'legend-item ' + (this.state.selected ? 'selected' : '')}
            onClick={this.handleClick}>
            <span className={'legend-item-sport ' + value.id_sport}></span>
            <span>{ this.formatTime(value.match_start_datetime) }</span> -
            <span className="legend-item-value">{ value.match_display_name || value.match_name }</span>
        </div>
    }
});

var HighchartsLegend = React.createClass({
    getInitialState: function () {
        return ({
            data: [],
            filteredData: [],
            sports: []
        });
    },
    setData: function(data, sports) {
        this.setState({
            data: data,
            filteredData: data,
            sports: sports
        });
    },
    handleItemClick: function(index, selected, matchId) {
        if (this.state.data.length === this.state.filteredData.length) {
            this.props.legendItemClick(index, selected);


            this.state.data[index].selected = selected;
            this.setState({
                data: this.state.data,
                filteredData: this.state.data
            });
            return;
        }

        var selectedIndex = _.findIndex(this.state.data, function(value) {
            return value.id === matchId;
        });
        this.props.legendItemClick(selectedIndex, selected);
        this.state.data[selectedIndex].selected = selected;
        this.state.filteredData[index].selected = selected;
        this.setState({
            data: this.state.data,
            filteredData: this.state.filteredData
        });
    },
    filterSports: function(id) {
        var filtered = this.state.data;
        if (id) {
            filtered = _.filter(this.state.data, function(value) {
                return ('sport-' + id) === value.id_sport;
            });
        }

        this.setState({
            filteredData: filtered
        });
    },
    filterMatches: function(searchValue, idSport) {
        if (!searchValue) {
            this.filterSports(idSport);
            return;
        }

        if (!searchValue && !idSport) {
            this.setState({
                filteredData: this.state.data
            });
            return;
        }

        var filtered = _.filter(this.state.data, function(value) {
            var matchName = value.match_display_name.toLowerCase();
            if (idSport) {
                return (('sport-' + idSport) === value.id_sport) && matchName.indexOf(searchValue) > -1;
            } else {
                return matchName.indexOf(searchValue) > -1;
            }
        });
        this.setState({
            filteredData: filtered
        });
    },
    render: function() {
        var sports = this.state.sports;

        return <div className="highcharts-legend-items">
            <HighchartsFilters
                data={ sports }
                filterSports={ this.filterSports }
                filterMatches={ this.filterMatches } />
            {this.state.filteredData.map(function(value, index) {
                if(!value.match_selected) return null;
                return <HighchartsLegendRow
                    rowIndex={index}
                    value={value}
                    handleItemClick={this.handleItemClick}/>
            }.bind(this))}
        </div>;
    }
});
;

var PrematchBodyExpandRows = React.createClass({
    mixins: [ExpendableTable],

    localsettings: {},

    inputChangeAction: function (value, cellKey) {
        this.props.data[cellKey] = value;
        this.props.gridMethods.inputAction(this.props.data, this.props.cachedData, this.props.rowKey, 'saveMatch');
    },

    handleCheckboxChange: function (value, cellKey) {
        this.props.data[cellKey] = value;
        this.props.gridMethods.inputAction(this.props.data, this.props.cachedData, this.props.rowKey, 'saveMatch');
    },

    handleSelectionChange: function (data, selectionIndex) {

        var selection = _.clone(data),
            expandTableStatus = this.props.gridMethods.getExpandTableStatus();

        delete selection.selection_0;
        this.props.data.selections = selection;

        this.processSelectionChange(selection, selectionIndex, expandTableStatus.loadedId, 'matchSettings', this.props.data.id);
        this.props.gridMethods.inputAction(this.props.data, this.props.cachedData, this.props.rowKey, 'saveMatch');

    },

    handleContextMenu: function (evt) {
        evt.preventDefault();
        $('#react-context-menu').css({display: 'none'});
        if(this.props.config.expandTableContextMenu !== 'yes') return;

        this.props.setContextMenuData(this.props.data);
        this.props.setContextMenuPosition(evt.pageX, evt.pageY);
    },

    handleClick: function (evt) {
        this.props.gridMethods.highlightExpandRow(this.props.rowKey);
        this.props.gridMethods.selectMatch(this.props.data);
    },

    handleEditClick: function () {
        this.props.gridMethods.expandTableEditAction(this.props.data);
    },

    handleDeleteClick: function () {
        this.props.gridMethods.expandTableDeleteAction(this.props.data);
    },

    cacheData: function() {
        this.props.cachedData = Object.assign({}, this.props.data);
    },

    render: function () {

        var columns = this.props.columns,
            data    = this.props.data,
            cells   = [],
            config  = this.props.config || {};

        this.cacheData();

        for (var i in columns) {
            if (columns[i].visibility) {
                cells.push(<TableBodyCell
                    column                  = {columns[i]}
                    value                   = {data[i]}
                    config                  = {config}
                    type                    = {columns[i].type}
                    key                     = {this.props.rowKey + '-cell-' + i}
                    rowKey                  = {this.props.rowKey}
                    dateFormat              = {this.props.dateFormat}
                    cellKey                 = {i}

                    tableEditAction         = {this.props.tableEditAction}
                    checkboxToggleAction    = {this.handleCheckboxChange}
                    inputChangeAction       = {this.inputChangeAction}
                    handleSelectionChange   = {this.handleSelectionChange}
                />)
            }
        }


        return (
            <tr className='clickable' onClick={this.handleClick} id={"prematch-expandable-row-id-" + this.props.rowKey} onContextMenu={this.handleContextMenu}>

                {cells}

                {function(){
                    if (this.props.config.editAction === 'yes') {

                        return <td> <EditButtonCell  handleDeleteBtnClick={this.handleDeleteClick} handleEditBtnClick={this.handleEditClick} config={this.props.config} /> </td>
                    }
                }.bind(this)()}

                <td></td>
            </tr>
        )
    }
});


var PrematchHeaderExpandRow = React.createClass({
    mixins: [LocationMixin],

    render: function () {
        var titles      = this.props.data.titles,
            columns     = this.props.columns,
            urlFilters  = this.props.urlFilters,
            cells       = [],
            config      = this.props.config || {};


        config.columnSaveKey = this.getCurrentLocation() + '/expandable-table';

        for (var i in columns) {

            if (columns[i].visibility) {
                cells.push(<TableHeaderCell
                    updateFilters           = {this.props.updateFilters}
                    updateLocationFilter    = {this.props.updateLocationFilter}
                    setVisibility           = {this.props.setVisibility}

                    value                   = {titles[i]}
                    column                  = {columns[i]}
                    columnKey               = {i}
                    urlFilters              = {urlFilters}
                    key                     = {'table-header-cell-' + i}
                />);
            }
        }



        return (
            <tr className='table-header'>

                {cells}

                {function(){
                    if (this.props.config && this.props.config.editAction === 'yes') {
                        return <th>Edit</th>
                    }
                }.bind(this)()}


                <th className='relative-pos column-select-header-cell'>
                    <ColumnSelectionComponent
                        data            = {titles}
                        columns         = {columns}

                        setVisibility   = {this.props.setVisibility}
                        config          = {config}
                        key             = 'column-select'
                    />
                </th>

            </tr>
        )
    }
});


var PrematchOfferExpandTable = React.createClass({
    mixins: [LocationMixin, ExpendableTable],

    previousRow: 0,
    contextData: null,

    getInitialState: function () {
        var columns = this.getLocalStorageColumns() ? this.getLocalStorageColumns() : this.props.data.columns;
        return ({columns: columns});
    },

    // Get columns saved to local storage by url as object key
    getLocalStorageColumns: function getLocalStorageColumns () {
        var a = this.getCurrentLocation() + '/expandable-table';
        return JSON.parse(localStorage.getItem(a));
    },

    // Highlight first row on component update
    componentDidUpdate: function () {
        this.highlightExpandRow(0);
    },

    // Update  columns
    componentWillReceiveProps: function (newProps) {
        var columns = this.getLocalStorageColumns() ? this.getLocalStorageColumns() : newProps.data.columns;
        this.setState({columns: columns});
    },

    // Highlight row by provided row key
    highlightExpandRow: function (rowKey) {
        var preRow = ReactDOM.findDOMNode(this.refs['p-o-expand-row-' + this.previousRow]);
        var row = ReactDOM.findDOMNode(this.refs['p-o-expand-row-' + rowKey]);

        if (!preRow || !row) return;

        preRow.classList.remove('selected-prematch-expanded');
        row.classList.add('selected-prematch-expanded');

        this.previousRow = rowKey;
    },

    // Columns visibility changed in child component. Update it
    setVisibility: function setVisibility (oldColumns) {
        this.oldColumns = oldColumns;
        var columns = this.getLocalStorageColumns() !== null ? this.getLocalStorageColumns() : this.props.data.columns;
        this.setState({columns: columns});
    },

    setContextMenuPosition: function (x, y) {
        $('#react-context-menu').css({display: 'block', left: x - 150, top: y - 200});
    },

    setContextMenuData: function (data) {
        this.contextData = data;
    },

    resolveContextMenuAction: function (id) {
        this.props.gridMethods.contextMenuAction(this.contextData, id);
    },

    render: function () {
        if (!this.props.data || !this.props.data.data) return (<EmptyCell />);

        var columns             = this.state.columns,
            data                = this.props.data,
            dateFormat          = this.props.dateFormat,
            gridMethods         = this.props.gridMethods,
            config              = this.props.config || {};

        gridMethods.highlightExpandRow = this.highlightExpandRow;

        var bodyRows = data.data.map(function (value, rowKey) {
            return (
                <PrematchBodyExpandRows
                    data                    = {value}
                    ref                     = {"p-o-expand-row-" + rowKey}
                    columns                 = {columns}
                    config                  = {config}
                    dateFormat              = {dateFormat}
                    rowKey                  = {rowKey}
                    gridMethods             = {gridMethods}
                    setContextMenuPosition  = {this.setContextMenuPosition}
                    setContextMenuData      = {this.setContextMenuData}
                />
            );
        }.bind(this));

        return (
            <div>
                <div className='table-expand-content'>
                    <table className={'table table-condensed table-responsive'} id="react-expand-table">
                        <thead>

                        <PrematchHeaderExpandRow data={data} columns={columns} config={this.props.config} urlFilters={this.props.urlFilters} setVisibility={this.setVisibility} />
                        </thead>

                        <tbody>
                        {bodyRows}
                        </tbody>
                    </table>
                    <ContextMenu resolveContextMenuAction={this.resolveContextMenuAction} rcActions={config.rcActions} />
                </div>
            </div>
        );

    }
});;

var POFilterName = React.createClass({
    displayFilter: function (evt) {
        evt.stopPropagation();
        this.props.displayFilter(this.props.filterKey);
    },

    handleClick: function (evt) {
        evt.stopPropagation();
    },

    render: function () {
        var urlFilters = this.props.urlFilters,
            selected = [];

        if (urlFilters && urlFilters[this.props.filterKey]) {
            selected = urlFilters[this.props.filterKey].split(',');
        }

        return(
            <div className='filter pull-left'>
                <div onClick={this.displayFilter} className={urlFilters && urlFilters[this.props.filterKey] ? 'filter-name active-filter' : 'filter-name'}>
                    <div className = 'filter-title'> {this.props.name} </div>
                    <i className = 'n-i n-i-arrow-down-b filter-arrow'>{}</i>
                </div>

                {function(){
                    if (!this.props.displayContent) return;

                    var classList = 'filter-content' + (this.props.filterKey === 'id_tournament' ? ' wide-filter-content' : '');

                    return (<div className={classList} onClick={this.handleClick}>
                        <MultiFilter setMultiFilter={this.props.setMultiFilter} clearFilteredItems={this.props.clearFilteredItems} selectAllFilters={this.props.selectAllFilters} selected={selected} values={this.props.values} filterKey={this.props.filterKey} />
                    </div>);

                }.bind(this)()}

            </div>
        )
    }
});

var PrematchOfferFilters = React.createClass({
    mixins: [TranslateMixin, ExpendableTable],

    filters: {},

    filterTimeout: null,

    getInitialState: function () {
        //this.initSelectedFilters();
        return({data: {filters: {}}, displayContent: ''});
    },

    initSelectedFilters: function (filters) {
        // If state.openedFilter is set to filter's key, it's content will be displayed
        this.filters = {};

        // Set values to filter object from provided url filters
        for (var i in this.props.urlFilters) {
            this.filters[i] = String(filters[i]).split(',');
        }
    },

    componentWillReceiveProps: function  () {
        //this.initSelectedFilters();
    },

    handleGlobalClick: function handleGlobalClick (e) {
        if (this.state.displayContent === '') return;
        this.setState({displayContent: ''});
    },

    handleDateFilterContentClick: function (event){
        event.stopPropagation();
    },

    componentWillMount: function () {
        window.addEventListener('click', this.handleGlobalClick);
    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('click', this.handleGlobalClick);
    },

    setData: function (data, urlFilters) {
        this.setState({data: data, urlFilters: urlFilters});
        // populate 'this.filters' object with current 'activeFilters'
        // if this object is empty click event on an already active filter will deselect others options in that filter
        // (e.g. this occurs after refresh or sending the link to someone else)
        this.initSelectedFilters(urlFilters);
    },

    displayFilter: function (filterKey) {
        var displayContent = this.state.displayContent === filterKey ? '' : filterKey;
        this.setState({displayContent: displayContent});
    },

    displayDateFilter: function (evt) {
        evt.stopPropagation();
        this.setState({displayContent: this.state.displayContent !== 'date' ? 'date' : ''});
    },

    selectAllFilters: function (filterKey, filterVals) {
        for (var valIndex in filterVals) {
            if (!this.filters[filterKey] || this.filters[filterKey].length == 0) {
                this.filters[filterKey] = filterVals;
            } else if (this.filters[filterKey].indexOf(filterVals[valIndex]) == -1) {
                this.filters[filterKey].push(filterVals[valIndex]);
            }
        }

        // Set currently selected filter to url
        this.hideExpandTable();
        this.props.updateFilters(filterKey, this.filters[filterKey].join());
        this.props.updateFilters('page', '1');
        this.setState({renderFilters: !this.state.renderFilters});
    },

    clearFilteredItems: function(filterKey, filterVals) {

        if (this.filters[filterKey] && this.filters[filterKey].length > 0) {
            for (var valIndex in filterVals) {
                var thisIndex = this.filters[filterKey].indexOf(filterVals[valIndex]);
                if ( thisIndex !== -1 ) {
                    this.filters[filterKey].splice( thisIndex, 1)
                }
            }
            // Set currently selected filter to url
            this.hideExpandTable();
            this.props.updateFilters(filterKey, this.filters[filterKey].join());
            this.props.updateFilters('page', '1');
            this.setState({renderFilters: !this.state.renderFilters});
        }
    },

    setSearchFilter: function (key, value) {
        if (typeof value === 'undefined') return;

        this.filters.search = value;
        this.props.updateFilters('search', value);
        this.props.updateFilters('page', '1');
    },

    setMultiFilter: function (key, value) {
        if (!this.filters[key]) {
            this.filters[key] = [];
        }

        var index = this.filters[key].indexOf(value);
        if (index === -1) {
            this.filters[key].push(value);
        } else {
            this.filters[key].splice(index, 1);
        }

        // Set currently selected filter to url
        this.hideExpandTable();
        this.props.updateFilters(key, this.filters[key].join());
        this.props.updateFilters('page', '1');
    },

    setDateFilter: function (key, value, state) {

        if (typeof key !== 'undefined' && typeof value !== 'undefined') {
            this.hideExpandTable();
            this.props.updateFilters(key, value, state);
            this.props.updateFilters('page', '1', state);
        }
    },

    closeFilter: function () {
        this.setState({displayContent: ''});
    },

    render: function () {

        var filterKeys          = Object.keys(this.state.data.filters),
            filters             = this.state.data.filters,
            dateFilters         = this.state.data.dateFilters,
            serverTime          = this.state.data.serverTime,
            dateFormat          = this.state.data.dateFormat,
            filterDisplayName   = this.state.data.filterDisplayName,
            urlFilters          = this.state.data.urlFilters,
            selectedSearch      = urlFilters && urlFilters['search'],
            locale              = this.state.data.locale,
            toggler             = null;

        if (this.props.config && this.props.config.tableWidthToggler === 'yes') {
            toggler = <TableWidthToggler changeTableWidth={this.props.changeTableWidth} config={this.props.config}/>
        }


        return(<div className="filters-container prematch-offer-container">
                {/*SEARCH FILTER*/}
                <SearchFilter
                    updateFilters       = {this.setSearchFilter}
                    updateLocationFilter= {this.setSearchFilter}
                    selected            = {selectedSearch}
                    filterKey           = 'search'
                />

                {toggler}

                {/*DATE FILTER*/}
                <div>
                    <div className='filter pull-left' onClick={this.displayDateFilter}>
                        <div className='filter-name active-filter'>
                            <div className = 'filter-title'> {this.translate('general.date')} </div>
                            <i className = 'n-i n-i-arrow-down-b filter-arrow'>{}</i>
                        </div>
                        {function(){
                            if (this.state.displayContent !== 'date') return;

                            return (<div className="filter-content filter-date" onClick={this.handleDateFilterContentClick}>
                                <DateFilter
                                    updateFilters       = {this.setDateFilter}
                                    updateLocationFilter= {this.setDateFilter}
                                    closeFilter         = {this.closeFilter}
                                    dateFilterData      = {dateFilters}
                                    serverTime          = {serverTime}
                                    dateFormat          = {dateFormat}
                                    locale              = {locale}
                                    filterKey           = 'date'
                                />
                               </div>);
                        }.bind(this)()}
                    </div>
                </div>

                {/*MULTI FILTERS*/}
                {
                    filterKeys.map(function(filterKey) {
                        return (
                            <div>
                                <POFilterName
                                    name            = {filterDisplayName[filterKey]}
                                    values          = {filters[filterKey]}
                                    filterKey       = {filterKey}
                                    urlFilters      = {urlFilters}
                                    selectAllFilters= {this.selectAllFilters}
                                    clearFilteredItems={this.clearFilteredItems}
                                    displayContent  = {this.state.displayContent === filterKey}
                                    setMultiFilter  = {this.setMultiFilter}
                                    displayFilter   = {this.displayFilter} />
                            </div>
                        );
                    }.bind(this))
                }

                <div className='react-filter-btns'>
                    <div className='save-react-filter' onClick={this.props.resetFilters}>
                        {this.translate('general.reset')}
                    </div>
                </div>
            </div>
        );

    }
});;
var PrematchOfferSideTable = React.createClass({
    getInitialState: function () {
        return({
            data: {}
        });
    },
    componentDidMount: function () {
        window.addEventListener('scroll', this.handleScroll);
    },
    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    setData: function (data, meta, selected) {
        // Selected will be used to save columns to local storage using different keys
        this.selected = selected;
        this.setState({
            data: data,
            meta: meta,
            selected: selected
        });
    },
    handleScroll: function () {
        if (!this.sideTable) {
            this.sideTable = $('#prematch-side-table');
        }
        if (window.pageYOffset > 120) {
            this.sideTable.addClass('p-o-sticky-table');
        } else {
            this.sideTable.removeClass('p-o-sticky-table');
        }
    },
    render: function () {
        var selected = this.state.selected,
            meta = this.state.meta,
            data = this.state.data,
            mode = this.props.mode,
            gridMethods = this.props.gridMethods;

        if (!selected || !data) return null;

        var selectedView = null;
        if (selected === 'tournaments') {
            selectedView = <OfferSideTournaments
                meta={meta}
                data={data}
                gridMethods={gridMethods}/>;
        } else if (selected === 'matches') {
            selectedView = <OfferSideMatches
                meta={meta}
                data={data}
                mode={mode}
                gridMethods={gridMethods}/>;
        } else {
            return null;
        }

        return <div id="prematch-side-table" className="col-5 offer-side-table">
            {selectedView}
        </div>
    }
});;
var PrematchBodyRows = React.createClass({
    mixins: [ExpendableTable],

    inputChangeAction: function (value, cellKey) {
        console.log('change value: ', value);
        this.props.data[cellKey] = value;
        this.props.gridMethods.inputAction(this.props.data, {}, this.props.rowKey, 'saveTournamentOffer');
    },

    handleCheckboxChange: function (value, cellKey) {
        this.props.data[cellKey] = value;
        this.props.gridMethods.inputAction(this.props.data, {}, this.props.rowKey, 'saveTournamentOffer');
    },

    handleSelectionChange: function (data, selectionIndex) {

        var selection = _.clone(data),
            expandTableStatus = this.props.gridMethods.getExpandTableStatus();

        delete selection.selection_0;
        this.processSelectionChange(selection, selectionIndex, this.props.data.id, 'tournamentSettings');

        // check if this row's data is loaded >> if true apply changes immediately
        if (this.props.data.id == expandTableStatus.loadedId) {
            var updatedExpandTableData = this.updateExpandTableMatches(this.props.gridMethods.getExpandTableData(), this.props.data.id);
            this.props.gridMethods.updateExpandTableData(updatedExpandTableData, this.props.rowKey);
        }

        this.props.data.selections = selection;
        this.props.gridMethods.inputAction(this.props.data, {}, this.props.rowKey, 'saveTournamentOffer');
    },


    // Row clicked... => Check row keys, if keys dont match => Highlight row, hide expandable table and request tournaments details
    handleClick: function (evt) {
        if (!this.props.gridMethods.checkRowKeys(this.props.rowKey)) return;

        this.props.gridMethods.highlightRow(this.props.rowKey);
        this.hideExpandTable();
        this.props.gridMethods.selectTournament(this.props.data);

        // if clicked anything except 'showMatches' => set expandTable visibility status to false
        if (evt.target.id.indexOf('show-matches-button-') == -1 && evt.target.id.indexOf('arrow-id-') == -1) {
            this.props.gridMethods.updateExpandTableStatus('none', false);
            this.props.gridMethods.setArrowState();
        }
    },


    // Set expandable table below selected row.
    // Request matches
    showMatches: function () {
        // check if clicked on the same row(1st condition) while data request in progress(2nd condition) >>> if both true >>> do nothing
        if ( !this.props.gridMethods.checkRowKeys(this.props.rowKey) && this.props.gridMethods.checkPendingRequest()) return;

        var expandTableStatus = this.props.gridMethods.getExpandTableStatus(),
            expandTable = $('#prematch-offer-expand-table'),
            selectedRow = $('#prematch-row-id-' + this.props.rowKey);

        // if ID's match, the data has been loaded already => only trigger SHOW or HIDE table
        if (expandTableStatus.loadedId == this.props.data.id) {
            expandTableStatus.expanded ? this.hideExpandTable() : this.showExpandTable();
            this.props.gridMethods.updateExpandTableStatus(false, !(expandTableStatus.expanded));
            this.props.gridMethods.setArrowState(this.props.data.id);
            return;
        }

        // if ID's dont match => load that row's match data
        this.props.gridMethods.setPendingRequest(true);
        this.props.gridMethods.updateExpandTableStatus(this.props.data.id, true);
        expandTable.insertAfter(selectedRow);
        this.props.gridMethods.showMatches(this.props.data, this.props.rowKey);

    },


    render: function () {

        var columns = this.props.columns,
            data    = this.props.data,
            cells   = [],
            config  = {highlightOnChange: 'yes'},
            arrowClass = (this.props.arrowClassId == this.props.data.id) && this.props.gridMethods.getExpandTableStatus().expanded ? 'up' : 'down';

        for (var i in columns) {
            if (columns[i].visibility) {
                cells.push(<TableBodyCell
                    column                  = {columns[i]}
                    value                   = {data[i]}
                    config                  = {config}
                    type                    = {columns[i].type}
                    key                     = {this.props.rowKey + '-cell-' + i}
                    rowKey                  = {this.props.rowKey}
                    dateFormat              = {this.props.dateFormat}
                    cellKey                 = {i}

                    tableEditAction         = {this.props.tableEditAction}
                    checkboxToggleAction    = {this.handleCheckboxChange}
                    inputChangeAction       = {this.inputChangeAction}
                    handleSelectionChange   = {this.handleSelectionChange}
                />)
            }
        }

        var expandedRow = this.props.rowKey === this.props.expandedRow ? 'expanded-row' : '';

        return (
            <tr className={'clickable ' + expandedRow} onClick={this.handleClick} id={"prematch-row-id-" + this.props.rowKey}>
                <td id={"show-matches-button-" + this.props.rowKey} onClick={this.showMatches}><i className={"n-i n-i-arrow-"+arrowClass+"-a"} id={"arrow-id-" + this.props.rowKey}>{}</i></td>
                {cells}
            </tr>
        )
    }
});



var PrematchHeaderRow = React.createClass({

    handleColumnSelection: function () {
        this.props.reloadTable();
    },

    render: function () {
        var titles      = this.props.data.titles,
            columns     = this.props.columns,
            urlFilters  = this.props.urlFilters,
            cells       = [],
            config      = {};

        for (var i in columns) {

            if (columns[i].visibility) {
                cells.push(<TableHeaderCell
                    updateFilters           = {this.props.updateFilters}
                    updateLocationFilter    = {this.props.updateLocationFilter}
                    setVisibility           = {this.props.setVisibility}

                    value                   = {titles[i]}
                    column                  = {columns[i]}
                    columnKey               = {i}
                    urlFilters              = {urlFilters}
                    key                     = {'table-header-cell-' + i}
                />);
            }
        }



        return (
            <tr className='table-header'>

                <th className="relative-pos column-select-header-cell">
                    <ColumnSelectionComponent
                        data            = {titles}
                        columns         = {columns}

                        updateFilters   = {this.handleColumnSelection}
                        setVisibility   = {this.props.setVisibility}
                        config          = {config}
                        key             = 'column-select'
                    />
                </th>

                {cells}

            </tr>
        )
    }
});

var PrematchOfferMainTable = React.createClass({
    mixins: [LocationMixin, ExpendableTable],

    previousRow: 0,
    requestInProgress: false,
    oldColumns: null,

    stickyHeaderScrollStart: null,

    called: 0,

    getInitialState: function () {
        return({
            data: {},
            expandData: {},
            selectedRow: null,
            expandedRow: null,
            columns: this.props.columns || {},
            arrowClassId: ''
        });
    },

    currentLocation: function () {
        var a = window.location.hash,
            subVal = a.indexOf('?') !== -1 ? a.indexOf('?') : a.length;
        a = a.substring(1, subVal);
        return a;
    },

    // Get columns saved to local storage by url as object key
    getLocalStorageColumns: function getLocalStorageColumns () {
        var a = this.currentLocation();
        return JSON.parse(localStorage.getItem(a));
    },

    removeColumn: function removeColumn () {
        var columns = this.state.columns,
            columnKeys = Object.keys(columns);

        for (var i = columnKeys.length - 1; i >= 0; i--) {

            if (columns[columnKeys[i]].visibility) {
                columns[columnKeys[i]].visibility = false;
                this.setState({columns: columns});
                return;
            }

        }
    },

    checkTableWidth: function checkTableWidth () {
        var details = document.getElementById('prematch-side-table');
        var table = document.getElementById('prematch-offer-table');
        if(details && table) {
            var tableRight = table.getBoundingClientRect().right,
                detailsLeft = details ? details.getBoundingClientRect().left : document.getElementsByTagName('body')[0].offsetWidth;
            return (tableRight - 10) >= detailsLeft;
        }
    },

    handleResize: function () {
        if (this.checkTableWidth()) {
            this.removeColumn();
        }
    },


    handleGlobalClick: function (evt) {
        $('#react-context-menu').css({display: 'none'});
    },

    // Highlight last select row and update columns
    componentWillReceiveProps: function (newProps) {
        this.setState({columns: newProps.columns})
    },

    componentDidMount: function () {
        window.addEventListener('scroll', this.handleScroll);
        // window.addEventListener('resize', this.handleResize);
        document.addEventListener('click', this.handleGlobalClick, false);

        // Check if table width overflow, if so, hide some of columns
        /*if (this.checkTableWidth()) {
            this.removeColumn();
        }*/
    },

    componentWillUnmount: function () {
        window.removeEventListener('scroll', this.handleScroll);
        // window.removeEventListener('resize', this.handleResize);
        document.removeEventListener('click', this.handleGlobalClick, false);
    },

    componentDidUpdate: function () {
        this.handleScroll();
        this.highlightRow(this.previousRow);
        this.props.gridMethods.setPendingRequest(false);

        // Check if table overflows and use last saved columns
        if (this.checkTableWidth() && this.oldColumns !== null ) {
            var a = this.currentLocation();
            localStorage.setItem(a, JSON.stringify(this.oldColumns));
            this.setState({columns: this.oldColumns});
            document.getElementById('columns-overflow-error').classList.remove('hidden');
        } /*else if (this.checkTableWidth()) { // If there is no old columns, just hide last visible column
            this.removeColumn();
        }*/

        this.oldColumns = null;
    },

    // Columns visibility changed in child component. Update it
    setVisibility: function (oldColumns) {
        //if (state)
        this.oldColumns = oldColumns;
        var columns = this.getLocalStorageColumns() !== null ? this.getLocalStorageColumns() : this.props.columns;
        this.setState({columns: columns});
    },

    // Hide expand table on filter change
    // Page default to 1 if filter is changed
    updateFilters: function (key, value) {
        // on filter change update the expanded-table status, clear data and highlight 1st row
        this.hideExpandTable();
        this.props.gridMethods.updateExpandTableStatus('none', false);
        this.setExpandData({data:[]}, 0);

        if (key && value) {
            this.props.gridMethods.updateFilters('page', '1');
            this.props.gridMethods.updateFilters(key, value, true);
        }

        setTimeout(() => {this.highlightRow(0, true)}, 1000);
    },

    calculateStickyHeader: function calculateStickyHeader () {

        var tableHeader = ReactDOM.findDOMNode(this.refs['p-o-header-row']).getElementsByTagName('th'),
            pageYOffset = window.pageYOffset || document.documentElement.scrollTop;

        if (!this.stickyHeaderScrollStart) {
            var tableHeaderTopOffset = tableHeader[0].getBoundingClientRect().top;
            this.stickyHeaderScrollStart = pageYOffset + tableHeaderTopOffset - 45;;
        }

        if (this.stickyHeaderScrollStart) {
            var offsetPosition = pageYOffset > this.stickyHeaderScrollStart ? (pageYOffset - this.stickyHeaderScrollStart) + 'px' : '0px';

            for (var i = 0; i < tableHeader.length; i++) {
                if (tableHeader[i].style.position !== 'relative') {
                    tableHeader[i].style.position = 'relative';
                }
                tableHeader[i].style.top = offsetPosition;
            }
        }
    },

    handleScroll: function () {
        window.requestAnimationFrame(this.calculateStickyHeader)
    },

    // Highlight row by provided row key
    // filterChange >> if filter's changed this parameter is passed as true >> select first row (index 0)
    highlightRow: function (rowKey, filterChange) {
        var preRow = ReactDOM.findDOMNode(this.refs['p-o-row-' + (this.previousRow == 'none' ? 0 : this.previousRow)]);
        var row = ReactDOM.findDOMNode(this.refs['p-o-row-' + rowKey]);
        if ((!preRow || !row)) return;

        preRow.classList.remove('selected-prematch-offer');
        row.classList.add('selected-prematch-offer');

        this.previousRow = filterChange ? 'none' : rowKey;
    },

    setArrowState: function(id) {
        id ? this.setState({arrowClassId: id}) : this.setState({arrowClassId: ''});
    },

    // Check if row keys are the same to skip loading/fetching the same data
    checkRowKeys: function(rowKey) {
        return rowKey !== this.previousRow;
    },
    setPendingRequest: function(status) {
        this.requestInProgress = status;
    },

    checkPendingRequest: function() {
        return this.requestInProgress;
    },

    // Set main table data
    setData: function (data, selectedRow) {
        this.setState({data: data, columns: data.columns, selectedRow: selectedRow});
    },

    // Set expandable table data and show it
    setExpandData: function (data, selectedRow, tournamentId) {
        // stop if there is no data (e.g. request cancelled)
        if (!data || data.data.length == 0) {
            this.setState({expandData: {}, selectedRow: selectedRow, arrowClassId: tournamentId, expandedRow: selectedRow});
            return;
        }

        var updatedData = tournamentId ? this.updateExpandTableMatches(data, tournamentId) : this.state.expandData;

        this.showExpandTable();
        this.setState({expandData: updatedData, selectedRow: selectedRow, arrowClassId: tournamentId, expandedRow: selectedRow});
    },

    getExpandTableData: function() {
        if (this.state.expandData.data && this.state.expandData.data.length > 0) {
            return this.state.expandData;
        }
        return false;
    },

    updateExpandTableData: function(data, selectedRow) {
        this.setState({expandData: data, selectedRow: selectedRow});
    },

    render: function () {
        if (Object.keys(this.state.data).length === 0) return (<EmptyCell />);

        var columns             = this.state.columns,
            expandData          = this.state.expandData,
            dateFormat          = this.props.dateFormat,
            urlFilters          = this.props.urlFilters,
            gridMethods         = this.props.gridMethods,
            config              = this.props.config || {};
        gridMethods.highlightRow                        = this.highlightRow;
        gridMethods.checkRowKeys                        = this.checkRowKeys;
        gridMethods.getExpandTableData                  = this.getExpandTableData;
        gridMethods.updateExpandTableData               = this.updateExpandTableData;
        gridMethods.checkPendingRequest                 = this.checkPendingRequest;
        gridMethods.setPendingRequest                   = this.setPendingRequest;
        gridMethods.setArrowState                       = this.setArrowState;

        var bodyRows = this.state.data.data.map(function (value, rowKey) {
            return (
                    <PrematchBodyRows
                        data={value}
                        ref={'p-o-row-' + rowKey}
                        columns={columns}
                        dateFormat={dateFormat}
                        rowKey={rowKey}
                        expandedRow={this.state.expandedRow}
                        gridMethods={gridMethods}
                        arrowClassId={this.state.arrowClassId}
                    />
            );
        }.bind(this));

        return (
            <div className='table-content'>
                <table className={'table table-condensed main-table table-responsive ' + (config.class ? config.class : 'col-7')} id='prematch-offer-table'>
                    <thead>
                        <tr id='sticky-p-o-header-row' className='hide-sticky-header'>{}</tr>
                        <PrematchHeaderRow data                 ={this.state.data}
                                           columns              ={this.state.columns}
                                           ref                  ="p-o-header-row"
                                           urlFilters           ={urlFilters}
                                           setVisibility        ={this.setVisibility}
                                           updateFilters        ={this.updateFilters}
                                           updateLocationFilter = {this.updateFilters}
                                           reloadTable          = {this.props.gridMethods.reloadTable}
                        />
                    </thead>


                    <tbody id='react-table-body'>
                        {bodyRows}
                        <tr id="prematch-offer-expand-table" className="prematch-offer-row hidden">
                            <td className="prematch-offer-cell" colSpan="40">

                                <PrematchOfferExpandTable data={expandData} config={this.props.config} dateFormat={dateFormat} urlFilters={urlFilters} gridMethods={gridMethods} />
                            </td>
                        </tr>
                    </tbody>


                    <tfoot>
                    <tr>
                        <td colSpan='40'>
                            <Pagination
                                data                = {this.state.data.pagination}
                                updateFilters       = {this.updateFilters}
                                updateLocationFilter= {this.updateFilters}
                            />
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        );

    }
});;
var OfferSideInputField = React.createClass({
    getInitialState: function () {
        return {
            value: this.props.value || ''
        };
    },
    componentWillReceiveProps: function (newProps) {
        this.setState({
            value: newProps.value || ''
        });
    },
    handleChange: function (e) {
        var value = e.target.value;
        this.setState({
            value: value
        });
        this.props.changeValue(this.props.rowIndex, this.props.cellKey, value);
    },
    render: function () {
        return <input
            className="offer-sidebar-input"
            value={this.state.value}
            onChange={this.handleChange}
            type="text"/>
    }
});;
var OfferSideMatches = React.createClass({
    mixins: [LocationMixin, TranslateMixin],
    getInitialState: function () {
        return {
            selected: 'bets',
            selectedRow: 0
        };
    },
    setActiveTab: function (selected) {
        this.setState({
            selected: selected
        });
    },
    handleContentScroll: function (e) {
        if (!this.header) {
            this.header = document.querySelector('.offer-grid-header-wrap');
        }
        this.header.scrollLeft = e.target.scrollLeft;
    },
    getData: function() {
        var selected = this.state.selected;
        return this.props.data[selected].data;
    },
    getColumns: function () {
        var selected = this.state.selected;
        return this.props.data[selected].columns;
    },
    getTitles: function () {
        var selected = this.state.selected;
        return this.props.data[selected].titles;
    },
    getLocalStorageKey: function () {
        return this.getCurrentLocation() + '/' + this.props.mode + '/matches/' + this.state.selected + '/side-table';
    },
    getLocalStorageColumns: function () {
        var columnVisibilityKey = this.getLocalStorageKey(),
            localColumns = localStorage.getItem(columnVisibilityKey);
        return localColumns ? JSON.parse(localStorage.getItem(columnVisibilityKey)) : localColumns;
    },
    // Change columns visibility
    setVisibility: function () {
        var localStorageColumn = this.getLocalStorageColumns();
        var columns = localStorageColumn !== null ? localStorageColumn : this.getColumns();
        this.setState({columns: columns});
    },
    handleInputChange: function (rowIndex, cellKey, value) {
        var data = this.getData();
        data[rowIndex][cellKey] = value;
        this.props.gridMethods.inputAction(data[rowIndex], {}, rowIndex, 'saveTeamTranslation');
    },
    handleSelectionChange: function (rowSelection, index, meta) {
        var selection = _.clone(rowSelection);
        delete selection.selection_0;

        var data = this.getData();
        data[meta.rowIndex].selections = selection;

        this.props.gridMethods.inputAction(data[meta.rowIndex], {}, meta.rowIndex, 'saveMatchBets');
    },
    generateOutcomesHeader: function () {
        var arr = [],
            outcomesLength = this.props.meta.outcomesLength,
            data = this.getData(),
            selectedRow = this.state.selectedRow;

        for (var i = 0; i < outcomesLength; i++) {
            arr.push(<div className="grid-cell grid-cell-outcome">
                {data[selectedRow].outcomes && data[selectedRow].outcomes[i]
                    ? data[selectedRow].outcomes[i].outcome
                    : ''}
            </div>);
        }
        return arr;
    },
    selectRow: function (selectedRow) {
        this.setState({
            selectedRow: selectedRow
        });
    },
    render: function () {
        var data = this.getData();

        var selected = this.state.selected,
            columns = this.getColumns(),
            titles = this.getTitles(),
            localStorageColumns = this.getLocalStorageColumns(),
            meta = this.props.meta,
            outcomesHeader = [],
            config = {
                columnSaveKey: this.getLocalStorageKey()
            };

        if (localStorageColumns) {
            columns = localStorageColumns;
        }

        if (selected === 'bets') {
            outcomesHeader = this.generateOutcomesHeader();
        }

        return <div>
            <div className="meta-info">
                {meta.match_name + ' | ' + meta.match_datetime}
                <div className="column-selection-wrap">
                    <ColumnSelectionComponent
                        data={titles}
                        columns={columns}
                        setVisibility={this.setVisibility}
                        config={config}
                        key='column-select'
                    />
                </div>
            </div>
            <div className="offer-header-wrapper">
                <div
                    className={selected === 'bets' && 'selected'}
                    onClick={this.setActiveTab.bind(this, 'bets')}>
                    {meta.isOutright ? this.translate('product.outright_bets') : this.translate('product.match_bets')}
                </div>
                <div
                    className={selected === 'translations' && 'selected'}
                    onClick={this.setActiveTab.bind(this, 'translations')}>
                    {meta.isOutright ? this.translate('product.outright_translations') : this.translate('product.match_translations')}
                </div>
            </div>
            <div className="offer-content">
                <div className="offer-grid">
                    {selected === 'bets' && <div className="offer-grid-wrap">
                        <div className="offer-grid-header-wrap">
                            <div className="offer-grid-header">
                                {columns.id.visibility && <div className="grid-cell grid-cell-bet-id">{titles.id}</div>}
                                {columns.display_id && columns.display_id.visibility && <div className="grid-cell grid-cell-display-id">{titles.display_id}</div>}
                                {columns.bet_name.visibility && <div className="grid-cell grid-cell-name">{titles.bet_name}</div>}
                                {columns.special_value.visibility && <div className="grid-cell grid-cell-special">{titles.special_value}</div>}
                                {columns.selections.visibility && <div className="grid-cell grid-cell-platforms">{titles.selections}</div>}
                                {columns.outcomes.visibility && outcomesHeader}
                            </div>
                        </div>
                        <div className="offer-grid-content" onScroll={this.handleContentScroll}>
                            {
                                data.map(function (row, index) {
                                    return <div className="grid-body-row" onClick={this.selectRow.bind(this, index)}>
                                        {columns.id.visibility && <div className="grid-cell grid-cell-bet-id">{row.id}</div>}
                                        {columns.display_id && columns.display_id.visibility && <div className="grid-cell grid-cell-display-id">{row.display_id}</div>}
                                        {columns.bet_name.visibility && <div className="grid-cell grid-cell-name">{row.bet_name}</div>}
                                        {columns.special_value.visibility && <div className="grid-cell grid-cell-special">{row.special_value}</div>}
                                        {columns.selections.visibility && <div className="grid-cell grid-cell-platforms">
                                            <SelectionComponent
                                                value={row.selections}
                                                meta={{ rowIndex: index }}
                                                betBlocked={row.blocked}
                                                handleSelectionChange={this.handleSelectionChange}
                                            />
                                        </div>}
                                        {columns.outcomes.visibility && row.outcomes.map(function (outcome, outcomeIndex) {
                                            return <div className="grid-cell grid-cell-outcome">{outcome.odd}</div>
                                        })}
                                        {row.blocked && <div className="blocked-bets-row">
                                            <i className="n-i n-i-lock" />
                                        </div>}
                                    </div>
                                }.bind(this))
                            }
                        </div>
                    </div>}

                    {selected === 'translations' && <div>
                    <div className="offer-grid-header">
                        {columns.id.visibility && <div className="grid-cell grid-cell-tournament-id">{titles.id}</div>}
                        {columns.name.visibility && <div className="grid-cell grid-cell-name">{titles.name}</div>}
                        {columns.language.visibility && <div className="grid-cell grid-cell-language">{titles.language}</div>}
                        {columns.display_name.visibility && <div className="grid-cell grid-cell-input-names">{titles.display_name}</div>}
                        {columns.short_name.visibility && <div className="grid-cell grid-cell-input-names">{titles.short_name}</div>}
                    </div>
                    <div className="offer-grid-content">
                        {
                            data.map(function (row, index) {
                                return <div className="grid-body-row">
                                    {columns.id.visibility && <div className="grid-cell grid-cell-tournament-id">{row.id}</div>}
                                    {columns.name.visibility && <div className="grid-cell grid-cell-name">{row.name}</div>}
                                    {columns.language.visibility && <div className="grid-cell grid-cell-language">{row.language}</div>}
                                    {columns.display_name.visibility && <div className="grid-cell grid-cell-input-names">
                                        <OfferSideInputField
                                            value={row.display_name}
                                            rowIndex={index}
                                            cellKey='display_name'
                                            changeValue={this.handleInputChange}/>
                                    </div>}
                                    {columns.short_name.visibility && <div className="grid-cell grid-cell-input-names">
                                        <OfferSideInputField
                                            value={row.short_name}
                                            rowIndex={index}
                                            cellKey='short_name'
                                            changeValue={this.handleInputChange}/>
                                    </div>}
                                </div>
                            }.bind(this))
                        }
                    </div>
                    </div> }
                </div>
            </div>
        </div>;
    }
});
;
var OfferSideTournaments = React.createClass({
    mixins: [LocationMixin, TranslateMixin],
    header: null,
    getInitialState: function () {
        return {
            selected: 'bets'
        };
    },
    handleContentScroll: function (e) {
        if (!this.header) {
            this.header = document.querySelector('.offer-grid-header-wrap');
        }
        this.header.scrollLeft = e.target.scrollLeft;
    },
    setActiveTab: function (selected) {
        this.setState({
            selected: selected
        });
    },
    getData: function() {
        var selected = this.state.selected;
        return this.props.data[selected].data;
    },
    getColumns: function () {
        var selected = this.state.selected;
        return this.props.data[selected].columns;
    },
    getLocalStorageKey: function () {
        return this.getCurrentLocation() + '/tournaments/' + this.state.selected + '/side-table';
    },
    getLocalStorageColumns: function () {
        var columnVisibilityKey = this.getLocalStorageKey(),
            localColumns = localStorage.getItem(columnVisibilityKey);
        return localColumns ? JSON.parse(localStorage.getItem(columnVisibilityKey)) : localColumns;
    },
    // Change columns visibility
    setVisibility: function () {
        var localStorageColumn = this.getLocalStorageColumns();
        var columns = localStorageColumn !== null ? localStorageColumn : this.getColumns();
        this.setState({columns: columns});
    },
    handleInputChange: function (rowIndex, cellKey, value) {
        var data = this.getData();
        data[rowIndex][cellKey] = value;
        this.props.gridMethods.inputAction(data[rowIndex], {}, rowIndex, 'saveTournamentTranslation');
    },
    handleSelectionChange: function (rowSelection, index, meta) {
        var selection = _.clone(rowSelection);
        delete selection.selection_0;

        var data = this.getData();
        data[meta.rowIndex].selections = selection;

        this.props.gridMethods.inputAction(data[meta.rowIndex], {}, meta.rowIndex, 'saveTournamentBetSelectionOffer');
    },
    render: function () {
        var selected = this.state.selected,
            data = this.getData(),
            columns = this.getColumns(),
            localStorageColumns = this.getLocalStorageColumns(),
            titles = this.props.data[selected].titles,
            meta = this.props.meta,
            config = {
                columnSaveKey: this.getLocalStorageKey()
            };

        if (localStorageColumns) {
            columns = localStorageColumns;
        }

        if (!data) return null;

        return <div>
            <div className="meta-info">
                {meta.category_name + ' | ' + meta.tournament_name}
                <div className="column-selection-wrap">
                    <ColumnSelectionComponent
                        data={titles}
                        columns={columns}
                        setVisibility={this.setVisibility}
                        config={config}
                        key='column-select'
                    />
                </div>
            </div>
            <div className="offer-header-wrapper">
                <div
                    className={selected === 'bets' && 'selected'}
                    onClick={this.setActiveTab.bind(this, 'bets')}>{this.translate('product.tournament_bets')}</div>
                <div
                    className={selected === 'translations' && 'selected'}
                    onClick={this.setActiveTab.bind(this, 'translations')}>{this.translate('product.tournament_translations')}</div>
            </div>
            <div className="offer-content">
                <div className="offer-grid">
                    {selected === 'bets' && <div className="offer-grid-wrap">
                        <div className="offer-grid-header-wrap">
                            <div className="offer-grid-header">
                                {columns.id_tournament.visibility && <div className="grid-cell grid-cell-tournament-id">{titles.id_tournament}</div>}
                                {columns.id_bet.visibility && <div className="grid-cell grid-cell-bet-id">{titles.id_bet}</div>}
                                {columns.bet_name.visibility && <div className="grid-cell grid-cell-name">{titles.bet_name}</div>}
                                {columns.selections.visibility && <div className="grid-cell grid-cell-platforms">{titles.selections}</div>}
                                {columns.bet_outcomes.visibility && <div className="grid-cell grid-cell-outcomes">{titles.bet_outcomes}</div>}
                            </div>
                        </div>
                        <div className="offer-grid-content" onScroll={this.handleContentScroll}>
                            {
                                data.map(function (row, index) {
                                    return <div className="grid-body-row">
                                        {columns.id_tournament.visibility && <div className="grid-cell grid-cell-tournament-id">{row.id_tournament}</div>}
                                        {columns.id_bet.visibility && <div className="grid-cell grid-cell-bet-id">{row.id_bet}</div>}
                                        {columns.bet_name.visibility && <div className="grid-cell grid-cell-name">{row.bet_name}</div>}
                                        {columns.selections.visibility && <div className="grid-cell grid-cell-platforms">
                                            <SelectionComponent
                                                value={row.selections}
                                                meta={{rowIndex: index}}
                                                handleSelectionChange={this.handleSelectionChange}
                                            />
                                        </div>}
                                        {columns.bet_outcomes.visibility && <div className="grid-cell grid-cell-outcomes">{row.bet_outcomes}</div>}
                                    </div>
                                }.bind(this))
                            }
                        </div>
                    </div>}

                    {selected === 'translations' && <div>
                    <div className="offer-grid-header">
                        {columns.id_tournament.visibility && <div className="grid-cell grid-cell-tournament-id">{titles.id_tournament}</div>}
                        {columns.language.visibility && <div className="grid-cell grid-cell-language">{titles.language}</div>}
                        {columns.name.visibility && <div className="grid-cell grid-cell-name">{titles.name}</div>}
                        {columns.display_name.visibility && <div className="grid-cell grid-cell-input-names">{titles.display_name}</div>}
                        {columns.short_name.visibility && <div className="grid-cell grid-cell-input-names">{titles.short_name}</div>}
                        {columns.prefix.visibility && <div className="grid-cell grid-cell-input-names prefix">{titles.prefix}</div>}
                    </div>
                    <div className="offer-grid-content">
                        {
                            data.map(function (row, index) {
                                return <div className="grid-body-row">
                                    {columns.id_tournament.visibility && <div className="grid-cell grid-cell-tournament-id">{row.id_tournament}</div>}
                                    {columns.language.visibility && <div className="grid-cell grid-cell-language">{row.language}</div>}
                                    {columns.name.visibility && <div className="grid-cell grid-cell-name">{row.name}</div>}
                                    {columns.display_name.visibility && <div className="grid-cell grid-cell-input-names">
                                        <OfferSideInputField
                                            value={row.display_name}
                                            rowIndex={index}
                                            cellKey='display_name'
                                            changeValue={this.handleInputChange}/>
                                    </div>}
                                    {columns.short_name.visibility && <div className="grid-cell grid-cell-input-names">
                                        <OfferSideInputField
                                            value={row.short_name}
                                            rowIndex={index}
                                            cellKey='short_name'
                                            changeValue={this.handleInputChange}/>
                                    </div>}
                                    {columns.prefix.visibility && <div className="grid-cell grid-cell-input-names prefix">
                                        <OfferSideInputField
                                            value={row.prefix}
                                            rowIndex={index}
                                            cellKey='prefix'
                                            changeValue={this.handleInputChange}/>
                                    </div>}
                                </div>
                            }.bind(this))
                        }
                    </div>
                    </div> }
                </div>
            </div>
        </div>;
    }
});;
var VisualizationLayout = React.createClass({
    mixins: [TranslateMixin],
    getInitialState: function () {
        return {
            data: [],
            templates: [],
            sectionSports: {},
        };
    },

    handleValueChange: function(sectionId, propertyKey , e) {
        var sectionsArray = this.state.data;
        var tabObj = _.find(sectionsArray, function(section) {
            return section.id === sectionId;
        });
        tabObj[propertyKey] = isNaN(e.target.value) || Number(e.target.value) <= 0
          ? 1
          : Number(e.target.value);

        this.setState({ data: sectionsArray });
    },

    updateSettings: function() {
      var updateData = _.map(this.state.data, function(item) {
        delete item.displayOrder;
        return item;
      });
      this.props.updateVisualizationSettings({ data: updateData });
    },

    handleKeyPress: function(e) {
        if (e.key === 'Enter') {
          this.updateSettings();
        }
    },

    parseSportPositions: function(sports) {
      return _.map(sports, function(sport) {
        sport.position = parseInt(sport.position, 10) || null;
        return sport;
      });
    },

    setData: function ({ settings, templates }) {
        var sectionSports = {};
        var parseSportPositions = this.parseSportPositions;
        _.forEach(settings.data, function(section) {
            var sports = [];
            var parsedSports = [];

            if (settings && settings.included && settings.included.sports) {
              parsedSports = parseSportPositions(settings.included.sports);
              sports = _.orderBy(parsedSports, 'position');
              _.each(sports, function(sport, i) {
                  sport.expanded = i < 5;
                  sport.template = (_.find(templates.data, function(t) {
                    if (t.sports.id === sport.id && section.templates.indexOf(t.id) > -1) {
                      return t;
                    }
                  }) || {}).id || '';
              });
            }

            sectionSports[section.id] = _.cloneDeep(sports);

            switch (section.id) {
                case 'TOP_OFFER': section.displayOrder = 0; break;
                case 'TIMELIST': section.displayOrder = 1; break;
                case 'EVENT_VIEW': section.displayOrder = 2; break;
                default: break;
            }
        });

        this.setState({
            data: _.orderBy(settings.data, 'displayOrder'),
            templates: templates.data,
            sectionSports: sectionSports,
        });
    },

    toggleSportDropdown: function(tabId, index, e) {
      var sectionSports = this.state.sectionSports;
      var sport = sectionSports[tabId][index];
      sport.expanded = !sport.expanded;
      this.setState({
        sectionSports: sectionSports,
      });
    },

    selectTemplate: function(sportId, tabId, e) {
      e.stopPropagation;
      var templateId = e.target.value;
      var settings = this.state.data;
      var section = _.find(settings, function(s){ return s.id === tabId; }) || {};
      var sectionSports = this.state.sectionSports;
      var tabSports = sectionSports[tabId];
      var sportIndex = _.findIndex(tabSports, function(s) {return s.id === sportId});
      var sportTemplates = _.filter(this.state.templates, function(t) { return t.sports.id === sportId; });
      var selectedIndex = _.findIndex(section.templates, function(st) {
        return _.findIndex(sportTemplates, function(t) { return t.id === st }) > -1;
      });
      if (selectedIndex > -1) {
        section.templates.splice(selectedIndex, 1);
      }
      if (templateId) section.templates.push(templateId);
      tabSports[sportIndex].template = templateId;

      this.setState({
        sectionSports: sectionSports,
        data: settings,
      });

      this.updateSettings();
    },

    render: function () {
        if (_.isEmpty(this.state.data)) return null;
        var tabsData = this.state.data;

        var tabs = _.map(tabsData, function(tab){
            return (<div className={"col-4 settings-card " + _.toLower(tab.id).replace('_', '-') + "-tab"}>
              <div className="tab-header">{ tab.name }</div>
              <div className="visualization-card-content">
                <div className="visualization-settings-input-field">
                  <div className="visualization-settings-label">
                    <label>{this.translate('general.number_of_rotations')}</label>
                  </div>
                  <input
                    type="number"
                    min="0"
                    value={tab.pages}
                    onChange={this.handleValueChange.bind(this, tab.id, 'pages')} />
                </div>
                <div className="visualization-settings-input-field">
                  <div className="visualization-settings-label">
                    <label>{this.translate('general.time_per_screen')}</label>
                  </div>
                  <input
                    type="number"
                    min="1"
                    value={tab.pageDuration}
                    onChange={this.handleValueChange.bind(this, tab.id, 'pageDuration')} />
                </div>
                <div className="visualization-settings-input-field">
                  <div className="visualization-settings-label">
                    <label>{this.translate('general.days_range')}</label>
                  </div>
                  <input
                    type="number"
                    min="1"
                    value={tab.offerDaysRange}
                    onChange={this.handleValueChange.bind(this, tab.id, 'offerDaysRange')} />
                </div>
              </div>
              {
                tab.id !== 'EVENT_VIEW' && <div className="visualization-layout-template-per-sport-card">
                  <div className="template-per-sport-divider">
                    { this.translate('general.template_per_sport') }
                  </div>
                  <div className="visualization-card-content scrollable-visualization-card">
                    {
                      this.state.sectionSports[tab.id] && _.map(this.state.sectionSports[tab.id], function(sport, index) {
                        return <div className={
                            'visualization-settings-input-field '
                            + (sport.expanded ? 'expanded' : 'collapsed')
                            + (sport.expanded && this.state.sectionSports[tab.id][index + 1] ? (this.state.sectionSports[tab.id][index + 1].expanded ? '' : ' last-expanded') : '')
                            + (sport.expanded && this.state.sectionSports[tab.id][index - 1] ? (this.state.sectionSports[tab.id][index - 1].expanded ? '' : ' first-expanded') : '')}>
                          <div
                            onClick={this.toggleSportDropdown.bind(this, tab.id, index)}
                            className="visualization-settings-label">
                            <label>{sport.name}</label>
                          </div>
                          {
                            sport.expanded && <div className="visualization-settings-dropdown">
                              <select onChange={this.selectTemplate.bind(this, sport.id, tab.id)} value={ sport.template }>
                                <option value=""></option>
                                {
                                  _.map(this.state.templates, function(t) {
                                    if (t.sports.id === sport.id && t.active) {
                                      return <option value={ t.id }>{ t.name }</option>;
                                    }
                                  }.bind(this))
                                }
                              </select>
                            </div>
                          }
                        </div>
                      }.bind(this))
                    }
                  </div>
                </div>
              }
            </div>);
        }.bind(this));

        return <div className="tabs-details-wrap">
            <div
                className="visualization-layout-details-content"
                onKeyPress={ this.handleKeyPress }>
                { tabs }
            </div>
        </div>
    }
});;
var ManualResolveContextMenu = React.createClass({

    handleClick: function (id, evt) {
        this.props.resolveContextMenuAction(id);
    },


    render: function () {
        if (!this.props.rcActions) return null;
        var className = 'react-context-menu context-menu dropdown-menu manual-resolve-context-menu';
        className += this.props.displayed ? ' displayed' : 'hidden';

        var offsetX = this.props.xAxis + 140 - window.innerWidth;
        var left = this.props.xAxis + 140 > window.innerWidth ? this.props.xAxis - offsetX : this.props.xAxis;
        var dynamicStyle = {
            top: this.props.yAxis,
            left: left
        };

        return (
            <ul className={className}
                id={this.props.idValue ? this.props.idValue : 'react-context-menu'}
                style={dynamicStyle}>
            {
                this.props.rcActions.map(function (action) {
                    return <li className={action.value + '-context-action'}
                        onClick={this.handleClick.bind(this, action.value)} >
                        <i className={action.iconClass}></i>
                        <span className='mr-context-action-title'>{action.title}</span>
                    </li>
                }.bind(this))
            }
        </ul>
        )
    }
});;
var ManualResolveDetails = React.createClass({
    mixins: [TranslateMixin],
    getInitialState: function () {
        return {
            headerInfo           : {},
            data                 : [],
            columnSelector       : false,
            selectedRow          : 0,
            selectedOutcome      : null,
            rcOutcome            : {},
            contextMenuDisplayed : false
        }
    },

    componentWillMount: function () {
        this.getStatusClass = this.getStatusClass.bind(this);

        this.statuses = {
            open     : 'OPEN',
            won      : 'WON',
            lost     : 'LOST',
            cancelled: 'CANCELLED',
            half_won : 'HALF_WON',
            half_lost: 'HALF_LOST'
        };

        this.contextMenuActions = [
            {
                title: 'Open',
                value: this.statuses.open,
                iconClass: 'n-i n-i-clock-round',
                classes: 'open-status-context'
            },
            {
                title: 'Won',
                value: this.statuses.won,
                iconClass: 'n-i n-i-check-d',
                classes: 'won-status-context'
            },
            {
                title: 'Lost',
                value: this.statuses.lost,
                iconClass: 'n-i n-i-close-b',
                classes: 'lost-status-context'
            },
            {
                title: 'Canceled',
                value: this.statuses.cancelled,
                iconClass: 'n-i n-i-whistle',
                classes: 'canceled-status-context'
            },
            {
                title: 'Half Won',
                value: this.statuses.half_won,
                iconClass: 'n-i n-i-won-void',
                classes: 'half-won-status-context'
            },
            {
                title: 'Half Lost',
                value: this.statuses.half_lost,
                iconClass: 'n-i n-i-lost-void',
                classes: 'half-lost-status-context'
            }
        ];

        document.addEventListener('click', this.handleClick, false);
    },

    componentDidMount: function () {
    },

    componentWillUnmount: function componentWillUnmount () {
        document.removeEventListener('click', this.handleClick, false);
    },

    componentDidUpdate: function () {
    },

    handleClick: function (e) {
        if (!this.outcomeSelectorRef) return;
        if (!this.outcomeSelectorRef.contains(e.target)) {
            this.setState({
                columnSelector: false,
            });
        }
        return;
    },

    setWrapperRef: function(ref) {
        this.wrapperRef = ref;
    },

    setOutcomeSelectorRef: function(ref) {
        this.outcomeSelectorRef = ref;
    },

    setData: function (data) {
        var maxOdds = _.max(_.map(data, function(o) { return o.outcomes.length; }));
        var outcomeColumns = {};
        for (var i = 0; i < maxOdds; i++) {
            outcomeColumns['outcome-'+i] = {
                visibility: true,
                title:  (i+1) + '.' + ' ' + this.translate('general.odd')
            };
        }

        if (data.length) {
            _.each(data, function (bet) {
                if (_.has(bet, 'outcomes')) {
                    bet.outcomes = _.orderBy(bet.outcomes, 'position');
                }
            });
        }

        this.setState({
            data           : data,
            maxOdds        : maxOdds,
            outcomeColumns : outcomeColumns,
            selectedRow    : 0,
            selectedOutcome: null,
            rcOutcome      : {},
            contextMenuDisplayed : false
        });
    },

    setHeaderInfo: function (data) {
        this.setState({headerInfo: data});
    },

    setColumns: function (columns, titles) {
        this.setState({
            columns: columns,
            titles : titles
        });
    },

    handleRowClick: function (index, evt) {
        this.setState({selectedRow: index});
    },

    showColumnSelector: function (e) {
        e.stopPropagation();
        this.setState({
            columnSelector: !this.state.columnSelector,
            contextMenuDisplayed: false
        });
    },
    handleOutcomeVisibilityChange : function (key, e) {
        e.stopPropagation();
        var outcomeColumns = this.state.outcomeColumns;
        outcomeColumns[key].visibility = !outcomeColumns[key].visibility;
        this.setState({
            outcomeColumns : outcomeColumns
        });
    },

    handleColumnVisibilityChange : function (column, e) {
        e.stopPropagation();
        var columns = this.state.columns;
        columns[column].visibility = !columns[column].visibility;
        this.setState({
            columns : columns
        });
    },

    selectOutcome: function(index, e) {
        this.setState({
            selectedOutcome: index
        });
    },

    handleContextMenu: function(outcome, rowIndex, outcomeIndex, e) {
        e.preventDefault();

        var offsetY = e.clientY + 260 - window.innerHeight;
        var offsetX = e.clientX + 150 - window.innerWidth;
        var top = e.clientY + 260 > window.innerHeight ? e.clientY - offsetY : e.clientY;
        var left = e.clientX + 150 > window.innerWidth ? e.clientX - offsetX : e.clientX;

        if(rowIndex !== this.state.selectedRow || this.state.selectedOutcome !== outcomeIndex){
            this.setState({
                columnSelector: false,
                rcOutcome: {},
                contextMenuDisplayed :false
            });
            return;
        }
        var rcSlectedOutcome = this.state.rcOutcome;
        var isSameOutcomeRc = rcSlectedOutcome.id_match_bet_outcome === outcome.id_match_bet_outcome;
        this.setState({
            columnSelector: false,
            rcOutcome: outcome,
            contextMenuDisplayed : isSameOutcomeRc ? !this.state.contextMenuDisplayed : true,
            contextMenuXAxis: left,
            contextMenuYAxis: top
        });
    },

    handleContextAction: function(action) {
        this.setState({
            contextMenuDisplayed: false
        });
        var rcOutcome = this.state.rcOutcome;

        if(rcOutcome.manual_resolve_allowed) {
            this.props.setOutcomeStatus(action, rcOutcome.id_match_bet_outcome);
        }
    },

    handleScroll: function() {
        if (!this.wrapperRef) return;
        if (window.scrollY > 100) {
            this.wrapperRef.style.top = window.scrollY - 100 + 'px';
        } else {
            this.wrapperRef.style.top = '0px';
        }
    },

    getStatusClass : function(status, allowed) {
        var classString = '';
        switch (status) {
            case this.statuses.open:
                classString = allowed ? 'open n-i n-i-clock-round' : 'open n-i n-i-clock-round disabled-outcome'; break;
            case this.statuses.won:
                classString = allowed ? 'won n-i n-i-check-d' : 'won n-i n-i-check-d disabled-outcome'; break;
            case this.statuses.lost:
                classString = allowed ? 'lost n-i n-i-close-b' : 'lost n-i n-i-close-b disabled-outcome'; break;
            case this.statuses.cancelled:
                classString = allowed ? 'cancelled n-i n-i-whistle' : 'cancelled n-i n-i-whistle disabled-outcome'; break;
            case this.statuses.half_won:
                classString = allowed ? 'half_won n-i n-i-won-void' : 'half_won disabled-outcome'; break;
            case this.statuses.half_lost:
                classString = allowed ? 'half_lost n-i n-i-lost-void' : 'half_lost disabled-outcome'; break;
            default:
                break;
        }
        return classString;
    },

    render: function () {
        if (!this.state.data || !this.state.data.length) return null;

        var data = this.state.data,
            columns = this.state.columns,
            columnKeys = _.keys(columns),
            outcomeColumns = _.keys(this.state.outcomeColumns);
        var additionalColumns = [];
        var selectedRow = this.state.selectedRow;
        for (var i = 0; i < this.state.maxOdds; i++) {
            if (this.state.outcomeColumns['outcome-'+i].visibility) {
                additionalColumns.push(<th className="manual-resolve-outcomes-th">
                {data.length && data[selectedRow].outcomes.length-1 >= i ? data[selectedRow].outcomes[i].outcome : ''}
                </th>);
            }
        }

        var displayedColumns = [
            <div ref={this.setOutcomeSelectorRef}
                className="pull-right column-select">
            <p  className="ng-scope">{this.translate('general.show_hide_columns')}</p>
            <div className="filter-multi-value" >
                {columnKeys.map(function(column){
                    if(column === 'outcomes'){
                        return outcomeColumns.map(function(key){
                            return <div>
                            <input id={"column_vis_"+ key +"_icon"}
                                type="checkbox"
                                checked={this.state.outcomeColumns[key].visibility}/>
                            <label
                                onClick={this.handleOutcomeVisibilityChange.bind(this, key)}
                                for={"column_vis_"+ key +"_icon"}
                                className="checkbox">{this.state.outcomeColumns[key].title}</label>
                            </div>
                        }.bind(this))
                    } else {
                        return <div>
                        <input id={"column_vis_"+ column +"_icon"}
                            type="checkbox"
                            checked={this.state.columns[column].visibility}/>
                        <label
                            onClick={this.handleColumnVisibilityChange.bind(this, column)}
                            for={"column_vis_"+ column +"_icon"}
                            className="checkbox">{this.state.titles[column]}</label>
                        </div>
                    }

                }.bind(this))
                }
            </div>
        </div>];

        return <div ref={this.setWrapperRef}
            className="tabs-details-wrap">
            <div className="details-data-wrap details-data-wrap-info">
                <div>{this.state.headerInfo.event}</div>
                <div><strong>{this.state.headerInfo.tournament}</strong></div>
            </div>

            <div className="manual-resolve-details-content" >
                <table className="table" >
                    <thead>
                        <tr className="table-header">
                            {
                                columnKeys.map(function(column){
                                    if(this.state.columns[column].visibility) {
                                        return <th className={column === 'special_value' ? "manual-resolve-special-value-th" : "manual-resolve-"+column }>
                                            <span>{this.state.titles[column]}</span>
                                        </th>
                                    }
                                }.bind(this))
                            }
                            {additionalColumns}
                            <th className="th-column-select"
                                onClick={this.showColumnSelector.bind(this)}>
                                <div className="column-select-header-cell pull-right">
                                    <i className=" n-i n-i-columns clickable"></i>
                                    {this.state.columnSelector && displayedColumns}
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                    {
                        data.map(function(row, rowIndex){
                            return <tr
                                className={"manual-resolve-row " + (this.state.selectedRow === rowIndex ? 'selected' : '')}
                                onClick={this.handleRowClick.bind(this, rowIndex)}>
                                {
                                    columnKeys.map(function(column){
                                        if (column === 'outcomes') {
                                            return row.outcomes.map(function(o, index){
                                                if(this.state.outcomeColumns['outcome-'+index].visibility){
                                                    return <td className="manual-resolve-odd clickable">
                                                        <div className={[
                                                            this.getStatusClass(o.match_bet_outcome_status, o.manual_resolve_allowed),
                                                            this.state.selectedRow === rowIndex && this.state.selectedOutcome === index ? "focused" : ""
                                                            ].join(' ')}
                                                            onClick={this.selectOutcome.bind(this, index)}
                                                            onContextMenu={this.handleContextMenu.bind(this, o, rowIndex, index )}>
                                                            <span className="manual-resolve-odd-value">{o.odd}</span>
                                                        </div>
                                                    </td>
                                                }
                                            }.bind(this))
                                        }
                                        if(this.state.columns[column].visibility) {
                                            return <td className={"manual-resolve-td-" + column} title={row[column]}><span>{row[column]}</span></td>
                                        }
                                    }.bind(this))
                                }
                                <td colSpan={this.state.maxOdds - row.outcomes.length + 1}></td>
                            </tr>
                        }.bind(this))
                    }
                    </tbody>

                </table>
            </div>
            <ManualResolveContextMenu
                resolveContextMenuAction={this.handleContextAction}
                rcActions={this.contextMenuActions}
                displayed={this.state.contextMenuDisplayed}
                xAxis={this.state.contextMenuXAxis}
                yAxis={this.state.contextMenuYAxis}/>
        </div>
    }
});;
var SmCustomSelectionContentValueCheckbox = React.createClass({

    handleChange: function (value,e) {
        if(this.props.source === 'bets' && !value.activeInTemplate){
            e.preventDefault();
            return;
        }
        this.props.checkboxChange(!value.active, this.props.rowKey, value.id);
    },

    handleSelectChange: function (e) {
        this.props.changeTournamentsSelectValue(e.target.value, this.props.value.id);
    },

    handleStatisticsSelect: function (tournamentId, e) {
        this.props.changeTournamentsStatisticsValue(tournamentId, e.target.checked);
    },

    handleInputChange: function (e) {
        this.props.changeMatchesPageNumber(e.target.value, this.props.value.id);
    },

    toggleStatisticsPopup: function (tournament, e) {
        this.props.methods.toggleStatisticsPopup(tournament);
    },

    render: function () {
        var value = this.props.value,
            rowKey = this.props.rowKey,
            categoryFlag = null,
            outRightIcon = null,
            iso = typeof value.isoCode !== 'undefined' ? (value.isoCode.length ? value.isoCode : 'INT') : '',
            tournamentStatistics;

        if (iso) {
            categoryFlag = (<i className={'sm-selection-flag flag flag-' + iso}>{}</i>);
        }
        if(value.providerId) {
            if(value.providerId.charAt(0) === "O"){
                outRightIcon = <i className={'category-info-outright-icon outright-icon n-i n-i-podium'}>{}</i>
            }
        }
        if(this.props.source === 'tournaments' && this.props.typeId != 3){
            tournamentStatistics = this.props.value.statistics ? this.props.value.statistics : {active:false, config:[]};
        }
        let disableClass = this.props.source === 'bets' && !value.activeInTemplate ? 'label-disabled' : '',
            selectListClass = this.props.typeId == 1 ? 'sm-cst-select sm-time-list-select-list' : 'sm-cst-select';
        return (
            <label title={value.categoryName ? value.categoryName : ''}
                   htmlFor={"cst-row-" + value.id + rowKey}
                   className={'cst-checkbox-wrap clickable ' + disableClass}>
                <input type="checkbox" id={"cst-row-" + value.id + rowKey} checked={value.active} className="cst-checkbox checkbox-stop"/>
                <i onClick={this.handleChange.bind(null, value)}>{}</i>
                <span>{categoryFlag}</span>
                <span>{value.name}</span>
                {this.props.source === 'bets' && value.competitors && value.competitors.length > 0 &&
                value.competitors.map(function (competitor) {
                    return(
                        <span>{' - ' + competitor.playerName + ' (' + competitor.teamName + ')'}</span>)
                })
                }
                {this.props.source === 'tournaments' && this.props.typeId != 3 &&
                <span className="sm-cst-tournament-config">
                    {(this.props.typeId == 2 || this.props.typeId == 4 || this.props.typeId == 10) &&
                    <span>
                        <div className="cst-sel-header-icons-checkbox">
                            <label htmlFor={"select-statistics-" + value.id}  className="cst-checkbox-wrap cst-select-all-checkbox">
                                <input type="checkbox" id={"select-statistics-" + value.id} checked={this.props.value.statisticsChecked} onChange={this.handleStatisticsSelect.bind(null,value.id)} className="cst-checkbox"/>
                                <i>{}</i>
                            </label>
                        </div>
                        <div className="cst-sel-header-icons clickable">
                            <i className={value.statistics && value.statistics.active ? 'n-i n-i-settings sm-selection-statistics-highlight' : 'n-i n-i-settings'} onClick={this.toggleStatisticsPopup.bind(this, {tournamentId: value.id, statistics: tournamentStatistics, tournament: value})}>{}</i>
                        </div>
                    </span>}
                    {(this.props.typeId == 1 || this.props.typeId == 2 || this.props.typeId == 5 || this.props.typeId == 6 || this.props.typeId == 10) &&
                    <div className={selectListClass}>
                        <label className="sm-custom-select">
                            <select value={this.props.value.offerTemplateId || ''} onChange={this.handleSelectChange}>
                                <option value=" ">{}</option>
                                {this.props.templates.map(function (template) {
                                    return(
                                        <option value={template.id}>{template.name}</option>)

                                })}
                            </select>
                        </label>
                    </div>}
                </span>
                }
                {this.props.source === 'matches' && this.props.typeId == 2 &&
                <span className="sm-cst-select-input">
                        <input value={this.props.value.pageNumber} onChange={this.handleInputChange}/>
                </span>
                }
                <span>{outRightIcon}</span>
            </label>
        );
    }
});


var SmCustomSelectionContentValues = React.createClass({

    handleSelect: function (value, key, e) {
        this.props.selectAction(value.id, key);
    },

    render: function () {
        var methods = this.props.methods,
            source = this.props.source;

        return (
            <div className="sm-cst-sel-content nano-content">

                {
                    this.props.data.map(function (value, key) {
                        let classHighlighted = this.props.selectedRow === key ? 'sm-selection-highlight ' : '';
                        if(source === 'bets'){
                            if(this.props.templates){
                                this.props.templates.forEach(function (template) {
                                    if(this.props.selectedTournament.offerTemplateId == template.id){
                                        if(template.content){
                                            template.content.forEach(function (item) {
                                                if(item.bets.find(function(bet) {
                                                        return  bet.checked && bet.id === value.marketId;
                                                    })){
                                                    value.activeInTemplate = true;
                                                }
                                            });
                                        }
                                    }
                                }.bind(this));
                            }
                        }
                        return(<div className={classHighlighted}>
                            <SmCustomSelectionContentValueCheckbox value={value} changeTournamentsSelectValue={this.props.changeTournamentsSelectValue} changeTournamentsStatisticsValue={this.props.changeTournamentsStatisticsValue} rowKey={key} source={source} templates={this.props.templates} methods={methods} typeId={this.props.typeId} checkboxChange={this.props.checkboxChange} changeMatchesPageNumber={this.props.changeMatchesPageNumber} />

                            {function () {
                                if (!this.props.selectAction) return '';
                                return (
                                    <div onClick={this.handleSelect.bind(this, value, key)} className="cst-checkbox-select">
                                        <i className="n-i n-i-arrow-right">{}</i>
                                    </div>
                                )
                            }.bind(this)()}

                        </div>);
                    }.bind(this))
                }
            </div>
        );
    }

});



var SmCustomSelectionContent = React.createClass({
    mixins: [CustomSelectionMixin],

    selectedSport                   : null,
    selectedTournament              : null,
    selectedMatch                   : null,

    selectedSportId                 : null,
    selectedTournamentId            : null,
    selectedMatchId                 : null,

    getInitialState: function () {
        var typeId = this.getStringFromUrl('typeId') ? parseInt(this.getStringFromUrl('typeId')) : this.props.typeId;
        return({
            typeId                  : typeId,
            sports                  : [],
            tournaments             : [],
            matches                 : [],
            bets                    : [],
            templates               : [],
            allSportsSelected       : false,
            allTournamentsSelected  : false,
            allMatchesSelected      : false,
            allBetsSelected         : false,
            allTournamentsStatisticsSelected : false
        });
    },

    componentDidUpdate: function () {
        $('.nano').nanoScroller();
    },

    // Setting list data
    setSportsData: function (data) {
        this.sports = data;
        this.setState({sports: data,
            tournaments: [],
            matches: [],
            bets: [],
            templates: [],
            allSportsSelected: this.checkAllSelected(data),
            allTournamentsSelected : false,
            allMatchesSelected     : false,
            allBetsSelected        : false,
            allTournamentsStatisticsSelected : false
        });
    },

    setTournamentsData: function (data) {
        this.tournaments = data;
        this.setState({tournaments: data, matches: [], bets: [], allTournamentsSelected: this.checkAllSelected(data), allTournamentsStatisticsSelected : false});
    },

    setMatchesData: function (data) {
        this.matches = data;
        this.setState({matches: data, bets: [], allMatchesSelected: this.checkAllSelected(data)});
    },

    setBetsData: function (data) {
        this.bets = data;
        this.setState({bets: data, allBetsSelected: this.checkAllSelected(data)});
    },

    setTemplatesData: function (data) {
        this.templates = data;
        this.setState({templates: data});
    },

    checkAllSelected: function (data) {
        if(!data.length) return;
        var allSelected = true;
        data.map(function (item) {
            if(!item.active) {
                allSelected = false;
            }
        });

        return allSelected;
    },

    updateTournaments: function () {
        this.props.methods.loadTournaments(this.selectedSportId);
    },

    // Row select methods
    sportSelectClick: function (id, key) {
        // set current active sport row
        this.selectedSport = key;
        this.selectedSportId = id;
        // reset tournament and bet active row
        this.selectedTournament = null;
        this.selectedMatch = null;
        this.selectedTournamentId = null;
        this.selectedMatchId = null;
        // get tournaments from db
        this.props.methods.loadTournaments(id);
        this.props.methods.loadTemplates(id);
    },

    tournamentSelectClick: function (id, key) {
        // set current active tournament row
        this.selectedTournament = key;
        this.selectedTournamentId = id;
        // remove bet active row
        this.selectedMatch = null;
        this.selectedMatchId = null;
        // get tournaments from db
        this.props.methods.loadMatches(id);
    },

    matchSelectClick: function (id, key) {
        // set current active bet row
        this.selectedMatch = key;
        this.selectedMatchId = id;
        // load bets from db
        this.props.methods.loadBets(id);
    },

    // change checkbox value for child state by provided key
    changeChildValues: function (key, value) {
        for (var i = 0; this.state[key] && i < this.state[key].length; i++) {
            this.state[key][i].active = value;
        }
    },

    // get list of all child state keys
    getChildKeys: function (key) {
        var allKeys = [];
        for (var i = 0; this.state[key] && i < this.state[key].length; i++) {
            allKeys.push(this.state[key][i].id);
        }
        return allKeys;
    },


    // Checkbox change methods
    sportCheckboxChange: function (value, key, id) {
        this.props.methods.sportCheckboxChange(value, [id]);

        if (this.selectedSport === key) {
            this.selectAllTournaments(this, value);
            this.selectAllBets(this, value);
            this.selectAllMatches(this, value);
        }


        this.state.sports[key].active = value;
        this.setState({sports: this.state.sports, tournaments: this.state.tournaments, allSportsSelected: this.checkAllSelected(this.state.sports)});
    },

    tournamentCheckboxChange: function (value, key, id) {
        this.props.methods.tournamentCheckboxChange(value, [id], this.state.tournaments[key].offerTemplateId);
        if (value) {
            this.state.sports[this.selectedSport].active = value;
        }

        if (this.selectedTournament === key) {
            this.selectAllBets(this, value);
            this.selectAllMatches(this, value);
        }

        this.state.tournaments[key].active = value;
        this.setState({sports: this.state.sports, tournaments: this.state.tournaments, matches: this.state.matches, allTournamentsSelected: this.checkAllSelected(this.state.tournaments)});
    },

    matchCheckboxChange: function (value, key, id) {
        this.props.methods.matchCheckboxChange(value, [id]);

        if (value) {
            this.state.sports[this.selectedSport].active = value;
            this.state.tournaments[this.selectedTournament].active = value;
        }

        if (this.selectedMatch === key) {
            this.selectAllBets(this, value);
        }

        this.state.matches[key].active = value;
        this.setState({sports: this.state.sports, tournaments: this.state.tournaments, matches: this.state.matches, bets: this.state.bets, allMatchesSelected: this.checkAllSelected(this.state.matches)})
    },

    betCheckboxChange: function (value, key, id) {

        this.props.methods.betCheckboxChange(value, [id]);
        this.state.bets[key].active = value;
        if (value) {
            this.state.sports[this.selectedSport].active = value;
            this.state.tournaments[this.selectedTournament].active = value;
            this.state.matches[this.selectedMatch].active = value;
        }
        this.setState({matches: this.state.matches, bets: this.state.bets, tournaments: this.state.tournaments, sports: this.state.sports, allBetsSelected: this.checkAllSelected(this.state.bets)});
    },


    //Select all actions
    selectAllSports: function (evt, value) {
        value = typeof value !== 'undefined'  ? value : evt.target.checked;
        var sports = this.state.sports;

        var allKeys = [];
        for (var i = 0; sports && i < sports.length; i++) {
            allKeys.push(sports[i].id);
            sports[i].active = value;
        }

        this.props.methods.sportCheckboxChange(value, allKeys);

        if (this.selectedSport !== null) {
            this.selectAllTournaments(this, value);
            this.selectAllBets(this, value);
            this.selectAllMatches(this, value);
        }

        this.setState({sports: sports, allSportsSelected: value});
    },

    selectAllTournaments: function (evt, value) {
        if (this.selectedSport === null) return;

        value = typeof value !== 'undefined'  ? value : evt.target.checked;
        var tournaments = this.state.tournaments;

        var allKeys = [];
        for (var i = 0; tournaments && i < tournaments.length; i++) {
            allKeys.push(tournaments[i].id);
            tournaments[i].active = value;
        }

        if (value) {
            this.state.sports[this.selectedSport].active = value;
        }

        this.props.methods.tournamentCheckboxChange(value, allKeys);
        this.setState({tournaments: tournaments, sports: this.state.sports, allTournamentsSelected: value});
    },

    selectAllTournamentStatistics: function (e) {
        var tournaments = this.state.tournaments;

        for (var i = 0; tournaments && i < tournaments.length; i++) {
            tournaments[i].statisticsChecked = e.target.checked;
        }

        this.setState({tournaments: tournaments, sports: this.state.sports, allTournamentsStatisticsSelected: e.target.checked});
    },

    selectAllMatches: function (evt, value) {
        if (this.selectedTournament === null) return;

        value = typeof value !== 'undefined'  ? value : evt.target.checked;
        var matches = this.state.matches;

        var allKeys = [];
        for (var i = 0; matches && i < matches.length; i++) {
            allKeys.push(matches[i].id);
            matches[i].active = value;
        }

        if (value && this.state.sports[this.selectedSport]) {
            this.state.sports[this.selectedSport].active = value;
        }

        if (value && this.state.tournaments[this.selectedTournament]) {
            this.state.tournaments[this.selectedTournament].active = value;
        }

        this.props.methods.matchCheckboxChange(value, allKeys);
        this.setState({
            matches: matches,
            tournaments: this.state.tournaments,
            sports: this.state.sports,
            allMatchesSelected: value
        });
    },

    selectAllBets: function (evt, value) {
        if (this.selectedMatch === null) return;

        value = typeof value !== 'undefined'  ? value : evt.target.checked;
        var bets = this.state.bets;

        var allKeys = [];
        for (var i = 0; bets && i < bets.length; i++) {
            allKeys.push(bets[i].id);
            bets[i].active = value;
        }

        if (value) {
            this.state.sports[this.selectedSport].active = value;
            this.state.tournaments[this.selectedTournament].active = value;
            this.state.matches[this.selectedMatch].active = value;
        }

        this.props.methods.betCheckboxChange(value, allKeys);
        this.setState({
            bets: bets,
            matches: this.state.matches,
            tournaments: this.state.tournaments,
            sports: this.state.sports,
            allBetsSelected: value
        });
    },

    changeTournamentsSelectValue: function (value, tournamentId) {

        var tournaments = this.state.tournaments;
        tournaments.forEach(function (tournament) {
            if(tournamentId === tournament.id){
                tournament.offerTemplateId = value;
                this.props.methods.tournamentSelectChange(value, [tournamentId], tournament.active);
            }
        }.bind(this));
        this.setState({tournaments: tournaments, sports: this.state.sports});
    },

    changeTournamentsStatisticsValue: function (tournamentId, value) {

        var tournaments = this.state.tournaments;
        tournaments.forEach(function (tournament) {
            if(tournamentId === tournament.id){
                tournament.statisticsChecked = value;
            }
        }.bind(this));
        this.setState({tournaments: tournaments, sports: this.state.sports});
    },

    changeMatchesPageNumber: function (value, matchId) {

        var matches = this.state.matches;
            matches.forEach(function (match) {
                if(matchId === match.id){
                    match.pageNumber = value;
                    this.props.methods.changeMatchesPageNumber(value, matchId, match.active);
                }
            }.bind(this));
        this.setState({matches: matches, tournaments: this.state.tournaments, sports: this.state.sports});
    },

    getStringFromUrl: function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    },

    applyGlobalStatistics: function () {
        var tournaments = this.state.tournaments,
            statisticsCheckedArray = [];

        tournaments.forEach(function (tournament) {
            if(tournament.statisticsChecked){
                statisticsCheckedArray.push(tournament);
            }
        }.bind(this));
        this.props.methods.applyTournamentStatistics(statisticsCheckedArray);
    },

    render: function ()  {

        var methods = this.props.methods,
            selectedSport = this.getRowIndexById(this.state.sports, this.selectedSportId),
            selectedTournament = this.getRowIndexById(this.state.tournaments, this.selectedTournamentId),
            selectedMatch = this.getRowIndexById(this.state.matches, this.selectedMatchId),
            selectedTournamentForBets = this.state.tournaments[selectedTournament];

        return(<div className="cst-sel-container sm-cst-sel clearfix">
            { /*Sports*/ }
            <div className="cst-sel-sports-wrap nano">
                <div className="sm-cst-sel-header cst-sports-header">
                    <div className="cst-sel-header-checkbox">
                        <label htmlFor="sport-select-all" className="cst-checkbox-wrap cst-select-all-checkbox">
                            <input type="checkbox" id="sport-select-all" checked={this.state.allSportsSelected} onChange={this.selectAllSports} className="cst-checkbox"/>
                            <i>{}</i>
                        </label>
                    </div>
                    <div className="cst-sel-header-div">Sports</div>
                </div>
                <SmCustomSelectionContentValues
                    data={this.state.sports}
                    source="sports"
                    methods={methods}
                    typeId={this.state.typeId}
                    selectedRow={selectedSport}
                    selectAction={this.sportSelectClick}
                    checkboxChange={this.sportCheckboxChange} />
            </div>

            { /*Tournaments*/ }
            <div className="cst-sel-tournaments-wrap nano">
                <div className="sm-cst-sel-header cst-tournaments-header">
                    <div className="cst-sel-header-checkbox">
                        <label htmlFor="tournament-select-all" className="cst-checkbox-wrap cst-select-all-checkbox">
                            <input type="checkbox" id="tournament-select-all" checked={this.state.allTournamentsSelected} onChange={this.selectAllTournaments} className="cst-checkbox"/>
                            <i>{}</i>
                        </label>
                    </div>
                    <div className="cst-sel-header-div">Tournaments</div>
                    {(this.state.typeId == 2 || this.props.typeId == 4 || this.props.typeId == 10) &&
                    <span>
                        <div className="cst-sel-header-icons cst-sel-header-apply clickable" onClick={this.applyGlobalStatistics}>
                            <i className="n-i n-i-refresh-a">{}</i>
                        </div>
                        <div className="cst-sel-header-icons-checkbox">
                            <label htmlFor="select-all-statistics" className="cst-checkbox-wrap cst-select-all-checkbox">
                                <input type="checkbox" id="select-all-statistics" checked={this.state.allTournamentsStatisticsSelected} onChange={this.selectAllTournamentStatistics} className="cst-checkbox"/>
                                <i>{}</i>
                            </label>
                        </div>
                        <div className="cst-sel-header-icons clickable" onClick={this.props.methods.toggleStatisticsPopup}>
                            <i className="n-i n-i-settings">{}</i>
                        </div>
                    </span>}
                </div>
                <SmCustomSelectionContentValues
                    data={this.state.tournaments}
                    templates={this.state.templates}
                    source="tournaments"
                    methods={methods}
                    typeId={this.state.typeId}
                    selectedRow={selectedTournament}
                    selectAction={this.tournamentSelectClick}
                    checkboxChange={this.tournamentCheckboxChange}
                    changeTournamentsSelectValue={this.changeTournamentsSelectValue}
                    changeTournamentsStatisticsValue={this.changeTournamentsStatisticsValue}/>
            </div>

            { /*Matches*/ }
            <div className="cst-sel-matches-wrap nano">
                <div className="sm-cst-sel-header cst-bets-header">
                    <div className="cst-sel-header-checkbox">
                        <label htmlFor="match-select-all" className="cst-checkbox-wrap cst-select-all-checkbox">
                            <input type="checkbox" id="match-select-all" checked={this.state.allMatchesSelected} onChange={this.selectAllMatches} className="cst-checkbox"/>
                            <i>{}</i>
                        </label>
                    </div>
                    <div className="cst-sel-header-div">Matches</div>
                </div>
                <SmCustomSelectionContentValues
                    data={this.state.matches}
                    source="matches"
                    methods={methods}
                    templates={this.state.templates}
                    typeId={this.state.typeId}
                    selectedRow={selectedMatch}
                    selectAction={this.matchSelectClick}
                    checkboxChange={this.matchCheckboxChange}
                    changeMatchesPageNumber={this.changeMatchesPageNumber}
                />
            </div>

            { /*Bets*/ }
            <div className="cst-sel-bets-wrap nano">
                <div className="sm-cst-sel-header cst-matches-header">
                    <div className="cst-sel-header-checkbox">
                        <label htmlFor="bet-select-all" className="cst-checkbox-wrap cst-select-all-checkbox">
                            <input type="checkbox" id="bet-select-all" checked={this.state.allBetsSelected} onChange={this.selectAllBets} className="cst-checkbox"/>
                            <i>{}</i>
                        </label>
                    </div>
                    <div className="cst-sel-header-div">Bets</div>
                </div>
                <SmCustomSelectionContentValues
                    data={this.state.bets}
                    source="bets"
                    methods={methods}
                    templates={this.state.templates}
                    typeId={this.state.typeId}
                    selectedTournament={selectedTournamentForBets}
                    checkboxChange={this.betCheckboxChange} />
            </div>

        </div>);
    }
});
;
var SmMarketsOddsSchemaList = React.createClass({
    getInitialState: function () {
        return { selected: 0 }
    },

    componentWillReceiveProps: function () {
        this.setState({ selected: 0 });
        if (this.oddsSchemeRef) {
            this.oddsSchemeRef.focus();
        }
    },

    handleKeyDown: function (e) {
        e.preventDefault();
        e.stopPropagation();

        var keyCode = e.keyCode,
            selected = this.state.selected,
            data = this.props.data.multipleRows.rows,
            dataLength = data.length;

        switch (keyCode) {
            case 38: selected = (selected === 0) ? dataLength - 1 : selected - 1; break;
            case 40: selected = (selected === (dataLength - 1)) ? 0 : selected + 1; break;
            case 13: this.props.handleClickAction(data[selected], this.props.index, this.props.marketIndex, this.props.outcomeIndex); break;
        }

        this.setState({ selected: selected });
    },

    handleClick: function (data, index, evt) {
        evt.stopPropagation();
        this.setState({selected: index});
        this.props.handleClickAction(data, this.props.index, this.props.marketIndex);
    },

    render: function () {
        if (!this.props.data) return null;

        var outcomes = this.props.data.multipleRows
            ? _.sortBy(this.props.data.multipleRows.outcomes, 'id')
            : [];
        var rows = this.props.data.multipleRows
            ? this.props.data.multipleRows.rows
            : [];

        return (<div className="sm-odds-scheme-list">
            <table ref={this.props.tableRef}
                tabIndex="1"
                className="table sm-odds-scheme-table"
                id="sm-odds-scheme-table"
                onKeyDown={this.handleKeyDown}>
                <thead>
                    <tr>
                        {
                            outcomes.map(function (outcome) {
                                return <th className="sm-os-l-th">{outcome.name}</th>
                            })
                        }
                    </tr>
                </thead>

                <tbody>
                    {
                        rows.map(function (row, index) {
                            return (<tr onClick={this.handleClick.bind(this, row, index)} className={this.state.selected === index && 'selected'}>
                                {
                                    row.outcomes.sort(function (a, b) {
                                        return a.id - b.id;
                                    }).map(function (outcome) {
                                        return <td className="sm-os-l-td">{outcome.odd}</td>
                                    })
                                }
                            </tr>)
                        }.bind(this))
                    }
                </tbody>
            </table>
        </div>)
    }
});

var SmEventsDetailsInputCell = React.createClass({
    getInitialState: function () {
        return { value: this.props.value || '' }
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({ value: newProps.value || '' });
    },

    getKeys: function () {
        return {
            market: Number(this.props.marketIndex),
            eventMarket: Number(this.props.eventMarketIndex),
            outcome: Number(this.props.outcomeId),
            outcomeIndex: Number(this.props.outcomeIndex)
        };
    },

    handleChange: function (e) {
        // Update value in model
        this.props.updateInputValue(e.target.value.replace(/,/g, '.'), this.getKeys());
        this.setState({ value: e.target.value.replace(/,/g, '.') });
    },

    handleKeyUp: function (e) {
        this.props.handleKeyUp(e.keyCode);
    },

    handleKeyDown: function (e) {
        // + is pressed, submit value
        if (e.keyCode === 107 || e.keyCode === 187) {
            e.preventDefault();
            var oldValue = this.state.oldValue;
            var newValue = parseFloat(this.state.value).toFixed(2);
            var validNewOdd = newValue && newValue >= 1;
            if(!validNewOdd) return;

            var validOddChange = this.props.checkPercentageRule(oldValue, newValue);

            if(!validOddChange && oldValue) {
              this.setState({oddValidationPopupOpen: true})
              this.props.showOddValidationPopup(this.getKeys(), oldValue, newValue);
              return;
            }
            this.props.handleKeyDown(e.keyCode);
            this.props.submitInputValue(this.getKeys());
            this.setState({submitedInputValue: true})
            return;
        }
        // Enter is pressed
        if (e.keyCode === 13) {
            this.props.handleEnterKey(this.getKeys());
        }

        if ([37, 38, 39, 40, 9, 16].indexOf(e.keyCode) > -1) {
            e.preventDefault();
            this.props.handleKeyDown(e.keyCode);
        }
    },

    handleClick: function (e) {
        e.stopPropagation();
        this.props.handleDetailsClick();
        this.props.handleInputClick(this.props.marketIndex, this.props.eventMarketIndex, this.props.outcomeIndex);
    },

    handleFocus: function(e) {
        var oldValue = this.state.oldValue ? this.state.oldValue : this.state.value;
        this.setState({ oldValue });
        e.target.select();
    },

    handleBlur: function(e) {
        var oldValue = this.state.oldValue;
        if(this.state.oddValidationPopupOpen) {
          this.setState({ oddValidationPopupOpen: false, oldValue: ''})
        }
        if(this.state.submitedInputValue) {
          this.setState({ submitedInputValue: false, oldValue: ''})
          return;
        }
        this.setState({ value: oldValue })
    },

    render: function () {
        var manualChange = '',
            status = '';
        if (this.props.data && this.props.data.manualOddChange) {
            if (this.props.data.manualOddChange.active)
                manualChange = this.props.data.manualOddChange.oddChangeDirection < 0 ? 'manual-down-change' : 'manual-up-change';
            else {
                manualChange = 'manual-change';
            }
        }
        if (this.props.data && !this.props.data.status) {
            status = 'inactive-value'
        }
        return <input
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            onKeyUp={this.handleKeyUp}
            onClick={this.handleClick}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            value={this.state.value}
            disabled={this.props.disabled}
            className={'sm-event-details-input ' + manualChange + ' ' + status}
            type="text" />
    }
});

var SmEventsDateInputCell = React.createClass({
    getInitialState: function () {
        return { value: this.props.value || '' }
    },

    componentWillReceiveProps: function (props) {
        this.setState({ value: props.value || '' })
    },

    handleClick: function (evt) {
        evt.stopPropagation();
    },

    handleChange: function (evt) {
        this.setState({ value: evt.target.value })
    },

    handleKeyDown: function (evt) {
        if (evt.keyCode === 13) {
            this.props.submitValue(evt.target.value, this.props.rowKey, this.props.cellKey)
        }
    },

    render: function () {
        return <div>
            <input
                type='text'
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                onClick={this.handleClick}
                value={this.state.value}
            />
        </div>
    }
});

var SmEventsDetailsSpecialInputCell = React.createClass({
    getInitialState: function () {
        return { value: this.props.value || '' }
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({ value: newProps.value || '' })
    },

    getKeys: function () {
        return {
            market: this.props.marketIndex,
            special: this.props.specialValueIndex,
            eventMarket: this.props.eventMarketIndex
        }
    },

    handleChange: function (e) {
        this.setState({ value: e.target.value.replace(/,/g, '.') });
        this.props.changeAction(e.target.value.replace(/,/g, '.'), this.getKeys());
    },

    handleKeyDown: function (e) {
        if (e.keyCode === 13) {
            this.props.submitAction(this.getKeys());
        }
    },

    handleClick: function (e) {
        e.stopPropagation();
        this.props.handleDetailsClick();
    },

    render: function () {
        return <input
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            onClick={this.handleClick}
            value={this.state.value}
            title={this.props.title}
            disabled={this.props.disabled}
            className="sm-event-details-input sm-event-special-input"
            type="text" />
    }
});

var SmEventsDetailsCheckbox = React.createClass({
    getInitialState: function () {
        return {
            checked: !!this.props.value
        }
    },
    componentWillReceiveProps: function (newProps) {
        this.setState({
            checked: !!newProps.value
        })
    },
    handleClick: function (evt) {
        evt.stopPropagation();
        this.setState({
            checked: !this.state.checked
        });
        this.props.handleActiveChange(this.props.index);
    },
    render: function () {
        return <div>
            <input
                id={this.props.id}
                type="checkbox"
                checked={this.state.checked}
                className='styled-checkbox'/>
            <label htmlFor={this.props.id} onClick={this.handleClick}> </label>
        </div>
    }
});

var SmEventsDetailsCompetitorPlayerSelect = React.createClass({
    getInitialState: function () {
        return { value: this.props.value || '' }
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({ value: newProps.value || '' })
    },

    getKeys: function () {
        return {
            market: this.props.marketIndex,
            eventMarket: this.props.eventMarketIndex,
            competitor: this.props.competitorId
        }
    },

    handleChange: function(event) {
        this.setState({value: event.target.value});
        this.props.competitorSelectChange(event.target.value, this.getKeys(), 'player');
    },

    handleClick: function(event) {
        event.stopPropagation();
        this.props.handleDetailsClick();
    },

    render: function () {
        return <select value={this.state.value} onClick={this.handleClick}
                    onChange={this.handleChange}
                    disabled={[15,16].indexOf(this.props.marketDistributionStatus)>-1}>
            <option value={null}>Select value</option>
            {this.props.competitors.map(function (team) {
                {return team.players.map(function (player) {
                    return <option value={player.id + '-' + team.teamId}>{player.displayName + ' (' + team.name + ')'}</option>
                })}
            })}
        </select>
    }
});

var SmEventsDetailsCompetitorTeamSelect = React.createClass({
    getInitialState: function () {
        return { value: this.props.value || '' }
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({ value: newProps.value || '' })
    },

    getKeys: function () {
        return {
            market: this.props.marketIndex,
            eventMarket: this.props.eventMarketIndex,
            competitor: this.props.competitorId
        }
    },

    handleChange: function (event) {
        this.setState({value: event.target.value});
        this.props.competitorSelectChange(event.target.value, this.getKeys(), 'team');
    },

    handleClick: function(event) {
        event.stopPropagation();
        this.props.handleDetailsClick();
    },

    render: function () {
        return <select value={this.state.value} onClick={this.handleClick} onChange={this.handleChange}>
            <option value={null}>Select value</option>
            {this.props.competitors.map(function (team) {
                return <option value={team.teamId}>{team.name}</option>
            })}
        </select>
    }
});

var SmEventsReferentInputCell = React.createClass({
    getInitialState: function () {
        return { value: this.props.value || '' }
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({ value: newProps.value || '' })
    },

    handleInputClick: function (e) {
        this.props.handleInputClick(this.props.eventIndex, this.props.marketIndex, this.props.outcomeIndex)
    },

    handleChange: function (e) {
        this.props.inputChangeAction(e.target.value.replace(/,/g, '.'), this.props.eventIndex, this.props.marketIndex, this.props.outcomeIndex);
        this.setState({ value: e.target.value.replace(/,/g, '.') });
    },

    handleFocus: function (e) {
        e.stopPropagation();
        e.currentTarget.select();
    },

    handleInputKeyPress: function (e) {
        if (e.keyCode === 107) {
            e.preventDefault();
            this.props.submitAction(e.target.value, this.props.eventIndex, this.props.marketIndex, this.props.outcomeIndex);
            return;
        }

        if (e.keyCode === 13) {
            this.props.oddsSchemaChangeAction(e.target.value, this.props.eventIndex, this.props.marketIndex, this.props.outcomeIndex);
        }
        this.props.handleInputKeyPress(e.keyCode, this.props.eventIndex, this.props.marketIndex, this.props.outcomeIndex);

    },

    handleSelectChange: function (e) {
        this.props.riskFactorUpdate(e.target.value, this.props.eventIndex, this.props.marketIndex, this.props.outcomeIndex);
    },

    render: function () {
        return <input
            onChange={this.handleChange}
            onKeyUp={this.handleInputKeyPress}
            onClick={this.handleInputClick}
            onFocus={this.handleFocus}
            value={this.state.value || ''}
            className="sm-event-details-input"
            type="text" />
    }
});

var SmEventsContextMenu = React.createClass({

    getInitialState: function () {
        return { config: {} };
    },

    handleClick: function (id, evt) {
        this.props.resolveContextMenuAction(id);
    },

    setConfig: function (config) {
        this.setState({
            config: config
        });
    },

    getConfig: function () {
        return this.state.config;
    },

    render: function () {
        if (!this.props.rcActions) return null;
        var className = 'react-context-menu context-menu dropdown-menu';
        className += this.props.fixedPosition ? ' fixed' : '';
        className += this.props.wide ? ' wideMenu' : '';

            return (
                <ul className={className} id={this.props.idValue ? this.props.idValue : 'react-context-menu'}>
                {function () {
                    return this.props.rcActions.map(function (action) {
                        if (['deactivate', 'activate', 'activateAll', 'deactivateAll', 'divider', 'enable', 'disable', 'enableAll', 'disableAll', 'addNewMarket', 'copy'].indexOf(action.id) === -1) {
                            return <li onClick={this.handleClick.bind(this, action.id)} className={'event-markets-dist'}>
                                <i className={action.class}>{}</i>
                                {action.displayName}
                            </li>
                        }
                        if ( action.id === 'activateAll') {
                            return [<li className={'divider-li'}><hr/></li>,
                                <li onClick={this.handleClick.bind(this, action.id)} className={'event-markets-status'}>
                                    <i className={action.class}>{}</i>
                                    {action.displayName}
                                </li>]
                        }

                        if ( action.id === 'deactivateAll'){
                            return <li onClick={this.handleClick.bind(this, action.id)} className={'event-markets-status'}>
                                <i className={action.class}>{}</i>
                                {action.displayName}
                            </li>
                        }
                        if ( action.id === 'addNewMarket' ){
                            return this.state.config.enabledAddNew && [<li className={'divider-li'}><hr/></li>,
                            <li onClick={this.handleClick.bind(this, action.id)} className={'event-markets-status'}>
                                <i className={action.class}>{}</i>
                                {action.displayName}
                            </li>]
                        }

                        if ( action.id === 'copy' ){
                            return this.state.config.enabledCopy && [
                                !this.state.config.enabledAddNew && <li className={'divider-li'}><hr/></li>,
                            <li onClick={this.handleClick.bind(this, action.id)} className={'event-markets-status'}>
                                <i className={action.class}>{}</i>
                                {action.displayName}
                            </li>]
                        }

                        if ( action.id === 'activate') {
                            return [<li className={'divider-li'}><hr/></li>,
                                <li onClick={this.handleClick.bind(this, action.id)} className={'event-markets-status'}>
                                    <i className={action.class}>{}</i>
                                    {action.displayName}
                                </li>]
                        }
                        if ( action.id === 'deactivate'){
                            return <li onClick={this.handleClick.bind(this, action.id)} className={'event-markets-status'}>
                                <i className={action.class}>{}</i>
                                {action.displayName}
                            </li>
                        }
                        if ( action.id === 'divider') {
                            return <li className={'divider-li'}><hr/></li>
                        }
                        if ( ['enable', 'disable', 'enableAll', 'disableAll'].indexOf(action.id) > -1){
                            return <li onClick={this.handleClick.bind(this, action.id)} className={'event-outcome-status'}>
                                <i className={action.class}>{}</i>
                                {action.displayName}
                            </li>
                        }
                    }.bind(this));
                }.bind(this)()}
            </ul>
            )
    }
});

var SmEventsDetailsRiskFactorSelect = React.createClass({
    getInitialState: function () {
        return { value: this.props.value || '' }
    },

    componentWillReceiveProps: function (newProps) {
        this.setState({ value: newProps.value || '' })
    },

    getKeys: function () {
        return {
            riskFactorList: this.props.riskFactorList,
        }
    },

    handleChange: function (event) {
        event.stopPropagation();

        this.setState({
            id: this.props.id,
            value: event.target.value
        });

        this.props.handleChange(this.props.id, event.target.value);
    },

    handleClick: function(event)  {
        event.stopPropagation();
    },

    render: function () {

        return <select className="sm-event-riskfactor-select" value={this.state.value} onClick={this.handleClick} onChange={this.handleChange}>
            {this.props.riskFactors.map(function (risk) {
                if (risk.value) {
                    return <option value={risk.id}>{risk.name} ({risk.value})</option>
                } else {
                    return <option value={risk.id} disabled="true"></option>
                }
            })}
        </select>
    }
});
;
var SmEventsDetailsSingleRow = React.createClass({
    getInitialState: function () {
        return {
            displayContextMenu: false
        }
    },

    handleActiveChange: function (eventMarketIndex) {
        this.props.checkboxChange(this.props.rowIndex, eventMarketIndex);
    },


    handleRowClick: function (eventMarketId, eventMarketIndex, event) {
        this.props.handleRowClick(this.props.rowIndex, eventMarketId, eventMarketIndex, event);
    },

    componentDidUpdate: function () {
        if (this.props.oddsScheme && this.props.oddsScheme.multipleRows && this.oddsSchemeTableElement) {
            this.oddsSchemeTableElement.focus();
        }
    },

    handleOutcomeContextMenu: function (marketOutcome, evt) {
        if(!!marketOutcome){
            var row = this.props.row,
                eventMarkets = row.eventMarkets,
                data = row.eventMarkets.length ? row.eventMarkets[0] : {},
                outcomeId = marketOutcome.id;
            if(data.hasOwnProperty('eventMarketOutcomes') &&  data.eventMarketOutcomes.hasOwnProperty(outcomeId)) {
                this.props.outcomesContextMenu(evt, data.eventMarketOutcomes[outcomeId].id, data.eventMarketOutcomes[outcomeId].marketOutcomeId );

            }
        }
    },

    handleContextMenu: function(eventMarketId, e) {
        e.preventDefault();
        var selected = {
            index: this.props.rowIndex,
            eventMarketId: eventMarketId,
            marketId: this.props.row.id
        };
        var config = {
            enabledAddNew: this.props.row.isSpecial,
            enabledCopy: this.props.row.isSpecial
        };
        this.props.setContextMenuConfig(config);
        this.props.handleRowContextMenu(e.clientX, e.clientY, selected, config);
    },

    setRef: function(evtIndex, outcomeIndex, input) {
        this.refs['evt-market-outcome-' + this.props.rowIndex + '-' + evtIndex + '-' + outcomeIndex] = input
    },

    render: function () {
        var row = this.props.row,
            rowIndex = this.props.rowIndex,
            eventMarkets = row.eventMarkets,
            data = row.eventMarkets.length ? row.eventMarkets[0] : {},
            outcomeList = this.props.outcomeList,
            oddsScheme = this.props.oddsScheme,
            oddsSchemeSelected = this.props.oddsSchemeSelected,
            columns = this.props.columns,
            eventId = this.props.eventId;

        return <div>
            <div
                onClick={this.handleRowClick.bind(this, data.id, 0)}
                onContextMenu={this.handleContextMenu.bind(this, data.id)}
                className={'grid-body-row ' + (this.props.marketsForDistribution.indexOf(data.id) > -1 ? 'distributionSelected': '')}>

                <div className='grid-cell grid-cell-active'>
                    <SmEventsDetailsCheckbox
                        id={'sm-e-o-c-' + row.id + '-' + 0}
                        key={'sm-e-o-c-' + eventId + '-' + row.id + '-' + 0}
                        value={!!(data.active)}
                        index={0}
                        handleActiveChange={this.handleActiveChange}
                    />
                </div>
                {columns.id.visibility && <div className='grid-cell grid-cell-id'>{row.id}</div>}
                {columns.displayId.visibility && <td className='grid-cell grid-cell-displayId'>{data.eventMarketDisplayId || ''}</td>}
                {columns.name.visibility && <div title={row.name} className='grid-cell grid-cell-name'>
                    { ((row.marketDistributionTemplateActive) &&
                        <i className='n-i n-i-table template-active-indicator'>{}</i>) || <i></i>
                    }
                    <div className="event-details-distribution-wrap">
                        <i className={'distribution-status distribution-status-' + (data.distributionStatus || '')}>{}</i>
                    </div>
                    <span className={'sm-e-m-name' + (row.marketDistributionTemplateActive ? '' : ' name-add-padding')}>{row.name}</span>
                </div>}
                {columns.status.visibility && <div className='grid-cell grid-cell-status event-markets-status'>
                    <i className={'n-i event-markets-status-' + data.status}> </i>
                </div>}
                {columns.eventMarketId.visibility && <div className='grid-cell grid-cell-event-market-id'>{data.id || ''}</div>}

                {columns.description.visibility && <div title={row.marketDescription}
                    className='grid-cell grid-cell-description'>
                    <span>{row.marketDescription}</span>
                </div>}

                {columns.specialValues.visibility
                    && <div className={'grid-cell grid-cell-sv ' + (row.marketSpecialValues.length ? 'multiple-specials' : '')}>{
                    row.marketSpecialValues.map(function (sv) {
                        return <SmEventsDetailsSpecialInputCell
                            key={'market-special-value-' + this.props.rowIndex + '-' + sv.id}
                            submitAction={this.props.submitMarketSpecialValue}
                            changeAction={this.props.changeMarketSpecialValue}
                            handleDetailsClick={this.props.handleDetailsClick}
                            marketIndex={this.props.rowIndex}
                            eventMarketIndex={0}
                            specialValueIndex={sv.id}
                            value={data.eventMarketSpecials && data.eventMarketSpecials[sv.id] && data.eventMarketSpecials[sv.id].specialValue}
                        />
                    }.bind(this))
                }</div>}
                {
                    columns.marketOutcomes.visibility && row.marketOutcomes && row.marketOutcomes.length && row.marketOutcomes.map(function (marketOutcome, outcomeIndex) {
                        return <div className='grid-cell grid-cell-outcome'
                                    onContextMenu={this.handleOutcomeContextMenu.bind(event,row.marketOutcomes[outcomeIndex])} >
                            { row.marketOutcomes[outcomeIndex] && <SmEventsDetailsInputCell
                                ref={this.setRef.bind(this, 0, outcomeIndex)}
                                eventMarketIndex={0}
                                marketIndex={this.props.rowIndex}
                                outcomeId={row.marketOutcomes[outcomeIndex].id}
                                outcomeIndex={outcomeIndex}
                                handleDetailsClick={this.props.handleDetailsClick}
                                key={'evt-input-key' + this.props.rowIndex + '-' + 0 + outcomeIndex}
                                handleKeyDown={this.props.handleKeyDown}
                                handleKeyUp={this.props.handleKeyUp}
                                handleInputClick={this.props.handleInputClick}
                                updateInputValue={this.props.updateInputValue}
                                submitInputValue={this.props.submitInputValue}
                                showOddValidationPopup={this.props.showOddValidationPopup}
                                checkPercentageRule={this.props.checkPercentageRule}
                                handleEnterKey={this.props.handleEnterKey}
                                data={data.eventMarketOutcomes && data.eventMarketOutcomes[row.marketOutcomes[outcomeIndex].id]}
                                manualChange={data.eventMarketOutcomes && data.eventMarketOutcomes[row.marketOutcomes[outcomeIndex].id] && data.eventMarketOutcomes[row.marketOutcomes[outcomeIndex].id].manualOddChange}
                                value={data.eventMarketOutcomes && data.eventMarketOutcomes[row.marketOutcomes[outcomeIndex].id] && data.eventMarketOutcomes[row.marketOutcomes[outcomeIndex].id].odd}
                            />
                            }
                            {oddsSchemeSelected.market === this.props.rowIndex && oddsSchemeSelected.outcome === outcomeIndex &&
                            <SmMarketsOddsSchemaList
                                handleClickAction={this.props.handleOddsSchemeListClick}
                                tableRef={function (el) {this.oddsSchemeTableElement = el}.bind(this)}
                                index={oddsSchemeSelected.market}
                                marketIndex={0}
                                data={oddsScheme}
                            />
                            }
                        </div>
                    }.bind(this))
                }
                {
                    columns.marketOutcomes.visibility && row.marketOutcomes && row.marketOutcomes.length && row.marketOutcomes.length < outcomeList.length &&
                    <div className='grid-cell-empty'> </div>
                }
            </div>
            {
                eventMarkets.map(function (eventMarket, eventMarketIndex) {
                    if (eventMarketIndex === 0) return null;
                    return <div
                        onClick={this.handleRowClick.bind(this, eventMarket.id, eventMarketIndex)}
                        onContextMenu={this.handleContextMenu.bind(this, eventMarket.id)}
                        className={'grid-body-row ' + (this.props.marketsForDistribution.indexOf(eventMarket.id) > -1 ? 'distributionSelected': '')}>
                        <div className='grid-cell grid-cell-active'>
                            <SmEventsDetailsCheckbox
                                id={'sm-e-o-c-' + row.id + '-' + eventMarketIndex}
                                key={'sm-e-o-c-' + eventId + '-' + row.id + '-' + eventMarketIndex}
                                value={!!(eventMarket.active)}
                                index={eventMarketIndex}
                                handleActiveChange={this.handleActiveChange}
                            />
                        </div>

                        {columns.id.visibility && <div className='grid-cell grid-cell-id'>{row.id}</div>}
                        {columns.displayId.visibility && <td className='grid-cell grid-cell-displayId'>{eventMarket.eventMarketDisplayId || ''}</td>}
                        {columns.name.visibility && <div title={row.name} className='grid-cell grid-cell-name'>
                            { ((row.marketDistributionTemplateActive) &&
                                <i className='n-i n-i-table template-active-indicator'>{}</i>) || <i></i>
                            }
                            <div className="event-details-distribution-wrap">
                                <i className={'distribution-status distribution-status-' + (eventMarket.distributionStatus || '')}>{}</i>
                            </div>
                            <span className={'sm-e-m-name' + (row.marketDistributionTemplateActive ? '' : ' name-add-padding')}>{row.name}</span>
                        </div>}
                        {columns.status.visibility && <div className='grid-cell grid-cell-status event-markets-status'>
                            <i className={'n-i event-markets-status-' + eventMarket.status}> </i>
                        </div>}
                        {columns.eventMarketId.visibility && <div className='grid-cell grid-cell-event-market-id'>{eventMarket.id || ''}</div>}
                        {columns.description.visibility && <div className='grid-cell grid-cell-description'>{row.marketDescription}</div>}
                        {columns.specialValues.visibility && <div className='grid-cell grid-cell-sv'>{
                            row.marketSpecialValues.map(function (sv, svIndex) {
                                return <SmEventsDetailsSpecialInputCell
                                    key={'market-special-value-' + this.props.rowIndex + '-' + eventMarketIndex + '-' + svIndex}
                                    submitAction={this.props.submitMarketSpecialValue}
                                    changeAction={this.props.changeMarketSpecialValue}
                                    handleDetailsClick={this.props.handleDetailsClick}
                                    marketIndex={this.props.rowIndex}
                                    eventMarketIndex={eventMarketIndex}
                                    specialValueIndex={sv.id}
                                    value={eventMarket.eventMarketSpecials && eventMarket.eventMarketSpecials[sv.id] && eventMarket.eventMarketSpecials[sv.id].specialValue}
                                />
                            }.bind(this))
                        }</div>}
                        {columns.marketOutcomes.visibility && row.marketOutcomes && row.marketOutcomes.length && row.marketOutcomes.map(function (outcome, outcomeIndex) {
                            return <div className='grid-cell grid-cell-outcome'
                                        onContextMenu={this.handleOutcomeContextMenu.bind(event,row.marketOutcomes[outcomeIndex])}>
                                <SmEventsDetailsInputCell
                                    ref={this.setRef.bind(this, eventMarketIndex, outcomeIndex)}
                                    eventMarketIndex={eventMarketIndex}
                                    handleDetailsClick={this.props.handleDetailsClick}
                                    handleKeyDown={this.props.handleKeyDown}
                                    handleKeyUp={this.props.handleKeyUp}
                                    handleInputClick={this.props.handleInputClick}
                                    marketIndex={this.props.rowIndex}
                                    outcomeId={outcome.id}
                                    outcomeIndex={outcomeIndex}
                                    showOddValidationPopup={this.props.showOddValidationPopup}
                                    checkPercentageRule={this.props.checkPercentageRule}
                                    updateInputValue={this.props.updateInputValue}
                                    submitInputValue={this.props.submitInputValue}
                                    key={' ' + this.props.rowIndex + eventMarketIndex + outcomeIndex}
                                    handleEnterKey={this.props.handleEnterKey}
                                    data={eventMarket.eventMarketOutcomes && eventMarket.eventMarketOutcomes[outcome.id]}
                                    value={eventMarket.eventMarketOutcomes && eventMarket.eventMarketOutcomes[outcome.id] && eventMarket.eventMarketOutcomes[outcome.id].odd}
                                />

                                {oddsSchemeSelected.market === rowIndex && oddsSchemeSelected.eventMarket === eventMarketIndex && oddsSchemeSelected.outcome === outcomeIndex &&
                                <SmMarketsOddsSchemaList
                                    handleClickAction={this.props.handleOddsSchemeListClick}
                                    index={oddsSchemeSelected.market}
                                    marketIndex={eventMarketIndex}
                                    data={oddsScheme}
                                />
                                }
                            </div>
                        }.bind(this))}

                        {
                            columns.marketOutcomes.visibility && row.marketOutcomes && row.marketOutcomes.length && row.marketOutcomes.length < outcomeList.length &&
                            <div className='grid-cell-empty'> </div>
                        }

                    </div>
                }.bind(this))
            }
        </div>;
    }
});
;
var SmEventsDetailsExpandableRow = React.createClass({
    componentDidUpdate: function () {
        if (!this.props.row.isCollapsed) return;

        $('#sm-events-details-expanded-table' + this.props.rowIndex).width(document.getElementById('sm-event-details-wrap').offsetWidth - 20);
    },

    handleExpandClick: function (event) {
        event.stopPropagation();
        this.props.handleExpandClick(this.props.rowIndex);
    },

    handleActiveChange: function (eventMarketIndex) {
        this.props.checkboxChange(this.props.rowIndex, eventMarketIndex);
    },

    handleButtonClick: function () {
        this.props.handleAddButtonClick(this.props.rowIndex);
        this.props.selectRowOnly(this.props.rowIndex);
    },

    handleRowClick: function (eventMarketId, eventMarketIndex, event) {
        this.props.handleRowClick(this.props.rowIndex, eventMarketId, eventMarketIndex, event);
    },

    handleOutcomeContextMenu: function (marketOutcome, eventMarketIndex, evt) {
        if(!!marketOutcome){
            var row = this.props.row,
                eventMarkets = row.eventMarkets,
                data = row.eventMarkets.length ? row.eventMarkets[eventMarketIndex] : {},
                outcomeId = marketOutcome.id;
            if(data.hasOwnProperty('eventMarketOutcomes') &&  data.eventMarketOutcomes.hasOwnProperty(outcomeId)) {
                this.props.outcomesContextMenu(evt, data.eventMarketOutcomes[outcomeId].id, data.eventMarketOutcomes[outcomeId].marketOutcomeId );
            }
        }
    },

    handleContextMenu: function (eventMarketId, e) {
        e.preventDefault();
        var selected = {
            index: this.props.rowIndex,
            eventMarketId: eventMarketId,
            marketId: this.props.row.id
        };
        var config = {
            enabledCopy: this.props.row.isSpecial
        };
        this.props.setContextMenuConfig(config);
        this.props.handleRowContextMenu(e.clientX, e.clientY, selected, config);
    },

    setRef: function(evtIndex, outcomeIndex, input) {
        this.refs['evt-market-outcome-' + this.props.rowIndex + '-' + evtIndex + '-' + outcomeIndex] = input
    },

    render: function () {
        var row = this.props.row,
            rowIndex = this.props.rowIndex,
            columns = this.props.columns,
            oddsScheme = this.props.oddsScheme,
            oddsSchemeSelected = this.props.oddsSchemeSelected;

        return <div id={'sm-events-details-expanded-table' + this.props.rowIndex}
                    className={row.isCollapsed ? 'expanded-table' : ''}>
            <div className='expanded-table-header grid-body-row'>
                <div className='grid-cell grid-cell-active'
                    onClick={this.handleExpandClick}>
                    <i className={'sm-events-details-expand-icon n-i n-i-' + (row.isCollapsed ? 'arrow-up-a' : 'arrow-down-a')}> </i>
                </div>
                {columns.id.visibility && <div className='grid-cell grid-cell-id'>{row.id}</div>}
                {columns.displayId.visibility && <div className='grid-cell grid-cell-displayId'> </div>}
                {columns.name.visibility && <div title={row.name} className='grid-cell grid-cell-name'>
                    { (row.marketDistributionTemplateActive) &&
                        <i className='n-i n-i-table template-active-indicator'>{}</i>
                    }
                    <span className={'sm-e-m-name' + (row.marketDistributionTemplateActive ? '' : ' name-add-padding')}>{row.name}</span>
                </div>}
                {columns.status.visibility && <div className='grid-cell grid-cell-status'></div>}
                {columns.eventMarketId.visibility && <div className='grid-cell grid-cell-event-market-id'></div>}
                {columns.description.visibility && <div className='grid-cell grid-cell-description'>{row.marketDescription}</div>}
                {columns.specialValues.visibility && <div className='grid-cell grid-cell-sv'></div>}
                {
                    columns.specialValues.visibility && row.isCollapsed && row.marketOutcomes.map(function (outcome) {
                        return <div className='grid-cell grid-cell-outcome'>{outcome.name}</div>
                    })
                }
            </div>
            {
                row.isCollapsed && <div>
                    <div>
                        {
                            row.eventMarkets.map(function (market, eventMarketIndex) {
                                return <div className={'grid-body-row expanded-table-row ' + (this.props.marketsForDistribution.indexOf(market.id) > -1 ? 'distributionSelected': '') + (' grid-size-' + row.marketSpecialValues.length)}
                                            onContextMenu={this.handleContextMenu.bind(this, market.id)}
                                            onClick={this.handleRowClick.bind(this, market.id, eventMarketIndex)}>
                                    <div className='grid-cell grid-cell-active'>
                                        <SmEventsDetailsCheckbox
                                            id={'sm-e-o-c-' + market.id + '-' + eventMarketIndex}
                                            key={'sm-e-o-c-' + market.id + '-' + eventMarketIndex}
                                            value={!!(market.active)}
                                            index={eventMarketIndex}
                                            handleActiveChange={this.handleActiveChange.bind(this, eventMarketIndex)}
                                        />
                                    </div>
                                    {columns.id.visibility && <div className='grid-cell grid-cell-id'> </div>}
                                    {columns.displayId.visibility && <div className='grid-cell grid-cell-displayId'>{market.eventMarketDisplayId}</div>}
                                    {columns.name.visibility && <div className='grid-cell grid-cell-name empty-name'>
                                        {row.marketCompetitors.length > 0 && <div className='grid-cell grid-cell-player'>
                                            {row.marketCompetitors.map(function (competitor) {
                                                if (competitor.competitorType === 1) {
                                                    return <div>
                                                        <SmEventsDetailsCompetitorTeamSelect
                                                            marketIndex={this.props.rowIndex}
                                                            eventMarketIndex={eventMarketIndex}
                                                            handleDetailsClick={this.props.handleDetailsClick}
                                                            competitorId={competitor.id}
                                                            competitors={this.props.competitors}
                                                            competitorSelectChange={this.props.competitorSelectChange}
                                                            value={market.eventMarketCompetitors[competitor.id] && market.eventMarketCompetitors[competitor.id].teamId}
                                                        />
                                                    </div>
                                                } else {
                                                    return <div>
                                                        <SmEventsDetailsCompetitorPlayerSelect
                                                            marketDistributionStatus={market.distributionStatus}
                                                            marketIndex={this.props.rowIndex}
                                                            eventMarketIndex={eventMarketIndex}
                                                            handleDetailsClick={this.props.handleDetailsClick}
                                                            competitorId={competitor.id}
                                                            competitors={this.props.competitors}
                                                            competitorSelectChange={this.props.competitorSelectChange}
                                                            value={market.eventMarketCompetitors[competitor.id] && (market.eventMarketCompetitors[competitor.id].playerId + '-' + market.eventMarketCompetitors[competitor.id].teamId)}
                                                        />
                                                    </div>
                                                }
                                            }.bind(this))}
                                        </div>}
                                        <div className="event-details-distribution-wrap">
                                            <i className={'distribution-status distribution-status-' + (market.distributionStatus || '')}>{}</i>
                                        </div>
                                    </div>}
                                    {columns.status.visibility && <div className='grid-cell grid-cell-status event-markets-status'>
                                        <i className={'n-i event-markets-status-' + (market.status)}> </i>
                                    </div>}
                                    {columns.description.visibility && <div className='grid-cell grid-cell-description'> </div>}

                                    <div className='grid-cell grid-cell-sv'>
                                        {row.marketSpecialValues.map(function (sv) {
                                            return  <SmEventsDetailsSpecialInputCell
                                                        submitAction={this.props.submitMarketSpecialValue}
                                                        changeAction={this.props.changeMarketSpecialValue}
                                                        handleDetailsClick={this.props.handleDetailsClick}
                                                        marketIndex={this.props.rowIndex}
                                                        eventMarketIndex={eventMarketIndex}
                                                        specialValueIndex={sv.id}
                                                        value={market.eventMarketSpecials && market.eventMarketSpecials[sv.id] && market.eventMarketSpecials[sv.id].specialValue}
                                                    />;
                                        }.bind(this))}
                                    </div>
                                    {row.marketOutcomes.map(function (outcome, outcomeIndex) {
                                        return <div className='grid-cell grid-cell-outcome'
                                                onContextMenu = {this.handleOutcomeContextMenu.bind(event,row.marketOutcomes[outcomeIndex], eventMarketIndex)}>
                                            <SmEventsDetailsInputCell
                                                ref={this.setRef.bind(this, eventMarketIndex, outcomeIndex)}
                                                eventMarketIndex={eventMarketIndex}
                                                marketIndex={this.props.rowIndex}
                                                key={' ' + this.props.rowIndex + eventMarketIndex + outcomeIndex}
                                                handleKeyDown = {this.props.handleKeyDown}
                                                handleKeyUp={this.props.handleKeyUp}
                                                handleDetailsClick={this.props.handleDetailsClick}
                                                handleInputClick = {this.props.handleInputClick}
                                                outcomeId={outcome.id}
                                                outcomeIndex={outcomeIndex}
                                                updateInputValue={this.props.updateInputValue}
                                                submitInputValue={this.props.submitInputValue}
                                                showOddValidationPopup={this.props.showOddValidationPopup}
                                                checkPercentageRule={this.props.checkPercentageRule}
                                                handleEnterKey={this.props.handleEnterKey}
                                                data={market.eventMarketOutcomes && market.eventMarketOutcomes[row.marketOutcomes[outcomeIndex].id]}
                                                value={market.eventMarketOutcomes[outcome.id] && market.eventMarketOutcomes[outcome.id].odd}
                                            />
                                            {oddsSchemeSelected.market === rowIndex && oddsSchemeSelected.eventMarket === eventMarketIndex && oddsSchemeSelected.outcome === outcomeIndex &&
                                                <SmMarketsOddsSchemaList
                                                    handleClickAction={this.props.handleOddsSchemeListClick}
                                                    index={oddsSchemeSelected.market}
                                                    marketIndex={eventMarketIndex}
                                                    data={oddsScheme}
                                                />
                                            }
                                        </div>
                                    }.bind(this))}
                                </div>
                            }.bind(this))
                        }
                        <div className='sm-event-details-add' onClick={this.handleButtonClick}>Add</div>
                    </div>
                </div>
            }
        </div>
    }
});
;
var SmEventsDetailsCombinedRow = React.createClass({
    componentDidUpdate: function () {
        if (!this.props.row.isCollapsed) return;

        $('#sm-events-details-combined-table' + this.props.rowIndex).width(document.getElementById('sm-event-details-wrap').offsetWidth - 20);
    },

    handleExpandClick: function (event) {
        event.stopPropagation();
        this.props.handleExpandClick(this.props.rowIndex);
    },

    handleActiveChange: function (event) {
        event.stopPropagation();
        this.props.checkboxChange(this.props.rowIndex, 'mode-1');
    },

    handleButtonClick: function () {
        this.props.handleAddButtonClick(this.props.rowIndex);
    },

    handleContextMenu: function (eventMarketId, e) {
        e.preventDefault();
        var selected = {
          index: this.props.rowIndex,
          eventMarketId: eventMarketId,
          marketId: this.props.row.id
        };
        this.props.handleRowContextMenu(e.clientX, e.clientY, selected);
    },

    handleOutcomeContextMenu: function (marketOutcome, evt) {
        if(!!marketOutcome){
            var row = this.props.row,
                eventMarkets = row.eventMarkets,
                data = row.eventMarkets.length ? row.eventMarkets[0] : {},
                outcomeId = marketOutcome.id;
            if(data.hasOwnProperty('eventMarketOutcomes') &&  data.eventMarketOutcomes.hasOwnProperty(outcomeId)) {
                this.props.outcomesContextMenu(evt, data.eventMarketOutcomes[outcomeId].id, data.eventMarketOutcomes[outcomeId].marketOutcomeId );
            }
        }
    },

    handleRowClick: function (eventMarketId, eventMarketIndex, event) {
        this.props.handleRowClick(this.props.rowIndex, eventMarketId, eventMarketIndex, event);
    },

    handleContextMenu: function (eventMarketId, e) {
        e.preventDefault();
        var selected = {
          index: this.props.rowIndex,
          eventMarketId: eventMarketId,
          marketId: this.props.row.id
        };
        this.props.handleRowContextMenu(e.clientX, e.clientY, selected);
    },

    setRef: function(evtIndex, outcomeIndex, input) {
        this.refs['evt-market-outcome-' + this.props.rowIndex + '-' + evtIndex + '-' + outcomeIndex] = input
    },

    render: function () {
        var row = this.props.row,
            outcomeList = this.props.outcomeList,
            columns = this.props.columns;

        return <div>
            <div className={'grid-body-row ' + (row.isCollapsed && 'expanded')}>
                <div className='grid-cell grid-cell-active'
                    onClick={this.handleExpandClick}>
                    <i className={'sm-events-details-expand-icon n-i n-i-' + (row.isCollapsed ? 'arrow-up-a' : 'arrow-down-a')}> </i>
                </div>
                {columns.id.visibility && <div className='grid-cell grid-cell-id'>{row.id}</div>}
                {columns.displayId.visibility && <td className='grid-cell grid-cell-displayId'></td>}
                {columns.name.visibility && <div title={row.name} className='grid-cell grid-cell-name'>
                    { (row.marketDistributionTemplateActive) &&
                        <i className='n-i n-i-table template-active-indicator'>{}</i>
                    }
                    <span className={'sm-e-m-name' + (row.marketDistributionTemplateActive ? '' : ' name-add-padding')}>{row.name}</span>
                </div>}
                {columns.status.visibility && <div className='grid-cell grid-cell-status'></div>}
                {columns.eventMarketId.visibility && <div className='grid-cell grid-cell-event-market-id'></div>}
                {columns.description.visibility && <div className='grid-cell grid-cell-description'>{row.marketDescription}</div>}
                {columns.specialValues.visibility && <div className='grid-cell grid-cell-sv'></div>}
            </div>
            {
                row.isCollapsed && <div id={'sm-events-details-expanded-table' + this.props.rowIndex}>
                    <div className='grid-body-row header-row'>
                        <div className='grid-cell grid-cell-status'></div>
                        <div className='grid-cell grid-cell-status'></div>
                        <div className='grid-cell grid-cell-displayId'>Display id</div>
                        <div className='grid-cell grid-cell-status'>Status</div>
                        {row.marketCompetitors.length > 0 && <div className='grid-cell grid-cell-player'>Player</div>}
                        <div className='grid-cell grid-cell-sv'>SV</div>
                        <div className='grid-cell-empty'> </div>
                    </div>
                    <div>
                        {
                            row.eventMarkets.map(function (market, eventMarketIndex) {
                                return <div className={'grid-body-row expanded-table-row' + (this.props.marketsForDistribution.indexOf(market.id) > -1 ? 'distributionSelected': '')}
                                            onContextMenu={this.handleContextMenu.bind(this, market.id)}
                                            onClick={this.handleRowClick.bind(this, market.id, eventMarketIndex)}>
                                    <div className='grid-cell grid-cell-status'>
                                        <input
                                            id={'expandedCheckbox-' + market.id}
                                            type="checkbox"
                                            checked={!!(market.active)}
                                            onChange={this.handleActiveChange.bind(this, eventMarketIndex)}
                                            className='styled-checkbox'/>
                                        <label htmlFor={'expandedCheckbox-' + market.id}> </label></div>
                                    <div className='grid-cell grid-cell-status'>
                                        <i className={'distribution-status distribution-status-' + (market.distributionStatus || '')}>{}</i>
                                    </div>
                                    <div className='grid-cell grid-cell-displayId'>
                                        {market.eventMarketDisplayId}
                                    </div>
                                    <div className='grid-cell grid-cell-status'>
                                        <i className={'n-i event-markets-status-' + (market.status)}> </i>
                                    </div>
                                    {row.marketCompetitors.length > 0 && <div className='grid-cell grid-cell-player'>
                                        {row.marketCompetitors.map(function (competitor) {
                                            if (competitor.competitorType === 1) {
                                                return <div className='sm-event-details-competitor-select'>
                                                    <SmEventsDetailsCompetitorTeamSelect
                                                        marketIndex={this.props.rowIndex}
                                                        eventMarketIndex={eventMarketIndex}
                                                        handleDetailsClick={this.props.handleDetailsClick}
                                                        competitorId={competitor.id}
                                                        competitors={this.props.competitors}
                                                        competitorSelectChange={this.props.competitorSelectChange}
                                                        value={market.eventMarketCompetitors[competitor.id] && market.eventMarketCompetitors[competitor.id].teamId}
                                                    />
                                                </div>
                                            } else {
                                                return <div className='sm-event-details-competitor-select'>
                                                    <SmEventsDetailsCompetitorPlayerSelect
                                                        marketDistributionStatus={market.distributionStatus}
                                                        marketIndex={this.props.rowIndex}
                                                        eventMarketIndex={eventMarketIndex}
                                                        handleDetailsClick={this.props.handleDetailsClick}
                                                        competitorId={competitor.id}
                                                        competitors={this.props.competitors}
                                                        competitorSelectChange={this.props.competitorSelectChange}
                                                        value={market.eventMarketCompetitors[competitor.id] && (market.eventMarketCompetitors[competitor.id].playerId + '-' + market.eventMarketCompetitors[competitor.id].teamId)}
                                                    />
                                                </div>
                                            }
                                        }.bind(this))}
                                    </div>}
                                    <div className={'grid-cell grid-cell-sv ' + (row.marketSpecialValues.length ? 'multiple-specials' : '')}>
                                        {row.marketSpecialValues.map(function (sv) {
                                            return  <SmEventsDetailsSpecialInputCell
                                                        submitAction={this.props.submitMarketSpecialValue}
                                                        changeAction={this.props.changeMarketSpecialValue}
                                                        handleDetailsClick={this.props.handleDetailsClick}
                                                        marketIndex={this.props.rowIndex}
                                                        eventMarketIndex={eventMarketIndex}
                                                        specialValueIndex={sv.id}
                                                        value={market.eventMarketSpecials && market.eventMarketSpecials[sv.id] && market.eventMarketSpecials[sv.id].specialValue}                                                    />;
                                        }.bind(this))}
                                    </div>
                                    {row.marketOutcomes.map(function (outcome, outcomeIndex) {
                                        return <div className='grid-cell grid-cell-outcome'
                                                onContextMenu = {this.handleOutcomeContextMenu.bind(event,row.marketOutcomes[outcomeIndex], eventMarketIndex)}>
                                            <SmEventsDetailsInputCell
                                                ref={this.setRef.bind(this, eventMarketIndex, outcomeIndex)}
                                                eventMarketIndex={eventMarketIndex}
                                                marketIndex={this.props.rowIndex}
                                                key={' ' + this.props.rowIndex + eventMarketIndex + outcomeIndex}
                                                handleKeyDown = {this.props.handleKeyDown}
                                                handleDetailsClick={this.props.handleDetailsClick}
                                                handleInputClick = {this.props.handleInputClick}
                                                outcomeId={outcome.id}
                                                outcomeIndex={outcomeIndex}
                                                updateInputValue={this.props.updateInputValue}
                                                submitInputValue={this.props.submitInputValue}
                                                showOddValidationPopup={this.props.showOddValidationPopup}
                                                checkPercentageRule={this.props.checkPercentageRule}
                                                handleEnterKey={this.props.handleEnterKey}
                                                data={market.eventMarketOutcomes && market.eventMarketOutcomes[row.marketOutcomes[outcomeIndex].id]}
                                                value={market.eventMarketOutcomes[outcome.id] && market.eventMarketOutcomes[outcome.id].odd}
                                            />
                                        </div>
                                    }.bind(this))}
                                </div>
                            }.bind(this))
                        }
                        <div className='sm-event-details-add' onClick={this.handleButtonClick}>Add</div>
                    </div>
                </div>
            }
        </div>
    }
});
;
var SmEventsComponent = React.createClass({
    mixins: [LocationMixin],

    getInitialState: function () {
        return {
            tournamentsData: null,
            selectedRow: null,
            selectedTournament: null,
            expandedTournament: null,
            eventsVisible: false,
            selectedEvent: null,
            focusDetails: false,
            eventsData: null
        }
    },

    handleGlobalClick: function () {
        $('#sm-events-context-menu').css({display: 'none'});
        $('#sm-event-markets-context-menu').css({display: 'none'});
        $('#sm-event-outcomes-context-menu').css({display: 'none'});
    },

    componentWillMount: function () {
        window.addEventListener('click', this.handleGlobalClick);
    },

    componentWillUnmount: function () {
        window.removeEventListener('click', this.handleGlobalClick);
    },

    setData: function (data, focusDetails, ignoreFocus) {
        this.setState({
            tournamentsData: data,
            focusDetails: focusDetails,
            ignoreFocus: ignoreFocus
        });
    },

    focusDetails: function () {
        this.setState({focusDetails: true})
    },

    handleEventsClick: function () {
        this.setState({focusDetails: false});
        this.props.methods.handleEventsClick();
    },

    handleTournamentsArrowClick: function (data, rowKey) {
        var index = rowKey !== this.state.expandedTournament  ? rowKey : null;
        this.props.methods.handleTournamentsArrowClick(data, rowKey, this.state.expandedTournament);
        var indexAfterExpanded = this.state.expandedTournament && this.state.expandedTournament === rowKey ? rowKey - 1 : rowKey;
        this.setState({
            expandedTournament: index,
            eventsVisible: true,
            selectedRow: indexAfterExpanded
        });
    },

    handleTournamentsRowClick: function (data, rowKey) {
        var expandedRowIndex = this.state.expandedTournament === rowKey ? rowKey : null;
        this.setState({
            selectedTournament: rowKey,
            selectedRow: rowKey,
            eventsVisible: false,
            expandedTournament: expandedRowIndex
        });
        this.props.methods.handleTournamentsRowClick(data, rowKey);
    },

    handleEventsDateInputSubmit: function (data) {
        this.props.methods.updateEventStartDateValue(data);
    },

    handleEventsRowClick: function (data, rowKey) {
        this.setState({selectedEvent: rowKey, focusDetails: false});
        this.props.methods.handleEventsRowClick(data, rowKey);
    },

    handleFilterChange: function (filterKey, filterValue) {
        this.props.methods.updateFilters(filterKey, filterValue);
    },

    handleFilterChangeAll: function (filterKey, filterValue) {
        this.props.methods.updateAllFilters(filterKey, filterValue);
    },

    handleFilterClearAll: function (filterKey, filterValue) {
        this.props.methods.clearAllFilters(filterKey, filterValue);
    },

    handleEventEditClick: function(data, index){
        this.props.methods.handleEventEditClick(data, index);
    },

    handleEventsContextMenuChange: function (id, selectedIndexList) {
        this.props.methods.handleContextMenuClick(id, 'events', selectedIndexList);
    },

    handleEventsOddsSchemeListClick: function (data, index, marketIndex) {
        this.props.methods.handleEventsOddsSchemeListClick(data, index, marketIndex);
    },

    oddsSchemeChangeAction: function (value, marketIndex, outcomeIndex) {
        var selectedRow = this.state.eventsDetails.data[marketIndex];
        selectedRow.marketOutcomes[outcomeIndex].eventMarketOutcomeOdd = value;

        this.props.methods.handleEventMarketsOddSchemeChange(selectedRow, marketIndex, outcomeIndex);
    },

    eventReferentInputChange: function (value, eventIndex, marketIndex, outcomeIndex) {
        this.props.methods.handleEventReferentInputChange(value, eventIndex, marketIndex, outcomeIndex);
    },

    eventReferentInputSubmit: function () {},

    eventReferentOddsSchemeRequest: function (value, eventIndex, marketIndex, outcomeIndex) {
        this.props.methods.handleEventReferentOddsSchemeRequest(value, eventIndex, marketIndex, outcomeIndex);
    },

    setTournamentsColumnVisibility: function () {
        this.props.methods.updateColumnVisibility('tournaments');
    },

    setEventsColumnVisibility: function () {
        this.props.methods.updateColumnVisibility('events');
    },

    updateEventRiskFactor: function(eventId, riskFactorId) {
        this.props.methods.updateEventRiskFactor(eventId, riskFactorId);
    },

    render: function () {
        if (!this.state.tournamentsData) return null;
        var data = this.state.tournamentsData;

        return(
            <div className="sm-events-wrap col-12">
                <div>
                    <SmEventsTournamentsTable
                        data={data}
                        eventsData={this.state.eventsData}
                        selectedTournament={this.state.selectedTournament}
                        selectedRow={this.state.selectedRow}
                        expandedTournament={this.state.expandedTournament}
                        eventsVisible={this.state.eventsVisible}
                        ignoreFocus={this.state.ignoreFocus}
                        selectedFilters={this.props.selectedFilters}
                        contextMenuItems={this.props.contextMenuItems}
                        handleContextMenuChange={this.handleEventsContextMenuChange}
                        eventsDateInputSubmit={this.handleEventsDateInputSubmit}
                        setTournamentsColumnVisibility={this.setTournamentsColumnVisibility}
                        handleEventsClick={this.props.methods.handleEventsClick}
                        clearEventMarketOdds={this.props.methods.clearEventMarketOdds}
                        setEventsColumnVisibility={this.setEventsColumnVisibility}
                        handleTournamentsArrowClick={this.handleTournamentsArrowClick}
                        handleTournamentsRowClick={this.handleTournamentsRowClick}
                        handleEventsOddsSchemeListClick={this.handleEventsOddsSchemeListClick}
                        handleEventsRowClick={this.handleEventsRowClick}
                        eventReferentInputChange={this.eventReferentInputChange}
                        eventReferentInputSubmit={this.eventReferentInputSubmit}
                        eventReferentOddsSchemeRequest={this.eventReferentOddsSchemeRequest}
                        updateFilters={this.handleFilterChange}
                        handleEventEditClick={this.handleEventEditClick}
                        focusDetails={this.state.focusDetails}
                        specialsTab={this.props.specialsTab}
                        updateEventRiskFactor={this.updateEventRiskFactor}
                    />
                </div>
            </div>
        )
    }

});
;
var SmEventsDetails = React.createClass({
    mixins: [TranslateMixin, DistributionSelectionChecker],
    lastSelectedIndex: 0,
    lastEventMarketSelectedIndex: 0,
    focusedInput: null,
    inputRefs: {},
    shiftPressed: false,

    getInitialState: function () {
        return {
            data: null,
            selectedRowIndex: 0,
            marketsForDistribution: [],
            eventMarketsForDistribution: [],
            xIndex: 0,
            yIndex: 0,
            zIndex: 0,
            focusDetails: false,
            expandHeader: false,
            selectedMarketGroup: 'all',
            showMoreMarketGroups: false,
            ignoreFocus: false
        }

    },

    handleGlobalClick: function handleGlobalClick () {
        this.setState({
            showMoreMarketGroups: false
        });
    },

    componentDidMount: function () {
        window.addEventListener('scroll', this.handleScroll);
        this.setDetailsWidth();
    },

    componentDidUpdate: function () {
        if (this.state.focusDetails && !this.state.ignoreFocus) {
            this.focusInput();
        }
    },

    componentWillUnmount: function () {
        window.removeEventListener('scroll', this.handleScroll);
    },

    componentWillReceiveProps: function () {
        this.lastSelectedIndex = 0;
        this.setState({
            marketsForDistribution: [],
            eventMarketsForDistribution: []
        })
    },

    setGridBodyHeight: function () {
      var t = setTimeout(function() {
        clearTimeout(t)
        var gridHeaderHeight = $('.sm-event-markets-grid .grid-header').height();
        $('#sm-event-markets-grid').height(window.innerHeight  - (265 + gridHeaderHeight));
      }, 0)
    },

    setData: function (data, focusDetails, resetSelectedRows, ignoreFocus, resetMarketGroups) {
        var marketsForDistribution = resetSelectedRows ? [] : this.state.marketsForDistribution,
            eventMarketsForDistribution = resetSelectedRows ? [] : this.state.eventMarketsForDistribution;
        this.setState({
            data: data,
            marketsForDistribution: marketsForDistribution,
            eventMarketsForDistribution: eventMarketsForDistribution,
            focusDetails: focusDetails,
            ignoreFocus: ignoreFocus
        });

        if (resetMarketGroups) {
            this.setState({
               selectedMarketGroup: 'all'
            });
        }

        if (resetSelectedRows) {
            this.setState({
                selectedRowIndex: 0
            });
        }
        this.setGridBodyHeight();
    },

    handleExpandClick: function (index) {
        this.props.handleExpandClick(index);
        this.setState({
            xIndex: index,
            yIndex: 0,
            zIndex: 0
        });
    },

    handleRowClick: function (index, eventMarketId, eventMarketIndex, event) {
        this.props.handleDetailsClick();

        var shiftKey = event ? event.shiftKey : false,
            ctrlKey = event ? event.ctrlKey : false;

        var params = {
            selectedEventMarkets: this.state.eventMarketsForDistribution,
            selectedMarkets: this.state.marketsForDistribution,
            markets: this.state.data.data,
            index: index,
            shiftPressed: shiftKey,
            ctrlPressed: ctrlKey,
            marketId: this.state.data.data[index].id,
            eventMarketId: eventMarketId,
            eventMarketIndex: eventMarketIndex,
            lastSelectedIndex: this.lastSelectedIndex,
            lastEventMarketSelectedIndex: this.lastEventMarketSelectedIndex
        };

        var selectedDistributionList = this.deepCheckSelection(params);

        this.setState({
            eventMarketsForDistribution: selectedDistributionList.eventMarkets,
            marketsForDistribution: selectedDistributionList.markets,
            selectedRowIndex: index,
            focusDetails: true,
            xIndex: index,
            yIndex: eventMarketIndex,
            zIndex: 0
        });

        this.lastSelectedIndex = index;
        this.lastEventMarketSelectedIndex = eventMarketIndex;
        this.setGridBodyHeight();
    },

    selectRowOnly: function(index) {
        this.setState({
            xIndex: index,
            yIndex: 0,
            zIndex: 0
        });
    },

    submitInputValue: function (keys) {
        this.props.submitInputValue(keys);
    },

    focusEventMarket: function(yIndex) {
        this.setState({
            yIndex: yIndex,
        }, function(){
            this.focusInput()
        });
    },

    focusInput: function () {
        var x = this.state.xIndex,
            y = this.state.yIndex,
            z = this.state.zIndex;

        var marketRef = this.refs['market-' + x];
        var input = marketRef ? marketRef.refs['evt-market-outcome-' + x + '-' + y + '-' + z] : null;

        if (input) {
            ReactDOM.findDOMNode(input).focus();
        }
    },

    setDetailsWidth: function () {
        var wrap = $('#sm-event-details-wrap'),
            detailsWidth = document.getElementById('sm-event-details').offsetWidth - 20;
        wrap.width(detailsWidth);
        $('#grid-body-content').height(window.innerHeight  - 295);
    },

    handleScroll: function () {
        if (!this.table) {
            this.table = document.getElementById('sm-event-details-wrap');
        }

        if (!this.table) return;

        this.table.style.top = document.documentElement.scrollTop > 90 ? document.documentElement.scrollTop - 90 + 'px' : '0px';
    },

    handleSchemeChange: function (index, evt) {
        this.props.updateEventScheme(evt.target.value, index);
    },

    handleContextMenuChange: function (id) {
        this.props.handleContextMenuClick(id, 'markets', this.state.eventMarketsForDistribution, this.state.marketsForDistribution);
        $('#sm-event-markets-context-menu').css({display: 'none'});
        $('#sm-event-outcomes-context-menu').css({display: 'none'});
    },

    handleRowContextMenu: function (clientX, clientY, selectedRow, menuConfig) {
        if (!this.state.eventMarketsForDistribution.length) {
            if(!selectedRow.eventMarketId) return;
            this.setState({
                eventMarketsForDistribution: [selectedRow.eventMarketId || ''],
                marketsForDistribution: [selectedRow.marketId || ''],
                selectedRowIndex: selectedRow.index
            })
        }
        var menuHeight = 400;

        if (menuConfig) {
            // increase by size of menu item
            if (menuConfig.enabledAddNew) menuHeight+=34;
            if (menuConfig.enabledCopy) menuHeight+=34;
            // increase by size of additional divider
            if (menuConfig.enabledAddNew || menuConfig.enabledCopy) menuHeight+=40;
        }

        var offsetY = clientY + menuHeight - window.innerHeight;
        var offsetX = clientX + 290 - window.innerWidth;
        var top = clientY + menuHeight > window.innerHeight ? clientY - offsetY : clientY;
        var left = clientX + 290 > window.innerWidth ? clientX - offsetX : clientX;

        $('#sm-events-context-menu').css({display: 'none'});
        $('#sm-event-outcomes-context-menu').css({display: 'none'});
        $('#sm-event-markets-context-menu').css({display: 'block', left: left, top: top - document.body.scrollTop});
    },

    handleOutcomesContextMenu: function (evt, id, marketOutcomeId ) {
        var input = event.target;
        this.props.handleDetailsClick();
        evt.preventDefault();
        evt.stopPropagation();

        var offsetY = evt.clientY + 230 - window.innerHeight;
        var offsetX = evt.clientX + 300 - window.innerWidth;
        var top = evt.clientY + 230 > window.innerHeight ? evt.clientY - offsetY : evt.clientY;
        var left = evt.clientX + 300 > window.innerWidth ? evt.clientX - offsetX : evt.clientX;
        $('#sm-events-context-menu').css({display: 'none'});
        $('#sm-event-markets-context-menu').css({display: 'none'});
        $('#sm-event-outcomes-context-menu').css({display: 'block', left: left, top: top - document.body.scrollTop});
        this.setState({
            selectedEventMarketOutcomeId: id,
            selectedMarketOutcomeId: marketOutcomeId
        }, function(){
            input.focus();
        });

    },

    handleOutcomeContextMenuChange: function (id) {
        this.props.handleOutcomesContextMenuClick(id, this.state.selectedEventMarketOutcomeId, this.state.selectedMarketOutcomeId);
        $('#sm-event-markets-context-menu').css({display: 'none'});
        $('#sm-event-outcomes-context-menu').css({display: 'none'});
    },

    updateColumnVisibility: function () {
        this.props.updateColumnVisibility('eventMarkets');
    },

    setContextMenuConfig: function(config) {
        this.refs['market-marketsContextMenu'].setConfig(config);
    },

    createRef: function(index, input) {
        this.refs['market-' + index] = input;
    },

    findNextAvailableRow: function(x, y) {
        var data = this.state.data.data;

        if (data[x].eventMarkets[y + 1]) {
            return { x: x, y: y + 1, z: 0};
        }

        for (var i = x + 1; i < data.length; i++) {
            if (data[i].displayMode === 1 || (data[i].isCollapsed && data[i].eventMarkets[0])) {
                return { x: i, y: 0, z: 0};
            }
        }
        return { x: x, y: 0, z: 0};
    },

    findPreviousAvailableRow: function(x, y) {
        var data = this.state.data.data;

        if (data[x].eventMarkets[y - 1]) {
            return { x: x, y: y - 1, z: 0};
        }

        for (var i = x - 1; i >= 0; i--) {
            if (data[i].displayMode === 1 || (data[i].isCollapsed && data[i].eventMarkets[0])) {
                return { x: i, y: 0, z: 0};
            }
        }
        return { x: x - 1, y: 0, z: 0};
    },

    handleKeyUp: function(keyCode) {
        if (keyCode === 16) {
            this.shiftPressed = false;
        }
    },

    handleKeyDown: function(keyCode) {
        if (keyCode === 16)  {
            this.shiftPressed = true;
        }

        var x = this.state.xIndex,
            y = this.state.yIndex,
            z = this.state.zIndex,
            data = this.state.data.data;

        var setNextRow = function(resetZIndex) {
            var nextRow = this.findNextAvailableRow(x, y);
            x = nextRow.x;
            y = nextRow.y;
            if (resetZIndex) {
                z = 0;
            } else {
                z = data[x].marketOutcomes.length - 1 <= z ? data[x].marketOutcomes.length - 1 : z;
            }
        }.bind(this);

        var setPreviousRow = function(resetZIndex) {
            var previousRow = this.findPreviousAvailableRow(x, y);
            x = previousRow.x;
            y = previousRow.y;
            if (x < 0) {
                x = 0;
                return;
            }
            if (resetZIndex) {
                z = data[x].marketOutcomes.length - 1;
            } else {
                z = data[x].marketOutcomes.length - 1 <= z ? data[x].marketOutcomes.length - 1 : z;
            }
        }.bind(this);

        function handleRightArrowAction () {
            if (data[x].marketOutcomes.length - 1 === z) { // Last outcome in row
                setNextRow(true)
            } else {
                z++;
            }
        }

        function handleLeftArrowAction () {
            if (z === 0) {
                setPreviousRow(true);
            } else {
                z--;
            }
        }

        switch(keyCode) {
            case 107:  // + key
            case 187:  // + key
            case 39: { // Right arrow
                handleRightArrowAction();
            } break;
            case 40: { // Down arrow
                setNextRow();
            } break;
            case 38: { // Up arrow
                setPreviousRow();
            } break;
            case 37: { // Left arrow
                handleLeftArrowAction();
            } break;
            case 9: {
                console.log("shift pressed: ", this.shiftPressed);
                if (this.shiftPressed) {
                    handleLeftArrowAction();
                } else {
                    handleRightArrowAction();
                }
            } break;
            default: return null;
        }

        this.setState({
            xIndex: x,
            yIndex: y,
            zIndex: z
        });
    },

    handleInputClick: function (x, y, z) {
        this.setState({
            focusDetails: true,
            xIndex: x,
            yIndex: y,
            zIndex: z
        });
    },

    toggleHeaderInfo: function () {
        this.setState({
            expandHeader: !this.state.expandHeader
        })
    },

    filterByMarketGroup: function (id) {
        this.setState({
            xIndex: 0,
            yIndex: 0,
            zIndex: 0,
            selectedRowIndex: 0,
            selectedMarketGroup: id
        });
        this.props.filterMarketGroup(id);
    },

    generateMarketGroups: function (marketGroups) {
        if (!marketGroups || (marketGroups && !marketGroups.length)) return [];
        var content = [];
        for (var i = 0; i < 7; i++) {
            if (marketGroups[i]) {
                content.push(
                    <span className={'sm-e-market-group-item ' + (this.state.selectedMarketGroup === marketGroups[i].id ? 'active' : '')}
                          onClick={this.filterByMarketGroup.bind(this, marketGroups[i].id)}>{marketGroups[i].name}</span>
                )
            }
        }
        return content;
    },

    generateMoreMarketGroups: function (marketGroups) {
        if (!marketGroups || !marketGroups[7] || (marketGroups && !marketGroups.length)) return [];
        var content = [];
        for (var i = 7; i < marketGroups.length; i++) {
            content.push(
                <div className={'sm-e-market-group-more-item ' + (this.state.selectedMarketGroup === marketGroups[i].id ? 'active' : '')}
                     onClick={this.filterByMarketGroup.bind(this, marketGroups[i].id)}>{marketGroups[i].name}</div>
            )
        }
        return content;
    },

    toggleMoreGroups: function (evt) {
        evt.stopPropagation();
        this.setState({
            showMoreMarketGroups: !this.state.showMoreMarketGroups
        })
    },

    submitMarketSpecialValue: function (keys) {
        this.props.submitMarketSpecialValue(keys);
        this.setState({
            xIndex: keys.market,
            yIndex: keys.eventMarket,
            zIndex: 0
        });
    },

    render: function () {
        if (!this.state.data) return <div>
            <div id='sm-event-details-wrap' className='sm-e-t-details-header-wrap col-12'>
                <div> </div>
                <div>
                    <div></div>
                    <div>
                        <div id="grid-body-content"></div>
                    </div>
                </div>
            </div>
        </div>;

        var data = this.state.data,
            markets = data.data,
            titles = data.titles,
            meta = data.meta,
            competitors = data.competitors,
            outcomeList = data.maxOutcomesLength,
            columns = data.localColumns || data.columns,
            schemes = data.schemes,
            eventsSchemes = data.eventsSchemes,
            oddsScheme = data.oddsScheme,
            oddsSchemeSelected = data.oddsSchemeSelected,
            selectedIndex = this.state.selectedRowIndex,
            config = {columnSaveKey: 'sm-event-markets-table'},
            marketGroupsToDisplay = this.generateMarketGroups(meta.marketGroups),
            marketGroupsMore = this.generateMoreMarketGroups(meta.marketGroups),
            eventCompetitors = data.meta.competitors;

        if (!data) return null;

        return (<div>
            <div id='sm-event-details-wrap' onClick={this.handleClick} className='sm-e-t-details-header-wrap col-12'>
                <div className='sm-e-t-details-header sm-events-details-header col-12'>
                    <div className="sm-e-header-info col-12">
                        <div className="sm-e-t-event-name">
                            <span onClick={this.props.displayPopupDetails.bind(this, 'team', eventCompetitors[0])} >
                                {eventCompetitors[0].team.shortName || eventCompetitors[0].team.name}
                            </span>
                            <span class="divider"> - </span>
                            <span onClick={this.props.displayPopupDetails.bind(this, 'team', eventCompetitors[1])} >
                                {eventCompetitors[1].team.shortName || eventCompetitors[1].team.name}
                            </span>
                            {' | ' + meta.eventDate + ' | ' + meta.eventTime}
                        </div>
                        <div className="sm-e-t-category-name" title={this.translate('sportsbook.click_to_edit_sport')} onClick={this.props.displayPopupDetails.bind(this, 'sport')}>{meta.sportName}</div>
                        <div className="sm-e-t-category-name left-border" title={this.translate('sportsbook.click_to_edit_category')} onClick={this.props.displayPopupDetails.bind(this, 'category')}>{meta.categoryName}</div>
                        <div className="sm-e-t-category-name left-border" title={this.translate('sportsbook.click_to_edit_tournament')} onClick={this.props.displayPopupDetails.bind(this, 'tournament')}>{meta.tournamentName}</div>
                    </div>

                    {
                        this.state.expandHeader && <div className="sm-e-header-schemes col-12">
                            <div className="sm-e-t-market-template-name col-4">
                                {this.translate('sportsbook.default_template')}
                                {
                                    <div className="sm-custom-input-wrap">
                                        <input value={meta.defaultMarketTemplateName} disabled/>
                                    </div>
                                }
                            </div>

                            <div className="sm-e-t-scheme-assignment col-8">
                                {
                                    eventsSchemes.map(function (eventScheme, index) {
                                        return <div className="sm-e-t-scheme-assignment-item col-6">
                                            <span className="col-6 sm-e-t-scheme-assignment-name">{eventScheme.schemeTypeName}</span>
                                            <span className="col-6 sm-e-t-scheme-assignment-value">
                                            <select className="sm-custom-select" value={eventScheme.schemeId} onChange={this.handleSchemeChange.bind(this, index)}>
                                                <option value="null">Select scheme</option>
                                                {
                                                    schemes.filter(function (scheme) {
                                                        return scheme.schemeTypeId === eventScheme.schemeTypeId
                                                    }).map(function (scheme) {
                                                        return <option value={scheme.id}>{scheme.name}</option>
                                                    }.bind(this))
                                                }
                                            </select>
                                        </span>
                                        </div>
                                    }.bind(this))
                                }
                            </div>
                        </div>
                    }

                    {
                        marketGroupsToDisplay.length > 0 && <div className={'sm-e-market-groups col-12'}>
                            <span className={'sm-e-market-group-item ' + (this.state.selectedMarketGroup === 'all' ? 'active' : '')}
                                  onClick={this.filterByMarketGroup.bind(this, 'all')}>All</span>
                            { marketGroupsToDisplay }

                            { marketGroupsMore.length > 0 &&
                                <span onClick={this.toggleMoreGroups} className='sm-e-market-group-item item-more'>
                                    More
                                    <i className='n-i n-i-arrow-down-b icon-more'> </i>
                                    {

                                        this.state.showMoreMarketGroups && <div className='sm-e-market-group-more-content'>
                                            {marketGroupsMore}
                                        </div>
                                    }
                                </span>
                            }
                        </div>
                    }

                    <div className='sm-e-t-details-column-select'>
                        <ColumnSelectionComponent
                            updateFilters   = {this.updateColumnVisibility}
                            setVisibility   = {this.updateColumnVisibility}
                            data            = {titles}
                            columns         = {columns}
                            config          = {config}
                            key             = 'column-select'
                        />
                    </div>

                    <div className="s-e-t-details-info-dropdown">
                        <i onClick={this.toggleHeaderInfo}
                            className={"n-i " + (this.state.expandHeader ? "n-i-arrow-up-a" : "n-i-arrow-down-a")}> </i>
                    </div>
                </div>
                <div className='sm-event-markets-grid' tabIndex="1">
                    <div>
                        <div className="grid-header">
                            <div className='grid-cell grid-cell-active'> </div>
                            {columns.id.visibility && <div className='grid-cell grid-cell-id'>{titles.id}</div>}
                            {columns.displayId.visibility && <div className='grid-cell grid-cell-displayId'>{titles.displayId}</div>}
                            {columns.name.visibility && <div className='grid-cell grid-cell-name'>{titles.name}</div>}
                            {columns.status.visibility && <div className='grid-cell grid-cell-status'>{titles.status}</div>}
                            {columns.eventMarketId.visibility && <div className='grid-cell grid-cell-event-market-id'>{titles.eventMarketId}</div>}
                            {columns.description.visibility && <div className='grid-cell grid-cell-description'>{titles.description}</div>}
                            {columns.specialValues.visibility && <div className="grid-cell grid-cell-sv">SV</div>}
                            {columns.marketOutcomes.visibility && outcomeList.map(function (index) {
                                var outcomeName = markets[selectedIndex]
                                    && markets[selectedIndex].marketOutcomes
                                    && markets[selectedIndex].marketOutcomes[index]
                                    && markets[selectedIndex].marketOutcomes[index].name;

                                return (<div className='grid-cell grid-cell-outcome'>{outcomeName || ''}</div>)
                            })}
                        </div>
                    </div>

                    <div className="grid-body">
                    <div className="grid-body-content" id="sm-event-markets-grid">
                        {markets.map(function (row, index) {
                            switch (row.displayMode) {
                                case 1: return <SmEventsDetailsSingleRow
                                    key={'evt-details-single-row-' + index}
                                    columns = {columns}
                                    row = {row}
                                    ref={this.createRef.bind(this, index)}
                                    eventCompetitors={eventCompetitors}
                                    eventId={data.eventId}
                                    rowIndex = {index}
                                    outcomeList = {outcomeList}
                                    selectedIndex = {selectedIndex}
                                    oddsScheme = {oddsScheme}
                                    oddsSchemeSelected = {oddsSchemeSelected}
                                    handleDetailsClick = {this.props.handleDetailsClick}
                                    handleKeyDown = {this.handleKeyDown}
                                    handleKeyUp = {this.handleKeyUp}
                                    handleInputClick = {this.handleInputClick}
                                    marketsForDistribution={this.state.eventMarketsForDistribution}
                                    handleRowClick={this.handleRowClick}
                                    updateInputValue={this.props.updateInputValue}
                                    submitInputValue={this.submitInputValue}
                                    handleRowContextMenu={this.handleRowContextMenu}
                                    handleEnterKey={this.props.handleEnterKey}
                                    checkboxChange = {this.props.checkboxChange}
                                    handleOddsSchemeListClick={this.props.handleOddSchemeClick}
                                    checkPercentageRule={this.props.checkPercentageRule}
                                    showOddValidationPopup={this.props.showOddValidationPopup}
                                    submitMarketSpecialValue={this.submitMarketSpecialValue}
                                    changeMarketSpecialValue={this.props.changeMarketSpecialValue}
                                    outcomesContextMenu = {this.handleOutcomesContextMenu}
                                    setContextMenuConfig = { this.setContextMenuConfig }
                                />;
                                case 2:
                                case 3: return <SmEventsDetailsExpandableRow
                                    key={'evt-details-expanded-row-' + index}
                                    columns = {columns}
                                    row = {row}
                                    ref={this.createRef.bind(this, index)}
                                    rowIndex = {index}
                                    outcomeList = {outcomeList}
                                    competitors = {competitors}
                                    selectedIndex = {selectedIndex}
                                    oddsScheme = {oddsScheme}
                                    oddsSchemeSelected = {oddsSchemeSelected}
                                    updateInputValue={this.props.updateInputValue}
                                    submitInputValue={this.props.submitInputValue}
                                    handleEnterKey={this.props.handleEnterKey}
                                    marketsForDistribution={this.state.eventMarketsForDistribution}
                                    checkboxChange = {this.props.checkboxChange}
                                    handleKeyDown = {this.handleKeyDown}
                                    handleKeyUp = {this.handleKeyUp}
                                    handleInputClick = {this.handleInputClick}
                                    handleExpandClick={this.handleExpandClick}
                                    handleDetailsClick = {this.props.handleDetailsClick}
                                    handleRowClick={this.handleRowClick}
                                    selectRowOnly={this.selectRowOnly}
                                    handleRowContextMenu={this.handleRowContextMenu}
                                    competitorSelectChange={this.props.competitorSelectChange}
                                    handleAddButtonClick={this.props.handleAddButtonClick}
                                    handleOddsSchemeListClick={this.props.handleOddSchemeClick}
                                    checkPercentageRule={this.props.checkPercentageRule}
                                    showOddValidationPopup={this.props.showOddValidationPopup}
                                    submitMarketSpecialValue={this.submitMarketSpecialValue}
                                    changeMarketSpecialValue={this.props.changeMarketSpecialValue}
                                    outcomesContextMenu = {this.handleOutcomesContextMenu}
                                    setContextMenuConfig = { this.setContextMenuConfig }
                                />;
                            }
                        }.bind(this))}
                    </div>

                    </div>
                </div>
                <SmEventsContextMenu
                        ref={this.createRef.bind(this,'marketsContextMenu')}
                        resolveContextMenuAction={this.handleContextMenuChange}
                        numberOfSelectedRows={this.state.eventMarketsForDistribution.length}
                        fixedPosition={true}
                        idValue="sm-event-markets-context-menu"
                        rcActions={this.props.statusContextMenuItems}
                        config={this.state.contextMenuConfig} />
                <SmEventsContextMenu
                    resolveContextMenuAction={this.handleOutcomeContextMenuChange}
                    numberOfSelectedRows={this.state.eventMarketsForDistribution.length}
                    fixedPosition={true}
                    wide={true}
                    idValue="sm-event-outcomes-context-menu"
                    rcActions={this.props.outcomesContextMenuItems} />
            </div>
        </div>)
    }
});
;
var SmEventsTable = React.createClass({
    selectedEvents: [],
    inputRefs: {},
    lastSelectedIndex: 0,

    mixins: [DistributionSelectionChecker, TranslateMixin],

    getInitialState: function () {
        this.lastSelectedIndex = 0;

        return {
            eventsForDistribution: [],
            selectedEvents: [],
            selectedRow: null,
            specialsTab: this.props.specialsTab,
            xIndex: 0,
            yIndex: 0,
            zIndex: 0
        }
    },

    componentWillReceiveProps: function () {
        this.selectedEvents = [];

        this.setState({
            selectedRowIndex: 0,
            marketsForDistribution: []
        })
    },

    componentDidUpdate: function () {
        if (this.props.focusDetails || this.props.ignoreFocus) return;

        if (this.props.data.oddsScheme && this.oddsSchemeTableElement) {
            this.oddsSchemeTableElement.focus();
            return;
        }

        this.focusInput();
    },

    handleInputKeyPress: function (code, eventIndex, marketIndex, outcomeIndex) {

        var x = this.state.xIndex,
            y = this.state.yIndex,
            z = this.state.zIndex,
            data = this.props.data.data,
            markets = data[eventIndex].referentEventMarketOutcomes;

        if (code === 13) {
            this.setState({selectedRow: eventIndex});
            this.props.handleEventsRowClick(this.props.data.data[eventIndex], eventIndex);
        }

        if ([37, 38, 39, 40, 27, 13, 106].indexOf(code) === -1) {
            return;
        }

        switch (code) {
            case 38: x = (x === 0) ? data.length - 1 : Number(x) - 1; break;
            case 40: x = (x === (data.length - 1)) ? 0 : Number(x) + 1; break;
            case 39: // Right arrow
                if (outcomeIndex === (markets[marketIndex].length - 1) && ((markets.length - 1) !== marketIndex)) { // Current focus on last input in market
                    y++;
                    z = 0;
                } else if (markets.length - 1 === marketIndex && markets[marketIndex].length - 1 === outcomeIndex) { // Current focus on last input in row
                    x = (x === (data.length - 1)) ? 0 : Number(x) + 1;
                    y = 0;
                    z = 0;
                } else {
                    z++;
                }
                break;
            case 37: // Left arrow
                if (marketIndex > 0 && outcomeIndex === 0) { // Current focus on last market and first input in that market
                    y--;
                    z = markets[marketIndex - 1].length - 1;
                } else if (marketIndex === 0 && outcomeIndex === 0) { // Current focus in first input in first market
                    x = (x === 0) ? data.length - 1 : Number(x) - 1;
                    y = markets.length - 1;
                    z = markets[markets.length - 1].length - 1;
                } else {
                    z--;
                }
                break;
            case 27: this.props.closeEvents(); break;
            case 106: this.props.clearEventMarketOdds([x]); break;
        }

        if (x !== this.state.selectedRow) {
            this.handleRowClick(this.props.data.data[x], x);
        }

        this.setState({
            xIndex: x,
            yIndex: y,
            zIndex: z
        })
    },

    focusInput: function () {
        var x = this.state.xIndex,
            y = this.state.yIndex,
            z = this.state.zIndex,
            input = this.inputRefs['event-outcome-' + x + y + z];

        if (input) {
            ReactDOM.findDOMNode(input).focus();
        }
    },

    stopProp: function (e) {
        e.stopPropagation();
    },

    handleRowClick: function (data, index, event) {
        var detailsTable = document.getElementById('sm-event-markets-grid');
        if (detailsTable) {
            detailsTable.scrollTop = 0;
        }

        this.props.handleEventsClick();

        var selected = this.state.eventsForDistribution,
            shiftKey = event ? event.shiftKey : false,
            ctrlKey = event ? event.ctrlKey : false;

        this.setState({
            eventsForDistribution: this.checkSelection(selected, shiftKey, ctrlKey, index, this.lastSelectedIndex),
            selectedRow: index,
            xIndex: index
        });

        if (this.state.selectedRow !== index && (!shiftKey && !ctrlKey)) {
            this.props.handleEventsRowClick(data, index);
        }

        this.lastSelectedIndex = index;
    },

    handleEditClick: function(data, index){
        this.props.handleEventEditClick(data, index);
    },

    handleEventDateInputSubmit: function (value, rowKey, cellKey) {
        var data = this.props.data.data[rowKey];
        data[cellKey] = value;
        this.props.eventsDateInputSubmit(data);
    },

    handleInputClick: function (x, y, z) {
        this.setState({
            xIndex: x,
            yIndex: y,
            zIndex: z
        });
        this.props.handleEventsClick();
    },

    handleEventsOddsSchemeListClick: function (data, index, marketIndex, outcomeIndex) {
        this.props.handleEventsOddsSchemeListClick(data, index, marketIndex);

        var rows = this.props.data.data,
            x = index,
            y = marketIndex,
            z = outcomeIndex,
            markets = rows[index].referentEventMarketOutcomes;

        if (marketIndex < markets[marketIndex].length - 1) {
            y++;
            z = 0;
        } else {
            x++;
            y = 0;
            z = 0;
        }

        if (x !== this.state.selectedRow) {
            this.handleRowClick(this.props.data.data[x], x);
        }

        this.setState({
            xIndex: x,
            yIndex: y,
            zIndex: z
        });
    },

    handleCheckChange: function (id, evt) {
        if (!evt.target.checked) {
            this.selectedEvents.splice(this.selectedEvents.indexOf(id), 1);
        } else {
            this.selectedEvents.push(id);
        }
        this.props.setContextData(this.selectedEvents);

        this.setState({
            selectedEvents: this.selectedEvents.map(function (value) {
                return value;
            })
        });
    },

    handleContextMenuChange: function (id) {
        console.log('id: ', id);
        this.props.handleContextMenuChange(id, this.state.eventsForDistribution);
    },

    handleContextMenu: function (data, evt) {
        evt.preventDefault();
        $('#sm-event-markets-context-menu').css({display : 'none'});
        $('#sm-event-outcomes-context-menu').css({display : 'none'});
        var wiewportH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
        contextMenuH = $('#sm-events-context-menu').outerHeight(true);
        if(wiewportH-contextMenuH<=evt.clientY){
            var contextMenuOffset = evt.clientY - ((evt.clientY+contextMenuH)-wiewportH);
            $('#sm-events-context-menu').css({display: 'block', left: evt.clientX, top: contextMenuOffset });
        }else{
            $('#sm-events-context-menu').css({display: 'block', left: evt.clientX, top: evt.clientY});
        }
    },

    selectAllEvents: function (e) {
        var data = this.props.data.data,
            selected = [];
        if (e.target.checked) {
            data.forEach(function (v, k) {
                selected.push(k)
            });
        }

        this.setState({
            eventsForDistribution: selected
        });
    },

    eventReferentOddsSchemeRequest: function (value, eventIndex, marketIndex, outcomeIndex){
        this.props.eventReferentOddsSchemeRequest(value, eventIndex, marketIndex, outcomeIndex);
    },

    getReferentTitles: function () {
        var data = this.props.data.data.length && this.props.data.data[0].referentEventMarketOutcomes || [],
            arr = [];

        for (var i = 0; i < data.length; i++) {
            arr.push(<th className="empty-cell-separator">{}</th>);
            for (var j = 0; j < data[i].length; j++) {
                arr.push(<th className="center-align">{data[i][j].marketOutcomeName}</th>)
            }
        }

        return arr;
    },

    handleSortAction: function (sort, direction) {
        this.props.updateFilters('eventSort', sort);
        this.props.updateFilters('eventDirection', direction);
    },

    handleEventRiskFactorChange: function(eventId, riskFactorId) {
        this.props.updateEventRiskFactor(eventId, riskFactorId);
    },

    render: function () {
        if (!this.props.data.data) return null;

        var data = this.props.data.data,
            titles = this.props.data.titles,
            columns = this.props.data.localColumns ? this.props.data.localColumns : this.props.data.columns,
            oddsScheme = this.props.data.oddsScheme,
            lastSelectedEventIndex = this.props.data.eventIndex,
            lastSelectedOutcomeIndex = this.props.data.outcomeIndex,
            lastSelectedMarketIndex = this.props.data.marketIndex,
            referentTitles = this.getReferentTitles(),
            config = {columnSaveKey: 'sm-events-table'},
            selectedFilters = {};

        selectedFilters.sort = this.props.selectedFilters.eventSort;
        selectedFilters.direction = this.props.selectedFilters.eventDirection;

        return (
                <div>
                    <table tabIndex="0" className="table sm-events-table" id="sm-events-table">
                        <thead>
                        <tr>
                            <th>
                                <span className="distributed-checkbox-info">
                                    <input
                                        id="sm-event-select-all"
                                        type="checkbox"
                                        onChange={this.selectAllEvents}
                                        className='styled-checkbox'/>
                                    <label htmlFor="sm-event-select-all"> </label>
                                </span>
                            </th>
                            <SmEventsHeaderCell id="id" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="displayIds" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="name" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="eventDescription" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="eventDate" className="center-align" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="eventTime" className="center-align" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            {!this.state.specialsTab && columns.referentOdds.visibility && referentTitles}
                            <SmEventsHeaderCell id="status" className="center-align" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            {columns.riskFactor && <SmEventsHeaderCell id="riskFactor" className="center-align" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>}
                            <th className="th-column-select">
                                <ColumnSelectionComponent
                                    updateFilters   = {this.props.setEventsColumnVisibility}
                                    data            = {titles}
                                    setVisibility   = {this.props.setEventsColumnVisibility}
                                    columns         = {columns}
                                    config          = {config}
                                    key             = 'column-select'
                                />
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        {
                            data && data.map(function (row, index) {
                                var competitor1ShortName = row.competitors[0] && row.competitors[0].team && row.competitors[0].team.shortName;
                                var competitor1Name = row.competitors[0] && row.competitors[0].team && row.competitors[0].team.name;
                                var competitor2ShortName = row.competitors[1] && row.competitors[1].team && row.competitors[1].team.shortName;
                                var competitor2Name = row.competitors[1] && row.competitors[1].team && row.competitors[1].team.name;

                                return (
                                    <tr
                                        onClick={this.handleRowClick.bind(this, row, index)}
                                        className={this.state.eventsForDistribution.indexOf(index) !== -1 ? 'distributionSelected': ''}
                                        onContextMenu={this.handleContextMenu.bind(this, row)}>
                                        <td>
                                            <span className="distributed-checkbox-info">
                                                <input
                                                    id={'distributed-checkbox-' + index}
                                                    type="checkbox"
                                                    checked={this.state.eventsForDistribution.indexOf(index) !== -1}
                                                    className='styled-checkbox'/>
                                                <label class="middle-align" htmlFor={'distributed-checkbox-' + index}> </label>
                                            </span>
                                        </td>
                                        {columns.id.visibility && <td>{row.id}</td>}
                                        {columns.displayIds.visibility && <td>{row.displayIds}</td>}
                                        {columns.name.visibility && <td>
                                            <i className={'distribution-status events-distribution-status distribution-status-' + row.distributionStatus}>{}</i>
                                            <span>{ competitor1ShortName || competitor1Name }</span>
                                            <span> - </span>
                                            <span>{ competitor2ShortName || competitor2Name }</span>
                                        </td>}
                                        {columns.eventDescription.visibility && <td className="event-description">
                                            {row.eventDescription && <div data-tip={row.eventDescription} className="cell-icon">
                                                <i className="n-i n-i-info"></i>
                                                <ReactTooltip multiline={true} className="custom-react-tooltip" />
                                            </div>}
                                        </td>}
                                        {columns.eventDate.visibility && <td className="date-input">
                                            {row.eventDate}
                                        </td>}
                                        {columns.eventTime.visibility && <td className="time-input">
                                            {row.eventTime}
                                        </td>}
                                        {
                                            columns.referentOdds.visibility && function () {
                                                var arr = [];

                                                if (!row.referentEventMarketOutcomes || this.state.specialsTab) return null;

                                                row.referentEventMarketOutcomes.forEach(function (market, i) {
                                                    arr.push(<td>{}</td>);

                                                    market.forEach(function (outcome, j) {
                                                        arr.push(<td className='relative-pos center-align outcome-input input-padding'>
                                                            <SmEventsReferentInputCell
                                                                value = {outcome.eventMarketOutcomeOdd}
                                                                eventIndex={index}
                                                                marketIndex={i}
                                                                outcomeIndex={j}
                                                                ref={function (input) {
                                                                    this.inputRefs['event-outcome-' + index + i + j] = input;
                                                                }.bind(this)}
                                                                inputChangeAction={this.props.eventReferentInputChange}
                                                                handleInputKeyPress={this.handleInputKeyPress}
                                                                handleInputClick={this.handleInputClick}
                                                                submitAction={this.props.eventReferentInputSubmit}
                                                                oddsSchemaChangeAction={this.eventReferentOddsSchemeRequest}
                                                            />

                                                            {
                                                                outcome &&
                                                                index === this.state.selectedRow &&
                                                                lastSelectedEventIndex === index &&
                                                                lastSelectedOutcomeIndex === j &&
                                                                lastSelectedMarketIndex === i && oddsScheme &&
                                                                <SmMarketsOddsSchemaList
                                                                    handleClickAction={this.handleEventsOddsSchemeListClick}
                                                                    index={index}
                                                                    tableRef={function (el) {this.oddsSchemeTableElement = el}.bind(this)}
                                                                    marketIndex={i}
                                                                    outcomeIndex={j}
                                                                    data={oddsScheme}
                                                                />
                                                            }
                                                        </td>)
                                                    }.bind(this))
                                                }.bind(this));

                                                return arr;

                                            }.bind(this)()
                                        }

                                        {columns.status.visibility && <td className="center-align events-status">
                                            <i className={'n-i events-status-' + row.status} title={this.translate('smEventStatus'+row.status)}>{}</i>
                                        </td>}
                                        <td className="center-align">
                                            {columns.riskFactor && row.riskFactor.selected.id && <div className="sm-event-riskfactor-select-wrap">
                                                <SmEventsDetailsRiskFactorSelect
                                                    riskFactors={row.riskFactor.options}
                                                    id={row.riskFactor.eventId}
                                                    value={row.riskFactor.selected.id}
                                                    handleChange={this.handleEventRiskFactorChange}
                                                />
                                            </div>}
                                        </td>
                                        <td className="center-align">
                                            <div className={'sm-events-edit'} onClick={this.handleEditClick.bind(this, row, index)}>
                                                <i className={'n-i n-i-edit clickable'} >{}</i>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }.bind(this))
                        }
                        </tbody>
                    </table>
                    <ContextMenu resolveContextMenuAction={this.handleContextMenuChange} fixedPosition={true} idValue="sm-events-context-menu" rcActions={this.props.contextMenuItems} />
                </div>
        )
    }
});

function SmEventsHeaderCell (props) {
    if (!props) return;
    if (!(props.columns[props.id].visibility)) return null;

    var className = props.className || '';

    function handleSort(e) {
        var direction = props.selected.direction === 'desc' ? 'asc' : 'desc';
        props.sortAction(props.id, direction);
    }

    return <th onClick={handleSort} className={className}>
        {props.titles[props.id]}
        <i className={(props.selected.sort === props.id && ('n-i react-header-sort-' + props.selected.direction))}>{}</i>
    </th>
}
;
var SmEventsFilters = React.createClass({
    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
            data: null,
            displayedFilter: '',
            searchValue:'',
            checkedAll:false,
            supportedExportFormats: ['xlsx']
        }
    },

    setData: function (data) {
        this.setState({
            data: data
        })
    },

    // Hide all filters
    handleGlobalClick: function handleGlobalClick (e) {
        if (this.props.handleFilterDisplayClick && this.state.displayedFilter) {
            this.props.handleFilterDisplayClick('');
        }
        this.setState({displayedFilter: '', searchValue:''});
    },

    closeFilter: function () {
        this.setState({displayedFilter: '', searchValue:''})
    },

    componentWillMount: function () {
        window.addEventListener('click', this.handleGlobalClick);
    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('click', this.handleGlobalClick);
    },

    handleClick: function (key, e) {
        e.stopPropagation();
        var displayedValue = this.state.displayedFilter !== key ? key : '',
            searchValue = this.state.displayedFilter !== key ? '' : this.state.searchValue;

        this.setState({
            displayedFilter: displayedValue,
            checkedAll: false,
            searchValue: searchValue
        });


        if (this.props.handleFilterDisplayClick) {
            this.props.handleFilterDisplayClick(displayedValue);
        }
    },

    handleChange: function (filterKey, filterValue) {
        this.props.handleFilterChange(filterKey, filterValue.id);
    },

    handleChangeAll: function(filterKey, filterValue){
        this.props.handleFilterChangeAll(filterKey,filterValue.id);
    },

    handleRadioChange: function(filterKey, filterValue){
        this.setState({checkedAll:false});
        this.props.handleFilterChange(filterKey,filterValue.id);
    },

    handleClearAll: function(filterKey, filterValue){
        this.setState({checkedAll:false});
        this.props.handleFilterClearAll(filterKey,filterValue.id);
    },

    handleSearchChange: function (value) {
        this.props.handleFilterChange('search', value);
    },

    handleSearchClick: function () {
        if (this.props.handleFilterDisplayClick) {
            this.props.handleFilterDisplayClick('search');
        }
    },

    dateUpdate: function (filterKey, filterValue) {
        this.props.handleFilterChange(filterKey, filterValue);
    },

    setSearchVal: function(event){
        this.setState({searchValue:event.target.value});
    },

    selectAll: function( key, data, ct){
        var handleChangeAll = this.handleChangeAll;
        var stateData = this.state.data;
        var selectedAll = stateData.selectedFilters[key] ? stateData.selectedFilters[key].split(',') : [];

        data.forEach(function(element){
            if (this.state.searchValue !== '') {
                if(element.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1){
                    this.handleChange(key,element);
                    selectedAll.push(element.id);
                }
                stateData.selectedFilters[key] = selectedAll.join(',');
                this.setState({ data: stateData });
                return;
            } else {
                this.setState({checkedAll:true});
                handleChangeAll(key,element, true);
            }
        }.bind(this));
    },

    stopProp: function (e) {
        e.stopPropagation();
    },

    render: function () {
        if (!this.state.data) return null;

        var data = this.state.data.data,
            names = this.state.data.filterNames,
            selectedFilters = this.state.data.selectedFilters || {},
            filterKeys = Object.keys(data),
            selectedFilterFormatted = {};

        for (var i in selectedFilters) {
            if (selectedFilters.hasOwnProperty(i) && selectedFilters[i] ) {
                selectedFilterFormatted[i] = String(selectedFilters[i]).split(',')
            }
        }

        return (
            <div className="sm-filters-wrap col-12">
                <div className="sm-filters-name" onClick={this.handleClick.bind(this, 'date')}>
                    <div className={"sm-filter-name-text active-filter"}>Date</div>
                    <i className="n-i n-i-arrow-down-b sm-filters-arrow">{}</i>
                    {
                        this.state.displayedFilter === 'date' && (
                            <div className="sm-filters-content rc-date-filter" onClick={this.stopProp}>
                                <DateFilter
                                    updateFilters       = {this.dateUpdate}
                                    updateLocationFilter= {this.dateUpdate}
                                    closeFilter         = {this.closeFilter}

                                    dateFilterData      = {data.date.dateFilterData}
                                    serverTime          = {data.date.serverTime}
                                    dateFormat          = {data.date.dateFormat}
                                    locale              = {data.date.locale}
                                    filterKey           = {'date'}
                                />
                            </div>
                        )
                    }
                </div>
                {
                filterKeys.map(function(key) {
                    if (key === 'date') return null;
                     return (
                         <div className="sm-filters-name">
                             <div className={"sm-filter-name-text " + (selectedFilters[key] && "active-filter")} onClick={this.handleClick.bind(this, key)}>{names[key]}</div>
                             <i className="n-i n-i-arrow-down-b sm-filters-arrow">{}</i>

                             {
                                 this.state.displayedFilter === key && (
                                    <div className="sm-filters-content sm-filters-content-wrap" onClick={this.stopProp}>
                                    {
                                            data[key].length>2 && (
                                                    <div className="filter-options">
                                                        {function() {
                                                            if (data[key].length >= 10)
                                                            return <div className="filter-option" >
                                                                <input
                                                                    name            = {'filter-search'}
                                                                    type            = {'text'}
                                                                    placeholder     = {this.translate('general.search_filters')}
                                                                    value           = {this.state.searchValue}
                                                                    onChange        = {this.setSearchVal}
                                                                    className       = {'react-filter-search'}
                                                                    autoFocus       = "autoFocus"
                                                                />
                                                            </div>
                                                        }.bind(this)()}
                                                        {
                                                            key!=='language' && (
                                                                <div className="filter-option">
                                                                    <span onClick={this.selectAll.bind(this, key, data[key] )}>{this.translate('general.select_all')}</span>
                                                                    <span onClick={this.handleClearAll.bind(this, key, data[key])}>{this.translate('general.clear')}</span>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                 )
                                    }

                                    <div className="sm-filters-content-body">
                                    {
                                        data[key].map(function (filter, index) {
                                             if(filter.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1){
                                                 return <SmEventsFilterCheckbox
                                                    key={filter.id}
                                                    filterKey={key}
                                                    index={index}
                                                    value={selectedFilterFormatted[key] && selectedFilterFormatted[key].indexOf(String(filter.id)) !== -1 || this.state.checkedAll}
                                                    selectedFilterFormatted={selectedFilterFormatted}
                                                    filter={filter}
                                                    checkedAll={this.state.checkedAll}
                                                    handleChange={this.handleChange}
                                                    handleRadioChange={this.handleRadioChange}
                                                 />;
                                            }
                                         }.bind(this))
                                     }
                                     </div>
                                  </div>
                                 )
                             }
                         </div>
                     )
                }.bind(this))
                }
                <div className="table-header-options sm-events-toolbar">
                    <SmEventsExportToolbar
                        supportedExportFormats={this.state.supportedExportFormats}
                        excelExportMethod={this.props.excelExportMethod}/>
                </div>
                <div className="sm-search">
                    <SmEventsSearchFilter
                        value={selectedFilters['search']}
                        changeAction={this.handleSearchChange}
                        clickAction={this.handleSearchClick} />
                </div>
            </div>
        );
    }
});

var SmEventsFilterCheckbox = React.createClass({
    getInitialState: function () {
        return {value: this.props.value}
    },
    componentWillReceiveProps: function (newProps) {
        this.setState({value: newProps.value})
    },
    handleChange: function (e) {
        this.setState({value: e.target.checked});

        if (this.props.key !== 'language') {
            this.props.handleChange(this.props.filterKey, this.props.filter)
        } else {
            this.props.handleRadioChange(this.props.filterKey, this.props.filter)
        }
    },
    render: function () {
        var key = this.props.filterKey,
            index = this.props.index,
            filter = this.props.filter;

        return <div className="sm-filter-row-value">
            <label htmlFor={"filter-" + key + index} className={key !=='language' ? "checkbox": "single-select-radio" }>
                <input type={key!=='language' ? "checkbox": "radio" }
                       id={"filter-" + key + index}
                       className={key!=='language' ? "normal-checkbox": "normal-radio-btn" }
                       checked={this.state.value}
                       onChange={this.handleChange}/>
                <i></i>
                {key !== 'language' ? "": filter.name}
            </label>
            <label className="sm-filter-value-label" htmlFor={"filter-" + key + index}>{key!=='language' ? filter.name : "" }</label>
        </div>
    }
});

var SmEventsSearchFilter = React.createClass({
    mixins: [TranslateMixin],

    timeout: null,

    getInitialState: function () {
        return {value: this.props.value}
    },

    componentWillReceiveProps: function (props) {
        this.setState({value: props.value})
    },

    handleClick: function () {
        this.props.clickAction();
    },

    handleChange: function (e) {
        var value = e.target.value;
        if (!this.timeout) clearTimeout(timeout);

        this.timeout = setTimeout(function () {
            this.props.changeAction(value);
        }.bind(this), 1000);

        this.setState({value: value});
    },

    render: function () {
        return <input
             placeholder={this.translate('general.search')}
             value={this.state.value}
             onChange={this.handleChange}
             onClick={this.handleClick}
             type="text"/>
    }
});

var SmEventsExportToolbar = React.createClass({
    mixins: [TranslateMixin],

    getInitialState: function getInitialState () {
        return ({
            showExportContent: false,
        });
    },

    componentWillMount: function () {
        window.addEventListener('click', this.handleGlobalClick);
    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('click', this.handleGlobalClick);
    },

    handleGlobalClick: function () {
        if (this.state.showExportContent)
            this.setState({
                showExportContent: false,
            });
    },

    handleClick: function handleClick (event) {
        event.stopPropagation();
        this.setState({
            showExportContent: true,
        });
    },

    excelExport: function excelExport (e) {
        this.props.excelExportMethod && this.props.excelExportMethod();
    },

    exportMethod: function exportMethod (e) {
        // Implement specific methods for other export formats
        console.warn('Export method not implemented');
    },

    render: function render () {
        var formats = this.props.supportedExportFormats ? this.props.supportedExportFormats : ['xlsx'];
        var formatButtons = formats.map(function (value) {
            return <div className='btn export-format-btn'>
                {
                    value === 'xlsx' &&
                    <div className="export-link" onClick={this.excelExport}> {value.toUpperCase()} </div>
                }
                {
                    value !== 'xlsx' &&
                    <div className="export-link" onClick={this.exportMethod}> {value.toUpperCase()} </div>
                }
            </div>
        }, this);

        return (
                <div className='table-settings table-header-div' onClick={this.handleClick} title={this.translate('general.export')} >
                    <i className='n-i n-i-download'>
                        { this.state.showExportContent &&
                            <div className='file-formats'>
                                <div className="export-header-title">
                                    {this.translate('general.export')}
                                </div>
                                <div className="export-formats">{formatButtons}</div>
                            </div>
                        }
                    </i>
                </div>
            )
    }
})
;
var EventMarketList = React.createClass({
    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
            searchVal: ''
        }
    },

    setSearchVal: function(evt) {
        this.setState({
            searchVal: evt.target.value
        });
    },

    selectMarket: function(market, evt) {
        this.props.selectMarket(market);
    },

    render: function() {
        var eventMarkets = this.props.data;

        return(
            <div className="rules-event-markets-list">
                <div className='sm-reml-header'>{this.translate('sportsbook.event_markets')}</div>
                {
                eventMarkets.length>0 && <div className='sm-reml-search'>
                    <input
                        onChange={this.setSearchVal.bind(this)}
                        id='sm-reml-market-search'
                        type="text"
                        placeholder={this.translate('general.search')}/>
                </div>
                }
                <div className="sm-reml-market-list-wrap">
                    <div className="sm-reml-market-list-inner">
                    {
                        eventMarkets.length>0 && eventMarkets.map(function(market){
                            if(market.marketName.indexOf(this.state.searchVal)>=0){
                                return  <div className={['sm-reml-market-name',
                                                        this.props.selectedMarket.eventMarketId == market.eventMarketId ? "selected" : ""].join(' ')}
                                            onClick={this.selectMarket.bind(this, market)}>
                                            {market.marketName}
                                            {market.eventMarketSpecialValues.length>0 && ' (' + market.eventMarketSpecialValues.map(function(sv, index){
                                                    if(index+1 < market.eventMarketSpecialValues.length) {
                                                        return  sv + ' / ';
                                                    }
                                                    return sv;
                                                }).join('') + ') '
                                            }
                                            {market.eventMarketCompetitors && market.eventMarketCompetitors.length>0 && ' - ' + market.eventMarketCompetitors[0].playerName + ' ('+ market.eventMarketCompetitors[0].teamName+')'}
                                        </div>;
                            }
                        }.bind(this))
                    }
                    {
                        eventMarkets.length==0 && <div className='sm-reml-market-name sm-reml-no-markets'>
                            {this.translate('sportsbook.no_markets_for_selected_event')}
                        </div>
                    }
                    </div>
                </div>
            </div>
        );
    }
});;
var EventMarketOutcomes = React.createClass({
    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
        }
    },

    setData: function(data) {
        this.setState({
            data: data
        });
    },

    getData: function() {
        return this.state.data;
    },

    reloadRules: function() {
        this.props.reloadRules();
    },

    changeRuleValue: function(emoId, ruleId, ruleType, evt) {
        var tempData = this.state.data;
        _.forEach(tempData.eventMarketOutcomes, function(outcome){
            if(outcome.eventMarketOutcomeId == emoId){
                _.forEach(outcome.rulesData.rules, function(rule) {
                    if(rule.id == ruleId){
                        rule[ruleType].properties[0].value = evt.target.value;
                    }
                })
            }
        });
        this.setData(tempData);
    },

    handleScroll: function(e) {
        this.setState({scrollLeft: this.marketsOutcomeTable.scrollLeft});
    },

    componentWillUnmount() {
        if(this.marketsOutcomeTable){
            this.marketsOutcomeTable.removeEventListener('scroll', this.handleScroll);
        }
    },

    tableWrapMounted(node) {
        if(node){
            this.marketsOutcomeTable = node;
            this.marketsOutcomeTable.addEventListener('scroll', this.handleScroll.bind(this));
        }
    },

    createOutcomeRulesRows: function() {
        if(!this.state.data.eventMarketOutcomes) return <div></div>;
        var row;
        var outcomes = this.state.data.eventMarketOutcomes || [];
        var firstOutcomeRules = this.state.data.eventMarketOutcomes[0].rulesData.rules;

        row =   <tr>
                    <td className="sm-rules-label-td">
                        {this.firstColumnLabels()}
                    </td>
                    {
                        outcomes.map(function(outcome){
                            return <td className="sm-remo-rules-input-columns">
                                {
                                    outcome.rulesData.rules.map(function(rule){
                                        return <div>
                                            <div className="sm-remo-cell">
                                                <input type="number"
                                                       value={rule.restriction.properties[0].value || ''}
                                                       onChange={this.changeRuleValue.bind(this, outcome.rulesData.requestedId, rule.id, "restriction")}/>
                                            </div>
                                            <div className="sm-remo-cell">
                                                <input type="number"
                                                       value={rule.authorization.properties[0].value || ''}
                                                       onChange={this.changeRuleValue.bind(this, outcome.rulesData.requestedId, rule.id, "authorization")}/>
                                            </div>
                                        </div>
                                    }.bind(this))
                                }
                            </td>
                        }.bind(this))
                    }
                </tr>;
        return row;
    },

    firstColumnLabels: function() {
        if(!this.state.data.eventMarketOutcomes) return <div></div>;

        var firstColumn;
        var firstOutcomeRules = this.state.data.eventMarketOutcomes[0].rulesData.rules;
        var leftPosition = this.state.scrollLeft ? this.state.scrollLeft + 'px' : 0;

        firstColumn = <div className="sm-remo-first-column-wrap"
            style={{left:leftPosition}}>
            {
                firstOutcomeRules.map(function(rule){
                    return <div className="sm-remo-first-column-rules-names">
                        <div className="sm-remo-first-cell">
                            <i className="n-i n-i-restriction restriction-icon"></i>
                            <div>
                                {this.translate('sportsbook.'+ rule.restriction.properties[0].translationKey)}
                            </div>
                        </div>
                        <div className="sm-remo-first-cell">
                            <i className="n-i n-i-authorization authorization-icon"></i>
                            <div>
                                {this.translate('sportsbook.'+ rule.authorization.properties[0].translationKey)}
                            </div>
                        </div>
                    </div>
                }.bind(this))
            }
            </div>;

        return firstColumn;
    },

    render: function() {
        if(!this.state.data) return <div></div>;

        var market = this.state.data,
            tableHead = [],
            odds = [];

        if(market.length!=0){
            market.eventMarketOutcomes.map(function(outcome){
                tableHead.push(<th className="sm-remo-outcome-name">{outcome.name}</th>);
                odds.push(<td className="sm-remo-cell">
                        <div className="sm-remo-odd">{parseFloat(outcome.odd).toFixed(2)}</div>
                      </td>);
            });
        } else {
            tableHead.push(<div></div>);
            odds.push(<div></div>);
        }

        var leftPosition = this.state.scrollLeft ? this.state.scrollLeft + 'px' : 0;

        return(
            <div className="rules-event-markets-outcomes">
                <div className='sm-remo-header'>
                    {market.marketName}
                    {market.eventMarketSpecialValues.length>0 && ' (' + market.eventMarketSpecialValues.map(function(sv, index){
                            if(index+1 < market.eventMarketSpecialValues.length) {
                                return  sv + ' / ';
                            }
                            return sv;
                        }).join('') + ') '
                    }
                    {market.eventMarketCompetitors && market.eventMarketCompetitors.length>0 && ' - ' + market.eventMarketCompetitors[0].playerName + ' ('+ market.eventMarketCompetitors[0].teamName+')'}
                    {
                        market.length!=0 && <span className="sm-remo-header-reset" onClick={this.reloadRules} >
                            <i className="n-i n-i-reset sm-remo-reset-icon"></i>
                        </span>
                    }
                </div>
                <div className="sm-remo-market-outcomes-table-wrap"
                    ref={this.tableWrapMounted}
                    id="marketsOutcomeTable">
                        <table>
                        <thead>
                            <th className="sm-remo-blank-cell">
                                <div style={{left: leftPosition}}></div>
                            </th>
                            {tableHead}
                            <th className="sm-remo-last-cell"></th>
                        </thead>
                        <tbody>
                            <tr className="sm-remo-odds-row first-row-odds">
                                <td className="sm-remo-odds-cell">
                                    <div className="sm-remo-odds-label" style={{left: leftPosition}}>{market.length!=0 && this.translate('general.odds').toUpperCase()}</div>
                                </td>
                                {odds}
                                <td className="sm-remo-last-cell"></td>
                            </tr>
                            {this.createOutcomeRulesRows()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
});
;
var SmEventsTournamentsDetails = React.createClass({
    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
            data: null
        }
    },

    setData: function (data) {
        this.setState({
            data: data
        })
    },

    componentDidMount: function () {
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    },

    componentDidUpdate: function () {
        var content = $('#sm-events-tournaments-content'),
            table = $('#sm-tournament-details');

        content.height(window.innerHeight  - 225);
        table.tableHeadFixer();
    },

    componentWillUnmount: function () {
        window.removeEventListener('scroll', this.handleScroll);
    },

    displayPopupDetails: function (view) {
        this.props.displayPopupDetails(view)
    },

    handleScroll: function () {
        if (!this.table) {
            this.table = document.getElementById('sm-events-tournaments-details');
        }

        if (!this.table) return;

        this.table.style.top = document.documentElement.scrollTop > 90 ? document.documentElement.scrollTop - 90 + 'px' : '0px';
    },

    render: function () {
        var data = this.state.data;
        if (!data) return null;

        return(<div id="sm-events-tournaments-details" className="sm-e-t-details-header-wrap col-12">
                <div className="sm-e-t-details-header tournament-details col-12">
                    <div className="sm-e-t-category-name" title={this.translate('sportsbook.click_to_edit_sport')} onClick={this.props.displayPopupDetails.bind(this, 'sport')}>{data.meta.sportName}</div>
                    <div className="sm-e-t-category-name left-border" title={this.translate('sportsbook.click_to_edit_category')} onClick={this.props.displayPopupDetails.bind(this, 'category')}>{data.meta.categoryName}</div>
                    <div className="sm-e-t-category-name left-border" title={this.translate('sportsbook.click_to_edit_tournament')} onClick={this.props.displayPopupDetails.bind(this, 'tournament')}>{data.meta.name}</div>
                </div>

                <div className="col-12 sm-e-t-details-content">
                    <div id="sm-events-tournaments-content">
                        <table id="sm-events-tournaments-table" className="table">
                            <thead>
                            <tr>
                                <th>{data.titles.id}</th>
                                <th>{data.titles.name}</th>
                                <th className="center-align">{data.titles.active}</th>
                                <th>{data.titles.description}</th>
                            </tr>
                            </thead>

                            <tbody>
                            {
                                data.data.map(function (row) {
                                    return (
                                        <tr>
                                            <td>{row.id}</td>
                                            <td className={row.templateActive ? '' : 'tm-add-padding'}>
                                                { (row.templateActive) &&
                                                    <i className='n-i n-i-table template-active-indicator'>{}</i>
                                                }{row.name}</td>
                                            <td className="center-align"><i className={"n-i n-i-check-a sm-table-check-icon table-icon-" + String(row.active)}>{}</i></td>
                                            <td>{row.description}</td>
                                        </tr>
                                    )
                                }.bind(this))
                            }
                            </tbody>
                        </table>
                    </div>

                </div>

        </div>)
    }
});;
var SmEventsTournamentsTable = React.createClass({
    tableConfig: {},

    getInitialState: function () {
        return {
            selectedRow: this.props.selectedRow
        }
    },

    componentDidMount: function () {
        window.addEventListener('keydown', this.handleArrowNavigation);
    },

    componentWillUnmount: function () {
        window.removeEventListener('keydown', this.handleArrowNavigation);
    },

    componentWillReceiveProps: function (newProps) {
        var newIndex = this.props.expandedTournament && this.props.expandedTournament < newProps.selectedRow ? newProps.selectedRow - 1 : newProps.selectedRow;
        this.setState({
            selectedRow: newIndex
        });
    },

    handleArrowNavigation: function (e) {
        if (this.props.expandedTournament) return; // Ignore arrow navigation if events table is displayed

        var code = e.keyCode,
            index = this.state.selectedRow,
            data = this.props.data.data;

        if ([40, 38, 13].indexOf(code) === -1) return;

        e.preventDefault();
        e.stopPropagation();

        switch (code) {
            case 40: index = (index > data.length - 1) ? 0 : index + 1; break;
            case 38: index = (index === 0) ? data.length - 1 : index - 1; break;
            case 13: this.handleArrowClick(data[index], index, null, true); break;
        }

        if (code !== 13) {
            this.handleRowClick(data[index], index);
        }
    },

    setPagination: function (filterKey, filterValue) {
        if (!filterKey || !filterValue) return;
        this.props.updateFilters(filterKey, filterValue);
    },

    handleArrowClick: function (data, rowKey, evt) {
        evt && evt.stopPropagation();

        var detailsTable = document.getElementById('sm-event-markets-grid');
        if (detailsTable) {
            detailsTable.scrollTop = 0;
        }

        var expanded = this.props.expandedTournament,
            index = expanded === null || rowKey < expanded ? rowKey + 1 : rowKey;

        this.props.handleTournamentsArrowClick(data, index);
        var indexAfterExpanded = this.props.expandedTournament && this.props.expandedTournament < rowKey ? rowKey - 1 : rowKey;
        this.setState({
            selectedRow: indexAfterExpanded
        });
    },

    handleRowClick: function (data, index) {
        var newIndex = this.props.expandedTournament && this.props.expandedTournament < index ? index - 1 : index;
        this.props.handleTournamentsRowClick(data, index);
        this.setState({
            selectedRow: newIndex
        });
    },

    closeEvents: function () {
        var data = this.props.data.data,
            selected = this.state.selectedRow;

        this.props.handleTournamentsRowClick(data[selected], selected);
        this.setState({
            selectedRow: selected
        });
    },

    handleSortAction: function (sort, direction) {
        this.props.updateFilters('sort', sort);
        this.props.updateFilters('direction', direction);
    },

    render: function () {

        var data = this.props.data.data,
            titles = this.props.data.titles,
            columns = this.props.data.localColumns ? this.props.data.localColumns : this.props.data.columns,
            expandedTournament = this.props.expandedTournament,
            config = {columnSaveKey: 'sm-tournaments-table'},
            selectedFilters = this.props.selectedFilters,
            eventsVisible = this.props.eventsVisible;

        return (
            <table className="table main-table sm-table">
                <thead>
                <tr>
                    <SmTournamentsHeaderCell id="id" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                    <SmTournamentsHeaderCell id="sportIcon" className="center-align" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                    <SmTournamentsHeaderCell id="sportName" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                    <SmTournamentsHeaderCell id="isoCode" className="center-align" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                    <SmTournamentsHeaderCell id="categoryName" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                    <SmTournamentsHeaderCell id="name" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                    <SmTournamentsHeaderCell id="topOffer" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                    <SmTournamentsHeaderCell id="categoryId" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                    <SmTournamentsHeaderCell id="sportId" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                    <SmTournamentsHeaderCell id="tournamentGroupId" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>

                    <th className="th-column-select">
                        <ColumnSelectionComponent
                            updateFilters   = {this.props.setTournamentsColumnVisibility}
                            setVisibility   = {this.props.setTournamentsColumnVisibility}
                            data            = {titles}
                            columns         = {columns}
                            config          = {config}
                            key             = 'column-select'
                        />
                    </th>
                </tr>
                </thead>

                <tbody>
                {
                    data && data.map(function (row, index) {
                        if (eventsVisible && expandedTournament === index) {
                            return (
                                <tr className="sm-expandable-table" id="expandable-table-row">
                                    <td colSpan='40'>
                                        <SmEventsTable
                                            data={data[expandedTournament]}
                                            contextMenuItems={this.props.contextMenuItems}
                                            setContextData={this.props.setContextData}
                                            eventReferentInputChange={this.props.eventReferentInputChange}
                                            eventReferentInputSubmit={this.props.eventReferentInputSubmit}
                                            handleContextMenuChange={this.props.handleContextMenuChange}
                                            closeEvents={this.closeEvents}
                                            handleEventsClick={this.props.handleEventsClick}
                                            ignoreFocus={this.props.ignoreFocus}
                                            selectedFilters={selectedFilters}
                                            eventReferentOddsSchemeRequest={this.props.eventReferentOddsSchemeRequest}
                                            updateFilters={this.props.updateFilters}
                                            clearEventMarketOdds={this.props.clearEventMarketOdds}
                                            eventsDateInputSubmit={this.props.eventsDateInputSubmit}
                                            handleEventsOddsSchemeListClick={this.props.handleEventsOddsSchemeListClick}
                                            setEventsColumnVisibility={this.props.setEventsColumnVisibility}
                                            handleEventsRowClick={this.props.handleEventsRowClick}
                                            handleEventEditClick={this.props.handleEventEditClick}
                                            focusDetails={this.props.focusDetails}
                                            specialsTab={this.props.specialsTab}
                                            updateEventRiskFactor={this.props.updateEventRiskFactor}
                                        />
                                    </td>
                                </tr>
                            )
                        } else if (expandedTournament !== index) {
                            return (
                                <tr onClick={this.handleRowClick.bind(this, row, index)}
                                    className={'table-content-row ' + (index === 0 ? 'first' : '') + (this.state.selectedRow === index ? ' selected' : '') + (expandedTournament - 1 === index ? ' expanded' : '')}>

                                    {columns.id.visibility && <td>{row.id}</td>}
                                    {columns.sportIcon.visibility && <td className="sm-events-empty-cell center-align">
                                        <div className="cell-icon">
                                            <i className={'n-i n-i-' + (row.sportIcon ? row.sportIcon.toLowerCase() : '')}>{}</i>
                                        </div>
                                    </td>}
                                    {columns.sportName.visibility &&  <td>{row.sportName}</td>}
                                    {columns.isoCode.visibility && <td className="sm-events-empty-cell center-align">
                                        <div>
                                            <i className={'flag flag-' + row.isoCode}>{}</i>
                                        </div>
                                    </td>}
                                    {columns.categoryName.visibility && <td>{row.categoryName}</td>}
                                    {columns.name.visibility && <td className="event-name">
                                        <div className="event-name-wrap" title={row.name}>
                                            <div className="event-name-label">{ row.name }</div>
                                            <div className="total-events">{' (' + (row.events ? row.events.length : 0) + ')'}</div>
                                            <div className="top-offer">
                                                {row.hasTopOffer && <i className="top-offer-icon n-i n-i-specials"></i>}
                                            </div>
                                            <div className="distribution-statuses">
                                                {row.distributionStatuses && row.distributionStatuses.map(function (stat) {
                                                    return <i className={'distribution-status tournament-distribution-status distribution-status-' + stat}>{}</i>
                                                })}
                                            </div>
                                        </div>
                                    </td>}
                                    {columns.topOffer.visibility && <td>
                                        <div className="tournaments-top-offer-channels">
                                            <i className={"n-i n-i-shop " + (row.tournamentTopOffers[1] && row.tournamentTopOffers[1].active ? 'active' : '')}></i>
                                            <i className={"n-i n-i-terminal " + (row.tournamentTopOffers[2] && row.tournamentTopOffers[2].active ? 'active' : '')}></i>
                                            <i className={"n-i n-i-globe-a2 " + (row.tournamentTopOffers[3] && row.tournamentTopOffers[3].active ? 'active' : '')}></i>
                                            <i className={"n-i n-i-mobile " + (row.tournamentTopOffers[4] && row.tournamentTopOffers[4].active ? 'active' : '')}></i>
                                        </div>
                                    </td>}
                                    {columns.categoryId.visibility && <td>{row.categoryId}</td>}
                                    {columns.sportId.visibility && <td>{row.sportId}</td>}
                                    {columns.tournamentGroupId.visibility && <td>{row.tournamentGroupId}</td>}
                                    <td className={"td-arrow-select" + (expandedTournament - 1 === index ? ' expanded' : '')} onClick={this.handleArrowClick.bind(this, row, index)}>
                                        <i className={"n-i " + (expandedTournament - 1 === index ? ' n-i-arrow-up-a' : ' n-i-arrow-down-a')}>{}</i>
                                    </td>
                                </tr>
                            )
                        }
                    }.bind(this))
                }
                </tbody>

                <tfoot>
                <tr>
                    <td colSpan="40">
                        <Pagination
                            data                = {this.props.data.pagination}
                            updateFilters       = {this.setPagination}
                            updateLocationFilter= {this.setPagination}
                            config              = {this.tableConfig}
                        />
                    </td>
                </tr>
                </tfoot>
            </table>
        )
    }
});

function SmTournamentsHeaderCell (props) {
    if (!props
        || !((props || {}).columns)
        || !((props.columns[props.id] || {}).visibility)) return null;

    var className = props.className || '';

    function handleSort(e) {
        var direction = props.selected.direction === 'desc' ? 'asc' : 'desc';
        props.sortAction(props.id, direction);
    }

    return <th onClick={handleSort} className={className}>
        {props.titles[props.id]}
        <i className={(props.selected.sort === props.id && ('n-i react-header-sort-' + props.selected.direction))}>{}</i>
    </th>
}
;

var SmEventsSideTableBodyRows = React.createClass({


    inputChangeAction: function (value, cellKey) {
        this.props.data[cellKey] = value;
        this.props.gridMethods.inputAction(this.props.data, this.props.rowKey, this.props.saveAction);
    },

    marketOutcomesInputAction: function (value, cellKey, submit) {
        var outcome = this.props.data.marketOutcomes[cellKey];
        outcome.eventMarketOutcomeOdd = value;

        if (submit) {
            var outcomeInfo = {
                eventMarketOutcomeId: outcome.eventMarketOutcomeId,
                marketId: this.props.data.id,
                eventMarketId: this.props.data.eventMarketId,
                eventMarketOutcome: {
                    marketOutcomeId: outcome.marketOutcomeId,
                    odd: outcome.eventMarketOutcomeOdd
                }
            };

            this.props.gridMethods.marketOutcomesChange(outcomeInfo, this.props.rowKey, this.props.saveAction);
        }
    },

    handleCheckboxChange: function (value, cellKey) {
        this.props.data[cellKey] = value;
        this.props.gridMethods.inputAction(this.props.data, this.props.rowKey, this.props.saveAction);
    },

    handleSelectionChange: function (data) {
        var selection = _.clone(data);

        delete selection.selection_0;
        this.props.data.selections = selection;

        this.props.gridMethods.inputAction(this.props.data, this.props.rowKey, this.props.saveAction);
    },

    handleClick: function (evt) {
        if (!this.props.outcomesLength) return;

        this.props.updateTableHeader(this.props.data.marketOutcomes);
    },


    render: function () {

        var columns = this.props.columns,
            data    = this.props.data,
            outcomes= this.props.data.marketOutcomes,
            cells   = [],
            config  = {checkboxType: 'check'};


        for (var i in columns) {
            if (columns[i].visibility && columns[i].type !== 'hidden') {
                cells.push(<TableBodyCell
                    column                  = {columns[i]}
                    value                   = {data[i]}
                    config                  = {config}
                    type                    = {columns[i].type}
                    key                     = {this.props.rowKey + '-cell-' + i}
                    rowKey                  = {this.props.rowKey}
                    dateFormat              = {this.props.dateFormat}
                    cellKey                 = {i}

                    tableEditAction         = {this.props.tableEditAction}
                    checkboxToggleAction    = {this.handleCheckboxChange}
                    inputChangeAction       = {this.inputChangeAction}
                    handleSelectionChange   = {this.handleSelectionChange}
                />)
            }
        }

        // Load outcomes
        for (var i = 0; i < this.props.outcomesLength; i++) {
            cells.push(<TableBodyCell
                column                  = ''
                value                   = {outcomes[i] && outcomes[i].eventMarketOutcomeOdd ? outcomes[i].eventMarketOutcomeOdd : ''}
                config                  = {config}
                type                    = {outcomes[i] ? 'input-text' : 'string'}
                key                     = {'matche-bet-cell-' + i}
                rowKey                  = {this.props.rowKey}
                inputAction             = {this.marketOutcomesInputAction}
                dateFormat              = {this.props.dateFormat}
                cellKey                 = {i}
            />)
        }


        return (
            <tr className='clickable' onClick={this.handleClick} id={"prematch-row-side-id-" + this.props.rowKey}>
                {cells}
                <td></td>
            </tr>
        )
    }
});


var SmEventsSideTableHeaderRow = React.createClass({
    mixins: [LocationMixin],

    render: function () {
        var titles      = this.props.data.titles,
            columns     = this.props.columns,
            activeTab   = this.props.activeTab,
            selected    = this.props.selected,
            urlFilters  = this.props.urlFilters,
            cells       = [],
            config      = {columnSaveKey: this.getCurrentLocation() + '/' + selected + '/' + activeTab + '/side-table'};

        for (var i in columns) {
            if (columns[i].visibility && columns[i].type !== 'hidden') {
                cells.push(<TableHeaderCell
                    updateFilters           = {this.props.updateFilters}
                    updateLocationFilter    = {this.props.updateLocationFilter}

                    value                   = {titles[i]}
                    column                  = {columns[i]}
                    columnKey               = {i}
                    urlFilters              = {urlFilters}
                    key                     = {'table-header-cell-' + i}
                />);
            }
        }

        for (var i = 0; i < this.props.outcomesLength; i++) {
            cells.push(<TableHeaderCell
                updateFilters           = {this.props.updateFilters}
                updateLocationFilter    = {this.props.updateLocationFilter}

                value                   = {this.props.outcomesTitles[i]}
                column                  = {''}
                columnKey               = {i}
                urlFilters              = {urlFilters}
                key                     = {'table-header-cell-outcome' + i}
            />);
        }



        return (
            <tr className='table-header'>

                {cells}

                <th className='relative-pos column-select-header-cell'>
                    <ColumnSelectionComponent
                        data            = {titles}
                        columns         = {columns}

                        setVisibility   = {this.props.setVisibility}
                        config          = {config}
                        key             = 'column-select'
                    />
                </th>

            </tr>
        )
    }
});

var SmEventsSideTable = React.createClass({
    mixins: [LocationMixin],

    selected: null,

    outcomesTitles: [],

    saveActions: {
        'tournaments0': 'tournament-markets',
        'events0': 'event-markets',
    },

    getInitialState: function () {
        return({data: {}, titles: {}, columns: {}, activeTab: 0, reload: false});
    },

    componentDidMount: function () {
        window.addEventListener('scroll', this.handleScroll);
    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('scroll', this.handleScroll);
    },

    componentDidUpdate: function () {
        var sideTableWrap = $('#prematch-side-table');
        sideTableWrap.width(sideTableWrap.width());

        $('#p-o-side-table').tableHeadFixer();
        this.outcomesTitles = [];
    },

    setActiveTab: function (index) {
        this.setState({activeTab: index})
    },

    setTitles: function (titles) {
        this.setState({titles: titles});
    },


    setData: function (data, selected) {
        // Selected will be used to save columns to local storage using different keys
        this.selected = selected;
        this.setState({data: data});
    },

    // Get columns saved to local storage by url as object key
    getLocalStorageColumns: function getLocalStorageColumns () {
        var a = this.getCurrentLocation() + '/' + this.selected + '/' + this.state.activeTab + '/side-table';
        return JSON.parse(localStorage.getItem(a));
    },

    // Change columns visibility
    setVisibility: function setVisibility (oldColumns, state) {
        this.oldColumns = oldColumns;
        var columns = this.getLocalStorageColumns() !== null ? this.getLocalStorageColumns() : this.props.data.columns;
        this.setState({columns: columns});
    },

    handleScroll: function () {
        // Fix side table
        var sideTable = $('#prematch-side-table');
        if (window.pageYOffset > 120) {
            sideTable.addClass('p-o-sticky-table');
        } else {
            sideTable.removeClass('p-o-sticky-table');
        }
    },

    // Change table header titles by selected outcome
    updateTableHeader: function (data) {
        this.outcomesTitles = [];
        for (var i = 0; i < data.length; i++) {
            this.outcomesTitles.push(data[i].marketOutcomeName);
        }
        this.setState({reload: !this.state.reload});
    },

    displayPopupDetails: function (view) {
        this.props.gridMethods.displayPopupDetails(view);
    },

    render: function () {

        var tabName = this.state.titles.tabName || 'Tournament markets',
            headerInfo = this.state.titles.headerInfo  || {};

        if (Object.keys(this.state.data).length === 0) return (<EmptyCell />);

        var activeTab           = this.state.activeTab,
            data                = this.state.data,
            saveAction          = this.saveActions[this.selected + activeTab],
            dateFormat          = this.props.dateFormat,
            urlFilters          = this.props.urlFilters,
            gridMethods         = this.props.gridMethods,
            config              = this.props.config || {};

        var columns = this.getLocalStorageColumns() ? this.getLocalStorageColumns() : data.columns;

        var outcomes = _.clone(data.data),
            outcomesLength = 0;

        if (this.selected === 'events' && outcomes[0]) {
            outcomes.sort(function (a, b) {
                return (b.marketOutcomes.length - a.marketOutcomes.length);
            });
            outcomesLength = outcomes[0].marketOutcomes.length;
        }

        if (!data.data) {
            data.data = [];
        }

        var bodyRows = data.data.map(function (value, rowKey) {
            return (
                <SmEventsSideTableBodyRows
                    data            = {value}
                    columns         = {columns}
                    dateFormat      = {dateFormat}
                    rowKey          = {rowKey}
                    saveAction      = {saveAction}
                    gridMethods     = {gridMethods}
                    updateTableHeader= {this.updateTableHeader}
                    outcomesLength  = {outcomesLength}
                />
            );
        }.bind(this));


        return (
            <div id="prematch-side-table"  className={'details-table '  + config.class ? config.class : 'col-6'}>
                <div className="tabs-details-wrap">

                    <ul className="tabs-details">
                        <li className={activeTab === 0 ? "active" : ""} onClick={this.setActiveTab.bind(this, 0)}>
                            <span>
                                <label>{tabName}</label>
                            </span>
                        </li>
                    </ul>

                    <div className="prematch-offer-title col-12">
                        <div>
                            <span onClick={this.displayPopupDetails.bind(this, 'sport')}>{headerInfo.sportName}</span>
                            <span> | </span>
                            <span onClick={this.displayPopupDetails.bind(this, 'category')}>{headerInfo.categoryName}</span>
                            <span> - </span>
                            <span onClick={this.displayPopupDetails.bind(this, 'tournament')}>{headerInfo.tournamentName}</span>
                        </div>
                        <div className="secondary-title">{headerInfo.eventName ? (headerInfo.eventName + ' | ' + headerInfo.eventDate + ' | ' + headerInfo.eventTime) : ''}</div>
                    </div>

                    <div className='table-content prematch-offer-details-table col-12'>
                        <table className={'table table-condensed table-responsive'} id="p-o-side-table">
                            <thead>
                            <tr id='sticky-p-o-side-header-row' className='hide-sticky-header'>{}</tr>
                            <SmEventsSideTableHeaderRow
                                data={data}
                                columns={columns}
                                ref="p-o-side-header-row"
                                activeTab={activeTab}
                                selected={this.selected}
                                outcomesLength={outcomesLength}
                                outcomesTitles={this.outcomesTitles}
                                urlFilters={urlFilters}
                                setVisibility={this.setVisibility}
                                updateFilters={this.updateFilters}
                                updateLocationFilter= {this.updateFilters}/>
                            </thead>


                            <tbody id='react-table-body'>
                                {bodyRows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
});;
var SmLiabilityMarketsComponent = React.createClass({
    mixins: [TranslateMixin],
    getInitialState: function () {
        return {
            headerInfo: {},
            data: [],
            selectedMarket: 0,
            sortColumn: 'liabilityPercentage',
            reverseSort: true
        }
    },

    componentDidMount: function () {
        $('.scrollbar-inner').scrollbar();
        window.addEventListener('scroll', this.handleScroll);
    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('scroll', this.handleScroll);
    },

    componentDidUpdate: function () {
        ReactTooltip.rebuild();
        $('#sm-liability-markets-table').tableHeadFixer();
    },

    setData: function (data) {
        this.setState({
            data: data,
            sortColumn: 'liabilityPercentage',
            reverseSort: true
        });
    },

    setHeaderInfo: function (data) {
        this.setState({headerInfo: data});
    },

    handleRowClick: function (index) {
        this.setState({selectedMarket: index});
    },

    handleScroll : function () {
        var detailsTablePos = $('#sm-markets-table-wrap').offset(),
            pageOffset = Math.max(window.pageYOffset, document.documentElement.scrollTop),
            windowHeight = window.innerHeight,
            gridHeight = $("#react-table").height(),
            tableBottom = $("#react-table").offset().top + gridHeight - 75,
            detailsTableBottom =  pageOffset + 40 + $('#sm-markets-table-wrap').height();


        // calculate scroll position to start sticking the details table
        if (!this.stickyStartPosition) {
            this.stickyStartPosition = pageOffset + detailsTablePos.top - 33;
        }
        if (tableBottom > detailsTableBottom) {
            $('#sm-markets-table-wrap').css({
                top : pageOffset >= this.stickyStartPosition ? (pageOffset - this.stickyStartPosition) + 'px' : '0px'
            });
        }
    },
    setSortColumn: function(sortColumn) {
        var reverseSort = false;
        if (this.state.sortColumn === sortColumn) {
            reverseSort = !this.state.reverseSort;
        }
        this.setState({
            sortColumn: sortColumn,
            reverseSort: reverseSort
        });
    },

    render: function () {
        if (!this.state.data || !this.state.data.length) return null;

        var data = this.state.data,
            selectedMarket = this.state.selectedMarket,
            outcomeList = [];

        // Find market with max outcomes length
        var maxOutcomesLength = data.map(function (row) {
            return row.outcomes.length;
        }).sort(function (a, b) {
            return a < b ? 1 : a > b ? -1 : 0;
        })[0];

        for (var i = 0; i < maxOutcomesLength; i++) {
            outcomeList.push(i);
        }

        data = _.orderBy(
            data,
            this.state.sortColumn,
            this.state.reverseSort ? 'desc' : 'asc'
        );

        return <div className="sm-res-details-wrap" id="sm-markets-table-wrap">
            <div className="sm-res-details-header">
                <div className="sm-res-details-event">{this.state.headerInfo.event + ' | ' + this.state.headerInfo.date + ' | ' + this.state.headerInfo.time}</div>
                <div className="sm-res-details-tournament">{this.state.headerInfo.sport + ' | ' + this.state.headerInfo.category + ' | ' + this.state.headerInfo.tournament}</div>
            </div>

            <div className="sm-res-details-content scrollbar-inner" id="sm-liability-markets-table-wrap">
                <table className="table" id="sm-liability-markets-table">
                    <thead>
                        <tr>
                            <th onClick={this.setSortColumn.bind(this,'marketName')}>
                                <span>
                                    {this.translate('sportsbook.market_name')}
                                    {this.state.sortColumn === 'marketName'
                                        && <i className={this.state.reverseSort ? 'n-i n-i-arrow-down-b2' : 'n-i n-i-arrow-up-b2'}></i>}
                                </span>
                            </th>
                            <th onClick={this.setSortColumn.bind(this,'maxLiabilityNumber')}>
                                <span>
                                    {this.translate('sportsbook.max_liability')}
                                    {this.state.sortColumn === 'maxLiabilityNumber'
                                        && <i className={this.state.reverseSort ? 'n-i n-i-arrow-down-b2' : 'n-i n-i-arrow-up-b2'}></i>}
                                </span>
                            </th>
                            <th onClick={this.setSortColumn.bind(this,'currentLiabilityNumber')}>
                                <span>
                                    {this.translate('sportsbook.current_liability')}
                                    {this.state.sortColumn === 'currentLiabilityNumber'
                                        && <i className={this.state.reverseSort ? 'n-i n-i-arrow-down-b2' : 'n-i n-i-arrow-up-b2'}></i>}
                                </span>
                            </th>
                            <th onClick={this.setSortColumn.bind(this,'liabilityPercentage')}>
                                <span>
                                    %
                                    {this.state.sortColumn === 'liabilityPercentage'
                                        && <i className={this.state.reverseSort ? 'n-i n-i-arrow-down-b2' : 'n-i n-i-arrow-up-b2'}></i>}
                                </span>
                            </th>
                            {
                                outcomeList.map(function (index) {
                                    return(<th>
                                        {data[selectedMarket] && data[selectedMarket].outcomes && data[selectedMarket].outcomes[index] ? data[selectedMarket].outcomes[index].marketName : ''}
                                    </th>)
                                })
                            }
                        </tr>
                    </thead>

                    <tbody>
                    {
                        data && data.map(function (market, index) {
                            var tooltipData = market.outcomes.map(function (outcome) {
                                return ("<div class='sm-liability-tooltip-row'><div class='sm-liability-tooltip-name'><span>"
                                + outcome.marketName + "</span></div><div class='sm-liability-tooltip-value'><span>" + outcome.outcomeLiability + "</span></div></div>");
                            }).join(' ');

                            return <tr onClick={this.handleRowClick.bind(this, index)} className={this.state.selectedMarket === index && 'selected'}>
                                <td>
                                    {market.marketName}
                                    {market.competitorInfo && <span> - {market.competitorInfo} - </span>}
                                    {market.specialValues.length>0 &&
                                            ' (' + market.specialValues.map(function(sv, index){
                                                return (index+1 < market.specialValues.length) ? sv + ' / ' : sv;
                                            }).join('') + ') '
                                    }
                                </td>
                                <td>{market.maxLiability}</td>
                                <td data-tip={tooltipData}>
                                    {market.currentLiability}
                                    <ReactTooltip className="sm-liability-tooltip-content" html />
                                </td>
                                <td>{market.liabilityPercentage}</td>
                                {
                                    outcomeList.map(function (outcomeIndex) {
                                        return (<td>
                                            {data[index] && data[index].outcomes && data[index].outcomes[outcomeIndex] ? data[index].outcomes[outcomeIndex].eventMarketOutcomeOdd : ''}
                                        </td>)
                                    })
                                }
                            </tr>
                        }.bind(this))
                    }
                    </tbody>

                </table>
            </div>
        </div>
    }
});
;
var SmMarketGroupDetails = React.createClass({

    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
            data: null
        }
    },

    componentDidMount: function () {
        $('#sm-market-group-side').height(window.innerHeight - 300);
    },

    componentWillUnmount: function () {

    },

    setData: function (data) {
        this.setState({
            data: data
        })
    },

    render: function () {
        if (!this.state.data) return <div id='sm-market-group-side' className='sm-market-group-side'>
            <div className='sm-market-group-side-header'>{this.translate('sportsbook.select_a_market_group')}</div>
        </div>;

        var data = this.state.data;

        return <div id='#sm-market-group-side' className='sm-market-group-side'>
            <div className='sm-market-group-side-header'>{this.translate('sportsbook.combination')}: {this.translate('sportsbook.markets')}</div>
            <div className='sm-market-group-side-content'>
                <div className='sm-group-content'>
                    {data.markets.map(function(market){
                        return  <div className='sm-group-row'>
                                    {market.name}
                                </div>
                    }.bind(this))}
                </div>               
            </div>
        </div>
    }
});;
var SmMarketTemplates = React.createClass({
    marketTimeout: null,
    tournamentTimeout: null,
    selectedCategories: {},

    getInitialState: function () {
        return {
            data: null,
            displayCategories: false,
            marketList: [],
            tournamentList: [],
            filteredMarketList: [],
            filteredTournamentList: [],
            categories: []
        }
    },

    componentDidMount: function () {
        $('#sm-market-templates-side').height(window.innerHeight - 300);
    },

    componentWillMount: function () {
        window.addEventListener('click', this.handleGlobalClick);
    },

    componentWillUnmount: function () {
        clearTimeout(this.marketTimeout);
        clearTimeout(this.tournamentTimeout);
        window.removeEventListener('click', this.handleGlobalClick);
    },

    handleGlobalClick: function () {
        this.setState({
            displayCategories: false
        })
    },

    setData: function (data, resetSelectedCategories) {
        if (resetSelectedCategories) {
            this.selectedCategories = {};
        }
        this.setState({
            data: data,
            categories: data.categories,
            marketList: data.markets || [],
            tournamentList: data.tournaments || [],
            filteredMarketList: this.initFilteredMarkets(data.markets),
            filteredTournamentList: this.initFilteredTournaments(data.tournaments)
        })
    },

    initFilteredTournaments: function (tournaments) {
        var data = tournaments ? tournaments : this.state.tournamentList,
            value = $('#smt-tournament-search').val() || '',
            filteredByInput = data;

        if (!data) return [];
        if (value) {
            filteredByInput = data.filter(function (item) {
                return (item.name.toLowerCase().search(
                    value.toLowerCase()) !== -1)
            }.bind(this));
        }

       if (Object.keys(this.selectedCategories).length === 0) return filteredByInput;

       return filteredByInput.filter(function (item) {
           return this.selectedCategories[item.categoryId];
       }.bind(this))
    },

    initFilteredMarkets: function (markets) {
        var data = markets ? markets : this.state.marketList,
            value = $('#smt-market-search').val() || '';

        if (!data) return [];
        return data.filter(function(item){
            return item.marketName.toLowerCase().search(
                value.toLowerCase()) !== -1;
        });
    },

    filterMarkets: function (evt) {
        clearTimeout(this.marketTimeout);
        var value = evt.target.value,
            updatedList = this.state.marketList;

        this.marketTimeout = setTimeout(function () {
            if (value) {
                updatedList = updatedList.filter(function(item){
                    return item.marketName.toLowerCase().search(
                        value.toLowerCase()) !== -1;
                });
            }
            this.setState({filteredMarketList: updatedList});
        }.bind(this), 350);
    },

    filterTournaments: function (evt) {
        clearTimeout(this.tournamentTimeout);
        var value = evt.target.value,
            updatedList = this.state.tournamentList;

        this.tournamentTimeout = setTimeout(function () {
            if (value) {
                updatedList = updatedList.filter(function(item){
                    return item.name.toLowerCase().search(
                        value.toLowerCase()) !== -1;
                });
            }
            if (Object.keys(this.selectedCategories).length !== 0) {
                updatedList = updatedList.filter(function (item) {
                    return this.selectedCategories[item.categoryId];
                }.bind(this))
            }

            this.setState({filteredTournamentList: updatedList});
        }.bind(this), 350);
    },

    handleCategoryChange: function (data) {
        if (data.value) {
            this.selectedCategories[data.id] = data.value;
        } else {
            delete this.selectedCategories[data.id];
        }

        clearTimeout(this.tournamentTimeout);
        this.tournamentTimeout = setTimeout(function () {
            this.setState({
                filteredTournamentList: this.initFilteredTournaments()
            });
        }.bind(this), 500);
    },

    displayCategories: function (evt) {
        this.setState({
            displayCategories: true
        })
    },

    stopPropagation: function (evt) {
        evt.stopPropagation();
    },

    render: function () {
        if (!this.state.data) return <div id='sm-market-templates-side' className='sm-market-templates-side'>
            <div className='sm-market-template-side-header'>Select market template</div>
        </div>;

        var data = this.state.data,
            list = this.state.filteredTournamentList,
            changeActiveState = this.props.changeActiveState;

        function rowRenderer ({
                                  key,         // Unique key within array of rows
                                  index,       // Index of row within collection
                                  isScrolling, // The List is currently being scrolled
                                  isVisible,   // This row is visible within the List (eg it is not an overscanned row)
                                  style        // Style object to be applied to row (to position it)
                              }) {
            return (
                <div
                    key={key}
                    style={style}
                >
                    <SmMarketTemplateCheckbox
                        name={list[index].name}
                        id={list[index].tournamentId}
                        value={list[index].selected}
                        tournamentGroupId={list[index].tournamentGroupId}
                        table={'tournaments'}
                        changeActiveState={changeActiveState}
                        alreadyAssigned={list[index].assignedDifferentMarketTemplate}
                        assignedTemplateName={list[index].assignedMarketTemplateName}
                    />
                </div>
            )
        }
        return <div id='#sm-market-templates-side' className='sm-market-templates-side'>
            <div className='sm-market-template-side-header'>{data.name}</div>
            <div className='sm-market-template-side-content'>
                <div className='sm-market-templates-markets'>
                    <div className='smt-header'>Markets</div>
                    <div className='smt-search'>
                        <input
                            onChange={this.filterMarkets}
                            id='smt-market-search'
                            type="text"/>
                    </div>
                    <div className='smt-content'>
                        {this.state.filteredMarketList.map(function(market) {
                            return <SmMarketTemplateCheckbox
                                name={market.marketName}
                                id={market.marketId}
                                value={market.selected}
                                table={'markets'}
                                changeActiveState={this.props.changeActiveState}
                            />
                        }.bind(this))}
                    </div>
                </div>

                <div className='sm-market-templates-tournaments'>
                    <div className='smt-header'>Tournaments</div>
                    <div className='smt-search'>
                        <div className='smt-search-input'>
                            <input
                                onChange={this.filterTournaments}
                                id='smt-tournament-search'
                                type="text"/>
                        </div>

                        <div
                             className='smt-categories'
                             onClick={this.stopPropagation}>
                            <div
                                className='smt-categories-header'
                                onClick={this.displayCategories}>
                                Category
                                <i className='n-i n-i-arrow-down-b'> </i>
                            </div>
                            {this.state.displayCategories && <div className='smt-categories-content'>
                                {this.state.categories.map(function (category) {
                                    return <SmMarketTemplateCategoryCheckbox
                                        id={category.id}
                                        name={category.name}
                                        value={this.selectedCategories[category.id]}
                                        handleChange={this.handleCategoryChange}
                                    />
                                }.bind(this))}
                            </div>}

                        </div>
                    </div>
                    <div className='smt-content'>
                        <ReactVirtualized.List
                            width={window.innerWidth/4 - 20}
                            height={window.innerHeight - 385}
                            rowCount={this.state.filteredTournamentList.length}
                            rowHeight={30}
                            rowRenderer={rowRenderer}
                        />
                    </div>
                </div>
            </div>
        </div>
    }
});

var SmMarketTemplateCategoryCheckbox = React.createClass({
    getInitialState: function () {
        return {
            value: this.props.value
        }
    },
    componentWillReceiveProps: function (newProps) {
        this.setState({
            value: newProps.value
        })
    },
    handleChange: function (e) {
        e.stopPropagation();
        this.setState({
            value: e.target.checked
        });
        var data = {
            id: this.props.id,
            value: e.target.checked
        };
        this.props.handleChange(data);
    },
    render: function () {
        return <div>
            <input
                id={'smt-category-' + this.props.id}
                className='styled-checkbox'
                onChange={this.handleChange}
                checked={this.state.value}
                type="checkbox"/>
            <label htmlFor={'smt-category-' + this.props.id}>{this.props.name}</label>
        </div>
    }
});

var SmMarketTemplateCheckbox = React.createClass({
    getInitialState: function () {
        return {
            value: this.props.value
        }
    },
    componentWillReceiveProps: function (newProps) {
        this.setState({
            value: newProps.value
        })
    },
    handleChange: function (e) {
        this.setState({
            value: e.target.checked
        });
        var data = {
            id: this.props.id,
            table: this.props.table,
            tournamentGroupId: this.props.tournamentGroupId,
            active: e.target.checked
        };
        this.props.changeActiveState(data);
    },
    render: function () {
        var id = 'sm-t-' + this.props.table + this.props.id + (this.props.tournamentGroupId);
        return <div className='smt-row'>
            <input
                id={id}
                className='styled-checkbox'
                onChange={this.handleChange}
                checked={this.state.value}
                type="checkbox"/>
            <label htmlFor={id}>{this.props.name}</label>
            {this.props.alreadyAssigned &&
                <span className="n-i n-i-table assigned-template-indicator">
                    <div className="market-template-custom-tooltip">
                        <span>{this.props.assignedTemplateName}</span>
                    </div>
                </span>}
        </div>
    }
});;
var OddsSchemeSideTable = React.createClass({
    mixins: [TranslateMixin],

    getInitialState: function () {
        return({sportList: this.props.sportList || [],
                sportDetails: this.props.sportDetails || {},
                columns: {},
                activeTab: 0,
                searchSports: '',
                reload: false});
    },

    componentDidMount: function () {
        $('.scrollbar-inner').scrollbar();
        this.debouncedActivation = _.debounce(this.setActiveTab, 250);
    },

    setActiveTab: function (index, idSport) {
        this.props.getSchemeSportDetails(index,idSport);
    },

    updateState : function (index, sportDetails) {
        this.setState({activeTab: index, sportDetails:sportDetails});
    },

    handleInputChange: function (event) {
        this.setState({searchSports: event.target.value},
            function() {
                var sport = this.state.sportList.find(function(sport, index){
                    return sport.name.toUpperCase().includes(this.state.searchSports.toUpperCase());
                }.bind(this));
                if(sport){
                    this.debouncedActivation(0, sport.id);
                } else {
                    // empty side table when sport is not found
                    this.setState({sportDetails: {}});
                }
            });
    },

    componentWillReceiveProps: function (nextProps) {
        this.setState({activeTab: 0, sportDetails:nextProps.sportDetails});
    },

    render: function () {

        var sports = this.state.sportList.filter(function(sport){
            return sport.name.toUpperCase().includes(this.state.searchSports.toUpperCase());
        }.bind(this));

        var tabs = sports.map(function (sport, rowKey) {
            return (
                <tr key={sport.id} onClick={this.setActiveTab.bind(this, rowKey, sport.id)}>
                    <td className={this.state.activeTab === rowKey ? 'clickable no-border scheme-active-tab' : 'no-border clickable'}>{sport.name}</td>
                </tr>
            )
        }.bind(this));

        if (Object.keys(this.state.sportList).length === 0) return (<EmptyCell />);

        var activeTab           = this.state.activeTab,
            data                = this.state.sportList[activeTab];

        if (!data.data) {
            data.data = [];
        }


        var bodyRows = '';
        if(!_.isEmpty(this.state.sportDetails)){
            bodyRows = this.state.sportDetails.schemeTypeBets.map(function (type, rowKey) {
                var cells = [],
                    betFound = {};
                type.outcomes.map(function (outcome) {
                    for(var i=0;i<this.state.sportDetails.sportBets.length;i++){
                        betFound = this.state.sportDetails.sportBets[i].outcomes.filter(function (betOutcome) {
                            return betOutcome.schemeTypeBetOutcomeId === outcome.id;
                        })[0];
                        if(betFound && betFound.name) break;
                    }
                    var iconClass = type.referentBetOutcomeIds.indexOf(outcome.id)>-1 ? 'n-i n-i-check table-icon-true' : ' n-i n-i-close table-icon-false';
                    cells.push(
                        <tr  key={outcome.id}>
                            <td>{outcome.name}</td>
                            <td>{betFound ? betFound.name : ''}</td>
                            <td>
                                <i className={iconClass}>{}</i>
                            </td>
                        </tr>
                    )
                }.bind(this));
                return (
                <tbody id='react-table-body'>
                    <tr>
                        <td colSpan="3" className="bold-text">{type.name}</td>
                    </tr>
                    {cells}
                </tbody>
                );
            }.bind(this));
        }

        return (
            <div className="col-7 scheme-details-table">

                <div className="col-12 scheme-header">
                    <div className="r-s-header">{this.props.headerInfo}</div>
                </div>

                    <div className="col-3 scheme-side-list">
                        <div className='table-content scheme-details side-table-list scrollbar-inner'>
                            <table className='table table-condensed table-responsive side-list-margin'>
                                <thead>
                                <tr className=''>
                                    <td className='clickable scheme-side-list-header no-border'>
                                        {this.translate('general.sport')}
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="search-cell">
                                        <input className="col-12 no-border" value={this.state.searchSports} onChange={this.handleInputChange} type="text" placeholder={this.translate('general.search')}/>
                                    </td>
                                </tr>
                                {tabs}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className='col-9 table-content prematch-offer-details-table scheme-side-list-details'>
                        <table className='table table-condensed table-responsive'>
                            <thead>
                                <tr className='hide-sticky-header'>{}</tr>
                                <tr className='table-header'>

                                    <th className='clickable'>
                                        {this.translate('sportsbook.scheme')}
                                    </th>
                                    <th className='clickable'>
                                        {this.translate('sportsbook.core')}
                                    </th>
                                    <th className='clickable scheme-side-list-ref-bets'>
                                        {this.translate('sportsbook.referent_bets')}
                                    </th>
                                </tr>
                            </thead>
                            {bodyRows}
                        </table>
                    </div>
            </div>
        );

    }
});;
var SportAssignSideTable = React.createClass({
    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
            tournaments: this.props.tournaments || [],
            activeTourIndex: 0,
            activeTournament: this.props.tournaments[0] || {},
            headerInfo: this.props.headerInfo,
            searchTournaments: '',
            searchSchemes: '',
            schemes: this.props.schemes || []
        }
    },

    componentDidMount: function () {
        $('.scrollbar-inner').scrollbar();
    },

    componentWillReceiveProps: function (nextProps) {
        this.setState({
            tournaments: nextProps.tournaments,
            headerInfo: nextProps.headerInfo,
            activeTourIndex: 0,
            activeTournament: nextProps.tournaments[0]
        });
    },

    handleSchemeInputChange: function (event) {
      this.setState({searchSchemes: event.target.value});
    },

    handleInputChange: function (event) {
      this.props.loadCategoryTournamentsFilter(event.target.value);
      this.setState({searchTournaments: event.target.value});
    },

    filterTournaments: function (tournaments) {
        this.setState({
            tournaments: tournaments,
            activeTourIndex: 0,
            activeTournament: tournaments[0]
        });
    },

    setActiveTour: function (index, tournament) {
        this.setState({activeTourIndex: index, activeTournament: tournament, searchSchemes: ''});
    },

    updateCheckValue: function (scheme) {
        var activeTour = this.state.activeTournament;
        scheme.isChecked =! scheme.isChecked;
        if(scheme.isChecked) {
            activeTour.schemes.push({schemeId: scheme.id, schemeName: scheme.name})
        }
        else {
            activeTour.schemes.forEach(function (tourScheme, index) {
                if(scheme.id === tourScheme.schemeId){
                    activeTour.schemes.splice(index,1);
                }
            });
        }

        this.props.updateTournamentScheme(activeTour).then(function (response) {
            this.setState({activeTournament: activeTour});
        }.bind(this), function (error) {
            activeTour.schemes.forEach(function (tourScheme, index) {
                if(scheme.id === tourScheme.schemeId){
                    activeTour.schemes.splice(index,1);
                }
            });
            this.setState({activeTournament: activeTour});
        }.bind(this));
    },

    render: function () {
        var schemes = this.state.schemes.filter(function(scheme){
            return scheme.name.toUpperCase().includes(this.state.searchSchemes.toUpperCase());
        }.bind(this));

        var tabs = this.state.tournaments.map(function (tour, rowKey) {
            return (
                <tr key={tour.tournamentId} onClick={this.setActiveTour.bind(this, rowKey, tour)}>
                    <td className={this.state.activeTourIndex === rowKey ? 'clickable no-border scheme-active-tab' : 'no-border clickable'}>
                        ({tour.categoryName}) {tour.tournamentName}
                    </td>
                </tr>
            )
        }.bind(this));

        return (
            <div className="col-7 scheme-details-table">
                <div className="col-12 scheme-header">
                    <div className="r-s-header">{this.state.headerInfo}</div>
                </div>

                <div className="col-4 scheme-side-list">
                    <div className='table-content scheme-details side-table-list scrollbar-inner'>
                        <table className='table table-condensed table-responsive side-list-margin'>
                            <thead>
                                <tr>
                                    <td className='clickable scheme-side-list-header no-border'>
                                        {this.translate('product.tournaments')}
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="search-cell">
                                        <input className="col-12 no-border" value={this.state.searchTournaments} onChange={this.handleInputChange} type="text" placeholder={this.translate('sportsbook.search_tournament_category')}/>
                                    </td>
                                </tr>
                                {this.state.tournaments.length === 0 &&
                                <tr>
                                    <td className='no-border'>There are no tournaments for {this.state.headerInfo}</td>
                                </tr>
                                }
                                {tabs}
                            </tbody>
                        </table>
                    </div>
                </div>
                {this.state.tournaments.length > 0 &&
                <div className="col-8 scheme-side-list-details">
                    <div className='table-content scheme-details side-table-list'>
                        <table className='table table-condensed table-responsive scheme-assign-table'>
                            <thead>
                            <tr className='table-header'>
                                <th className='clickable'>
                                    {this.translate('sportsbook.scheme')}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="search-cell">
                                    <input className="col-12 no-border" value={this.state.searchSchemes} onChange={this.handleSchemeInputChange} type="text" placeholder={this.translate('sportsbook.search_schemes')}/>
                                </td>
                            </tr>
                            { schemes.map(function (scheme, i) {
                                if (this.state.activeTournament.schemes) {
                                    scheme.isChecked = false;
                                    this.state.activeTournament.schemes.forEach(function (tourScheme) {
                                        if (scheme.id === tourScheme.schemeId) scheme.isChecked = true;
                                    });
                                }

                                return (
                                    <tr key={scheme.id}>
                                        <td className="clickable scheme-checkbox">{scheme.name}
                                            <label className="pull-right bool-value">
                                                <label className="checkbox-toggle-btn">
                                                    <input type="checkbox"
                                                           onChange={this.updateCheckValue.bind(null, scheme)}
                                                           value={scheme.isChecked} checked={scheme.isChecked}/><i></i>
                                                </label>
                                            </label>
                                        </td>
                                    </tr>
                                )
                            }.bind(this))
                            }

                            </tbody>
                        </table>
                    </div>
                </div>
                }
            </div>
        )
    }
});;
var TournamentAssignSideTable = React.createClass({
    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
            activeTourIndex: 0,
            activeTournament: this.props.tournament || {},
            headerInfo: this.props.headerInfo,
            searchSchemes: '',
            schemes: this.props.schemes || []
        }
    },

    componentWillReceiveProps: function (nextProps) {
        this.setState({
            headerInfo: nextProps.headerInfo,
            activeTourIndex: 0,
            activeTournament: nextProps.tournament
        });
    },

    handleSchemeInputChange: function (event) {
        this.setState({searchSchemes: event.target.value});
    },

    setActiveTour: function (index, tournament) {
        this.setState({activeTourIndex: index, activeTournament: tournament, searchSchemes: ''});
    },

    updateCheckValue: function (scheme) {
        var activeTour = this.state.activeTournament;
        scheme.isChecked =! scheme.isChecked;
        if(scheme.isChecked) {
            activeTour.schemes.push({schemeId: scheme.id, schemeName: scheme.name})
        }
        else {
            activeTour.schemes.forEach(function (tourScheme, index) {
                if(scheme.id === tourScheme.schemeId){
                    activeTour.schemes.splice(index,1);
                }
            });
        }
        this.props.updateTournamentScheme(activeTour).then(function (response) {
            this.setState({activeTournament: activeTour});
        }.bind(this), function (error) {
            activeTour.schemes.forEach(function (tourScheme, index) {
                if(scheme.id === tourScheme.schemeId){
                    activeTour.schemes.splice(index,1);
                }
            });
            this.setState({activeTournament: activeTour});
        }.bind(this));
    },

    render: function () {
        var schemes = this.state.schemes.filter(function(scheme){
            return scheme.name.toUpperCase().includes(this.state.searchSchemes.toUpperCase());
        }.bind(this));

        return (
            <div className="col-5 scheme-details-table">
                <div className="col-12 scheme-header">
                    <div className="r-s-header">{this.state.headerInfo.sportName} | {this.state.headerInfo.categoryName} | {this.state.headerInfo.tournamentName}</div>
                </div>


                <div className="col-12 padding-10-0">
                    <div className='table-content scheme-details side-table-list'>
                        <table className='table table-condensed table-responsive scheme-assign-table'>
                            <thead>
                            <tr className='table-header'>
                                <th className='clickable'>
                                    {this.translate('sportsbook.schemes')}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="search-cell">
                                    <input className="col-12 no-border" value={this.state.searchSchemes} onChange={this.handleSchemeInputChange} type="text" placeholder={this.translate('sportsbook.search_schemes')}/>
                                </td>
                            </tr>
                            { schemes.map(function (scheme, i) {
                                if (this.state.activeTournament.schemes) {
                                    scheme.isChecked = false;
                                    this.state.activeTournament.schemes.forEach(function (tourScheme) {
                                        if (scheme.id === tourScheme.schemeId) scheme.isChecked = true;
                                    });
                                }

                                return (
                                    <tr key={scheme.id}>
                                        <td className="clickable scheme-checkbox">{scheme.name}
                                            <label className="pull-right bool-value">
                                                <label className="checkbox-toggle-btn">
                                                    <input type="checkbox"
                                                           onChange={this.updateCheckValue.bind(null, scheme)}
                                                           value={scheme.isChecked} checked={scheme.isChecked}/><i></i>
                                                </label>
                                            </label>
                                        </td>
                                    </tr>
                                )
                            }.bind(this))
                            }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
});;
var SmOddsSchemeFilters = React.createClass({
    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
            data: null,
            displayedFilter: '',
            searchValue:'',
            checkedAll:false
        }
    },

    setData: function (data) {
        this.setState({
            data: data
        })
    },

    // Hide all filters
    handleGlobalClick: function handleGlobalClick (e) {
        this.setState({displayedFilter: '', searchValue:''});
    },

    closeFilter: function () {
        this.setState({displayedFilter: '', searchValue:''})
    },

    componentWillMount: function () {
        window.addEventListener('click', this.handleGlobalClick);
    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('click', this.handleGlobalClick);
    },

    handleClick: function (key, e) {
        e.stopPropagation();
        if(this.state.displayedFilter!=key){
            this.setState({searchValue: ''});
        }
        this.setState({displayedFilter: key, checkedAll:false});
    },

    handleChange: function (filterKey, filterValue) {
        this.props.handleFilterChange(filterKey, filterValue.id);
    },

    handleClearAll: function(filterKey, filterValue){
        this.setState({checkedAll:false});
        this.props.handleFilterClearAll(filterKey);
    },

    setSearchVal: function(event){
        this.setState({searchValue:event.target.value});
    },

    selectAll: function( key, data, ct){
        var optionValues = data.map(function(option){
            return option.id;
        });
        this.props.selectAllFilter(key, optionValues);
    },

    stopProp: function (e) {
        e.stopPropagation();
    },

    render: function () {
        if (!this.state.data) return null;
        var data = this.state.data.data,
            names = this.state.data.filterNames,
            selectedFilters = this.props.selectedFilters || {},
            filterKeys = Object.keys(this.state.data.data),
            apiKeys = this.state.data.keys,
            selectedFilterFormatted = {};

        for (var i in selectedFilters) {
            if (selectedFilters.hasOwnProperty(i) && selectedFilters[i] ) {
                selectedFilterFormatted[i] = String(selectedFilters[i]).split(',')
            }
        }

        return (
            <div className="sm-filters-wrap col-12">
                {
                filterKeys.map(function(key) {
                     return (
                         <div className="sm-filters-name" onClick={this.handleClick.bind(this, key)}>
                             <div className={"sm-filter-name-text " + (selectedFilters[apiKeys[key]] && "active-filter")}>{names[key]}</div>
                             <i className="n-i n-i-arrow-down-b sm-filters-arrow">{}</i>
                             {
                                 this.state.displayedFilter === key && (
                                    <div className="sm-filters-content sm-filters-content-wrap">
                                        {
                                            data[key].length>2 && (
                                                    <div className="filter-options">
                                                        {function() {
                                                            if (data[key].length >= 10)
                                                            return <div className="filter-option" >
                                                                <input
                                                                    name            = {'filter-search'}
                                                                    type            = {'text'}
                                                                    placeholder     = {this.translate('general.search_filters')}
                                                                    value           = {this.state.searchValue}
                                                                    onChange        = {this.setSearchVal}
                                                                    className       = {'react-filter-search'}
                                                                    autoFocus       = "autoFocus"
                                                                />
                                                            </div>
                                                        }.bind(this)()}
                                                        {
                                                            <div className="filter-option">
                                                                <span onClick={this.selectAll.bind(this, key, data[key] )}>{this.translate('general.select_all')}</span>
                                                                <span onClick={this.handleClearAll.bind(this, key, data[key])}>{this.translate('general.clear')}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                        }
                                        <div className="sm-filters-content-body">
                                        {
                                            data[key].length == 0 && <div>
                                                <label className="sm-filter-no-data">{this.translate('general.no_data_for_filters')}</label>
                                            </div>
                                        }
                                        {
                                            data[key].map(function (filter, index) {
                                                if(filter.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase())>-1){
                                                    return (<div>
                                                        <label htmlFor={"filter-" + key + index} className={key!='language' ? "checkbox": "single-select-radio" }>
                                                            <input type="checkbox"
                                                                    id={"filter-" + key + index}
                                                                    className="normal-checkbox"
                                                                    checked={selectedFilterFormatted[apiKeys[key]] && selectedFilterFormatted[apiKeys[key]].indexOf(String(filter.id)) !== -1 || this.state.checkedAll}
                                                                    onChange={this.handleChange.bind(this, key, filter)}/>
                                                            <i></i>
                                                        </label>
                                                        <label className="sm-filter-value-label" htmlFor={"filter-" + key + index}>{filter.name}</label>
                                                    </div>)
                                                }
                                            }.bind(this))
                                        }
                                        </div>
                                    </div>
                                 )
                             }
                        </div>
                     )
                }.bind(this))
                }
            </div>
        );
    }
});;
var SmWinningCorrelationsComponent = React.createClass({
    propTypes: {
        check: React.PropTypes.func,
    },

    getInitialState: function () {
        return {
            data: [],
            searchQuery: '',
        }
    },

    check: function(outcome) {
        this.props.check(outcome);
    },

    setData: function (data) {
        this.setState({data: data});
    },

    setSearchQuery: function (query) {
        this.setState({searchQuery: query});
    },

    render: function () {
        if (!this.state.data || !this.state.data.length) return null;
        var data = this.state.data;
        var searchQuery = this.state.searchQuery;
        var filteredData = data;
        if (searchQuery && searchQuery.length>0) {
            filteredData = data.filter(market=> {
                return market.name.toLowerCase().indexOf(searchQuery.toLowerCase())!== -1 
                    || market.outcomes.some( outcome => {
                        return outcome.name
                                .toLowerCase()
                                .indexOf(searchQuery.toLowerCase())!== -1;
                });
            });
        }

        return (<div>
            {filteredData.map(function (market) {
                return <div>
                    <div className='winning-correlations-row row-market'>{market.name}</div>

                    {market.outcomes.map(function (outcome) {
                        return <div className='winning-correlations-row row-outcome'>
                            <input type="checkbox"
                                   className="styled-checkbox"
                                   defaultChecked={outcome.winningCorrelation.checked}
                                   onChange={this.check.bind(this, outcome)}
                                   id={ 'outcome-' + outcome.id }/>
                            <label htmlFor={'outcome-' + outcome.id}>{outcome.name}</label>
                        </div>
                    }.bind(this))}
                </div>
            }.bind(this))}
        </div>);
    }
});;
var ResultsExpandTable = React.createClass({
    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
            resultTypes : this.props.resultTypes || [],
            focusEnabled: true
        }
    },

    handleChange: function (type, index, e) {
        var resultTypes = this.state.resultTypes;
        resultTypes.forEach(function (resType) {
            if(resType.id === type.id){
                resType.resultInputs[index].value = e.target.value;
                if(type.value_type === 'ARRAY'){
                    if(!resType.resultInputs.find(function (res) {
                            return res.value==='';
                        })){
                        resType.resultInputs.push({value:''});
                    }
                    if(!e.target.value){
                        resType.resultInputs.splice(index,1);
                    }
                }
            }
        });
        this.setState({resultTypes: resultTypes});
    },

    componentWillMount: function () {
        this.handleResize();
    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('keydown', this.handleKeyboardInput);
        window.addEventListener('resize', this.handleResize);
    },

    componentDidMount: function () {
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('keydown', this.handleKeyboardInput);

        var resultsWrap = document.getElementById('results-grid-expanded');
        var firstInput = resultsWrap != null && _.first(resultsWrap.getElementsByTagName('input'));

        if(firstInput){
            firstInput.focus();
            firstInput.select();
        }
    },

    handleResize: function () {
        var targetElement = document.getElementById('main-table-row-' + this.props.rowKey),
            reactElement = document.getElementById('sm-results-expanded'),
            topDistance = targetElement !=  null && targetElement.classList.contains('first') ? 48 : 38;

        if(reactElement && targetElement) {
            reactElement.style.position = 'absolute';
            reactElement.style.top =  topDistance + targetElement.offsetTop + 'px';
            reactElement.style.width = targetElement.offsetWidth + 'px';
        }
    },

    handleIconClick: function () {
        this.props.expandRow(this.props.rowData, this.props.rowKey);
    },

    handleButtonClick: function () {
        var resultTypes = _.clone(this.state.resultTypes);
        resultTypes.forEach(function (resType) {
            switch (resType.value_type){
                case 'SCORE':
                    resType.value = resType.resultInputs[0].value + ':' + resType.resultInputs[1].value;
                    break;
                case 'ARRAY':
                    resType.value = resType.resultInputs.map(function (result) {
                        return result.value;
                    }).join(',');
                    if(resType.value && resType.value.charAt(resType.value.length - 1) === ',') {
                        resType.value = resType.value.substring(0, resType.value.length - 1);
                    }
                break;
                case 'VALUE':
                    resType.value = resType.resultInputs[0].value;
            }
        });
        var rowData = this.props.rowData;
        this.props.updateRowResults(rowData.eventId, rowData.eventName, this.props.rowKey, resultTypes);
        this.props.expandRow(this.props.rowData, this.props.rowKey);
    },

    setFocusValue: function (focusEnabled) {
        this.setState({
            focusEnabled: focusEnabled
        });
    },

    handleInputClick: function () {
        if (this.state.focusEnabled) return;
        this.setState({
            focusEnabled: true
        });
    },

    handleKeyboardInput: function (event) {
        if (!this.state.focusEnabled) return;

        event.stopPropagation();
        var inputList = '',
            inputListArray = [],
            applyButton = '';

        switch(event.keyCode){
            case 13:
                var focusedInputs = 0;
                inputList = document.getElementById('results-grid-expanded').getElementsByTagName('input');
                inputListArray = Array.prototype.slice.call(inputList);

                for(var i=0; i<inputListArray.length; i++){
                    if($(inputListArray[i]).is(':focus')) {
                        focusedInputs ++;
                        if(inputListArray[i+1]) {
                            inputList[i+1].focus();
                            inputList[i+1].select();
                            break;
                        }
                        else {
                            inputListArray[i].blur();
                            this.handleButtonClick();
                        }
                    }
                }

                if(focusedInputs === 0) {
                    inputListArray[0].focus();
                    inputListArray[0].select();
                }
                break;
            case 27:
                this.props.expandRow(this.props.rowData, this.props.rowKey);
                window.removeEventListener('keydown', this.handleKeyboardInput);
                break;
            case 107:
            case 187:
                event.preventDefault();
                this.handleButtonClick();
                break;
        }


    },

    render: function render() {

        return (
            <div className='grid-results-expand-wrapper' id="results-grid-expanded">
                <div className='table-content'>
                    <table className='table table-condensed table-responsive'>
                        <thead>
                        <tr>
                            <td className='expanded-td-element' colSpan={2}>
                                <div className="results-expanded-header col-12">
                                    <span className="">
                                        <i className='n-i n-i-arrow-up-a results-expanded-icon clickable' onClick={this.handleIconClick}>{}</i>
                                    </span>
                                    <span className="results-expanded-event">
                                        <span>{this.props.rowData.eventName}</span>
                                    </span>
                                    <span className="results-expanded-btn">
                                        <button className="btn" id="results-expanded-button" onClick={this.handleButtonClick}>{this.translate('general.apply')}</button>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.resultTypes.map(function (type) {
                            switch(type.value_type){
                                case 'SCORE':
                                    if(!type.value && !type.resultInputs){
                                        type.resultInputs = [{value:''}, {value:''}];
                                    }
                                    if(!type.resultInputs && type.value.split(':').length){
                                        type.resultInputs = [];
                                        type.resultInputs.push({value:type.value.split(':')[0]});
                                        type.resultInputs.push({value:type.value.split(':')[1]});
                                    }
                                    break;
                                case 'ARRAY':
                                    if(!type.value && !type.resultInputs){
                                        type.resultInputs = [{value:''}];
                                    }
                                    if(!type.resultInputs && type.value.split(',').length){
                                        type.resultInputs = [];
                                        var resultsSplitArray = type.value.split(',');
                                        resultsSplitArray.forEach(function (result){
                                            type.resultInputs.push({value:result});
                                        });
                                        if(!type.resultInputs.find(function (res) {
                                                return res.value==='';
                                            })){
                                            type.resultInputs.push({value:''});
                                        }
                                    }
                                    break;
                                case 'VALUE':
                                    if(!type.value && !type.resultInputs){
                                        type.resultInputs = [{value:''}];
                                    }
                                    if(!type.resultInputs && type.value){
                                        type.resultInputs = [];
                                        type.resultInputs.push({value:type.value});
                                    }
                            }
                            return <tr>
                                <td className="width-20">
                                    {type.name}
                                </td>
                                <td>
                                    {type.resultInputs.map(function (result, index) {
                                        return <span>
                                        <input
                                            type='text'
                                            className='input-sm grid-result-input grid-result-input-sm results-expanded'
                                            onClick={this.handleInputClick}
                                            value={result.value}
                                            onChange={this.handleChange.bind(null, type, index)}
                                        />
                                    </span>
                                    }.bind(this))
                                    }

                                </td>
                            </tr>
                        }.bind(this))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
});
;
var SmResultMarkets = React.createClass({
    mixins: [TranslateMixin],

    getInitialState: function () {
        return {
            data: [],
            headerInfo: {},
            selected: []
        }
    },

    handleGlobalClick: function handleGlobalClick () {
        $('#sm-results-markets-context').css({display: 'none'});
    },

    componentDidMount: function () {
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    },

    // Add event for global click when component is loaded
    componentWillMount: function () {
        window.addEventListener('click', this.handleGlobalClick);
    },

    componentWillUnmount: function componentWillUnmount () {
        window.removeEventListener('click', this.handleGlobalClick);
        window.removeEventListener('scroll', this.handleScroll);
    },

    setData: function (data, keepSelected) {
        this.stickyStartPosition = null;
        this.setState({
            data: data,
            selected: keepSelected ? this.state.selected : []
        });
    },

    setHeaderInfo: function (data) {
        this.setState({headerInfo: data});
    },

    handleOutcomeClick: function (outcome) {
        var index = this.state.selected.indexOf(outcome.eventMarketOutcomeId),
            selected = this.state.selected || [];

        if (index !== -1) {
            selected.splice(index, 1);
        } else {
            selected.push(outcome.eventMarketOutcomeId);
        }
        this.setState({selected: selected});
        this.oldSelectList = this.state.selected;
    },

    displayContextMenu: function (evt) {
        evt.preventDefault();
        var topDistance = (evt.clientY + 250 > window.innerHeight) ? evt.clientY - 250 : evt.clientY;
        $('#sm-results-markets-context').css({display: 'block', left: evt.clientX, top: topDistance});
    },

    handleContextMenuClick: function (data) {
        if (data.id === 'reverse') {
            this.reverseSelection();
            return;
        }
        this.props.setOutcomeDistributionState(data.id, this.state.selected);
        this.setState({
            selected: []
        })
    },

    reverseSelection: function () {
        var allOutcomes = [];

        this.state.data.map(function (market) {
            market.marketOutcomes.map(function (outcome) {
                allOutcomes.push(outcome.eventMarketOutcomeId);
            })
        });

        var newSelection = allOutcomes.filter(function (item) {
            return this.oldSelectList.indexOf(item) === -1;
        }.bind(this));

        this.setState({selected: newSelection});
        this.oldSelectList = newSelection;
    },

    handleScroll : function () {
        if (!this.table) {
            this.table = document.getElementById('sm-results-markets');
        }

        if (!this.table) return;

        this.table.style.top = document.documentElement.scrollTop > 90 ? document.documentElement.scrollTop - 90 + 'px' : '0px';
    },

    render: function () {
        var distributionClass = '',
            selectedClass = '';

       return(<div className="sm-res-details-wrap" id="sm-res-details-table-wrap">

           <div className="sm-res-details-header">
                <div className="sm-res-details-event">
                    {this.state.headerInfo.event ? this.state.headerInfo.event + ' | ' : null}
                    {this.state.headerInfo.date  ? this.state.headerInfo.date  + ' | ' : null}
                    {this.state.headerInfo.time  ? this.state.headerInfo.time  + ' | ' : null}</div>
                <div className="sm-res-details-tournament">
                    {this.state.headerInfo.sport      ? this.state.headerInfo.sport + ' | ' : null}
                    {this.state.headerInfo.category   ? this.state.headerInfo.category + ' | ' : null}
                    {this.state.headerInfo.tournament ? this.state.headerInfo.tournament + ' | ' : null}</div>
           </div>

           <div className="sm-res-details-content" id="sm-res-details-content-table">
               <table className="table" onContextMenu={this.displayContextMenu}>
                   <thead>
                   <tr>
                       <th>{}</th>
                       <th>{this.translate('sportsbook.market')}</th>
                       <th>SV</th>
                       <th>{this.translate('sportsbook.outcomes')}</th>
                   </tr>
                   </thead>

                   <tbody>
                   {
                       this.state.data.map(function (market) {
                           var specialValue = '',
                               distributionStatusClassName = ' markets-distribution-status distribution-status distribution-status-' + market.distributionStatus;

                           var competitorName = '';
                           if(market.eventMarketCompetitors && market.eventMarketCompetitors.length) {
                               market.eventMarketCompetitors.forEach(function (competitor, index) {
                                    competitorName += (index > 0 ? ' - ' : '') + competitor.playerName + ' (' + competitor.teamName + ')';
                               });
                           }

                           if(market.marketSpecialValues){
                               market.marketSpecialValues.forEach(function (special) {
                                   if(special.eventMarketSpecialValue){
                                       specialValue += special.eventMarketSpecialValue + '/';
                                   }
                               });
                           }
                           if(specialValue !== '' && specialValue.charAt(specialValue.length - 1) === '/') {
                               specialValue = specialValue.substring(0, specialValue.length - 1);
                           }
                           specialValue = specialValue !== '' ? specialValue : '*';
                           return (<tr>
                               <td>
                                   <div className='cell-icon'>
                                       <i className={distributionStatusClassName}>{}</i>
                                   </div>
                               </td>
                               <td>{market.marketName + (competitorName ? ' - ' + competitorName : '')}</td>
                               <td>{specialValue}</td>
                               <td>
                                   {
                                       market.marketOutcomes.map(function (outcome) {
                                           distributionClass = 'sm-res-content-outcome-' + outcome.eventMarketOutcomeResolutinStatus;
                                           selectedClass = this.state.selected.indexOf(outcome.eventMarketOutcomeId) !== -1 ? ' selected' : '';

                                           return (<div
                                               onClick={this.handleOutcomeClick.bind(this, outcome)}
                                               className={'sm-res-content-outcome ' + distributionClass + selectedClass}>
                                               <span className="sm-res-content-outcome-name">{outcome.marketOutcomeName}</span>
                                               <span className="sm-res-content-outcome-odd">{outcome.eventMarketOutcomeOdd}</span>
                                           </div>)
                                       }.bind(this))
                                   }
                               </td>
                           </tr>)
                       }.bind(this))
                   }
                   </tbody>

               </table>
               <ul className='react-context-menu context-menu dropdown-menu' id='sm-results-markets-context'>
                   {
                       this.props.contextMenuOptions.map( function(action) {
                           return <li onClick={this.handleContextMenuClick.bind(this, action)}>
                                   <i className={action.class}>{}</i>
                                   {action.displayName}
                               </li>
                       }.bind(this))
                   }
               </ul>
           </div>



       </div>);
    }
});;
var SmResultsTable = React.createClass({
    mixins: [DistributionSelectionChecker],
    inputRefs: {},
    shiftPressed: false,
    ctrlPressed: false,
    lastSelectedIndex: 0,
    tableConfig: {},

    getInitialState: function () {
        return {
            data: null,
            selectedFilters: null,
            expandedRow: null,
            selectedRow: 0,
            selectedRows: [0],
            xIndex: 0,
            yIndex: 0,
            zIndex: 0,
            focusTable: true
        }
    },

    componentDidMount: function () {
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('click', this.handleGlobalClick);
    },

    componentWillUnmount: function () {
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('click', this.handleGlobalClick);
    },

    componentDidUpdate: function () {
        this.focusInput();
    },


    setData: function (data, selectedFilters, focusTable) {
        this.inputRefs = {};

        this.setState({
            data: data,
            selectedFilters: selectedFilters || this.state.selectedFilters,
            expandedRow: null,
            focusTable: focusTable
        });
    },

    handleGlobalClick: function(evt) {
        $('#sm-results-context-menu').css({display: 'none'});
    },

    handleInputClick: function () {
        this.props.handleResultInputClick();
    },

    handleSortAction: function (sort, direction) {
        this.props.updateFilters('sort', sort);
        this.props.updateFilters('direction', direction);
    },

    setPagination: function (filterKey, filterValue) {
        if (!filterKey || !filterValue) return;
        this.props.updateFilters(filterKey, filterValue);
    },

    handleKeyDown: function (e) {
        if (this.state.expandedRow !== null) return; // Ignore arrow navigation if events table is displayed

        var code = e.keyCode,
            index = this.state.selectedRow,
            data = this.state.data.data;

        if ([40, 38, 27].indexOf(code) === -1) return;

        var targetElement = document.getElementById('main-table-row-' + index);

        e.preventDefault();
        e.stopPropagation();

        // Esc pressed. Expand row.
        if (code === 27) {
            this.handleArrowClick(index);
            return;
        }

        switch (code) {
            case 40: index = (index === data.length - 1) ? 0 : index + 1;
                    if(window.innerHeight - targetElement.getBoundingClientRect().top < 100){
                        window.scrollBy(0, targetElement.offsetHeight);
                    }
                    break;
            case 38: index = (index === 0) ? data.length - 1 : index - 1;
                    if( targetElement.getBoundingClientRect().top < 150){
                        window.scrollBy(0, -50);
                    }
                    break;
        }
        // Handle arrow navigation
        if (code !== 13) {
            this.handleRowClick(index, false, 0, 0, false);
        }
    },

    checkPreviousIndex: function (index) {
        var data = this.state.data.data;
        for (var i = index - 1; i >= 0; i--) {
            if (data[i].results.length) {
                return i;
            }
        }

        // Iterate from end of data if we haven't found any index yet
        for (var i = data.length; i > index; i--) {
            if (data[i].results.length) {
                return i;
            }
        }

        return null;
    },

    checkNextIndex: function (index) {
        var nextIndex = null;
        for (var i = index + 1; i < this.state.data.data.length; i++) {
            if (this.state.data.data[i].results.length) {
                return i;
            }
        }
        // Iterate from beginning
        if (index !== 0 && nextIndex === null) {
            for (var i = 0; i < index; i++) {
                if (this.state.data.data[i].results.length) {
                    return i;
                }
            }
        }
        return nextIndex;
    },

    handleEnterKey: function (total, value) {
        var x = this.state.xIndex,
            y = this.state.yIndex,
            z = this.state.zIndex;

        var data = this.state.data;
        var result = data.data[x].results[y].value;
        var fullResult = result.split(':');
        fullResult[z] = value;
        data.data[x].results[y].value = fullResult.join(':');

        // Go to next row
        if ((total === 1 && z !== 0) || (total > 1 && y !== 0 && z !== 0)) {
            var selectedValues = {
                x: x,
                y: y,
                value: data.data[x].results[y].value
            };
            this.props.updateRowResults(data.data[x].eventId, data.data[x].results, selectedValues, data.data[x].eventName);
            var nextIndex = data.data.length - 1 === x ? 0 : x + 1;

            if (!data.data[nextIndex].results.length) {
                var nextAvailableIndex = this.checkNextIndex(x);
                if (nextAvailableIndex === null) return;
                nextIndex = nextAvailableIndex;
            }
            this.handleRowClick(nextIndex, data, 0, 0, true);
            return;
        }

        var selectedValues = {
            x: x,
            y: y,
            value: data.data[x].results[y].value
        };
        this.props.updateRowResults(data.data[x].eventId, data.data[x].results, selectedValues, data.data[x].eventName);

        // Handle input position
        if (total === 1) {
            if (z === 0) z++;
        } else {
            if (y === 0) {
                if (z === 0) {
                    z++
                } else {
                    y ++;
                    z = 0;
                }
            } else {
                if (z === 0) {
                    z++;
                }
            }
        }

        this.setState({
            xIndex: x,
            yIndex: y,
            zIndex: z,
            data: data
        });
    },

    handleShiftTabKey: function (total) {
        var x = this.state.xIndex,
            y = this.state.yIndex,
            z = this.state.zIndex,
            data = this.state.data;

        // Go to previous row
        if (y === 0 && z === 0) {
            // If currently first row is selected, navigate to last row in table
            var previousIndex = x === 0 ? data.data.length - 1 : x - 1;

            if (!data.data[previousIndex].results.length) {
                var previousAvailableIndex = this.checkPreviousIndex(x);
                if (previousAvailableIndex === null) return;
                previousIndex = previousAvailableIndex;
            }

            var previousYIndex = data.data[previousIndex].results.length - 1;

            this.handleRowClick(previousIndex, data, previousYIndex, 1, true);
            return;
        }

        if (total === 1) {
            z = 0;
        } else {
            if (y === 0) {
                z--;
            } else if (y === 1 && z !== 0) {
                z--;
            } else if (y === 1 && z === 0) {
                y--;
                z = 1;
            }
        }

        this.setState({
            xIndex: x,
            yIndex: y,
            zIndex: z
        });
    },

    handleTabKey: function (total) {
        if (this.shiftPressed) {
            this.handleShiftTabKey(total);
            return;
        }
        var x = this.state.xIndex,
            y = this.state.yIndex,
            z = this.state.zIndex,
            data = this.state.data;

        // Go to next row
        if ((total === 1 && z !== 0) || (total > 1 && y !== 0 && z !== 0)) {
            var nextIndex = data.data.length - 1 === x ? 0 : x + 1;

            if (!data.data[nextIndex].results.length) {
                var nextAvailableIndex = this.checkNextIndex(x);
                if (nextAvailableIndex === null) return;
                nextIndex = nextAvailableIndex;
            }
            this.handleRowClick(nextIndex, data, 0, 0, true);
            return;
        }
        // Handle input position
        if (total === 1) {
            if (z === 0) z++;
        } else {
            if (y === 0) {
                if (z === 0) {
                    z++
                } else {
                    y ++;
                    z = 0;
                }
            } else {
                if (z === 0) {
                    z++;
                }
            }
        }

        this.setState({
            xIndex: x,
            yIndex: y,
            zIndex: z,
            data: data
        });
    },

    handleInputClick: function(values) {
        this.props.handleResultInputClick();
        this.setState({
            selectedRows: [values.x],
            selectedRow: values.x,
            xIndex: values.x,
            yIndex: values.y,
            zIndex: values.z,
            focusTable: true
        })
    },

    focusInput: function () {
        if (!this.state.focusTable) return;

        var x = this.state.xIndex,
            y = this.state.yIndex,
            z = this.state.zIndex,
            input = this.inputRefs['sm-result-row-' + x + '-' + y + '-' + z];

        if (input) {
            ReactDOM.findDOMNode(input).focus();
        }
    },

    handleArrowClick: function (index) {
        var data = this.state.data.data[index];
        this.setState({
            expandedRow: index
        });
        this.props.expandRow(data, index);
    },

    handleContextMenu: function(evt) {
        evt.preventDefault();
        $('#sm-results-context-menu').css({display: 'block', left: evt.pageX - 4, top: evt.pageY - 90});
    },

    resolveContextMenuAction: function (id) {
        this.props.contextMenuAction(this.state.selectedRows, id);
    },

    handleRowClick: function (index, newData, y, z, evt) {
        var shiftKey = evt ? evt.shiftKey : false,
            ctrlKey = evt ? evt.ctrlKey : false;

        var selectedList = this.checkSelection(this.state.selectedRows, shiftKey, ctrlKey, index, this.lastSelectedIndex);

        var data = this.state.data.data[index];
        this.setState({
            selectedRow: index,
            selectedRows: selectedList,
            xIndex: index,
            yIndex: y,
            zIndex: z,
            data: newData ? newData : this.state.data
        });
        this.lastSelectedIndex = index;
        this.props.handleRowClick(data, index);
    },

    shiftPressedChanged: function (value) {
        this.shiftPressed = value;
    },

    render: function () {
        if (!this.state.data) return null;
        var data = this.state.data,
            tableRows = data.data,
            titles = data.titles || {},
            columns = data.localColumns ? data.localColumns : data.columns,
            selectedFilters = this.state.selectedFilters || {},
            config = {columnSaveKey: 'sm-results-table'};

        return (
            <div>
                <table className="table main-table sm-table" id="react-table">
                    <thead>
                        <tr>
                            <th className='th-column-select'></th>
                            <SmEventsHeaderCell id="eventId" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="sportIcon" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="sportId" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="isoCode" className="center-align" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="categoryName" className="center-align" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="tournamentName" className="center-align" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="tournamentShortName" className="center-align" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="displayIds" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="eventName" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="startAt" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="lastsFrom" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <SmEventsHeaderCell id="lastsTo" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>
                            <th className='th-column-select'></th>
                            <SmEventsHeaderCell id="results" columns={columns} titles={titles} selected={selectedFilters} sortAction={this.handleSortAction}/>

                            <th className="th-column-select">
                                <ColumnSelectionComponent
                                    updateFilters   = {this.props.updateColumnVisibility}
                                    setVisibility   = {this.props.updateColumnVisibility}
                                    data            = {titles}
                                    columns         = {columns}
                                    config          = {config}
                                    key             = 'column-select'
                                />
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                    {
                        tableRows && tableRows.map(function (row, index) {
                            return(
                                <tr onClick={this.handleRowClick.bind(this, index, false, 0, 0)}
                                    onContextMenu={this.handleContextMenu}
                                    id={'main-table-row-' + index}
                                    className={this.state.selectedRows.indexOf(index) !== -1 ? 'selected' : ''}>
                                    <td onClick={this.handleArrowClick.bind(this, index)}>
                                        <i className={"n-i " + (this.state.expandedRow === index ? ' n-i-arrow-up-a' : ' n-i-arrow-down-a')}>{}</i>
                                    </td>
                                    {columns.eventId.visibility && <td>{row.eventId}</td>}
                                    {columns.sportIcon.visibility && <td className="sm-events-empty-cell center-align">
                                        <div className="cell-icon">
                                            <i className={'n-i n-i-' + (row.sportIcon ? row.sportIcon.toLowerCase() : '')}>{}</i>
                                        </div>
                                    </td>}
                                    {columns.sportId.visibility && <td> {row.sportId} </td>}
                                    {columns.isoCode.visibility && <td className="sm-events-empty-cell center-align">
                                        <div>
                                            <i className={'flag flag-' + row.isoCode}>{}</i>
                                        </div>
                                    </td>}
                                    {columns.categoryName.visibility && <td>{row.categoryName}</td>}
                                    {columns.tournamentName.visibility && <td>{row.tournamentName}</td>}
                                    {columns.tournamentShortName.visibility && <td>{row.tournamentShortName}</td>}
                                    {columns.displayIds.visibility && <td>{row.displayIds}</td>}
                                    {columns.eventName.visibility && <td>{row.eventName}</td>}
                                    {columns.startAt.visibility && <td>{row.startAt}</td>}
                                    {columns.lastsFrom.visibility && <td>{row.lastsFrom}</td>}
                                    {columns.lastsTo.visibility && <td>{row.lastsTo}</td>}
                                    {columns.distributionStatus.visibility && <td>
                                        <i className={'distribution-status tournament-distribution-status distribution-status-' + row.distributionStatus}>{}</i>
                                    </td>}
                                    {columns.results.visibility && <td>
                                        <div className="sm-results-value-row">
                                            {row.results.map(function(result, resultIndex) {
                                                if (resultIndex > 1) return null;

                                                var values = result.value.split(':'),
                                                    firstValue = '',
                                                    secondValue = '';
                                                if (values.length > 1) {
                                                    firstValue = values[0];
                                                    secondValue = values[1];
                                                }
                                                return <div className='sm-results-value'>
                                                    <div className='sm-results-type'>{result.short_name}</div>
                                                    <SmResultValueInput
                                                        key = {'sm-result-row-1-' + index + '-' + resultIndex}
                                                        ref = {function(node) {this.inputRefs['sm-result-row-' + index +'-' + resultIndex + '-' + 0] = node;}.bind(this)}
                                                        total = {row.results.length}
                                                        xIndex = {index}
                                                        yIndex = {resultIndex}
                                                        zIndex = {0}
                                                        updateResultValue =  {this.props.updateResultValue}
                                                        handleEnterKey = {this.handleEnterKey}
                                                        handleTabKey = {this.handleTabKey}
                                                        handleClick = {this.handleInputClick}
                                                        shiftPressedChanged = {this.shiftPressedChanged}
                                                        value={firstValue} />
                                                    <SmResultValueInput
                                                        key = {'sm-result-row-2-' + index + '-' + resultIndex}
                                                        ref = {function(node) {this.inputRefs['sm-result-row-' + index +'-' + resultIndex + '-' + 1] = node;}.bind(this)}
                                                        total = {row.results.length}
                                                        xIndex = {index}
                                                        yIndex = {resultIndex}
                                                        zIndex = {1}
                                                        updateResultValue =  {this.props.updateResultValue}
                                                        handleEnterKey = {this.handleEnterKey}
                                                        handleTabKey = {this.handleTabKey}
                                                        handleClick = {this.handleInputClick}
                                                        shiftPressedChanged = {this.shiftPressedChanged}
                                                        value={secondValue}/>
                                                </div>

                                            }.bind(this))}
                                        </div>
                                    </td>}
                                    <td></td>
                                </tr>
                            )
                        }.bind(this))
                    }
                    </tbody>

                    <tfoot>
                    <tr>
                        <td colSpan="40">
                            <Pagination
                                data                = {data.pagination}
                                updateFilters       = {this.setPagination}
                                updateLocationFilter= {this.setPagination}
                                config              = {this.tableConfig}
                            />
                        </td>
                    </tr>
                    </tfoot>
                </table>
                <ContextMenu idValue={'sm-results-context-menu'} resolveContextMenuAction={this.resolveContextMenuAction} rcActions={this.props.contextMenuItems} />
            </div>

        )
    }
});

var SmResultValueInput = React.createClass({
    getInitialState: function() {
        return {
            value: this.props.value
        }
    },
    componentWillReceiveProps: function(newProps) {
        this.setState({
            value: newProps.value
        })
    },
    handleChange: function(evt) {
        this.setState({
            value: evt.target.value
        });
        this.props.updateResultValue({
            x: this.props.xIndex,
            y: this.props.yIndex,
            z: this.props.zIndex,
            value: evt.target.value
        })
    },
    handleClick: function(evt) {
        evt.stopPropagation();
        this.props.handleClick({
            x: this.props.xIndex,
            y: this.props.yIndex,
            z: this.props.zIndex
        });
    },
    handleFocus: function(evt) {
        evt.target.select();
    },
    handleKeyUp: function(evt) {
        if (evt.keyCode === 16) {
            this.props.shiftPressedChanged(false);
        }
    },
    handleKeyDown: function(evt) {
        var code = evt.keyCode;

        switch(code) {
            case 13: {
                evt.preventDefault();
                this.props.handleEnterKey(this.props.total, this.state.value);
            } break;
            case 9: {
                evt.preventDefault();
                this.props.handleTabKey(this.props.total);
            } break;
            case 16: {
                this.props.shiftPressedChanged(true);
            } break;
            default: return null;
        }
    },
    render: function () {
        return <input type="text"
                    className='sm-result-value-input'
                    onChange={this.handleChange}
                    onClick={this.handleClick}
                    onKeyDown={this.handleKeyDown}
                    onKeyUp={this.handleKeyUp}
                    onFocus={this.handleFocus}
                    value={this.state.value}/>
    }
});
;
var GamePatternListValues = React.createClass({
    getInitialState: function () {
        return {
            searchField:''
        }
    },

    handleSelectClick: function (value, e) {
        this.props.handleSelectClick(value.id, e.target.checked);
    },

    handleArrowClick: function (value) {
        var arrowPosition = event.target.getBoundingClientRect();
        var top = (window.innerHeight - arrowPosition.top) < 300 ? arrowPosition.top - (300 - (window.innerHeight - arrowPosition.top)) : arrowPosition.top;
        if(this.props.setMarketsPosition){
            this.props.setMarketsPosition(top, arrowPosition.left-235);
        }

        if (!this.props.handleArrowClick) return;
        this.props.handleArrowClick(value);
    },

    searchList: function(event){
        this.setState({ searchField: event.target.value });
        this.props.reRender();
    },

    render: function () {
        if (!this.props.data.length) {
            return <div className="nano-content"></div>;
        }

        var data = this.props.data,
            selected,
            values;

        selected = this.props.selected.map(function (value) {
            return value.id;
        });

        values = data.map(function (value) {
            if(value.name.toLowerCase().indexOf(this.state.searchField.toLowerCase())>-1){
                return (<div className="col-12">
                {
                    function () {
                        if (!this.props.displayArrows) return null;
                        return (<i className="n-i n-i-arrow-left-b col-2" onClick={this.handleArrowClick.bind(this, value)}></i>);
                    }.bind(this)()
                }

                <label className={this.props.displayArrows ? 'col-10' : 'col-12'} htmlFor={this.props.uniqueKey + value.id} onClick={this.stopProp}>
                    {value.name}
                    <input
                        id={this.props.uniqueKey + value.id}
                        type="checkbox"
                        checked={selected.indexOf(value.id) !== -1}
                        onChange={this.handleSelectClick.bind(this, value)}
                        className="normal-checkbox"/>
                    <i></i>
                </label>
            </div>)
            }
        }.bind(this));

        return (<div className="punter-content nano-content">
            {this.props.data.length>5 && <input value={this.state.searchField} type="text" placeholder="Search" onChange={this.searchList}/> }
            {values}
        </div>);
    }
});

var GamePattern = React.createClass({
    getInitialState: function () {
        return {
            sports   : [],
            markets  : [],
            selectedSports: [],
            selectedMarkets: [],
            selected : {
                sports: [],
                markets: []
            }
        }
    },

    handleGlobalClick: function handleGlobalClick () {
        this.setState({
            sports  : [],
            markets : []
        });
    },

    componentWillMount: function () {
        document.addEventListener('click', this.handleGlobalClick, false);
    },

    componentWillUnmount: function componentWillUnmount () {
        document.removeEventListener('click', this.handleGlobalClick, false);
    },

    setArrowPosition:  function(top, left) {
        this.setState({
            marketLeft : left,
            marketTop  : top,
        });
    },

    componentDidUpdate: function () {
        $('.nano').nanoScroller();
    },

    setSportsData: function (data, selected) {
        this.setState({sports: data, markets: [], selectedSports: selected});
    },
    
    setMarketsData: function (data, selected) {
        this.setState({markets: data, selectedMarkets: selected});
    },

    setSelectedSportValues: function (selected) {
        this.setState({selectedSports: selected});
    },

    setSelectedMarketValues: function (selected) {
        this.setState({selectedMarkets: selected});
    },

    displaySports: function (evt) {
        if(evt.target.classList.contains('active')){
            this.handleGlobalClick();
            return;
        }
        evt.stopPropagation();
        this.props.loadSports();
    },

    stopProp: function (e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    },

    reRenderDropdown :  function(){
        this.forceUpdate();
    },

    render: function () {
        var sports          = this.state.sports || [],
            markets         = this.state.markets || [],
            selectedSports  = this.state.selectedSports || [],
            selectedMarkets = this.state.selectedMarkets || [],
            marketsCount    = 0,
            selectInfo      = 'Select value';

        selectedSports.map(function (value) {
            if (value.markets)
                marketsCount += value.markets.length;
        });

        if (marketsCount > 0) {
            selectInfo = marketsCount + ' selected';
        }


        return(<div>
            <div className={'col-12 punter-display-name ' + (this.state.sports.length ? 'active' : '')} onClick={this.displaySports}>
                {selectInfo}
                <div className={'punter-sports-list' + (sports.length ? ' nano' : '')} onClick={this.stopProp}>
                    <GamePatternListValues
                        data = {sports}
                        handleSelectClick = {this.props.selectSport}
                        handleArrowClick = {this.props.loadMarkets}
                        uniqueKey = 'sport-item'
                        displayArrows = {true}
                        selected = {selectedSports}
                        reRender={this.reRenderDropdown}
                        setMarketsPosition={this.setArrowPosition}/>
                </div>

                <div className={'punter-markets-list' +  (markets.length ? ' nano' : '')} 
                     onClick={this.stopProp}
                     style={{ top: this.state.marketTop + 'px', left: this.state.marketLeft + 'px'}}>
                    <GamePatternListValues
                        data = {markets}
                        handleSelectClick = {this.props.selectMarket}
                        uniqueKey = 'market-item'
                        displayArrows = {false}
                        selected = {selectedMarkets}
                        reRender={this.reRenderDropdown}/>
                </div>
            </div>
        </div>)
    }
});
